import React from "react"
import * as Flags from "country-flag-icons/react/3x2"

import NoFlag from "../assets/noFlag.svg?react"

const flags = {...Flags} as Record<string, Flags.FlagComponent>

export const Flag: React.FC<{code: keyof typeof Flags | string} & React.ComponentProps<Flags.FlagComponent>> = ({
  code,
  ...props
}) => {
  const countryCode = code.toUpperCase() ?? ""

  const Flag = countryCode in flags ? flags[countryCode] : (NoFlag as Flags.FlagComponent)

  return <Flag {...props} />
}
