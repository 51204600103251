import React from "react"
import {useQuery} from "@tanstack/react-query"
import {isAxiosError} from "axios"

import {fetchUserSettings} from "../queries/user.ts"
import {RouterError} from "../RouterError"
import api, {queryKey, rollbar} from "../services"

const isMaintenanceOverlayDisabled = true

export const MaintenanceChecker: React.FC<React.PropsWithChildren> = ({children}) => {
  const [isMaintenance, setIsMaintenance] = React.useState(false)
  useQuery({
    queryKey: queryKey.maintenanceCheck,
    queryFn: fetchUserSettings,
    enabled: isMaintenance,
    refetchInterval: 30_000,
    retry: false,
    throwOnError: false,
  })

  React.useEffect(() => {
    const interceptor = api.instance.interceptors.response.use(
      response => {
        setIsMaintenance(wasMaintenance => {
          if (wasMaintenance) {
            window.location.reload()
          }

          // change to `return true` if you wish to hide the overlay instead of refreshing the page
          return wasMaintenance
        })
        return response
      },
      error => {
        setIsMaintenance(wasMaintenance => {
          // When Heroku is put into maintenance mode, CORS fails and Axios does not assign an error code to the error,
          // just a generic "Network Error" message.
          // This, however is also produced when the browser is offline, so we check with navigator.onLine.
          const is503 =
            isAxiosError(error) &&
            (error.response?.status === 503 || (error.message === "Network Error" && navigator.onLine))

          if (wasMaintenance && !is503) {
            window.location.reload()
          }

          if (is503 && !wasMaintenance) {
            rollbar.warning(
              "Maintenance overlay triggered",
              error as Error,
              error instanceof Object && error !== null ? {...error} : undefined
            )
          }

          // change to `return is503` if you wish to hide the overlay instead of refreshing the page
          return is503 || wasMaintenance
        })

        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        return Promise.reject(error)
      }
    )

    return () => {
      api.instance.interceptors.response.eject(interceptor)
    }
  }, [])

  if (isMaintenance && !isMaintenanceOverlayDisabled) {
    return <RouterError maintenance />
  }

  return <>{children}</>
}
