import React from "react"
import {twMerge} from "tailwind-merge"

export type TBadgeColor = "blue" | "light-blue" | "mid-blue" | "cyan" | "grey" | "green" | "red" | "yellow" | "custom"

export const Badge: React.FC<{
  color: TBadgeColor
  children?: React.ReactNode
  className?: string
}> = ({color, className, children}) => {
  return (
    <div
      className={twMerge(
        "inline-block h-max whitespace-nowrap rounded-full px-3.5 py-2 text-center text-sm font-medium leading-none",
        color === "blue" && "bg-cr-blue text-cr-white",
        color === "mid-blue" && "bg-cr-blue-mid text-cr-white",
        color === "light-blue" && "bg-cr-blue-mid-light text-cr-blue",
        color === "cyan" && "bg-cr-cyan text-cr-black",
        color === "grey" && "bg-cr-grey-15 text-cr-grey-80",
        color === "green" && "bg-cr-green-light text-cr-green",
        color === "red" && "bg-cr-red-light text-cr-red",
        color === "yellow" && "bg-cr-yellow-light text-cr-yellow",
        className
      )}
      data-testid={"badge"}
    >
      {children}
    </div>
  )
}
