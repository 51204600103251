import React from "react"

import {getCurrentLanguage} from "../../../../../i18n.ts"

export const ActivityDayTitle: React.FC<{date: Date}> = ({date}) => {
  return (
    <div className={"relative isolate flex items-center justify-center py-2"}>
      <h3
        data-testid={"ActivityDay"}
        className={"z-10 rounded-full border border-cr-blue-mid-light bg-cr-white px-20 py-1"}
      >
        {date.toLocaleString(getCurrentLanguage(), {
          month: "long",
          year: "numeric",
          day: "numeric",
        })}
      </h3>
      <div className={"absolute h-px w-full bg-cr-blue-mid-light"} />
    </div>
  )
}
