import React from "react"

import {GenericErrorAlert} from "../../components/Alert"
import {LayoutBlock} from "../../components/Layout/LayoutBlock"
import {Loading} from "../../components/Loading"
import {SellingsBlockBadge} from "../../components/SellingsBlock"
import {useReportsSalesCycleQuery} from "../../queries/reports"
import {useNumParam} from "../../utils/hooks"
import {Reports} from "./index"
import {SuperadminSentAlert} from "./SuperadminSentAlert.tsx"

export const AdminReports: React.FC = () => {
  const salesCycleId = useNumParam("salesCycleId")

  const {data, error, refetch} = useReportsSalesCycleQuery(salesCycleId)

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data) {
    return <Loading size={"xl"} />
  }

  return (
    <div className={"mb-8 flex grow flex-col"}>
      <LayoutBlock outerClassName={"pt-16"} innerClassName={"flex flex-col gap-9"}>
        <SuperadminSentAlert />

        <div className={"flex items-end justify-between gap-8"}>
          <div className={"flex items-baseline gap-4"}>
            <h1 className={"text-5xl font-semibold text-cr-blue"}>{data.name}</h1>
            <h2>({data.company_name})</h2>
          </div>
          <SellingsBlockBadge isActive={true} status={data.status} />
        </div>
      </LayoutBlock>

      <Reports />
    </div>
  )
}
