import React from "react"
import {CheckIcon} from "@heroicons/react/24/solid"
import {useQuery} from "@tanstack/react-query"

import {i18n} from "../../i18n.ts"
import {useUserSettingsOrLogout} from "../../queries/user.ts"
import api, {queryKey} from "../../services"
import {ACommissionTypes} from "../../services/types.generated.ts"
import {commonTransComponents} from "../../utils/i18n.tsx"

export const useSalesPersonDashboardQuery = () => {
  const {user} = useUserSettingsOrLogout()

  return useQuery({queryKey: queryKey.salesDashboard(user.id), queryFn: () => api.salesPeople.dashboardDetail(user.id)})
}

export const getCommissionValueTranslated = (commisionType: ACommissionTypes, amount: string): string => {
  switch (commisionType) {
    case ACommissionTypes.OneOff:
      return i18n.t("SalespersonDashboard_NewOffer_Commission_OneOff", {amount})
    case ACommissionTypes.Recurring:
      return i18n.t("SalespersonDashboard_NewOffer_Commission_Recurring", {amount})
  }
}

export const transComponents = {
  ...commonTransComponents,
  bullet: <CheckIcon className={"size-6 translate-y-1.5 text-cr-blue"} />,
} as const
