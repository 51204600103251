import React from "react"

import {i18n} from "../../../../../i18n.ts"
import {AActivityFeed, AActivityFeedNotificationType} from "../../../../../services/types.generated.ts"

const notificationTypeTranslationMap = {
  weekly_update: i18n.t("SellingDetails_News_ActivityFeed_CustomType_WeeklyUpdate"),
  database_status: i18n.t("SellingDetails_News_ActivityFeed_CustomType_DatabaseStatus"),
  matching_in_progress: i18n.t("SellingDetails_News_ActivityFeed_CustomType_MatchingInProgress"),
  sales_talent_education: i18n.t("SellingDetails_News_ActivityFeed_CustomType_SalesTalentEducation"),
  status_meeting: i18n.t("SellingDetails_News_ActivityFeed_CustomType_StatusMeeting"),
  sales_outreach_plan_update: i18n.t("SellingDetails_News_ActivityFeed_CustomType_SalesOutreachPlanUpdate"),
  quality_check: i18n.t("SellingDetails_News_ActivityFeed_CustomType_QualityCheck"),
  account_setup: i18n.t("SellingDetails_News_ActivityFeed_CustomType_AccountSetup"),
  sales_assistant_updates: i18n.t("SellingDetails_News_ActivityFeed_CustomType_SalesAssistantUpdates"),
} satisfies {[type in AActivityFeedNotificationType]: string}

export const ActivityBodyStringNotification: React.FC<{activity: AActivityFeed}> = ({activity}) => {
  return (
    <div className={"flex flex-col gap-2"}>
      <h4 className={"font-semibold"}>{notificationTypeTranslationMap[activity.string_notification_type]}</h4>

      <div className={"whitespace-pre-wrap"}>{activity.body}</div>
    </div>
  )
}
