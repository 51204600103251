import React from "react"

import Modal from "./Modal.tsx"

export const VideoModal: React.FC<{isOpen?: boolean; youtubeId?: string | null; onClose: () => void}> = ({
  isOpen,
  youtubeId,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen ?? !!youtubeId} onClose={onClose} size={"xl"}>
      <iframe
        data-testid={"video"}
        src={`https://www.youtube.com/embed/${youtubeId}?rel=0`}
        className={"mt-7 aspect-video w-full rounded-lg"}
        allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"}
        allowFullScreen
      />
    </Modal>
  )
}
