import React from "react"
import {twMerge} from "tailwind-merge"

import {Logo} from "../../../components/HeroLayout.tsx"
import {Footer} from "../../../components/Layout/Footer.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {StepCounter} from "../../../components/StepCounter.tsx"
import {FinishLater} from "./FinishLater.tsx"

export const OnboardingLayout: React.FC<{
  step?: number
  total?: number
  title?: React.ReactNode
  subtitle?: React.ReactNode
  leftAction?: React.ReactNode
  rightAction?: React.ReactNode
  children?: React.ReactNode
  white?: boolean
  disableFinishLater?: boolean
}> = ({title, subtitle, disableFinishLater, step, total, white, leftAction, rightAction, children}) => {
  return (
    <div className={twMerge("flex grow flex-col justify-between", white ? "bg-cr-white" : "bg-cr-blue-super-light")}>
      <LayoutBlock outerClassName={"py-16 pb-8"} innerClassName={"flex flex-col gap-10 items-center"}>
        <div className={"flex items-center justify-between gap-10 self-stretch"}>
          <Logo className={"text-cr-blue"} disabled />
          {!disableFinishLater && <FinishLater />}
        </div>
        {step && total && <StepCounter total={total} step={step} />}
        {title && <h1 className={"text-5xl font-semibold text-cr-blue"}>{title}</h1>}
        {subtitle && <h2 className={"text-lg"}>{subtitle}</h2>}
      </LayoutBlock>
      <div className={"mb-28 flex grow flex-col"}>{children}</div>
      {(leftAction || rightAction) && (
        <LayoutBlock
          outerClassName={"border-t border-t-cr-blue-light py-10 w-full bg-inherit sticky bottom-0"}
          innerClassName={twMerge("flex justify-between", rightAction && "flex-row-reverse")}
        >
          {rightAction}
          {leftAction}
        </LayoutBlock>
      )}
      <Footer />
    </div>
  )
}
