import React from "react"
import {useTranslation} from "react-i18next"
import {PencilSquareIcon} from "@heroicons/react/24/outline"

import {GenericErrorAlert} from "../../components/Alert"
import {Link} from "../../components/Link"
import {Loading} from "../../components/Loading"
import {ProfileImage} from "../../components/ProfileImage.tsx"
import {TerseFormLayout} from "../../components/TerseFormLayout"
import {useSalespersonPublicQuery} from "../../queries/salespeople"
import {useUserSettingsOrLogout} from "../../queries/user.ts"
import {AUserTypes} from "../../services/types.generated"
import {useDocumentTitle, useLoadPicture, useNumParam} from "../../utils/hooks"
import {enumTranslKey} from "../../utils/i18n"

export const SalespersonPreview: React.FC = () => {
  const {t} = useTranslation()

  const salespersonId = useNumParam("salespersonId")

  const {error, data, refetch} = useSalespersonPublicQuery(salespersonId)
  const {user} = useUserSettingsOrLogout()

  useDocumentTitle(data ? data.first_name : t("T_Salesperson profile"))

  const canEdit = user.type === AUserTypes.SalesPerson && user.id === salespersonId

  const profilePictureUrl = data?.profile_picture_url ?? ""

  const {isLoading} = useLoadPicture(profilePictureUrl)

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data) {
    return <Loading size={"xl"} />
  }

  return (
    <div className={"mt-8 flex justify-center p-10 pt-0"}>
      <div className={"xl:w-screen-xl flex max-w-full grow flex-col items-center gap-8 xl:max-w-screen-xl"}>
        <div className={"my-8 flex w-full gap-8 break-all"}>
          {isLoading && profilePictureUrl ? (
            <div className={"size-40 shrink-0 rounded-full bg-cr-grey-5"}>
              <Loading size={"xl"} delayShow={false} />
            </div>
          ) : (
            <ProfileImage className={"size-40 shrink-0"} src={profilePictureUrl} />
          )}
          <div className={"flex grow flex-col gap-2"}>
            <span className={"text-sm text-cr-grey-50"}>{t("T_Salesperson profile")}</span>
            <h1 className={"text-2xl font-semibold"}>{data.first_name}</h1>
          </div>
        </div>

        <div className={"card w-full"}>
          <TerseFormLayout>
            <TerseFormLayout.Label>{t("T_Years of experience")}</TerseFormLayout.Label>
            <div>{data.years_of_experience}</div>

            <TerseFormLayout.Separator />

            <TerseFormLayout.Label>{t("T_Country")}</TerseFormLayout.Label>
            <div>{data.country?.name}</div>

            <TerseFormLayout.Separator />

            <TerseFormLayout.Label>{t("T_Native language")}</TerseFormLayout.Label>
            <div>{data.native_language?.name}</div>

            <TerseFormLayout.Separator />

            <TerseFormLayout.Label>{t("T_Other languages spoken")}</TerseFormLayout.Label>
            <div>{data.spoken_languages?.map(l => l.name).join(", ")}</div>

            <TerseFormLayout.Separator />

            <TerseFormLayout.Label>{t("T_Segment")}</TerseFormLayout.Label>
            <div>{(data.segment as undefined | string[])?.map(s => t(enumTranslKey("Segment", s))).join(", ")}</div>

            <TerseFormLayout.Separator />

            <TerseFormLayout.Label>{t("T_About")}</TerseFormLayout.Label>
            <div className={"whitespace-pre-wrap"}>{data.about}</div>

            {canEdit && (
              <>
                <TerseFormLayout.Separator invisible />
                <div className={"col-start-2"}>
                  <Link
                    to={`/salesperson/${salespersonId}/profile`}
                    variant={"neutral"}
                    className={"inline-flex gap-2"}
                  >
                    <PencilSquareIcon className={"w-5"} /> {t("T_Change profile info")}
                  </Link>
                </div>
              </>
            )}
          </TerseFormLayout>
        </div>
      </div>
    </div>
  )
}
