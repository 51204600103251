import React from "react"
import {twMerge} from "tailwind-merge"

import {UnstyledLink} from "../../../components/Link"
import {Trend, TrendSkeleton} from "./Trend"

export const Metric: React.FC<{
  title: string
  value: number
  trend?: number | null
  onClick?: () => void
  selected: boolean
}> = ({title, value, trend, selected, onClick}) => {
  return (
    <UnstyledLink
      className={twMerge(
        "card-shadow flex min-w-48 flex-1 flex-col gap-2 p-6 transition-all",
        selected && "border-cr-blue",
        onClick && "cursor-pointer"
      )}
      onClick={onClick}
      role={"radio"}
      aria-checked={selected}
    >
      <h2 className={"text-sm font-semibold"}>{title}</h2>
      <h3 className={"text-2xl font-bold"}>{value}</h3>
      <Trend value={trend} />
    </UnstyledLink>
  )
}

export const MetricSkeleton: React.FC<{title: string}> = ({title}) => {
  return (
    <div
      className={twMerge("card-shadow flex min-w-48 flex-1 flex-col gap-2 p-6")}
      role={"radio"}
      data-testid={"loading"}
    >
      <h2 className={"text-sm font-semibold"}>{title}</h2>
      <h3 className={"w-20 rounded-full bg-cr-grey-15 text-2xl"}>&nbsp;</h3>
      <TrendSkeleton />
    </div>
  )
}
