import React from "react"
import {Trans, useTranslation} from "react-i18next"

import Button from "../../components/Button.tsx"
import {LayoutBlock} from "../../components/Layout/LayoutBlock.tsx"
import {LayoutTitle} from "../../components/LayoutTitle.tsx"
import {Link} from "../../components/Link.tsx"
import {getCurrentLanguage} from "../../i18n.ts"
import {commonTransComponents} from "../../utils/i18n.tsx"

const effectiveDate = new Date("2024-10-16")
const lastUpdatedDate = new Date("2024-10-16")

export const CookiesPage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <>
      <LayoutTitle
        header={t("CookiesPage_Title")}
        subheader={
          <div>
            {t("CookiesPage_EffectiveDate")}{" "}
            {effectiveDate.toLocaleString(getCurrentLanguage(), {
              dateStyle: "medium",
            })}
            <br />
            {t("CookiesPage_LastUpdated")}{" "}
            {lastUpdatedDate.toLocaleString(getCurrentLanguage(), {
              dateStyle: "medium",
            })}
          </div>
        }
      />

      <LayoutBlock outerClassName={"py-10"} innerClassName={"flex flex-col gap-4 items-start"}>
        <h3 className={"text-lg font-bold"}>{t("CookiesPage_WhatAreCookies_Title")}</h3>
        <Trans i18nKey={"CookiesPage_WhatAreCookies_Text"} components={commonTransComponents} />
        <h3 className={"text-lg font-bold"}>{t("CookiesPage_OurUse_Title")}</h3>
        <Trans i18nKey={"CookiesPage_OurUse_Text"} components={commonTransComponents} />
        <h3 className={"text-lg font-bold"}>{t("CookiesPage_List_Title")}</h3>
        <div className={"cky-audit-table-element"} />
        <h3 className={"text-lg font-bold"}>{t("CookiesPage_Preferences_Title")}</h3>
        <Button className={"cky-banner-element"}>{t("CookiesPage_Preferences_Button")}</Button>
        <Trans i18nKey={"CookiesPage_Preferences_Text"} components={commonTransComponents} />
        <div>{t("CookiesPage_Browser_Text")}</div>
        <BrowserLink browserName={t("CookiesPage_Browser_Chrome_Title")} url={t("CookiesPage_Browser_Chrome_Url")} />
        <BrowserLink browserName={t("CookiesPage_Browser_Safari_Title")} url={t("CookiesPage_Browser_Safari_Url")} />
        <BrowserLink browserName={t("CookiesPage_Browser_Firefox_Title")} url={t("CookiesPage_Browser_Firefox_Url")} />
        <BrowserLink browserName={t("CookiesPage_Browser_IE_Title")} url={t("CookiesPage_Browser_IE_Url")} />
        <div>{t("CookiesPage_Browser_Addendum")}</div>
      </LayoutBlock>
    </>
  )
}

export const BrowserLink: React.FC<{browserName: React.ReactNode; url: string}> = ({browserName, url}) => {
  return (
    <p>
      <h4 className={"inline font-bold"}>{browserName}:</h4>{" "}
      <Link target={"_blank"} to={url}>
        {url}
      </Link>
    </p>
  )
}
