import {useTranslation} from "react-i18next"

import {TextareaField} from "../../components/fields/Textarea.tsx"
import Tags from "../../components/formElements/Tags"
import {stepReferences} from "./stepsConfig.ts"

const References = () => {
  const {t} = useTranslation()

  return (
    <>
      <Tags
        name={"references"}
        label={stepReferences.name}
        legend={t("SalesStrategy_List companies you worked with")}
      />
      <TextareaField rows={4} label={t("SalesStrategy_LinksToArticles")} name={"articles_links"} />
    </>
  )
}

export default References
