import React from "react"
import {useTranslation} from "react-i18next"
import {ChevronRightIcon} from "@heroicons/react/20/solid"

import Button from "../../../../components/Button.tsx"
import {UnstyledLink} from "../../../../components/Link.tsx"
import {EFeature, EStep} from "./index.tsx"

export const InitialStep: React.FC<{
  onStepPick: (step: EStep) => void
  onClose: () => void
  enabledFeatures: EFeature[]
}> = ({onStepPick, onClose, enabledFeatures}) => {
  const {t} = useTranslation()

  const featureButtons = React.useMemo(() => {
    return {
      [EFeature.Single]: (
        <SourceButton
          key={EFeature.Single}
          onSelect={() => onStepPick(EStep.Single)}
          title={t("Prospects_ImportModal_SingleStep_Button_Title")}
          subtitle={t("Prospects_ImportModal_SingleStep_Button_Subtitle")}
        />
      ),
      [EFeature.XLS]: (
        <SourceButton
          key={EFeature.XLS}
          onSelect={() => onStepPick(EStep.XLS)}
          title={t("Prospects_ImportModal_UploadStep_Button_Title")}
          subtitle={t("Prospects_ImportModal_UploadStep_Button_Subtitle")}
        />
      ),
      [EFeature.Leads]: (
        <SourceButton
          key={EFeature.Leads}
          onSelect={() => onStepPick(EStep.Leads)}
          title={t("Prospects_ImportModal_LeadsStep_Button_Title")}
          subtitle={t("Prospects_ImportModal_LeadsStep_Button_Subtitle")}
        />
      ),
    } as const satisfies {[feature in EFeature]: React.ReactNode}
  }, [onStepPick, t])

  return (
    <div className={"flex flex-col gap-10"}>
      <div className={"flex flex-col gap-3"}>
        <h1 className={"text-2xl font-semibold"}>{t("Prospects_ImportModal_InitialStep_Title")}</h1>
        <p className={"text-cr-grey-50"}>{t("Prospects_ImportModal_InitialStep_Description")}</p>
      </div>

      <div className={"flex flex-col gap-4"}>{enabledFeatures.map(feature => featureButtons[feature])}</div>
      <div className={"flex justify-end"}>
        <Button variant={"outlined"} color={"gray"} onClick={onClose}>
          {t("T_Cancel")}
        </Button>
      </div>
    </div>
  )
}

export const SourceButton: React.FC<{onSelect: () => void; title: React.ReactNode; subtitle: React.ReactNode}> = ({
  onSelect,
  subtitle,
  title,
}) => {
  return (
    <UnstyledLink
      onClick={onSelect}
      className={
        "group flex cursor-pointer gap-8 rounded-lg border border-cr-grey-50 px-6 py-4 transition-all hover:border-cr-blue"
      }
    >
      <div className={"flex grow flex-col"}>
        <h3 className={"font-bold"}>{title}</h3>
        <span className={"text-cr-grey-50"}>{subtitle}</span>
      </div>
      <div
        className={"flex shrink-0 items-center justify-end text-cr-grey-65 transition-all group-hover:text-cr-black"}
      >
        <ChevronRightIcon className={"size-6"} />
      </div>
    </UnstyledLink>
  )
}
