import React from "react"
import {Trans} from "react-i18next"

import {Badge} from "../../../../../components/Badge.tsx"
import {AProspectActivity} from "../../../../../services/types.generated.ts"
import {commonTransComponents} from "../../../../../utils/i18n.tsx"
import {getOrganizationName} from "../utils.ts"

export const ActivityBodyLost: React.FC<{activity: AProspectActivity}> = ({activity}) => {
  return (
    <div>
      <Trans
        i18nKey={"SellingDetails_News_ActivityFeed_Lost"}
        components={{...commonTransComponents, badge: <Badge color={"red"} />}}
        values={{
          organization: getOrganizationName(activity),
        }}
      />
    </div>
  )
}
