import React from "react"

export const MetricCard: React.FC<{
  title: string
  value: string | number
  Icon: React.ComponentType<{className?: string}>
}> = ({title, value, Icon}) => {
  return (
    <div className={"card-shadow relative flex basis-[230px] flex-col gap-2 overflow-hidden p-6"}>
      <h3 className={"z-10 font-semibold"}>{title}</h3>
      <h4 className={"z-10 text-2xl font-bold"}>{value}</h4>
      <Icon className={"absolute -bottom-6 -right-2 z-0 size-28 stroke-1 text-cr-blue-light"} />
    </div>
  )
}
