import React from "react"
import {useTranslation} from "react-i18next"

import {TextareaField} from "../../components/fields/Textarea.tsx"
import {stepAdditionalInformation} from "./stepsConfig.ts"

export const AdditionalInformation: React.FC = () => {
  const {t} = useTranslation()

  return (
    <>
      <TextareaField
        rows={4}
        label={stepAdditionalInformation.name}
        legend={t("SalesStrategy_AdditionalInformationLegend")}
        name={"additional_strategy_description"}
      />
      <TextareaField rows={4} label={t("SalesStrategy_SalesScripts")} name={"sales_scripts"} />
    </>
  )
}
