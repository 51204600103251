import React from "react"
import {Trans, useTranslation} from "react-i18next"

import {FieldErrorMessage} from "../../../../components/fields/components.tsx"
import {InputField} from "../../../../components/fields/Input.tsx"

export const PasswordPage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <>
      <InputField
        name={"password"}
        type={"password"}
        Icon={false}
        variant={"medium"}
        label={t("Signup_Company_AccountCreatePage_Password")}
        hideError
      />
      <ul className={"my-2 list-inside list-disc text-xs text-cr-grey-30"}>
        <Trans i18nKey={"Signup_Company_AccountCreatePage_PasswordRequirements"} components={{bullet: <li />}} />
      </ul>
      <FieldErrorMessage name={"password"} reserveSpace />
    </>
  )
}
