import React from "react"
import {useTranslation} from "react-i18next"
import AutoSizer from "react-virtualized-auto-sizer"
import {linearGradientDef} from "@nivo/core"
import {Line, Serie, SliceTooltipProps} from "@nivo/line"

import {GenericErrorAlert} from "../../../../components/Alert"
import {Loading} from "../../../../components/Loading"
import {getCurrentLanguage} from "../../../../i18n"
import {
  TReportsMetric,
  useReportsActivityOccurrencesChartQuery,
  useReportsMetricsQuery,
} from "../../../../queries/reports"
import {AActivityEvents} from "../../../../services/types.generated"
import {getEvenlySteppedItems} from "../../../../utils/array"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {enumTranslKey} from "../../../../utils/i18n"
import {tailwindColors} from "../../../../utils/tailwind"
import {useCurrentAssignmentId, useCurrentSelectionState} from "../../hooks"
import {LegendItem} from "../DataLegend"
import {MetricGraphCard} from "./MetricGraphCard"

const activityMetric = {
  [AActivityEvents.CallingDone]: "calls_made",
  [AActivityEvents.EmailSent]: "emails_sent",
  [AActivityEvents.MeetingHappened]: "meetings_done",
} satisfies {[key in AActivityEvents]: TReportsMetric}

const MAX_TICK_COUNT = 7

export const ActivityOccurrencesGraphCard: React.FC<{activity: AActivityEvents}> = ({activity}) => {
  const {t} = useTranslation()

  const {
    value: {salesCycleId, iterationId},
  } = useCurrentSelectionState()
  const assignmentId = useCurrentAssignmentId()

  const {data, error, isFetching, isLoading, refetch} = useReportsActivityOccurrencesChartQuery(
    activity,
    salesCycleId,
    iterationId,
    assignmentId
  )
  const {data: metricsData} = useReportsMetricsQuery(salesCycleId, iterationId, assignmentId)

  const graphData = React.useMemo<Serie[]>(() => {
    return [
      {
        id: t(enumTranslKey("ReportsMetric", activityMetric[activity])),
        data:
          data?.map(datum => ({
            y: datum.occurrences,
            x: apiDateToJS(datum.to),
          })) ?? [],
      },
    ]
  }, [activity, data, t])

  const tickValues = React.useMemo(() => {
    return getEvenlySteppedItems(
      graphData[0].data.map(d => d.x),
      MAX_TICK_COUNT
    )
  }, [graphData])

  if (error) {
    return (
      <MetricGraphCard>
        <GenericErrorAlert retry={refetch} />
      </MetricGraphCard>
    )
  }

  return (
    <MetricGraphCard
      className={isFetching ? "pointer-events-none animate-pulse" : undefined}
      title={t(enumTranslKey("ReportsMetric", activityMetric[activity]))}
      total={metricsData?.counters[activityMetric[activity]]}
    >
      <div className={"flex h-full items-center justify-center"}>
        {isLoading ? (
          <Loading delayShow={false} />
        ) : graphData[0].data.length === 0 ? (
          <span className={"text-sm text-cr-grey-50"}>{t("Reports_NoData")}</span>
        ) : (
          <div className={"h-full w-full"} data-testid={"chart"}>
            <AutoSizer>
              {({width, height}) => (
                <Line
                  width={width}
                  height={height}
                  // Nivo currently has trouble rerendering the line when data changes,
                  // so we need to recreate the component to prevent bugs
                  key={graphData[0].data.map(datum => datum.y).join()}
                  data={graphData}
                  motionConfig={"stiff"}
                  margin={{left: 30, top: 20, bottom: 40, right: 20}}
                  axisLeft={{tickValues: 4, tickSize: 0}}
                  axisBottom={{
                    tickValues,
                    tickSize: 0,
                    tickRotation: -45,
                    tickPadding: 10,
                    format: (date: Date) => {
                      return date.toLocaleString(getCurrentLanguage(), {
                        day: "numeric",
                        month: "short",
                      })
                    },
                  }}
                  gridYValues={4}
                  curve={"basis"}
                  enableArea
                  enablePoints={false}
                  enableGridX={false}
                  theme={{
                    grid: {
                      line: {
                        stroke: tailwindColors["cr-grey"]["15"],
                        strokeWidth: 1,
                        strokeDasharray: "4 6",
                      },
                    },
                    crosshair: {
                      line: {stroke: tailwindColors["cr-blue"]["DEFAULT"], strokeWidth: 1, strokeDasharray: "4 6"},
                    },
                    text: {
                      fontFamily: "inherit",
                    },
                  }}
                  defs={[
                    linearGradientDef("area", [
                      {offset: 0, color: tailwindColors["cr-blue"]["mid"]},
                      {offset: 100, color: tailwindColors["cr-blue"]["mid"], opacity: 0},
                    ]),
                  ]}
                  fill={[{match: "*" as const, id: "area"}]}
                  colors={tailwindColors["cr-blue"]["DEFAULT"]}
                  enableSlices={"x"}
                  sliceTooltip={Tooltip}
                />
              )}
            </AutoSizer>
          </div>
        )}
      </div>
    </MetricGraphCard>
  )
}

export const Tooltip: React.FC<SliceTooltipProps> = ({
  slice: {
    points: [data],
  },
}) => {
  return (
    <div className={"card-shadow px-4 py-2"}>
      <LegendItem
        label={`${data.serieId} - ${
          data.data.x instanceof Date
            ? data.data.x.toLocaleString(getCurrentLanguage(), {
                day: "numeric",
                month: "long",
                year: "numeric",
              })
            : data.data.x
        }`}
        color={data.color}
        value={Number(data.data.y)}
      />
    </div>
  )
}
