import React from "react"

import {TOption} from "../../components/formElements/Dropdown.tsx"
import {TOrderBy} from "../../components/Table/shared.ts"
import {createSimpleContext, createStateContext} from "../../utils/context.ts"
import {useDebouncedValue} from "../../utils/hooks.tsx"
import {TColumn} from "./table/SaaSUserManagementTable.tsx"
import {EStatus, TUser} from "./fakeTypes.ts"

export const InviteUsersModalContext = createStateContext<boolean>("invite users modal")

export const CancelInvitationContext = createStateContext<TUser | null>("cancel invitation")
export const ReactivateUserContext = createStateContext<TUser | null>("reactivate user")
export const DeactivateUserContext = createStateContext<TUser | null>("deactivate user")

export const FilteringContext = createSimpleContext<ReturnType<typeof useSaasUserManagementTableFiltering>>("filtering")

export const useSaasUserManagementTableFiltering = () => {
  const [orderBy, setOrderBy] = React.useState<TOrderBy<TColumn>>(undefined)

  const [searchString, setSearchString] = React.useState("")
  const searchStringDebounced = useDebouncedValue(searchString)

  const [statusFilter, setStatusFilter] = React.useState<Array<TOption<EStatus>>>([])
  const statusFilterDebounced = useDebouncedValue(statusFilter)

  return {
    statusFilter,
    setStatusFilter,
    statusFilterDebounced,
    searchString,
    setSearchString,
    searchStringDebounced,
    orderBy,
    setOrderBy,
  }
}
