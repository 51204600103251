import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {Badge} from "../../../components/Badge"
import {ACompanyUserSalesCycleProspects, ASalesCycleProspectStatuses} from "../../../services/types.generated"
import {enumTranslKey} from "../../../utils/i18n"

export const StatusBadgeCell: React.FC<{row: ACompanyUserSalesCycleProspects}> = ({row}) => {
  const {t} = useTranslation()
  const {status} = row

  if (!status) {
    return "-"
  }

  return (
    <Badge
      color={"custom"}
      className={twMerge(
        status === ASalesCycleProspectStatuses.Won && "bg-cr-blue text-cr-white",
        status === ASalesCycleProspectStatuses.Opened && "bg-cr-indigo-mid text-cr-white",
        status === ASalesCycleProspectStatuses.Lost && "bg-cr-blue-light text-cr-blue-mid"
      )}
    >
      {t(enumTranslKey("ProspectStatus", status))}
    </Badge>
  )
}
