import React from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"

import logo from "../../assets/logo.svg"
import Button from "../../components/Button"
import {FlyoutDataRow, FlyoutGrid, FlyoutSectionRow} from "../../components/Flyout"
import {Loading} from "../../components/Loading"
import {ConfirmModal} from "../../components/Modal"
import {useGDPRDeleteMutation, useGDPRQuery} from "../../queries/gdpr"
import {RouterError} from "../../RouterError"
import {useParam} from "../../utils/hooks"
import {enumTranslKey} from "../../utils/i18n"

export const GDPR: React.FC = () => {
  const {t} = useTranslation()

  const token = useParam("token")
  const isImmediate = !!useParam("immediate", true)

  const navigate = useNavigate()

  const [isConfirming, setIsConfirming] = React.useState(isImmediate)

  const query = useGDPRQuery(token)

  const deleteMutation = useGDPRDeleteMutation()

  const handleDelete = React.useCallback(async () => {
    try {
      await deleteMutation.mutateAsync(token)
      navigate("/privacy-request/deleted")
    } catch {
      toast.error(t("GDPR_ErrorToast"))
      return false
    }
  }, [deleteMutation, navigate, t, token])

  if (query.error || !token) {
    return <RouterError force404 />
  }

  if (query.isFetching || !query.data) {
    return <Loading size={"xl"} />
  }

  const {
    organization_name,
    segment,
    city,
    country: {name: country},
    website,
    organization_phone_number,

    contact_person_first_name,
    contact_person_last_name,
    position,
    phone_number,
    additional_phone_number,
    email,
  } = query.data.resource

  return (
    <div className={"flex grow flex-col items-center justify-center gap-10"}>
      <img src={logo} alt={t("CloseRocket")} />
      <div className={"flex w-full max-w-xl flex-col rounded-lg shadow-2xl"}>
        <div className={"rounded-t-lg bg-cr-blue px-8 py-4 font-bold text-cr-white"}>{t("GDPR_Title")}</div>
        <FlyoutGrid>
          <FlyoutSectionRow>{t("GDPR_Grid_CompanySection")}</FlyoutSectionRow>

          <FlyoutDataRow legend={t("GDPR_Grid_Name")}>{organization_name}</FlyoutDataRow>
          {segment && segment.length > 0 && (
            <FlyoutDataRow legend={t("GDPR_Grid_Segment")}>
              {segment.map(s => t(enumTranslKey("Segment", s), s)).join(", ")}
            </FlyoutDataRow>
          )}
          {city && <FlyoutDataRow legend={t("GDPR_Grid_City")}>{city}</FlyoutDataRow>}
          {country && <FlyoutDataRow legend={t("GDPR_Grid_Country")}>{country}</FlyoutDataRow>}
          {website && <FlyoutDataRow legend={t("GDPR_Grid_Website")}>{website}</FlyoutDataRow>}
          {organization_phone_number && (
            <FlyoutDataRow legend={t("GDPR_Grid_OrganizationPhone")}>{organization_phone_number}</FlyoutDataRow>
          )}

          <FlyoutSectionRow>{t("GDPR_Grid_PersonalSection")}</FlyoutSectionRow>

          {contact_person_first_name && (
            <FlyoutDataRow legend={t("GDPR_Grid_FirstName")}>{contact_person_first_name}</FlyoutDataRow>
          )}
          {contact_person_last_name && (
            <FlyoutDataRow legend={t("GDPR_Grid_LastName")}>{contact_person_last_name}</FlyoutDataRow>
          )}
          {position && <FlyoutDataRow legend={t("GDPR_Grid_Position")}>{position}</FlyoutDataRow>}
          {phone_number && <FlyoutDataRow legend={t("GDPR_Grid_Phone")}>{phone_number}</FlyoutDataRow>}
          {additional_phone_number && (
            <FlyoutDataRow legend={t("GDPR_Grid_AdditionalPhone")}>{additional_phone_number}</FlyoutDataRow>
          )}
          {email && <FlyoutDataRow legend={t("GDPR_Grid_Email")}>{email}</FlyoutDataRow>}
        </FlyoutGrid>
        <Button
          fullWidth
          variant={"outlined"}
          color={"gray"}
          className={"box-border rounded-t-none py-4"}
          onClick={() => setIsConfirming(true)}
        >
          {t("GDPR_Button")}
        </Button>
      </div>

      <ConfirmModal
        onConfirm={handleDelete}
        title={t("GDPR_Confirm_Title")}
        isOpen={isConfirming}
        onClose={() => setIsConfirming(false)}
        variant={"error"}
        confirmButtonText={t("GDPR_Confirm_Button")}
      >
        {t("GDPR_Confirm_Text")}
      </ConfirmModal>
    </div>
  )
}
