import {FC} from "react"
import {useTranslation} from "react-i18next"
import {ArrowPathIcon, PlusIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {UnstyledLink} from "../../../../components/Link"
import {ProfileImage} from "../../../../components/ProfileImage.tsx"
import {AAssignmentShort, AAssignmentStatuses, AMatchingSalesPerson} from "../../../../services/types.generated"
import {getFullName} from "../../../../utils"

export const isPending = (status: string | undefined): boolean => status === AAssignmentStatuses.Pending

const Package: FC<{
  assignment: AAssignmentShort
  highlighted: boolean
  selectedPerson?: AMatchingSalesPerson
  navigateRelative?: boolean
}> = ({assignment, highlighted, selectedPerson, navigateRelative}) => {
  const {t} = useTranslation()

  const matched = assignment.sales_person
  const pending = assignment.pending_assignment_offers?.[0]
  const person = matched ?? pending?.sales_person ?? selectedPerson

  return (
    <UnstyledLink
      to={`${navigateRelative ? "../" : ""}assignment/${assignment.id}`}
      className={twMerge([
        "group my-3 block w-80 flex-shrink-0 rounded-full border p-2 outline outline-2 outline-white transition-all hover:outline-cr-blue-mid",
        highlighted && "pointer-events-none outline-cr-blue",
      ])}
    >
      <div className={"flex items-center justify-between"}>
        <div className={"flex items-center"}>
          <ProfileImage
            className={"inline-block size-9"}
            disabled={isPending(assignment.status)}
            src={person?.profile_picture_thumbnail_url ?? undefined}
          />
          <div className={"ml-3"}>
            <p
              className={twMerge(
                "overflow-hidden text-ellipsis whitespace-nowrap text-sm font-semibold text-cr-grey-50 group-hover:text-cr-grey-80",
                highlighted && "text-cr-blue"
              )}
            >
              {person ? getFullName(person) : highlighted ? t("Matching_Matching") : t("Matching_Waiting for match")}
            </p>
            <p
              className={
                "overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium text-cr-grey-50 group-hover:text-cr-grey-80"
              }
            >
              {t("Matching_{{reaches}} reaches", {reaches: assignment.reaches})}
            </p>
          </div>
        </div>
        <div className={"mx-2 text-cr-grey-50"}>
          {selectedPerson ? <ArrowPathIcon className={"h-6 w-6"} /> : !person && <PlusIcon className={"h-6 w-6"} />}
        </div>
      </div>
    </UnstyledLink>
  )
}

export default Package
