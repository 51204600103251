import React from "react"
import {Navigate} from "react-router-dom"

import {useUserSettingsQuery} from "../queries/user.ts"
import {RouterError} from "../RouterError"
import {AUserTypes} from "../services/types.generated"
import {useTimeout} from "../utils/hooks"
import {Loading} from "./Loading"

export const LoginGate: React.FC<{Component: React.ComponentType; userTypes?: AUserTypes[]}> = ({
  Component,
  userTypes,
}) => {
  const {status, data} = useUserSettingsQuery()

  if (status === "pending") {
    return <Loading size={"xl"} />
  }
  if (status === "error") {
    const {pathname, search, hash} = window.location

    return <Navigate to={"/log-in"} state={{redirectTo: `${pathname}${search}${hash}`}} />
  }

  if (!!userTypes && !userTypes.includes(data?.user.type)) {
    return <RouterError force404 />
  }

  return <Component />
}

export type TSignpost = {[userType in AUserTypes | "logged-out"]?: React.ComponentType}

export const UserTypeCrossroads: React.FC<{
  signpost: TSignpost
}> = ({signpost}) => {
  const {status, data} = useUserSettingsQuery()

  if (status === "pending") {
    return <Loading size={"xl"} />
  }
  if (status === "error") {
    const Component = signpost["logged-out"]

    const {pathname, search, hash} = window.location

    return Component ? <Component /> : <Navigate to={"/log-in"} state={{redirectTo: `${pathname}${search}${hash}`}} />
  }

  const Component = signpost[data.user.type]
  return Component ? <Component /> : <RouterError force404 />
}

export function LogoutGate<P>({Component, ...props}: P & {Component: React.ComponentType<Omit<P, "Component">>}) {
  const {status} = useUserSettingsQuery()
  const [shouldRedirect, setShouldRedirect] = React.useState(false)
  const startTimeout = useTimeout()

  if (shouldRedirect) {
    return <Navigate to={"/"} />
  }

  if (status === "error") {
    return <Component {...props} />
  }

  if (status === "success") {
    // We wait a bit before redirecting, as the redirect can be undesirably triggered
    // when the LogoutGate requirements are suddenly no longer met.
    // An example of this is logging in, where concurrency sometimes makes LogoutGate react
    // before the login procedure has a chance to redirect the user to the dashboard.
    startTimeout(() => setShouldRedirect(true), 1000)
  }

  return <Loading size={"xl"} />
}
