import {useQuery} from "@tanstack/react-query"

import api, {queryKey} from "../services"

export const useLanguagesQuery = () => {
  return useQuery({
    queryKey: queryKey.languages,
    queryFn: async () => {
      const response = await api.languages.languagesList()
      return response.data.languages
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })
}
