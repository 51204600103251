import React from "react"
import {useTranslation} from "react-i18next"

import {AActivityFeed} from "../../../../../services/types.generated.ts"

export const ActivityBodyKpiCall: React.FC<{activity: AActivityFeed}> = () => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-col gap-2"}>
      <h4 className={"font-semibold"}>{t("SellingDetails_News_ActivityFeed_KpiCall_Title")}</h4>

      <div className={"whitespace-pre-wrap"}>{t("SellingDetails_News_ActivityFeed_KpiCall_Text")}</div>
    </div>
  )
}
