import React from "react"
import {useTranslation} from "react-i18next"
import {CheckIcon} from "@heroicons/react/20/solid"

import Button from "../../../../components/Button.tsx"

export const FinishedSingleStep: React.FC<{onClose: () => void}> = ({onClose}) => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-col gap-10"}>
      <div className={"flex flex-col gap-3"}>
        <h1 className={"text-2xl font-semibold"}>{t("Prospects_ImportModal_FinishedSingleStep_Title")}</h1>
        <p className={"text-cr-grey-50"}>{t("Prospects_ImportModal_FinishedSingleStep_Text")}</p>
      </div>

      <div className={"flex justify-end"}>
        <Button onClick={onClose} iconLeft={<CheckIcon />}>
          {t("Prospects_ImportModal_FinishedSingleStep_Button")}
        </Button>
      </div>
    </div>
  )
}
