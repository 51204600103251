import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {Badge, TBadgeColor} from "../../../components/Badge.tsx"
import {enumTranslKey} from "../../../utils/i18n.tsx"
import {EStatus, TUser} from "../fakeTypes.ts"

const statusToColor = {
  [EStatus.active]: "green",
  [EStatus.invited]: "yellow",
  [EStatus.deactivated]: "red",
} as const satisfies {[status in EStatus]: TBadgeColor}

export const StatusCell: React.FC<{row: TUser}> = ({row: {status}}) => {
  const {t} = useTranslation()

  return (
    <Badge color={statusToColor[status]} className={twMerge("py-1 text-xs")}>
      {t(enumTranslKey("SaaSUserStatus", status))}
    </Badge>
  )
}
