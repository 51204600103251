import React from "react"
import {twMerge} from "tailwind-merge"

type TProps = {
  isOpen: boolean
  onClick?: () => void
  children: React.ReactNode
}

export const MenuButton: React.FC<TProps> = ({isOpen, onClick, children}) => (
  <div
    onClick={onClick}
    className={twMerge([
      "size-8 shrink-0 cursor-pointer overflow-hidden rounded-full text-cr-grey-50 transition-colors hover:text-cr-blue",
      isOpen && "text-cr-blue",
    ])}
  >
    {children}
  </div>
)
