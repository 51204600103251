import React from "react"

import {getActiveAssignment, getActiveIteration, TProspectsIteration, TProspectsSalesCycle} from "./utils.ts"

export function useActiveIteration<T extends TProspectsSalesCycle>(salesCycle: T) {
  const [activeIteration, setActiveIteration] = React.useState<T["sales_cycle_iterations"][number] | null>(null)

  React.useEffect(() => {
    setActiveIteration(iteration => {
      return getActiveIteration(salesCycle, iteration)
    })
  }, [salesCycle])

  return [activeIteration, setActiveIteration] as const
}

export function useActiveAssignment<T extends TProspectsIteration>(activeIteration: T | null) {
  const [activeAssignment, setActiveAssignment] = React.useState<T["assignments"][number] | null>(null)

  React.useEffect(() => {
    setActiveAssignment(currentAssignment => getActiveAssignment(activeIteration, currentAssignment))
  }, [activeIteration])

  return [activeAssignment, setActiveAssignment] as const
}
