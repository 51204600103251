import React from "react"
import {useTranslation} from "react-i18next"
import {PlusCircleIcon} from "@heroicons/react/24/outline"

import Button, {TButtonProps} from "../../../components/Button.tsx"
import {IsUploadingContext} from "./context.ts"

export const UploadButton: React.FC<TButtonProps> = buttonProps => {
  const {t} = useTranslation()

  const {setValue: setIsOpen} = IsUploadingContext.useContext()

  return (
    <Button iconLeft={<PlusCircleIcon className={"h-5 w-5"} {...buttonProps} />} onClick={() => setIsOpen(true)}>
      {t("Prospects_AddProspectsButton")}
    </Button>
  )
}
