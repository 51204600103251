import {faker} from "@faker-js/faker"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {format} from "date-fns/format"

import {usePaginatedQuery} from "../components/Pagination.tsx"
import {EOrderDirection} from "../components/Table/shared.ts"
import {ERole, EStatus, makeUser, TMetaQueryData, TUser} from "../routes/SaaSUserManagement/fakeTypes.ts"
import {TColumn} from "../routes/SaaSUserManagement/table/SaaSUserManagementTable.tsx"
import {queryKey, saasUserManagementDataGeneralKey, saasUserManagementGeneralKey} from "../services"
import {APagination} from "../services/types.generated.ts"
import {getFullName} from "../utils"
import {apiDateToJS} from "../utils/dateArithmetics.ts"
import {wait} from "../utils/retry.ts"

const owners = [makeUser(true)()]

const admins = [
  makeUser()({role: ERole.admin, invited_by: faker.helpers.arrayElement(owners)}),
  makeUser()({role: ERole.admin, invited_by: faker.helpers.arrayElement(owners)}),
]

const invitable = [...owners, ...admins].filter(user => !!user.first_name)

const salesPeople = [
  makeUser()({
    role: ERole.salesperson,
    invited_by: faker.helpers.arrayElement(invitable),
  }),
  makeUser()({
    role: ERole.salesperson,
    invited_by: faker.helpers.arrayElement(invitable),
  }),
  makeUser()({
    role: ERole.salesperson,
    invited_by: faker.helpers.arrayElement(invitable),
  }),
  makeUser()({
    role: ERole.salesperson,
    invited_by: faker.helpers.arrayElement(invitable),
  }),
  makeUser()({
    role: ERole.salesperson,
    invited_by: faker.helpers.arrayElement(invitable),
  }),
  makeUser()({
    role: ERole.salesperson,
    invited_by: faker.helpers.arrayElement(invitable),
  }),
  makeUser()({
    role: ERole.salesperson,
    invited_by: faker.helpers.arrayElement(invitable),
  }),
]

export const useSaasUserManagementMetaQuery = () => {
  return useQuery({
    queryKey: queryKey.saasUserManagementMeta,
    queryFn: async () => {
      return {
        all_users_count: salesPeople.length + admins.length + owners.length,
        salespeople_count: salesPeople.length,
        admins_count: admins.length,
        owners_count: owners.length,
      } satisfies TMetaQueryData
    },
  })
}

const sortUsers = (users: TUser[], sortBy?: TColumn, sortDirection?: EOrderDirection) => {
  if (!sortBy || !sortDirection) {
    return users
  }

  switch (sortBy) {
    case "name":
      return users.toSorted(
        (A, B) => `${getFullName(A)} ${A.email}`.localeCompare(`${getFullName(B)} ${B.email}`) * sortDirection
      )
    case "joined_at":
      return users.toSorted(
        (A, B) => (apiDateToJS(A.joined_at).getTime() - apiDateToJS(B.joined_at).getTime()) * sortDirection
      )
    case "status":
      return users.toSorted((A, B) => (A.status.length - B.status.length) * sortDirection)
    case "role":
      return users.toSorted((A, B) => A.role.localeCompare(B.role) * sortDirection)
    default:
      return users
  }
}

export const useSaasUserManagementQuery = ({
  pageSize,
  ...filter
}: {
  pageSize: number
  role?: ERole
  searchString?: string | undefined
  statuses?: EStatus[] | undefined
  sort_by?: TColumn
  sort_direction?: EOrderDirection
}) => {
  return usePaginatedQuery(
    queryKey.saasUserManagementData(filter),
    async query => {
      const allUsers = [...owners, ...admins, ...salesPeople]

      const filteredUsers = allUsers.filter(user => {
        const statuses = (filter.statuses ?? []).length > 0 ? filter.statuses : undefined

        return (
          (filter.role ? user.role === filter.role : true) &&
          (statuses ? statuses.includes(user.status) : true) &&
          (filter.searchString
            ? `${getFullName(user)}${user.email}`.toLowerCase().includes(filter.searchString.toLowerCase())
            : true)
        )
      })

      const sortedUsers = sortUsers(filteredUsers, filter.sort_by, filter.sort_direction)

      const page = query.page - 1

      await wait(300)

      return {
        data: {
          users: sortedUsers.slice(page * pageSize, page * pageSize + pageSize),
          meta: {
            pagination: {
              per_page: pageSize,
              current_page: query.page,
              total_count: sortedUsers.length,
            } satisfies APagination,
          },
        },
      }
    },
    {pageSize}
  )
}

export const useInviteUsersMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({emails, role}: {emails: string[]; role: ERole}) => {
      await wait(300)

      emails.forEach(email => {
        const user = makeUser()({
          role,
          invited_by: owners[0],
          status: EStatus.invited,
          joined_at: format(new Date(), "yyyy-MM-dd"),
          email,
          first_name: null,
          last_name: null,
        })

        if (role === ERole.salesperson) {
          salesPeople.push(user)
        }
        if (role === ERole.admin) {
          admins.push(user)
        }
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: saasUserManagementGeneralKey,
      })
    },
  })
}

export const useDeactivateUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({id}: {id: number}) => {
      await wait(300)

      const allUsers = [...owners, ...admins, ...salesPeople]
      const user = allUsers.find(user => user.id === id)

      if (!user) {
        return
      }

      user.status = EStatus.deactivated
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: saasUserManagementDataGeneralKey,
      })
    },
  })
}

export const useReactivateUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({id}: {id: number}) => {
      await wait(300)

      const allUsers = [...owners, ...admins, ...salesPeople]
      const user = allUsers.find(user => user.id === id)

      if (!user) {
        return
      }

      user.status = EStatus.active
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: saasUserManagementDataGeneralKey,
      })
    },
  })
}

export const useResendInvitationMutation = () => {
  return useMutation({
    mutationFn: async ({id: _id}: {id: number}) => {
      await wait(300)
    },
  })
}

export const useCancelInvitationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({id}: {id: number}) => {
      await wait(300)

      const allUsers = [...owners, ...admins, ...salesPeople]
      const user = allUsers.find(user => user.id === id)

      if (user?.role === ERole.salesperson) {
        salesPeople.splice(salesPeople.indexOf(user), 1)
      }
      if (user?.role === ERole.admin) {
        admins.splice(admins.indexOf(user), 1)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: saasUserManagementGeneralKey,
      })
    },
  })
}
