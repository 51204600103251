import React from "react"
import {useTranslation} from "react-i18next"

import {enumTranslKey} from "../../../utils/i18n.tsx"
import {TUser} from "../fakeTypes.ts"

export const RoleCell: React.FC<{row: TUser}> = ({row: {role}}) => {
  const {t} = useTranslation()

  return t(enumTranslKey("SaaSUserRole", role))
}
