import React from "react"
import {useTranslation} from "react-i18next"

import {useReportsOpportunitiesChartQuery} from "../../../../queries/reports"
import {tailwindColors} from "../../../../utils/tailwind"
import {SimpleBarGraphCard} from "./SimpleBarGraphCard"

export const OpportunitiesGraphCard: React.FC = () => {
  const {t} = useTranslation()

  return (
    <SimpleBarGraphCard
      metric={"opportunities"}
      query={useReportsOpportunitiesChartQuery}
      config={[
        {
          key: "proposal_sent",
          label: t("Reports_Chart_Opportunities_ProposalSent"),
          color: tailwindColors["cr-blue"]["DEFAULT"],
        },
        {
          key: "future_deals",
          label: t("Reports_Chart_Opportunities_FutureDeals"),
          color: tailwindColors["cr-cyan"]["DEFAULT"],
        },
        {
          key: "negotiating",
          label: t("Reports_Chart_Opportunities_Negotiating"),
          color: tailwindColors["cr-blue"]["mid"],
        },
      ]}
    />
  )
}
