import React from "react"
import {twMerge} from "tailwind-merge"

import ArrowIcon from "../../assets/how-it-works/arrow.svg?react"

export type TItem = {
  title: React.ReactNode
  when: React.ReactNode
  description: React.ReactNode
  image: string
}

export const Item: React.FC<
  TItem & {
    odd?: boolean
    arrow?: boolean
    last?: boolean
  }
> = ({when, description, title, odd, arrow, last, image}) => {
  return (
    <div
      className={twMerge([
        "relative z-10 flex h-56 items-center gap-40 px-5 text-sm",
        odd && "flex-row-reverse",
        "max-sm:h-auto max-sm:flex-row-reverse max-sm:gap-4",
      ])}
    >
      <div
        className={twMerge([
          "flex basis-1/2 py-16",
          !odd && "justify-end text-right",
          "max-sm:grow max-sm:basis-auto max-sm:text-left",
        ])}
      >
        <div className={"flex max-w-md flex-col gap-2"}>
          <div className={"font-bold uppercase text-cr-cyan"}>{when}</div>
          <h3 className={"text-xl font-bold"}>{title}</h3>
          <div>{description}</div>
        </div>
      </div>

      <div
        className={twMerge([
          "absolute inset-y-0 left-1/2 flex w-1 shrink-0 items-center justify-center bg-cr-blue-light",
          last && "bottom-1/2 items-end",
          "max-sm:left-20",
        ])}
        aria-hidden={"true"}
      >
        <div
          className={twMerge([
            "absolute h-8 w-8 rounded-full border-4 border-cr-blue-light bg-cr-white",
            last && "translate-y-1/2",
            "max-sm:hidden",
          ])}
        />
      </div>

      <div
        className={twMerge([
          "relative flex basis-1/2 py-10",
          odd && "justify-end",
          "max-sm:shrink-0 max-sm:basis-[128px] max-sm:justify-center",
        ])}
      >
        <img src={image} alt={String(title)} className={"max-h-28 max-w-[128px]"} />
        {arrow && (
          <ArrowIcon
            className={twMerge([
              "absolute bottom-20 z-10 h-5 w-5 text-cr-cyan-dark",
              odd
                ? "-right-[45px] max-sm:left-[10px] max-sm:top-0"
                : "-left-[46px] rotate-180 max-sm:bottom-0 max-sm:left-[76px]",
            ])}
          />
        )}
      </div>
    </div>
  )
}
