import React from "react"
import {useTranslation} from "react-i18next"
import {DocumentCheckIcon} from "@heroicons/react/24/outline"

import {LayoutBlock} from "../../../../components/Layout/LayoutBlock.tsx"
import {Link, UnstyledLink} from "../../../../components/Link.tsx"
import {Section, Subsection} from "../../../../components/Section.tsx"
import {useSalesCycleDetailQuery} from "../../../../queries/salesCycles.ts"
import {useUserSettingsOrLogout} from "../../../../queries/user.ts"
import {renderPrice} from "../../../../utils"
import {commissionTitle} from "../../../../utils/goals.ts"
import {useDocumentTitle, useNumParam} from "../../../../utils/hooks.tsx"
import {enumTranslKey} from "../../../../utils/i18n.tsx"
import {isSalesPersonUser} from "../../../../utils/types.ts"
import {getFileNameFromURL} from "../../../../utils/url.ts"
import {isSaasCompanySalesCycleDetails} from "../../types.ts"
import {StopSalesPlanSection} from "../components/StopSalesPlan.tsx"

export const DetailsTab: React.FC = () => {
  const {t} = useTranslation()
  useDocumentTitle(t("SellingDetails_Tabs_Details"))

  const {user} = useUserSettingsOrLogout()

  const salesCycleId = useNumParam("salesCycleId")

  const {data} = useSalesCycleDetailQuery(salesCycleId)

  if (!data || isSaasCompanySalesCycleDetails(data)) {
    return null
  }

  const product = data.products[0]

  return (
    <LayoutBlock outerClassName={"py-16"} innerClassName={"flex flex-col gap-6"}>
      <Section
        title={t("SellingDetails_Details_Product_Title")}
        subtitle={t("SellingDetails_Details_Product_Subtitle")}
      >
        <div className={"grid grid-cols-3 gap-10 sm:max-md:grid-cols-1"}>
          <Subsection title={t("SellingDetails_Details_Product_Goal")}>
            <span className={"text-lg font-bold"}>{t(enumTranslKey("ProductGoal", product.goal))}</span>
          </Subsection>

          <Subsection title={t("SellingDetails_Details_Product_CommissionType")}>
            <span className={"text-lg font-bold"}>
              {commissionTitle[product.commission_type][product.commission_value_type]}
            </span>
          </Subsection>

          <Subsection title={t("SellingDetails_Details_Product_Commission")}>
            <span className={"text-lg font-bold"}>
              {renderPrice(
                isSalesPersonUser(user) ? product.sales_person_commission : product.commission,
                product.currency ?? data.default_currency ?? undefined,
                {
                  trailingZeroDisplay: "stripIfInteger",
                }
              )}
            </span>
          </Subsection>
        </div>
        {data.price_list_url && (
          <Subsection title={t("SellingDetails_Details_Product_PriceList_Title")}>
            <Link to={data.price_list_url} target={"_blank"}>
              {t("SellingDetails_Details_Product_PriceList_Link")}
            </Link>
          </Subsection>
        )}
      </Section>
      <Section
        title={t("SellingDetails_Details_Audience_Title")}
        subtitle={t("SellingDetails_Details_Audience_Subtitle")}
      >
        <Subsection title={t("SellingDetails_Details_Audience_Segment")}>
          {t(enumTranslKey("Segment", data.segment))}
        </Subsection>

        {data.segment_note && (
          <Subsection title={t("SellingDetails_Details_Audience_SegmentNote")}>{data.segment_note}</Subsection>
        )}

        <Subsection title={t("SellingDetails_Details_Audience_CompanySize")}>
          {t(enumTranslKey("CompanySize", data.ideal_customer_profile))}
        </Subsection>

        <Subsection title={t("SellingDetails_Details_Audience_DecisionMaker")}>
          {data.decision_maker_persona.map(persona => t(enumTranslKey("Persona", persona), persona)).join(", ")}
        </Subsection>
      </Section>
      <Section
        title={t("SellingDetails_Details_ElevatorPitch_Title")}
        subtitle={t("SellingDetails_Details_ElevatorPitch_Subtitle")}
      >
        <Subsection title={t("SellingDetails_Details_ElevatorPitch_ByCompany")}>{data.elevator_pitch}</Subsection>
      </Section>

      <Section
        title={t("SellingDetails_Details_ValuePropositions_Title")}
        subtitle={t("SellingDetails_Details_ValuePropositions_Subtitle")}
      >
        {(data.product_benefits ?? []).length > 0 && (
          <Subsection title={t("SellingDetails_Details_ValuePropositions_Benefits")}>
            {data.product_benefits.join(", ")}
          </Subsection>
        )}

        {(data.product_uniques ?? []).length > 0 && (
          <Subsection title={t("SellingDetails_Details_ValuePropositions_Uniques")}>
            {data.product_uniques!.join(", ")}
          </Subsection>
        )}
      </Section>

      {(data.website || (data.attachments ?? []).length > 0) && (
        <Section
          title={t("SellingDetails_Details_Documents_Title")}
          subtitle={t("SellingDetails_Details_Documents_Subtitle")}
        >
          {data.website && (
            <Subsection title={t("SellingDetails_Details_Documents_Website")}>
              <Link to={data.website} target={"_blank"}>
                {data.website}
              </Link>
            </Subsection>
          )}
          {(data.attachments ?? []).length > 0 && (
            <Subsection title={t("SellingDetails_Details_Documents_Documents")}>
              {data.attachments!.map(attachment => {
                return (
                  <UnstyledLink
                    to={attachment.url}
                    target={"_blank"}
                    className={"group flex flex-col items-center gap-1 text-sm"}
                  >
                    <div
                      className={
                        "rounded-full ring-cr-grey-15 transition-all group-hover:bg-cr-grey-15 group-hover:ring-[0.3rem]"
                      }
                    >
                      <DocumentCheckIcon className={"h-10"} />
                    </div>
                    {getFileNameFromURL(attachment.url)}
                  </UnstyledLink>
                )
              })}
            </Subsection>
          )}
        </Section>
      )}

      <Section
        title={t("SellingDetails_Details_AdditionalDetails_Title")}
        subtitle={t("SellingDetails_Details_AdditionalDetails_Subtitle")}
      >
        {(data.references ?? []).length > 0 && (
          <Subsection title={t("SellingDetails_Details_AdditionalDetails_References")}>
            {data.references!.join(", ")}
          </Subsection>
        )}

        <Subsection title={t("SellingDetails_Details_AdditionalDetails_YearsOnTheMarket")}>
          {t(enumTranslKey("YearsOfMarket", data.years_of_market))}
        </Subsection>

        {data.articles_links && (
          <Subsection title={t("SellingDetails_Details_AdditionalDetails_Links")}>{data.articles_links}</Subsection>
        )}

        {data.additional_strategy_description && (
          <Subsection title={t("SellingDetails_Details_AdditionalDetails_AdditionalInfo")}>
            {data.additional_strategy_description}
          </Subsection>
        )}

        {data.sales_scripts && (
          <Subsection title={t("SellingDetails_Details_AdditionalDetails_SalesScripts")}>
            {data.sales_scripts}
          </Subsection>
        )}
      </Section>

      {data.subscription && <StopSalesPlanSection subscription={data.subscription} />}
    </LayoutBlock>
  )
}
