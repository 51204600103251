import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import Button, {ButtonForm} from "../../components/Button.tsx"
import {CheckboxConnected} from "../../components/fields/Checkbox.tsx"
import {FieldErrorMessage} from "../../components/fields/components.tsx"
import {InputField} from "../../components/fields/Input.tsx"
import {DropdownField, TOption} from "../../components/formElements/Dropdown.tsx"
import {Link} from "../../components/Link.tsx"
import Modal from "../../components/Modal.tsx"
import {useUpdateBusinessInfoMutation} from "../../queries/salespeople.ts"
import {useUserSettingsOrLogout} from "../../queries/user.ts"
import {useCountryOptions} from "../../utils/hooks.tsx"
import {
  requiredFieldMessage,
  setFormErrorsFromAxios,
  validateNonemptyString,
  validateRequiredNumber,
} from "../../utils/validation.ts"

const validationSchema = z.object({
  company_name: validateNonemptyString(),
  company_id: validateNonemptyString(),
  street_address: validateNonemptyString(),
  city: validateNonemptyString(),
  postal_code: validateNonemptyString(),
  country_id: validateRequiredNumber(),
  state: z.string().nullish(),
  tacAgreement: z.literal(true, {
    errorMap: () => ({message: requiredFieldMessage}),
  }),
  dpaAgreement: z.literal(true, {
    errorMap: () => ({message: requiredFieldMessage}),
  }),
  authorizedAgreement: z.literal(true, {
    errorMap: () => ({message: requiredFieldMessage}),
  }),
})

export const BusinessInfoModal: React.FC<{isOpen: boolean; onClose: () => void; onSuccess: () => Promise<void>}> = ({
  isOpen,
  onSuccess,
  onClose,
}) => {
  const {t} = useTranslation()
  const countryOptions = useCountryOptions()
  const {countries} = useUserSettingsOrLogout()

  const methods = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
  })

  const updateBusinessInfoMutation = useUpdateBusinessInfoMutation()

  const watchedCountry = methods.watch("country_id")
  const stateOptions = React.useMemo<Array<TOption<string>> | undefined>(() => {
    const country = countries.find(c => c.id === watchedCountry)

    if (country?.states?.length) {
      methods.setValue("state", country.states[0].value)
      return country.states
    }

    methods.setValue("state", undefined)
    return undefined
  }, [countries, methods, watchedCountry])

  const handleSubmit = React.useCallback(async () => {
    const values = methods.getValues()

    try {
      await updateBusinessInfoMutation.mutateAsync({...values, state: values.state ?? undefined})
      await onSuccess()
      onClose()
    } catch (e) {
      setFormErrorsFromAxios(e, methods.setError)
    }
  }, [methods, onClose, onSuccess, updateBusinessInfoMutation])

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t("SalespersonDashboard_NewOffer_BusinessInfo_Title")} size={"sm"}>
      <div className={"flex flex-col gap-6"}>
        <div>{t("SalespersonDashboard_NewOffer_BusinessInfo_Subtitle")}</div>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)} className={"contents"}>
            <div>
              <InputField
                name={"company_name"}
                label={t("SalespersonDashboard_NewOffer_BusinessInfo_CompanyName")}
                variant={"small"}
              />
              <InputField
                name={"company_id"}
                label={t("SalespersonDashboard_NewOffer_BusinessInfo_CompanyId")}
                variant={"small"}
              />
              <InputField
                name={"street_address"}
                label={t("SalespersonDashboard_NewOffer_BusinessInfo_Address")}
                variant={"small"}
              />
              <div className={"grid grid-cols-2 gap-x-4"}>
                <InputField
                  name={"city"}
                  label={t("SalespersonDashboard_NewOffer_BusinessInfo_City")}
                  variant={"small"}
                />
                <InputField
                  name={"postal_code"}
                  label={t("SalespersonDashboard_NewOffer_BusinessInfo_ZIP")}
                  variant={"small"}
                />
                <DropdownField
                  variant={"small"}
                  label={t("SalespersonDashboard_NewOffer_BusinessInfo_Country")}
                  options={countryOptions}
                  name={"country_id"}
                  openUp
                />
                {stateOptions && (
                  <DropdownField
                    variant={"small"}
                    label={t("SalespersonDashboard_NewOffer_BusinessInfo_State")}
                    options={stateOptions}
                    name={"state"}
                    openUp
                  />
                )}
              </div>
            </div>
            <div className={"flex flex-col gap-2"}>
              <div>
                <CheckboxConnected name={"tacAgreement"}>
                  <Trans
                    i18nKey={"SalespersonDashboard_NewOffer_BusinessInfo_TAC"}
                    components={{
                      tacLink: <Link flipUnderline to={t("Url_TermsAndConditions_Salesperson")} target={"_blank"} />,
                    }}
                  />
                </CheckboxConnected>
                <FieldErrorMessage name={"tacAgreement"} />
              </div>
              <div>
                <CheckboxConnected name={"dpaAgreement"}>
                  <Trans
                    i18nKey={"SalespersonDashboard_NewOffer_BusinessInfo_DPA"}
                    components={{
                      dpaLink: <Link flipUnderline to={t("Url_DPA")} target={"_blank"} />,
                    }}
                  />
                </CheckboxConnected>
                <FieldErrorMessage name={"dpaAgreement"} />
              </div>
              <div>
                <CheckboxConnected name={"authorizedAgreement"}>
                  {t("SalespersonDashboard_NewOffer_BusinessInfo_Authorized")}
                </CheckboxConnected>
                <FieldErrorMessage name={"authorizedAgreement"} />
              </div>
            </div>

            <div className={"flex justify-end gap-4"}>
              <Button color={"gray"} variant={"light"}>
                {t("SalespersonDashboard_NewOffer_BusinessInfo_Cancel")}
              </Button>
              <ButtonForm>{t("SalespersonDashboard_NewOffer_BusinessInfo_Submit")}</ButtonForm>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  )
}
