import React from "react"

import {AutocompleteFilterContent} from "../../components/AutocompleteFilter.tsx"
import {EnumFilterContent} from "../../components/EnumFilter.tsx"
import {getLeadsAutocompleteQuery} from "../../queries/leads.ts"
import {ALeadAutocompleteFields} from "../../services/types.generated.ts"
import {enumToOptions} from "../../utils/i18n.tsx"
import {FilteringContext} from "./context.ts"
import {TCompanySizeOption} from "./useLeadsFiltering.ts"

const companySizeOptions = enumToOptions("CompanySize") as TCompanySizeOption[]
export const CompanySizeFilter: React.FC = () => {
  const {companySizeFilter, setCompanySizeFilter} = FilteringContext.useContext()

  return (
    <EnumFilterContent
      options={companySizeOptions}
      multiple
      value={companySizeFilter}
      onChange={setCompanySizeFilter}
    />
  )
}

export const SegmentFilter: React.FC = () => {
  const {segmentFilter, setSegmentFilter} = FilteringContext.useContext()
  const autocompleteQuery = getLeadsAutocompleteQuery(ALeadAutocompleteFields.Segment)

  return (
    <AutocompleteFilterContent
      useAutocompleteQuery={autocompleteQuery}
      onChange={setSegmentFilter}
      value={segmentFilter}
    />
  )
}

export const CountryFilter: React.FC = () => {
  const {countriesFilter, setCountriesFilter} = FilteringContext.useContext()
  const autocompleteQuery = getLeadsAutocompleteQuery(ALeadAutocompleteFields.Country)

  return (
    <AutocompleteFilterContent
      useAutocompleteQuery={autocompleteQuery}
      onChange={setCountriesFilter}
      value={countriesFilter}
    />
  )
}

export const PositionFilter: React.FC = () => {
  const {positionFilter, setPositionFilter} = FilteringContext.useContext()
  const autocompleteQuery = getLeadsAutocompleteQuery(ALeadAutocompleteFields.Position)

  return (
    <AutocompleteFilterContent
      useAutocompleteQuery={autocompleteQuery}
      onChange={setPositionFilter}
      value={positionFilter}
    />
  )
}
