import {useMutation, useQueryClient} from "@tanstack/react-query"

import {usePaginatedQuery} from "../components/Pagination.tsx"
import api, {activityFeedGeneralKey, queryKey} from "../services"
import {AUserTypes} from "../services/types.generated.ts"
import {useValueByUserType} from "../utils/userTypes.ts"

export function useActivityFeedQuery(salesCycleId: number, pageSize: number) {
  const queryFn = useValueByUserType({
    [AUserTypes.CompanyUser]: api.companyUsers.salesCyclesActivityFeedsDetail,
    [AUserTypes.SaasCompanyUser]: api.saasCompanyUsers.salesCyclesActivityFeedsDetail,
    [AUserTypes.SaasSalesPerson]: api.saasSalesPeople.salesCyclesActivityFeedsDetail,
    [AUserTypes.SalesPerson]: api.salesPeople.salesCyclesActivityFeedsDetail,
    [AUserTypes.Admin]: api.admins.salesCyclesActivityFeedsDetail,
  })

  return usePaginatedQuery(
    queryKey.activityFeed(salesCycleId),
    paginationQuery => queryFn(salesCycleId, paginationQuery),
    {pageSize}
  )
}

export function useActivityFeedLikeMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({activityId}: {activityId: number}) => api.companyUsers.activityFeedsLikePartialUpdate(activityId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: activityFeedGeneralKey})
    },
  })
}

export function useActivityFeedUnlikeMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({activityId}: {activityId: number}) => api.companyUsers.activityFeedsDislikePartialUpdate(activityId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: activityFeedGeneralKey})
    },
  })
}
