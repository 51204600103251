import {getCurrentLanguage} from "../i18n"
import {useUserSettingsOrLogout} from "../queries/user.ts"
import {isCompanyUser} from "./types"

export function getDeepValue<R>(sourceObject: Record<string, unknown>, dotNotationPath = ""): R | undefined {
  const [key, ...parts] = dotNotationPath.split(".")

  if (dotNotationPath === "") {
    return sourceObject as ReturnType<typeof getDeepValue<R>>
  }

  if (!key || !(key in sourceObject)) {
    return undefined
  }

  return getDeepValue(sourceObject[key] as Record<string, unknown>, parts.join("."))
}

export const renderPrice = (
  price: number | string,
  currency?: {code: string},
  {negative, noDecimal, ...optionsOther}: {negative?: boolean; noDecimal?: boolean} & Intl.NumberFormatOptions = {}
): string => {
  if (typeof price === "string") {
    price = parseFloat(price)
  }

  const value = negative && price > 0 ? price * -1 : price

  const options = {...optionsOther}
  if (noDecimal) {
    options.minimumFractionDigits = 0
    options.maximumFractionDigits = 0
  }
  if (currency) {
    options.style = "currency"
    options.currency = currency.code
  }

  return Intl.NumberFormat(getCurrentLanguage(), options).format(value)
}

export const addHttpToURL = (url: string) => {
  return /^https?:\/\//.test(url) ? url : `http://${url}`
}

export const useIsWelcomeScreenEnabled = () => {
  const userSettings = useUserSettingsOrLogout()

  if (isCompanyUser(userSettings.user)) {
    return userSettings.welcome_screen
  }

  return "hero_section_visible" in userSettings.user && userSettings.user.hero_section_visible
}

export const getFullName = (
  obj: undefined | null | {first_name?: string | null; last_name?: string | null},
  placeholder?: string | null
): string => {
  if (!obj?.first_name && !obj?.last_name) {
    return placeholder ?? ""
  }

  return [obj.first_name, obj.last_name].filter(Boolean).join(" ")
}

export const EMPTY_ARRAY = [] as const
export const EMPTY_OBJECT = {} as const
