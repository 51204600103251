import React from "react"
import {DefaultValues} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"

import {Alert} from "../../components/Alert"
import {Loading} from "../../components/Loading"
import {useSalesCycleQuery} from "../../queries/salesCycles"
import {ACommissionTypes} from "../../services/types.generated"
import {useDocumentTitle, useNumParam} from "../../utils/hooks"
import {Form} from "./Form"
import Layout from "./Layout"
import {TSalesFormData} from "./types"
import {salesCycleToValues} from "./utils"

export const SalesForm = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("SalesStrategy_Sales strategy form"))

  const salesCycleId = useNumParam("salesCycleId", true)
  const isEditing = salesCycleId != null

  const {isPending, data, error} = useSalesCycleQuery(salesCycleId)

  const formData = React.useMemo<DefaultValues<TSalesFormData>>(() => {
    return data
      ? salesCycleToValues(data)
      : {
          decision_maker_persona: [],
          products_attributes: [{commission_type: ACommissionTypes.OneOff}],
        }
  }, [data])

  React.useEffect(() => {
    if (data?.strategy_finalized) {
      toast.warning(t("SalesStrategy_This sales plan was already finished and can no longer be edited."))
    }
  }, [data?.strategy_finalized, t])

  if (isEditing && isPending) {
    return <Loading size={"xl"} />
  }

  if (isEditing && error) {
    return (
      <Alert title={t("SalesStrategy_Loading the sales strategy form has failed")} variant={"error"}>
        {t("T_Try refreshing the page or contact support if the problem persists.")}
      </Alert>
    )
  }

  return (
    <Layout
      heading={
        isEditing
          ? t("SalesStrategy_Adjust the basics of your sales plan")
          : t("SalesStrategy_Let's start your sales plan")
      }
      paragraph={t(
        "SalesStrategy_Give us more details about your product and target audience so we can create a tailored sales strategy for you. Based on the provided info we'll match you with the best sales team that will reach out to your clients."
      )}
    >
      <div className={"m-auto max-w-2xl sm:pt-12"}>
        <div className={"card mb-64"}>
          <Form formData={formData} />
        </div>
      </div>
    </Layout>
  )
}
