import React from "react"
import {CheckIcon} from "@heroicons/react/24/solid"
import {twMerge} from "tailwind-merge"

import {tailwindColors} from "../utils/tailwind"

type TColor<T extends string> = T | ((args: {isActive: boolean; isDone: boolean}) => T)

function resolveColor<T extends string>(color: TColor<T>, state: {isActive: boolean; isDone: boolean}): T {
  if (typeof color === "string") {
    return color
  }

  return color(state)
}

export const CircularProgress: React.FC<{
  active: boolean
  progress: number
  ringColor?: string | ((args: {isActive: boolean; isDone: boolean}) => string)
  secondaryRingColor?: string | ((args: {isActive: boolean; isDone: boolean}) => string)
  bgColor?: string | ((args: {isActive: boolean; isDone: boolean}) => string)
  textColor?: string | ((args: {isActive: boolean; isDone: boolean}) => string)
  children?: React.ReactNode
}> = ({
  active = true,
  progress: progressUnprocessed,
  children,
  ringColor = ({isActive, isDone}) =>
    isActive
      ? tailwindColors["cr-blue"].DEFAULT
      : isDone
        ? tailwindColors["cr-blue"]["mid-light"]
        : tailwindColors["cr-grey"]["30"],
  secondaryRingColor = tailwindColors["cr-grey"]["15"],
  bgColor = ({isActive, isDone}) =>
    isActive
      ? tailwindColors["cr-blue"].DEFAULT
      : isDone
        ? tailwindColors["cr-blue"]["mid-light"]
        : tailwindColors["cr-grey"]["15"],
  textColor = ({isActive, isDone}) =>
    isDone ? tailwindColors["cr-white"] : isActive ? tailwindColors["cr-white"] : tailwindColors["cr-grey"]["30"],
}) => {
  const progress = Math.min(100, Math.max(0, progressUnprocessed))

  const state = {isActive: active, isDone: progress === 100}

  return (
    <div
      className={"circular-progress flex items-center justify-center p-[2px]"}
      style={
        {
          "--circular-progress-color-primary": resolveColor(ringColor, state),
          "--circular-progress-percentage": `${progress}%`,
          "--circular-progress-color-secondary": resolveColor(secondaryRingColor, state),
        } as React.CSSProperties
      }
    >
      <div
        style={{
          backgroundColor: resolveColor(bgColor, state),
          color: resolveColor(textColor, state),
        }}
        className={twMerge([
          "flex h-full w-full items-center justify-center rounded-full border-[2px] border-cr-white text-sm font-bold",
        ])}
      >
        {children ?? (progress === 100 ? <CheckIcon className={"h-6 w-6"} /> : <span>{progress}%</span>)}
      </div>
    </div>
  )
}
