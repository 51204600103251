import React from "react"
import {FieldPath, FieldValues, useFormContext} from "react-hook-form"

import {IRegisterField} from "./components"

export interface ICheckboxButton extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "className"> {
  name: string
}

export const CheckboxButtonNative = React.forwardRef<HTMLInputElement, ICheckboxButton>(
  ({id: idProp, ...props}, ref) => {
    const reactId = React.useId()
    const id = idProp ?? reactId

    return (
      <div className={"inline-block"} data-testid={"checkbox-button"}>
        {/* The input cannot be set to display: none as that messes with setting isTouched by react-hook-form */}
        <input ref={ref} {...props} id={id} type={"checkbox"} className={"peer absolute opacity-0"} />

        <label htmlFor={id} className={"btn-peer relative"}>
          {props.value}
        </label>
      </div>
    )
  }
)
CheckboxButtonNative.displayName = "CheckboxButton"

function CheckboxButton<T extends FieldValues, N extends FieldPath<T>>({
  name,
  options,
  ...props
}: ICheckboxButton & IRegisterField<T, N>) {
  const {register} = useFormContext<T>()

  return <CheckboxButtonNative {...register(name, options)} {...props} />
}

export default CheckboxButton
