import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {TrashIcon} from "@heroicons/react/24/outline"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import {GenericErrorAlert} from "../../../components/Alert"
import Button, {ButtonForm} from "../../../components/Button"
import {Flyout} from "../../../components/Flyout"
import {UnstyledLink} from "../../../components/Link"
import {Loading} from "../../../components/Loading"
import {TabItem, TabsContainer} from "../../../components/Tabs"
import {i18n} from "../../../i18n"
import {useLeadDetailQuery, useUpdateLeadMutation} from "../../../queries/leads"
import {ALeadDetail} from "../../../services/types.generated"
import {getFullName} from "../../../utils"
import {usePrevious} from "../../../utils/hooks"
import {
  requiredFieldMessage,
  setFormErrorsFromAxios,
  validateEmail,
  validateNonemptyArray,
  validateNonemptyString,
} from "../../../utils/validation"
import {WarningsContext} from "../../Prospects/shared/context"
import {DeletingContext, EditingContext} from "../context"
import {Details} from "./Details"
import {LeadInfo} from "./LeadInfo"

const validationSchema = z.object({
  organization_name: validateNonemptyString(),
  organization_number: z.string().nullable(),
  segment: validateNonemptyArray(validateNonemptyString()),
  city: z.string().nullable(),
  country: z.object({
    id: z.number({required_error: requiredFieldMessage}),
  }),
  website: z.string().nullable(),
  organization_phone_number: z.string().nullable(),

  contact_person_first_name: z.string().nullable(),
  contact_person_last_name: z.string().nullable(),
  position: z.string().nullable(),
  phone_number: z.string().nullable(),
  email: validateEmail().nullable(),

  additional_phone_number: z.string().nullable(),
  notes: z.string().nullable(),
})

type TTab = "lead info" | "details" | "history"
export type TTabComponentProps = {lead: ALeadDetail}
const tabs = [
  {tab: "lead info", title: i18n.t("Leads_EditingFlyout_LeadInfo"), component: LeadInfo},
  {tab: "details", title: i18n.t("Leads_EditingFlyout_Details"), component: Details},
] as const satisfies Array<{tab: TTab; title: string; component: React.ComponentType<TTabComponentProps>}>

export const EditingFlyout: React.FC = () => {
  const {t} = useTranslation()

  const {value: currentLead, setValue: setEditingLead} = EditingContext.useContext()
  const lastLead = usePrevious(currentLead)
  const lead = currentLead ?? lastLead

  const deleteContext = DeletingContext.useContext()

  const {data, isFetching, error, refetch} = useLeadDetailQuery(lead?.id)
  const updateMutation = useUpdateLeadMutation()

  const warningsContextValue = WarningsContext.useProviderValue({})
  const setWarnings = warningsContextValue.setValue

  const methods = useForm<ALeadDetail>({
    mode: "onTouched",
    resolver: zodResolver(validationSchema),
  })

  React.useEffect(() => {
    if (!currentLead) {
      return
    }

    methods.reset(currentLead)
    setCurrentTab("lead info")
  }, [currentLead, methods])

  React.useEffect(() => {
    if (lastLead?.id === currentLead?.id) {
      return
    }
    setWarnings({})
  }, [currentLead?.id, lastLead?.id, setWarnings])

  const handleSubmit = React.useCallback(async () => {
    try {
      const response = await updateMutation.mutateAsync(methods.getValues())
      const newLead = response.data.lead

      setWarnings(response.data.meta?.warnings ?? {})
      setEditingLead(newLead)

      toast.success(t("Leads_EditingFlyout_SuccessToast"))
    } catch (e) {
      setFormErrorsFromAxios(e, methods.setError, "lead")
    }
  }, [methods, setEditingLead, t, updateMutation, setWarnings])

  const [currentTab, setCurrentTab] = React.useState<TTab>("lead info")
  const TabComponent = React.useMemo(() => {
    return tabs.find(({tab}) => tab === currentTab)?.component ?? (() => null)
  }, [currentTab])

  return (
    <WarningsContext.Provider value={warningsContextValue}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Flyout
            title={
              <>
                <span className={"font-bold"}>{lead?.organization_name}</span>
                <span className={"font-normal"}>
                  {" "}
                  -{" "}
                  {getFullName({
                    first_name: lead?.contact_person_first_name,
                    last_name: lead?.contact_person_last_name,
                  })}
                </span>
              </>
            }
            subtitle={`#${lead?.id}`}
            buttons={
              <>
                <UnstyledLink
                  className={"cursor-pointer transition-all hover:text-cr-red"}
                  onClick={() => {
                    deleteContext.setValue(currentLead)
                  }}
                >
                  <TrashIcon className={"h-6 w-6"} />
                </UnstyledLink>
                <div className={"flex items-center gap-4"}>
                  <Button variant={"outlined"} color={"gray"} onClick={() => setEditingLead(null)}>
                    {t("Leads_EditingFlyout_Cancel")}
                  </Button>
                  <ButtonForm>{t("Leads_EditingFlyout_Save")}</ButtonForm>
                </div>
              </>
            }
            isOpen={!!currentLead}
            onClose={() => setEditingLead(null)}
          >
            <TabsContainer className={"px-6"} size={"sm"}>
              {tabs.map(({title, tab}) => (
                <TabItem key={tab} onClick={() => setCurrentTab(tab)} isActive={tab === currentTab}>
                  {title}
                </TabItem>
              ))}
            </TabsContainer>
            {error ? (
              <GenericErrorAlert retry={refetch} />
            ) : isFetching || !data ? (
              <Loading />
            ) : (
              <TabComponent lead={data} />
            )}
          </Flyout>
        </form>
      </FormProvider>
    </WarningsContext.Provider>
  )
}
