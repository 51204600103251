import React from "react"
import {useTranslation} from "react-i18next"

export const Deleted: React.FC = () => {
  const {t} = useTranslation()

  return (
    <div className={"flex grow flex-col items-center justify-center gap-16 text-center"}>
      <h1 className={"text-6xl font-semibold"}>{t("GDPR_Deleted_Title")}</h1>
      <h2 className={"text-cr-grey-50"}>{t("GDPR_Deleted_Subtitle")}</h2>
    </div>
  )
}
