import React from "react"
import {Controller} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {FolderPlusIcon, TrashIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {EMPTY_ARRAY} from "../../utils"
import {useFilePicker} from "../../utils/hooks.tsx"
import {getFileNameFromURL} from "../../utils/url.ts"
import {IconButton} from "../Button.tsx"
import {Link} from "../Link.tsx"
import {ConfirmModal} from "../Modal.tsx"

export type TUploadedApiFile = {
  id: number
  url: string
}

type TProps = {
  files: File[]
  multiple?: boolean
  onChange: (files: File[]) => void
}

export const FileUploader: React.FC<TProps> = ({files, multiple, onChange}) => {
  const handleChange = React.useCallback(
    (value: File[]) => {
      if (value.length) {
        onChange(multiple ? [...files, ...value] : [value[0]])
      }
    },
    [files, multiple, onChange]
  )

  const onOpenFileDialog = useFilePicker({onChange: handleChange, multiple})

  const [isDragging, setIsDragging] = React.useState(false)

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
    const fileList = e.dataTransfer.files
    handleChange([...fileList])
  }

  const handleFileRemove = (index: number) => {
    const newValue = [...files]
    newValue.splice(index, 1)
    onChange(newValue)
  }

  return (
    <div className={"flex flex-col gap-6"}>
      <div
        className={twMerge(
          "flex flex-col items-center justify-items-center gap-3 rounded-md border-2 border-dashed py-12",
          "cursor-pointer text-cr-grey-30",
          isDragging && "bg-cr-grey-5"
        )}
        onClick={onOpenFileDialog}
        onDragOver={e => e.preventDefault()}
        onDrop={handleDropFile}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
      >
        <div className={twMerge("contents", isDragging && "pointer-events-none")}>
          <FolderPlusIcon className={"size-12"} />
          <div>
            <Trans
              i18nKey={"FileUploader_Label"}
              components={{
                uploadLink: <Link noUnderline className={"cursor-pointer"} />,
              }}
            />
          </div>
        </div>
      </div>
      <UploadedFiles fileNames={files.map(file => file.name)} onRemove={handleFileRemove} />
    </div>
  )
}

export const UploadedFiles: React.FC<{fileNames: React.ReactNode[]; onRemove: (index: number) => void}> = ({
  fileNames,
  onRemove,
}) => {
  if (!fileNames.length) {
    return null
  }

  return (
    <div className={"flex flex-col gap-3"} data-testid={"uploaded-files"}>
      {fileNames.map((fileName, index) => (
        <div
          key={index}
          className={
            "flex items-center justify-between gap-4 rounded-lg border border-cr-blue-light bg-cr-blue-super-light px-4 py-3"
          }
        >
          <div className={"overflow-hidden text-ellipsis whitespace-nowrap font-medium"}>{fileName}</div>
          <IconButton type={"button"} className={"hover:text-cr-red"} onClick={() => onRemove(index)}>
            <TrashIcon className={"size-6"} />
          </IconButton>
        </div>
      ))}
    </div>
  )
}

export const FileUploaderConnected: React.FC<{name: string; multiple?: boolean}> = ({name, multiple}) => {
  return (
    <Controller
      name={name}
      render={({field}) => {
        return <FileUploader files={field.value ?? []} multiple={multiple} onChange={field.onChange} />
      }}
    />
  )
}

export const UploadedFilesWithDeleteConfirm: React.FC<{
  files: TUploadedApiFile[] | undefined
  onConfirm: (file: TUploadedApiFile) => Promise<void>
}> = ({files = EMPTY_ARRAY, onConfirm}) => {
  const {t} = useTranslation()
  const [fileToDelete, setFileToDelete] = React.useState<TUploadedApiFile | null>(null)

  const fileNames = React.useMemo(
    () =>
      files.map(({url}) => {
        const fileName = getFileNameFromURL(url)

        return (
          <Link to={url} target={"_blank"}>
            {decodeURIComponent(fileName ?? url)}
          </Link>
        )
      }),
    [files]
  )

  const handleFileRemoveConfirm = React.useCallback(async () => {
    if (!fileToDelete) {
      return
    }

    try {
      await onConfirm(fileToDelete)
    } catch {
      toast.error(t("FileUploader_DeleteFileModal_Error"))
    }
  }, [fileToDelete, onConfirm, t])

  if (!fileNames.length) {
    return null
  }

  return (
    <div className={"flex flex-col gap-2"}>
      <div className={"text-sm"}>{t("FileUploader_CurrentlyUploaded")}:</div>
      <UploadedFiles fileNames={fileNames} onRemove={index => setFileToDelete(files[index] ?? null)} />
      <ConfirmModal
        variant={"error"}
        title={t("FileUploader_DeleteFileModal_Title")}
        confirmButtonText={t("FileUploader_DeleteFileModal_ConfirmButton")}
        isOpen={!!fileToDelete}
        onClose={() => setFileToDelete(null)}
        onConfirm={handleFileRemoveConfirm}
      >
        {t("FileUploader_DeleteFileModal_Text")}
      </ConfirmModal>
    </div>
  )
}
