import {addDays, endOfDay, endOfMonth, startOfDay} from "date-fns"

import {i18n} from "../../../i18n.ts"
import {ADashboardStages} from "../../../services/types.generated.ts"

const stageOrder = [
  ADashboardStages.FirstOutreach,
  ADashboardStages.SecondOutreach,
  ADashboardStages.PresentationDemo,
  ADashboardStages.Closing,
] as const satisfies ADashboardStages[]

export const getStageTimings = (startDate: Date): Array<{stage: ADashboardStages; start: Date; end: Date}> => {
  return [
    {stage: ADashboardStages.FirstOutreach, start: startDate, end: endOfDay(addDays(startDate, 3))},
    {
      stage: ADashboardStages.SecondOutreach,
      start: startOfDay(addDays(startDate, 4)),
      end: endOfDay(addDays(startDate, 9)),
    },
    {
      stage: ADashboardStages.PresentationDemo,
      start: startOfDay(addDays(startDate, 10)),
      end: endOfDay(addDays(startDate, 19)),
    },
    {stage: ADashboardStages.Closing, start: startOfDay(addDays(startDate, 20)), end: endOfMonth(startDate)},
  ]
}

export const getStageIndex = (stageToFind: ADashboardStages | undefined) => {
  return stageOrder.findIndex(stage => stage === stageToFind)
}

export function getCurrentStage(startDate: Date): {
  stage: ADashboardStages
  progress: number
  start: Date
  end: Date
} | null {
  const now = new Date()
  const stageTimings = getStageTimings(startDate)

  if (stageTimings[0].start > now) {
    return null
  }

  for (const {stage, start, end} of stageTimings) {
    if (now >= start && now <= end) {
      const stageDuration = end.getTime() - start.getTime()
      const currentTimeOfStage = now.getTime() - start.getTime()

      const progress = Math.floor((currentTimeOfStage / stageDuration) * 100)

      return {stage, progress, start, end}
    }
  }

  return null
}

export const titleByStage = {
  [ADashboardStages.FirstOutreach]: i18n.t("Dashboard_Stages_FirstOutreach"),
  [ADashboardStages.SecondOutreach]: i18n.t("Dashboard_Stages_SecondOutreach"),
  [ADashboardStages.PresentationDemo]: i18n.t("Dashboard_Stages_PresentationDemo"),
  [ADashboardStages.Closing]: i18n.t("Dashboard_Stages_Closing"),
} satisfies {[stage in ADashboardStages]: string}
