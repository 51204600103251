import React from "react"
import {useLocation} from "react-router-dom"

export function getStepFromPathname<TSteps extends ReadonlyArray<{href: string}>>(
  pathname: string,
  steps: TSteps
): {step: TSteps[number]; stepIndex: number} {
  for (const [index, step] of Object.entries(steps)) {
    if (new RegExp(`.*\\/${step.href}([/?#]|$)`).test(pathname)) {
      return {step, stepIndex: Number(index)}
    }
  }

  throw new Error("Unknown step")
}

export function useCurrentStep<TSteps extends ReadonlyArray<{href: string}>>(
  steps: TSteps
): ReturnType<typeof getStepFromPathname<TSteps>> {
  const {pathname} = useLocation()

  return React.useMemo(() => getStepFromPathname(pathname, steps), [pathname, steps])
}

export function usePreviousStep<TSteps extends ReadonlyArray<{href: string}>>(
  steps: TSteps
): ReturnType<typeof getStepFromPathname<TSteps>> | null {
  const currentStep = useCurrentStep(steps)
  const currentStepRef = React.useRef(currentStep)
  const [previousStep, setPreviousStep] = React.useState(currentStep)

  React.useEffect(() => {
    setPreviousStep(currentStepRef.current)
    currentStepRef.current = currentStep
  }, [currentStep])

  return previousStep.stepIndex === currentStep.stepIndex ? null : previousStep
}
