import {loadStripe} from "@stripe/stripe-js"
import {useQuery} from "@tanstack/react-query"

import {queryKey} from "../services"
import {useUserSettingsOrLogout} from "./user.ts"

export function useStripeQuery() {
  const apiKey = useUserSettingsOrLogout().payments.publishable_api_key

  return useQuery({queryKey: queryKey.stripe(apiKey), queryFn: () => loadStripe(apiKey), staleTime: Infinity})
}
