import React from "react"
import {PencilSquareIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {i18n} from "../i18n"
import {useFilePicker, useLoadPicture} from "../utils/hooks"
import {TFormValidationError} from "../utils/validation"
import {ErrorMessage} from "./fields/components"
import {Loading} from "./Loading"
import {PlaceholderAvatar} from "./ProfileImage.tsx"

export type TUploadablePictureOnChange = (file: File) => Promise<TFormValidationError | undefined>

type TPencilPosition = "top-left" | "top-right" | "bottom-left" | "bottom-right"

const pencilPositionClassNames = {
  "top-left": {
    pencil:
      "left-0 top-0 group-hover:top-1/2 group-hover:left-1/2 group-hover:-translate-x-1/2 group-hover:-translate-y-1/2",
    background: "origin-top-left",
  },
  "top-right": {
    pencil:
      "right-0 top-0 group-hover:right-1/2 group-hover:top-1/2 group-hover:translate-x-1/2 group-hover:-translate-y-1/2",
    background: "origin-top-right",
  },
  "bottom-left": {
    pencil:
      "left-0 bottom-0 group-hover:left-1/2 group-hover:bottom-1/2 group-hover:-translate-x-1/2 group-hover:translate-y-1/2",
    background: "origin-bottom-left",
  },
  "bottom-right": {
    pencil:
      "right-0 bottom-0 group-hover:right-1/2 group-hover:bottom-1/2 group-hover:translate-x-1/2 group-hover:translate-y-1/2",
    background: "origin-bottom-right",
  },
} as const satisfies {[position in TPencilPosition]: {pencil: string; background: string}}

type TProps = {
  url?: string
  pencilPosition: TPencilPosition
  maxSize?: number
  maxSizeMessage?: string
  rounded?: boolean
  imageClassNames?: string
  onChange: TUploadablePictureOnChange
}

export const UploadablePicture: React.FC<TProps> = ({
  url,
  pencilPosition,
  maxSize = Infinity,
  maxSizeMessage = i18n.t("T_The file you have picked is too large."),
  rounded = false,
  imageClassNames = "",
  onChange,
}) => {
  const [pictureUploadError, setPictureUploadError] = React.useState<TFormValidationError | null>(null)
  const [isUploading, setIsUploading] = React.useState(false)

  const {isLoading} = useLoadPicture(url ?? "")

  const handleChange = async ([file]: File[]) => {
    if (file.size > maxSize) {
      setPictureUploadError({message: maxSizeMessage})
      return
    }

    setPictureUploadError(null)
    setIsUploading(true)

    const error = await onChange(file)

    setPictureUploadError(error ?? null)
    setIsUploading(false)
  }

  const onOpenFileDialog = useFilePicker({
    onChange: handleChange,
    accept: "image/png, image/jpeg",
  })

  return (
    <div className={"flex flex-col items-center"}>
      {(isLoading && url) || isUploading ? (
        <div
          className={twMerge(
            "pointer-events-none relative h-40 w-40 bg-cr-grey-5",
            rounded && "rounded-full",
            imageClassNames
          )}
        >
          <Loading size={"xl"} delayShow={false} />
        </div>
      ) : (
        <button
          onClick={onOpenFileDialog}
          className={twMerge(
            "group relative size-40 cursor-pointer bg-cr-grey-5 bg-cover bg-center",
            rounded && "rounded-full",
            imageClassNames
          )}
          style={url ? {backgroundImage: `url("${url}")`} : undefined}
        >
          {!url && <PlaceholderAvatar className={"size-full rounded-full"} />}
          <div
            className={twMerge(
              "pointer-events-none absolute -inset-2 scale-0 bg-white/70 transition-all group-hover:scale-100",
              rounded && "rounded-full",
              pencilPositionClassNames[pencilPosition].background
            )}
          />
          <PencilSquareIcon
            className={twMerge(
              "pointer-events-none absolute w-5 text-cr-grey-50 transition-all group-hover:w-16 group-hover:text-black",
              pencilPositionClassNames[pencilPosition].pencil
            )}
          />
        </button>
      )}
      <ErrorMessage error={pictureUploadError} />
    </div>
  )
}
