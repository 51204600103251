import React from "react"
import {Trans, useTranslation} from "react-i18next"

import {ConfirmModal} from "../../../components/Modal.tsx"
import {useReactivateUserMutation} from "../../../queries/saasUserManagement.ts"
import requestError from "../../../services/requestError.tsx"
import {getFullName} from "../../../utils"
import {commonTransComponents} from "../../../utils/i18n.tsx"
import {ReactivateUserContext} from "../context.ts"

export const ReactivateUserModal: React.FC = () => {
  const {t} = useTranslation()

  const context = ReactivateUserContext.useContext()
  const user = context.value

  const reactivateUserMutation = useReactivateUserMutation()

  const handleReactivate = React.useCallback(async () => {
    try {
      if (user?.id === undefined) {
        return false
      }

      await reactivateUserMutation.mutateAsync({id: user.id})
    } catch (e) {
      requestError(e)
      return false
    }
  }, [reactivateUserMutation, user?.id])

  return (
    <ConfirmModal
      onConfirm={handleReactivate}
      isOpen={!!user}
      onClose={() => context.setValue(null)}
      title={t("SaaSUserManagement_Table_Reactivate_Title")}
      variant={"info"}
      confirmButtonText={t("SaaSUserManagement_Table_Reactivate_ConfirmButton")}
    >
      <Trans
        i18nKey={"SaaSUserManagement_Table_Reactivate_Text"}
        values={{name: getFullName(user, user?.email ?? t("SaaSUserManagement_Table_Reactivate_NamePlaceholder"))}}
        components={commonTransComponents}
      />
    </ConfirmModal>
  )
}
