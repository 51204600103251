import React from "react"

export const Section: React.FC<{title: React.ReactNode; subtitle?: React.ReactNode; children: React.ReactNode}> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <div className={"flex gap-10 rounded-lg border border-cr-blue-light p-6 max-sm:flex-col"}>
      <div className={"flex flex-col sm:w-[330px] sm:shrink-0 sm:grow-0"}>
        <h2 className={"text-lg font-bold"}>{title}</h2>
        {subtitle && <h3 className={"text-cr-grey-50"}>{subtitle}</h3>}
      </div>

      <div className={"flex grow flex-col items-start gap-10"}>{children}</div>
    </div>
  )
}

export const Subsection: React.FC<{title: React.ReactNode; children: React.ReactNode}> = ({title, children}) => {
  return (
    <div className={"flex flex-col gap-3"}>
      <h4 className={"text-sm text-cr-grey-50"}>{title}</h4>
      <div className={"whitespace-pre-wrap"}>{children}</div>
    </div>
  )
}
