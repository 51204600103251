import React from "react"

import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {useProspectsLifetimeDetailQuery} from "../../../queries/prospects.ts"
import {AAdminsProspectsSalesCycle} from "../../../services/types.generated.ts"
import {Section} from "./Section.tsx"

export const ProspectsLifetime: React.FC<{
  salesCycle: AAdminsProspectsSalesCycle
}> = ({salesCycle}) => {
  return (
    <LayoutBlock innerClassName={"flex flex-col gap-9"}>
      <Section
        assignmentId={-1}
        salesCycleId={salesCycle.id}
        isRejectedOnlyToggleVisible
        listQuery={useProspectsLifetimeDetailQuery}
        paginationKey={"prospects admin lifetime"}
      />
    </LayoutBlock>
  )
}
