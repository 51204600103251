import React from "react"
import {twMerge} from "tailwind-merge"

export const SectionHeader: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <div
      className={twMerge([
        "mt-10 inline-block self-center rounded-full bg-cr-blue px-4 py-2 font-bold text-cr-white",
        "max-sm:self-start max-sm:rounded-l-none",
      ])}
    >
      {children}
    </div>
  )
}
export const SectionSubheader: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <div
      className={twMerge([
        "z-20 inline-block self-center rounded-full bg-cr-cyan-light px-4 py-2 text-xs font-bold uppercase text-cr-black",
        "max-sm:self-start max-sm:rounded-l-none",
      ])}
    >
      {children}
    </div>
  )
}
