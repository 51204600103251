import React from "react"
import {useTranslation} from "react-i18next"
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {GenericErrorAlert} from "../../../../components/Alert.tsx"
import {IconButton} from "../../../../components/Button.tsx"
import {EnumFilter} from "../../../../components/EnumFilter.tsx"
import {ExpandableBox} from "../../../../components/ExpandableBox.tsx"
import {InputBase} from "../../../../components/fields/Input.tsx"
import Pagination from "../../../../components/Pagination.tsx"
import {RowCountPickerLS, usePageSize} from "../../../../components/RowCountPicker.tsx"
import {Table} from "../../../../components/Table"
import {TColumnsMetaWithEmpty} from "../../../../components/Table/shared.ts"
import {Tooltip} from "../../../../components/Tooltip.tsx"
import {getCurrentLanguage} from "../../../../i18n.ts"
import {useReportsProspectsQuery, useReportsSalesCycleQuery} from "../../../../queries/reports.ts"
import {useUserSettingsOrLogout} from "../../../../queries/user.ts"
import {
  ACompanyUserSalesCycleProspects,
  ASalesCycleFilterStages,
  ASalesCycleProspectStages,
  AUserTypes,
} from "../../../../services/types.generated.ts"
import {EMPTY_ARRAY} from "../../../../utils"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {enumToOptions, enumTranslKey} from "../../../../utils/i18n.tsx"
import {isSaaSCompanyUser} from "../../../../utils/types.ts"
import {ActivityFeedFlyout} from "../../components/ActivityFeedFlyout.tsx"
import {DownloadReportButton} from "../../components/DownloadReportButton.tsx"
import {StatusBadgeCell} from "../../components/StatusBadgeCell.tsx"
import {
  ActivityFeedContext,
  ProspectsFilteringContext,
  TLastChangeOption,
  TProspectsTableColumn,
  TProspectsTableMetadata,
  TStageOption,
  useCurrentAssignmentId,
  useCurrentSelectionState,
  useProspectsFiltering,
} from "../../hooks.ts"
import {SegmentFilter, StageFilter, StatusFilter} from "./Filters.tsx"

const lastUpdateOptions = enumToOptions("LastUpdate") as TLastChangeOption[]

const paginationKey = "reports prospects section"

export const ProspectsSection: React.FC = () => {
  const {t} = useTranslation()
  const prospectsFiltering = ProspectsFilteringContext.useProviderValue(useProspectsFiltering())

  const {user} = useUserSettingsOrLogout()
  const isAdminUser = user.type === AUserTypes.Admin
  const {
    value: {salesCycleId, iterationId},
  } = useCurrentSelectionState()
  const assignmentId = useCurrentAssignmentId()

  const activityFeedContextValue = ActivityFeedContext.useProviderValue(null)

  const {
    orderBy,
    setOrderBy,
    searchString,
    searchStringDebounced,
    setSearchString,
    lastUpdateFilter,
    lastUpdateFilterDebounced,
    setLastUpdateFilter,
    segmentFilter,
    segmentFilterDebounced,
    setSegmentFilter,
    stageFilter,
    stageFilterDebounced,
    setStageFilter,
    statusFilter,
    statusFilterDebounced,
    setStatusFilter,
    resetAllFilters,
  } = prospectsFiltering

  const {data, error, pagination, isFetching, refetch} = useReportsProspectsQuery({
    salesCycleId,
    iterationId,
    assignmentId,
    orderBy,
    pageSize: usePageSize(paginationKey),
    lastUpdate: lastUpdateFilterDebounced?.value,
    searchString: searchStringDebounced,
    segments: segmentFilterDebounced.map(opt => opt.value),
    stages: stageFilterDebounced.map(opt => opt.value),
    statuses: statusFilterDebounced.map(opt => opt.value),
  })
  const prospects = (data?.data.prospects ?? EMPTY_ARRAY) as ACompanyUserSalesCycleProspects[]

  const prospectStageOptions = React.useMemo<TStageOption[]>(() => {
    const stagesUsed = data?.data.meta?.stages_used ?? []
    const stagesSelected = stageFilter.map(option => option.value)

    const stagesToShow =
      stagesUsed.length > 0
        ? Array.from(new Set([...stagesUsed, ...stagesSelected]))
        : (Object.values(ASalesCycleProspectStages) as unknown as ASalesCycleFilterStages[])

    return (enumToOptions("ProspectStage") as TStageOption[]).filter(({value}) => stagesToShow.includes(value))
  }, [data?.data.meta?.stages_used, stageFilter])

  // reset all filters when iteration changes
  React.useEffect(() => {
    resetAllFilters()
  }, [iterationId, resetAllFilters])

  const handleClickRow = React.useMemo(() => {
    return isAdminUser || isSaaSCompanyUser(user)
      ? (row: ACompanyUserSalesCycleProspects) => {
          return () => activityFeedContextValue.setValue(row)
        }
      : undefined
  }, [activityFeedContextValue, isAdminUser, user])

  const totalProspectsCount = data?.data.meta?.total_prospects_in_db

  const columnsMeta = React.useMemo<TColumnsMetaWithEmpty<TProspectsTableColumn, ACompanyUserSalesCycleProspects>>(
    () => [
      isAdminUser && {
        column: "action",
        HeaderCellValue: () => t("Reports_Prospects_Table_Action_Title"),
        CellValue: ({row}) => {
          const url = row.crm_url
          const isDisabled = !url

          return (
            <Tooltip
              disabled={isDisabled}
              buttonNode={
                <IconButton
                  className={twMerge("bg-cr-white", isDisabled ? "text-cr-grey-30" : "hover:bg-cr-grey-5")}
                  disabled={isDisabled}
                  onClick={url ? () => window.open(url, "_blank") : undefined}
                  noEdges
                >
                  <ArrowTopRightOnSquareIcon className={"size-5"} />
                </IconButton>
              }
              closeDelay={0}
            >
              <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
                {t("Reports_Prospects_Table_Action_OpenCRM")}
              </div>
            </Tooltip>
          )
        },
      },
      {
        column: "status",
        HeaderCellValue: () => t("Reports_Prospects_Table_Status"),
        CellValue: StatusBadgeCell,
        isFiltered: () => statusFilter.length > 0,
        clearFilter: () => setStatusFilter([]),
        FilterContent: StatusFilter,
      },
      {
        column: "organization_name",
        sortFn: true,
        HeaderCellValue: () => t("Reports_Prospects_Table_Organization"),
        CellValue: ({row}) => <span className={"font-bold"}>{row.organization_name}</span>,
      },
      {
        column: "notes",
        HeaderCellValue: () => t("Reports_Prospects_Table_Notes"),
        CellValue: ({row}) => (row.notes ? <ExpandableBox>{row.notes}</ExpandableBox> : "-"),
      },
      {
        column: "segment",
        HeaderCellValue: () => t("Reports_Prospects_Table_Segment"),
        CellValue: ({row}) => row.segment?.join(", ") ?? "-",
        isFiltered: () => segmentFilter.length > 0,
        clearFilter: () => setSegmentFilter([]),
        FilterContent: SegmentFilter,
      },
      {
        column: "stage",
        HeaderCellValue: () => t("Reports_Prospects_Table_Stage"),
        CellValue: ({row}) => (row.stage ? t(enumTranslKey("ProspectStage", row.stage)) : "-"),
        isFiltered: () => stageFilter.length > 0,
        clearFilter: () => setStageFilter([]),
        FilterContent: StageFilter,
      },
      {
        column: "deal_details",
        HeaderCellValue: () => t("Reports_Prospects_Table_DealDetails"),
        CellValue: ({row}) => (row.deal_details ? <ExpandableBox>{row.deal_details}</ExpandableBox> : "-"),
      },
      {
        column: "meeting_at",
        HeaderCellValue: () => t("Reports_Prospects_Table_Meeting"),
        CellValue: ({row}) =>
          row.meeting_at ? apiDateToJS(row.meeting_at).toLocaleString(getCurrentLanguage(), {dateStyle: "long"}) : "-",
      },
      {
        column: "lost_reason",
        HeaderCellValue: () => t("Reports_Prospects_Table_LostReason"),
        CellValue: ({row}) => (row.lost_reason ? <ExpandableBox>{row.lost_reason}</ExpandableBox> : "-"),
      },
      {
        column: "position",
        HeaderCellValue: () => t("Reports_Prospects_Table_Position"),
      },
    ],
    [
      isAdminUser,
      segmentFilter.length,
      setSegmentFilter,
      setStageFilter,
      setStatusFilter,
      stageFilter.length,
      statusFilter.length,
      t,
    ]
  )

  if (error) {
    return (
      <ProspectsCard>
        <GenericErrorAlert retry={refetch} />
      </ProspectsCard>
    )
  }

  return (
    <ProspectsFilteringContext.Provider value={prospectsFiltering}>
      <ActivityFeedContext.Provider value={activityFeedContextValue}>
        <ProspectsCard actions={<DownloadReportButton />}>
          <div className={"flex flex-wrap-reverse items-center justify-end gap-6"}>
            {iterationId == null && (
              <>
                <div className={"font-semibold"}>{t("Reports_Prospects_Table_Filters")}:</div>
                <EnumFilter
                  options={lastUpdateOptions}
                  title={t("Reports_Prospects_Table_LastUpdate")}
                  multiple={false}
                  value={lastUpdateFilter}
                  onChange={setLastUpdateFilter}
                />
              </>
            )}
            <InputBase
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
              placeholder={t("SearchPlaceholder")}
            />
          </div>
          <div className={"overflow-auto"}>
            <Table<TProspectsTableColumn, ACompanyUserSalesCycleProspects, TProspectsTableMetadata>
              metadata={{stageOptions: prospectStageOptions}}
              className={"min-w-max"}
              data={prospects}
              loading={isFetching}
              orderBy={orderBy}
              onOrder={setOrderBy}
              ghost
              columnsMeta={columnsMeta}
            >
              {({data}) => (
                <>
                  {data.length > 0 &&
                    data.map(row => (
                      <Table.Row
                        key={row.id}
                        testId={`row-${row.id}`}
                        row={row}
                        className={"max-w-md whitespace-pre-wrap px-6 py-4"}
                        onClick={handleClickRow?.(row)}
                      />
                    ))}
                  {data.length === 0 && !isFetching && (
                    <div className={"col-span-full py-7 text-center text-sm"}>
                      {totalProspectsCount
                        ? t("Reports_Prospects_Table_NoProspects_Filtered")
                        : t("Reports_Prospects_Table_NoProspects_Total")}
                    </div>
                  )}
                </>
              )}
            </Table>
          </div>
          <RowCountPickerLS total={pagination.total} autoHide paginationKey={paginationKey} />
          <Pagination {...pagination} autoHide />
        </ProspectsCard>
        <ActivityFeedFlyout />
      </ActivityFeedContext.Provider>
    </ProspectsFilteringContext.Provider>
  )
}

const ProspectsCard: React.FC<{children: React.ReactNode; actions?: React.ReactNode}> = ({children, actions}) => {
  const {t} = useTranslation()

  const {
    value: {iterationId, salesCycleId},
  } = useCurrentSelectionState()

  const {data} = useReportsSalesCycleQuery(salesCycleId)
  const iterations = data?.sales_cycle_iterations ?? EMPTY_ARRAY

  const activeIteration = React.useMemo(() => {
    return iterations.find(iteration => iteration.id === iterationId)
  }, [iterationId, iterations])

  return (
    <div className={"card-shadow flex grow flex-col gap-6 p-6"}>
      <div className={"flex justify-between"}>
        <h2 className={"font-bold"}>
          {activeIteration
            ? t("Reports_Prospects_Title_Iteration", {
                date: apiDateToJS(activeIteration.start_date).toLocaleString(getCurrentLanguage(), {
                  month: "long",
                  year: "2-digit",
                }),
              })
            : t("Reports_Prospects_Title_Lifetime")}
        </h2>
        {actions}
      </div>
      {children}
    </div>
  )
}
