import {
  AAdminSalesCycleDetails,
  AAdminSalesCycleSalesNews,
  ACompanyUserSalesCycleDetails,
  ACompanyUserSalesCycleSalesNews,
  ASaasCompanyUserSalesCycle,
  ASalesPersonSalesCycleSalesNews,
} from "../../services/types.generated.ts"

export type TSalesCycleNews =
  | ACompanyUserSalesCycleSalesNews
  | ASalesPersonSalesCycleSalesNews
  | AAdminSalesCycleSalesNews

export const isSalespersonSalesCycleNews = (
  salesCycleNews: TSalesCycleNews
): salesCycleNews is ASalesPersonSalesCycleSalesNews => {
  return !("assignments" in salesCycleNews)
}

type TSalesCycleDetail = ACompanyUserSalesCycleDetails | AAdminSalesCycleDetails | ASaasCompanyUserSalesCycle

export const isSaasCompanySalesCycleDetails = (
  salesCycle: TSalesCycleDetail
): salesCycle is ASaasCompanyUserSalesCycle => {
  return "company" in salesCycle
}
