import React from "react"
import {skipToken, useMutation, useQuery, useQueryClient} from "@tanstack/react-query"

import {usePaginatedQuery} from "../components/Pagination"
import {EOrderDirection, TOrderBy} from "../components/Table/shared"
import {sortNumber} from "../components/Table/sortFunctions"
import {TProspectsTableColumn} from "../routes/Reports/hooks"
import api, {queryKey} from "../services"
import {
  AActivityEvents,
  ACompanyUsersScopedStatistics,
  AProspectLastChangeRanges,
  ASalesCycleFilterStages,
  ASalesCycleProspectAutocompleteFields,
  ASalesCycleProspectStatuses,
  ASalesCyclesProspectValues,
  AUserTypes,
} from "../services/types.generated"
import {EMPTY_ARRAY} from "../utils"
import {useValueByUserType} from "../utils/userTypes.ts"
import {directionToApi} from "./prospects"

export type TReportsMetric = keyof ACompanyUsersScopedStatistics

export const useReportsSalesCycleQuery = (salesCycleId: number) => {
  const endpoint = useReportsEndpoint("salesCyclesReportingDetail")

  return useQuery({
    queryKey: queryKey.reportsSalesCycle(salesCycleId),
    queryFn: async () => {
      return (await endpoint(salesCycleId)).data.sales_cycle
    },
  })
}

export const useReportsIterationQuery = (salesCycleId: number, iterationId: number | null) => {
  const endpoint = useReportsEndpoint("salesCyclesSalesCycleIterationsReportingDetail")

  return useQuery({
    queryKey: queryKey.reportsIteration(salesCycleId, iterationId),
    queryFn:
      iterationId == null
        ? skipToken
        : async () => {
            return (await endpoint(salesCycleId, iterationId)).data.sales_cycle_iteration
          },
  })
}

export const useReportsAssignmentQuery = (
  salesCycleId: number,
  iterationId: number | null,
  assignmentId: string | null
) => {
  const endpointIteration = useReportsEndpoint("salesCyclesSalesCycleIterationsAssignmentsReportingDetail")
  const endpointLifetime = useReportsEndpoint("salesCyclesAssignmentsReportingDetail")

  return useQuery({
    queryKey: queryKey.reportsAssignment(salesCycleId, iterationId, assignmentId),
    queryFn:
      assignmentId == null
        ? skipToken
        : async () => {
            const response =
              iterationId == null
                ? endpointLifetime(salesCycleId, assignmentId)
                : endpointIteration(salesCycleId, iterationId, assignmentId)

            return (await response).data.assignment
          },
  })
}

export function useReportsNewsAndInsightsQuery(id: number, iterationId: number | null) {
  const endpoint = useReportsEndpoint("salesCyclesNewsAndInsightsDetail")

  return useQuery({
    queryKey: queryKey.reportsNewsAndInsights(id, iterationId),
    queryFn: async () => {
      const queryParams = iterationId ? {sales_cycle_iteration_id: iterationId} : undefined

      return (await endpoint(id, queryParams)).data.sales_cycle_iteration
    },
  })
}

function prospectsSortColumnToApi(column?: TProspectsTableColumn): ASalesCyclesProspectValues | undefined {
  return column === "organization_name" ? ASalesCyclesProspectValues.OrganizationName : undefined
}

export const useReportsProspectsQuery = ({
  salesCycleId,
  iterationId,
  assignmentId,
  pageSize,
  ...filter
}: {
  salesCycleId: number
  iterationId: number | null
  assignmentId: string | null
  pageSize: number
  orderBy: TOrderBy<TProspectsTableColumn>
  searchString?: string
  lastUpdate?: AProspectLastChangeRanges
  segments?: string[]
  stages?: ASalesCycleFilterStages[]
  statuses?: ASalesCycleProspectStatuses[]
}) => {
  const endpoint = useReportsEndpoint("salesCyclesProspectsDetail")

  return usePaginatedQuery(
    queryKey.reportsProspects(salesCycleId, iterationId, assignmentId, filter),
    paginationQuery => {
      return endpoint(salesCycleId, {
        sales_cycle_iteration_id: iterationId ?? undefined,
        assignment_id: assignmentId ?? undefined,
        last_change: filter.lastUpdate,
        sort_by: prospectsSortColumnToApi(filter.orderBy?.column),
        sort_direction: directionToApi(filter.orderBy?.direction),
        search: filter.searchString,
        "statuses[]": filter.statuses,
        "stages[]": filter.stages,
        "segments[]": filter.segments,
        ...paginationQuery,
      })
    },
    {pageSize}
  )
}

export const getReportsProspectsAutocompleteQuery = (
  salesCycleId: number,
  field: ASalesCycleProspectAutocompleteFields
) => {
  return (searchString: string) => {
    const endpoint = useReportsEndpoint("salesCyclesProspectsAutocompleteValuesDetail")

    return useQuery({
      queryKey: queryKey.reportsProspectsAutocomplete(salesCycleId, field, searchString),
      queryFn: searchString
        ? async () => (await endpoint(salesCycleId, {field, value: searchString})).data.values
        : skipToken,
    })
  }
}

export const useReportsAssignmentsQuery = (salesCycleId: number, iterationId: number | null) => {
  const salesCycle = useReportsSalesCycleQuery(salesCycleId)
  const iteration = useReportsIterationQuery(salesCycleId, iterationId)

  const query = iterationId == null ? salesCycle : iteration
  const assignments = query.data?.assignments ?? EMPTY_ARRAY

  const sortedAssignments = React.useMemo(() => {
    return [...assignments].sort(
      sortNumber<(typeof assignments)[number]>(assignment => assignment.total_activities)(EOrderDirection.DESC)
    )
  }, [assignments])

  return {...query, data: sortedAssignments}
}

export const useReportsMetricsQuery = (
  salesCycleId: number,
  iterationId: number | null,
  assignmentId: string | null
) => {
  const salesCycle = useReportsSalesCycleQuery(salesCycleId)
  const iteration = useReportsIterationQuery(salesCycleId, iterationId)
  const assignment = useReportsAssignmentQuery(salesCycleId, iterationId, assignmentId)

  if (assignmentId) {
    return {...assignment, data: assignment.data?.statistics ?? undefined}
  }

  if (iterationId) {
    return {...iteration, data: iteration.data?.statistics ?? undefined}
  }

  return {...salesCycle, data: salesCycle.data?.statistics ?? undefined}
}

export const useReportsActivitiesChartQuery = (
  salesCycleId: number,
  iterationId: number | null,
  assignmentId: string | null
) => {
  const endpoint = useReportsEndpoint("salesCyclesActivitiesChartDetail")

  return useQuery({
    queryKey: queryKey.reportsChart("activities", salesCycleId, iterationId, assignmentId),
    queryFn: async () =>
      (
        await endpoint(salesCycleId, {
          sales_cycle_iteration_id: iterationId ?? undefined,
          assignment_id: assignmentId ?? undefined,
        })
      ).data.chart_data,
  })
}

export const useReportsClientsReachedChartQuery = (
  salesCycleId: number,
  iterationId: number | null,
  assignmentId: string | null
) => {
  const endpoint = useReportsEndpoint("salesCyclesClientsReachedChartDetail")

  return useQuery({
    queryKey: queryKey.reportsChart("clients_reached", salesCycleId, iterationId, assignmentId),
    queryFn: async () =>
      (
        await endpoint(salesCycleId, {
          sales_cycle_iteration_id: iterationId ?? undefined,
          assignment_id: assignmentId ?? undefined,
        })
      ).data.chart_data,
  })
}

export const useReportsActivityOccurrencesChartQuery = (
  activity: AActivityEvents,
  salesCycleId: number,
  iterationId: number | null,
  assignmentId: string | null
) => {
  const endpoint = useReportsEndpoint("salesCyclesActivityOccurrencesChartDetail")

  return useQuery({
    queryKey: queryKey.reportsChart(activity, salesCycleId, iterationId, assignmentId),
    queryFn: async () =>
      (
        await endpoint(salesCycleId, {
          sales_cycle_iteration_id: iterationId ?? undefined,
          assignment_id: assignmentId ?? undefined,
          activity_event: activity,
        })
      ).data.chart_data,
  })
}

export const useReportsOpportunitiesChartQuery = (
  salesCycleId: number,
  iterationId: number | null,
  assignmentId: string | null
) => {
  const endpoint = useReportsEndpoint("salesCyclesOpportunitiesChartDetail")

  return useQuery({
    queryKey: queryKey.reportsChart("opportunities", salesCycleId, iterationId, assignmentId),
    queryFn: async () =>
      (
        await endpoint(salesCycleId, {
          sales_cycle_iteration_id: iterationId ?? undefined,
          assignment_id: assignmentId ?? undefined,
        })
      ).data.chart_data,
  })
}

export const useSendReportMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({salesCycleId, iterationId}: {salesCycleId: number; iterationId: number}) =>
      api.admins.salesCyclesSalesCycleIterationsFinalizeReportCreate(salesCycleId, iterationId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({queryKey: queryKey.reportsSalesCycle(variables.salesCycleId)})
    },
  })
}

type TReportsApiKey = keyof typeof api.companyUsers & keyof typeof api.admins & keyof typeof api.saasCompanyUsers

const useReportsEndpoint = <T extends TReportsApiKey>(key: T) => {
  return useValueByUserType({
    [AUserTypes.CompanyUser]: api.companyUsers[key],
    [AUserTypes.Admin]: api.admins[key],
    [AUserTypes.SaasCompanyUser]: api.saasCompanyUsers[key],
  })
}
