import React from "react"

import {TCompanyProspectsAssignment} from "../../../../queries/prospects"
import {getFullName} from "../../../../utils"
import {AssignmentDisplay, TAssignmentVariant} from "../../shared/AssignmentDisplay"

export const getAssignmentPackageVariant = (assignmentPackage: TCompanyProspectsAssignment): TAssignmentVariant => {
  if (assignmentPackage.prospects_ready_to_approve_count === 0 && assignmentPackage.prospects_approved_count === 0) {
    return "initial"
  }

  if (
    assignmentPackage.prospects_approved_count >= assignmentPackage.prospects_mandatory_count &&
    assignmentPackage.prospects_ready_to_approve_count === 0
  ) {
    return "finished"
  }

  return "in_progress"
}

export const AssignmentPackage = React.forwardRef<
  HTMLAnchorElement,
  {
    assignmentPackage: TCompanyProspectsAssignment
    active: boolean
    onClick: () => void
  }
>(({assignmentPackage, active, onClick}, ref) => {
  return (
    <AssignmentDisplay
      ref={ref}
      onClick={onClick}
      active={active}
      variant={getAssignmentPackageVariant(assignmentPackage)}
      name={getFullName(assignmentPackage.sales_person, assignmentPackage.package_name)}
      min={assignmentPackage.prospects_approved_count}
      max={assignmentPackage.prospects_mandatory_count}
      image={assignmentPackage.sales_person?.profile_picture_thumbnail_url ?? undefined}
    />
  )
})
AssignmentPackage.displayName = "AssignmentPackage"
