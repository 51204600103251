import {ACompanyUserSalesCycleSalesPerson, ACountry, APackageWithPrice} from "../../services/types.generated.ts"

export enum ECreateSalesCyclePage {
  COUNTRY = "country",
  SALESPEOPLE = "sales-talents",
  BENEFITS = "benefits",
  CHECKOUT = "checkout",
}

export const DEFAULT_PAGE = ECreateSalesCyclePage.COUNTRY

export const getCreateSellingLink = ({
  salesCycleId,
  page,
  orderId,
}:
  | {
      salesCycleId?: number
      page?: typeof DEFAULT_PAGE
      orderId?: never
    }
  | {
      salesCycleId: number
      page: ECreateSalesCyclePage.COUNTRY | ECreateSalesCyclePage.SALESPEOPLE
      orderId?: never
    }
  | {
      salesCycleId: number
      page: ECreateSalesCyclePage.BENEFITS | ECreateSalesCyclePage.CHECKOUT
      orderId: number
    } = {}) => `/create-selling/${salesCycleId ?? "new"}/${page ?? DEFAULT_PAGE}${orderId ? `/${orderId}` : ""}`

export type TPlaceholderSalesPerson = {
  id: number
  placeholder: true
  first_name: string
  about: string
  years_of_experience?: never
  native_language_id?: never
  spoken_language_ids?: never
  country: ACountry
  packages: APackageWithPrice[]

  embed_video_id?: never
  profile_picture_url?: never
  rating?: never
}

export type TSalesPerson = ACompanyUserSalesCycleSalesPerson | TPlaceholderSalesPerson

export const isPlaceholderSalesperson = (salesPerson: TSalesPerson): salesPerson is TPlaceholderSalesPerson =>
  "placeholder" in salesPerson
