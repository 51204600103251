import React from "react"
import {FieldPath, FieldValues, useFormContext} from "react-hook-form"
import {debounce} from "lodash"
import {twMerge} from "tailwind-merge"

import {pickProps} from "../../utils/types"
import {TBaseSharedProps, TConnectedField, useGetFieldVisibleError} from "./components"
import {FieldLabel, pickFieldLabelProps, TFieldLabelProps} from "./FieldLabel"

export type TTextareaSharedProps = TBaseSharedProps & {
  inputClassName?: string
  borderClassName?: string
  debounced?: boolean | number
  ghost?: boolean | "hover"
  placeholder?: string
  rows?: number
}

export type TTextareaConnectedProps<T extends FieldValues, N extends FieldPath<T>> = TTextareaSharedProps &
  TConnectedField<T, N>

export type TTextareaBaseProps = TTextareaSharedProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>

const DEFAULT_DEBOUNCE_TIME = 500

export const TextareaBase = React.forwardRef<HTMLTextAreaElement, TTextareaBaseProps>(
  ({ghost, hasError, inputClassName, borderClassName, id: idProp, onChange, debounced, ...props}, ref) => {
    const id = idProp ?? props.name

    const handleChange = React.useMemo(() => {
      if (onChange && debounced) {
        return debounce(onChange, typeof debounced === "number" ? debounced : DEFAULT_DEBOUNCE_TIME)
      }

      return onChange
    }, [debounced, onChange])

    return (
      <div
        className={twMerge([
          "input-border relative flex items-center gap-0 overflow-hidden p-0",
          ghost === true && "input-border-ghost",
          ghost === "hover" && "input-border-ghost-hover",
          (props.readOnly || props.disabled) && "input-border-readonly",
          hasError && "input-border-error",
          borderClassName,
        ])}
      >
        <textarea
          ref={ref}
          {...props}
          id={id}
          className={twMerge(["w-full grow border-none bg-transparent text-sm", inputClassName])}
          onChange={handleChange}
        />
      </div>
    )
  }
)
TextareaBase.displayName = "Textarea"

export function TextareaConnected<T extends FieldValues, N extends FieldPath<T>>({
  name,
  options,
  ...props
}: TTextareaConnectedProps<T, N>) {
  const {register} = useFormContext<T>()
  const hasError = !!useGetFieldVisibleError(name)

  return <TextareaBase {...register(name, options)} hasError={hasError} {...props} />
}

export function TextareaField<T extends FieldValues, N extends FieldPath<T>>(
  props: TFieldLabelProps & TTextareaConnectedProps<T, N>
) {
  const fieldLabelProps = pickFieldLabelProps(props)
  const inputProps = pickTextareaConnectedProps(props)

  return (
    <FieldLabel {...fieldLabelProps}>
      <TextareaConnected {...inputProps} />
    </FieldLabel>
  )
}

function pickTextareaConnectedProps<T extends FieldValues, N extends FieldPath<T>>(
  props: React.ComponentProps<typeof TextareaField<T, N>>
) {
  return pickProps<TTextareaConnectedProps<T, N>>({
    name: true,
    hasError: true,
    debounced: true,
    borderClassName: true,
    ghost: true,
    options: true,
    inputClassName: true,
    placeholder: true,
    readOnly: true,
    disabled: true,
    rows: true,
  })(props)
}
