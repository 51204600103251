import * as React from "react";
const SvgNoFlag = (props) => /* @__PURE__ */ React.createElement("svg", { "data-testid": "placeholder-flag", width: "100%", height: "100%", viewBox: "0 0 124 83", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 124, height: 83, style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M65.852,54.115l-9.198,0c-0.024,-1.322 -0.036,-2.128 -0.036,-2.416c0,-2.982 0.493,-5.435 1.479,-7.359c0.986,-1.923 2.958,-4.088 5.916,-6.492c2.957,-2.405 4.725,-3.98 5.302,-4.725c0.89,-1.179 1.335,-2.477 1.335,-3.896c-0,-1.972 -0.794,-3.655 -2.381,-5.05c-1.563,-1.418 -3.679,-2.128 -6.348,-2.128c-2.573,0 -4.725,0.734 -6.457,2.2c-1.731,1.467 -2.921,3.704 -3.571,6.709l-9.306,-1.154c0.265,-4.304 2.092,-7.959 5.483,-10.965c3.414,-3.006 7.887,-4.509 13.418,-4.509c5.819,0 10.448,1.527 13.887,4.581c3.438,3.03 5.158,6.565 5.158,10.605c-0,2.236 -0.637,4.352 -1.912,6.348c-1.25,1.996 -3.944,4.713 -8.08,8.152c-2.14,1.779 -3.474,3.21 -4.003,4.292c-0.505,1.082 -0.734,3.018 -0.686,5.807Zm-9.198,13.635l0,-10.136l10.136,0l0,10.136l-10.136,-0Z", style: {
  fillRule: "nonzero"
} }));
export default SvgNoFlag;
