import React from "react"
import {Trans, useTranslation} from "react-i18next"

import image from "../../../assets/woman holds a big like.svg"
import {ButtonLink} from "../../../components/Button.tsx"
import {Link} from "../../../components/Link.tsx"
import {AOrder, APaymentMethods} from "../../../services/types.generated.ts"
import {commonTransComponents} from "../../../utils/i18n.tsx"

export const Finished: React.FC<{order: AOrder}> = ({order}) => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-col gap-4"} data-testid={"finished"}>
      <img src={image} className={"w-72 self-center"} />
      <h1 className={"text-2xl font-semibold"}>{t("CreateSalesCycle_Checkout_Finished_Title")}</h1>
      <div>
        {order.payment_method === APaymentMethods.BankTransfer ? (
          <Trans
            i18nKey={"CreateSalesCycle_Checkout_Finished_TextBankTransfer"}
            values={{email: order.customer_email ?? t("CreateSalesCycle_Checkout_Finished_EmailPlaceholder")}}
            components={commonTransComponents}
          />
        ) : (
          <Trans
            i18nKey={"CreateSalesCycle_Checkout_Finished_TextCard"}
            values={{email: order.customer_email ?? t("CreateSalesCycle_Checkout_Finished_EmailPlaceholder")}}
            components={commonTransComponents}
          />
        )}
      </div>
      <div className={"mt-9 flex flex-row-reverse items-baseline justify-between gap-10"}>
        <ButtonLink to={"/dashboard/draft"}>{t("CreateSalesCycle_Checkout_Finished_ContinueButton")}</ButtonLink>
        {order.external_invoice_url && (
          <Link
            to={order.external_invoice_url}
            target={"_blank"}
            variant={"neutral"}
            flipUnderline
            className={"font-semibold"}
          >
            {t("CreateSalesCycle_Checkout_Finished_InvoiceLink")}
          </Link>
        )}
      </div>
    </div>
  )
}
