import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {AxiosResponse} from "axios"
import {produce} from "immer"

import api, {multipartRequest, queryKey} from "../services"
import {ASalesCycle, ASalesCycleAttributes, AUserTypes} from "../services/types.generated"
import {useValueByUserType} from "../utils/userTypes.ts"
import {useUserSettingsOrLogout} from "./user.ts"

export function useSalesCycleQuery(id: number | undefined) {
  return useQuery({
    queryKey: queryKey.salesCycle(id),
    queryFn: async () => (await api.salesCycles.salesCyclesDetail(id ?? Number.NaN)).data.sales_cycle,
    enabled: id != null,
  })
}

export function useSalesCycleDetailQuery(id: number) {
  const queryFn = useValueByUserType({
    [AUserTypes.CompanyUser]: api.companyUsers.salesCyclesDetailsDetail,
    [AUserTypes.Admin]: api.admins.salesCyclesDetailsDetail,
    [AUserTypes.SaasCompanyUser]: api.saasCompanyUsers.salesCyclesDetail,
    [AUserTypes.SalesPerson]: api.salesPeople.salesCyclesDetailsDetail,
  })

  return useQuery({
    queryKey: queryKey.salesCycleDetail(id),
    queryFn: async () => {
      return (await queryFn(id)).data.sales_cycle
    },
  })
}

export function useSalesCycleNewsDetailQuery(id: number) {
  const queryFn = useValueByUserType({
    [AUserTypes.CompanyUser]: api.companyUsers.salesCyclesSalesNewsDetail,
    [AUserTypes.SaasCompanyUser]: api.saasCompanyUsers.salesCyclesSalesNewsDetail,
    [AUserTypes.SaasSalesPerson]: api.saasSalesPeople.salesCyclesSalesNewsDetail,
    [AUserTypes.SalesPerson]: api.salesPeople.salesCyclesSalesNewsDetail,
    [AUserTypes.Admin]: api.admins.salesCyclesSalesNewsDetail,
  })

  return useQuery({
    queryKey: queryKey.salesCycleNewsDetail(id),
    queryFn: async () => (await queryFn(id)).data.sales_cycle,
  })
}

export function useSalesCyclePeopleQuery(id: number) {
  return useQuery({
    queryKey: queryKey.salesCyclePeople(id),
    queryFn: async () => (await api.companyUsers.salesCyclesSalesPeopleDetail(id)).data,
  })
}

export function useCreateSalesCycleMutation() {
  const onSuccess = useUpdateSalesCyclesQueryCache()

  return useMutation({
    mutationFn: ({countryId}: {countryId: number}) =>
      api.salesCycles.salesCyclesCreate({sales_cycle: {country_id: countryId}}),
    onSuccess,
  })
}

export function useUpdateSalesCycleMutation() {
  const onSuccess = useUpdateSalesCyclesQueryCache()

  return useMutation({
    mutationFn: ({id, data}: {id: number; data: ASalesCycleAttributes}) =>
      multipartRequest({method: "PATCH", path: `sales_cycles/${id}`, data: {sales_cycle: data}}),
    onSuccess,
  })
}

export function useFinalizeSalesCycleKeyDetailsMutation() {
  const onSuccess = useUpdateSalesCyclesQueryCache()

  return useMutation({
    mutationFn: ({id}: {id: number}) => api.salesCycles.finalizeKeyDetailsCreate(id),
    onSuccess,
  })
}

export function useUpdateSalesCyclesQueryCache(refetchWelcomeScreen = true) {
  const {
    user: {id: userId},
  } = useUserSettingsOrLogout()

  const queryClient = useQueryClient()

  return (response: AxiosResponse<{sales_cycle: ASalesCycle}>) => {
    queryClient.invalidateQueries({
      queryKey: queryKey.companyWelcomeScreen(userId),
      type: refetchWelcomeScreen ? "all" : "active",
    })
    queryClient.invalidateQueries({
      queryKey: queryKey.salesCyclePeople(response.data.sales_cycle.id),
    })

    queryClient.setQueryData(queryKey.salesCycles, (salesCycles: ASalesCycle[] | undefined) => {
      if (!salesCycles) {
        return
      }

      return produce(salesCycles, draft => {
        const cycleIndex = draft.findIndex(draftCycle => draftCycle.id === response.data.sales_cycle.id)

        if (cycleIndex >= 0) {
          draft[cycleIndex] = response.data.sales_cycle
        } else {
          draft.push(response.data.sales_cycle)
        }
      })
    })

    queryClient.setQueryData(
      queryKey.salesCycle(response.data.sales_cycle.id),
      (salesCycle: ReturnType<typeof useSalesCycleQuery>["data"]) => {
        if (!salesCycle) {
          return
        }

        return response.data.sales_cycle
      }
    )
  }
}
