import React from "react"
import {useTranslation} from "react-i18next"

import {Alert, GenericErrorAlert} from "../../../components/Alert"
import {CheckboxBase} from "../../../components/fields/Checkbox.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock"
import {Loading} from "../../../components/Loading"
import {EOrderDirection} from "../../../components/Table/shared.ts"
import {sortDate} from "../../../components/Table/sortFunctions.ts"
import {
  TAdminsProspectsAssignment,
  TAdminsProspectsIteration,
  useBulkDisapproveProspectsMutation,
  useProspectsSalesCycleQuery,
} from "../../../queries/prospects"
import {AAdminsProspectsSalesCycle} from "../../../services/types.generated"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {useDocumentTitle, useNumParam} from "../../../utils/hooks"
import {lifetimeSymbol, PeriodToggle, TPeriodToggleValue} from "../../Reports/components/PeriodToggle.tsx"
import {AddProspectsModal, EFeature} from "../shared/AddProspectsModal"
import {
  AssignmentContext,
  DeletingContext,
  EditingContext,
  IsUploadingContext,
  RejectContextProvider,
  TProspectContext,
  TRejectProspect,
  WarningsOnlyContext,
} from "../shared/context"
import {DeleteModal} from "../shared/DeleteModal.tsx"
import {EditingFlyout} from "../shared/EditingFlyout.tsx"
import {RejectModal, TRejectSubmitParams} from "../shared/RejectModal"
import {salesCycleRemoveIterationsWithoutAssignments} from "../shared/utils.ts"
import {StatusBanner} from "./components/StatusBanner"
import {ProspectsIteration} from "./ProspectsIteration.tsx"
import {ProspectsLifetime} from "./ProspectsLifetime.tsx"

export const AdminProspects: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Prospects"))

  const salesCycleId = useNumParam("salesCycleId")
  const {data, error, refetch} = useProspectsSalesCycleQuery(salesCycleId)

  const salesCycle = React.useMemo(() => {
    if (!data) {
      return null
    }

    return salesCycleRemoveIterationsWithoutAssignments(data)
  }, [data])

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data) {
    return <Loading size={"xl"} />
  }

  return <ProspectsLoaded salesCycle={salesCycle as AAdminsProspectsSalesCycle} />
}

export const ProspectsLoaded: React.FC<{
  salesCycle: AAdminsProspectsSalesCycle
}> = ({salesCycle}) => {
  const {t} = useTranslation()

  const isUploadingContextValue = IsUploadingContext.useProviderValue(false)
  const editingContextValue = EditingContext.useProviderValue(null)
  const deleteContextValue = DeletingContext.useProviderValue(null)

  const assignmentContextValue = AssignmentContext.useProviderValue({assignment: null, iteration: null, salesCycle})
  const {setValue: setAssignmentContext} = assignmentContextValue

  const warningsOnlyContextValue = WarningsOnlyContext.useProviderValue(false)

  React.useEffect(() => {
    setAssignmentContext(ctx => ({...ctx, salesCycle}))
  }, [salesCycle, setAssignmentContext])

  const activeIteration = (assignmentContextValue.value?.iteration as TAdminsProspectsIteration) ?? null
  const activeAssignment = (assignmentContextValue.value?.assignment as TAdminsProspectsAssignment) ?? null

  const [rejectingProspects, setRejectingProspects] = React.useState<TRejectProspect[] | null>(null)
  const rejectContextValue = React.useMemo<TProspectContext>(
    () => ({
      prospects: rejectingProspects,
      setProspects: setRejectingProspects,
      rejectionsLeft: null,
    }),
    [rejectingProspects]
  )

  const bulkDisapproveMutation = useBulkDisapproveProspectsMutation()

  const handleRejectSubmit = React.useCallback(
    (params: TRejectSubmitParams) => {
      return bulkDisapproveMutation.mutateAsync({
        ids: params.prospects.map(({id}) => id),
        disapprove_reason: params.disapprove_reason,
        disapprove_reason_text: params.disapprove_reason_text,
      })
    },
    [bulkDisapproveMutation]
  )

  const iterationOptions = React.useMemo<string[]>(() => {
    return [...salesCycle.sales_cycle_iterations]
      .sort(sortDate<TAdminsProspectsIteration>(iteration => apiDateToJS(iteration.start_date))(EOrderDirection.DESC))
      .map(iteration => iteration.start_date)
  }, [salesCycle.sales_cycle_iterations])

  const handleChangeIteration = React.useCallback(
    (startDate: TPeriodToggleValue) => {
      setAssignmentContext(currentValue => ({
        ...currentValue,
        iteration: salesCycle.sales_cycle_iterations.find(iteration => iteration.start_date === startDate) ?? null,
      }))
    },
    [salesCycle, setAssignmentContext]
  )

  return (
    <AssignmentContext.Provider value={assignmentContextValue}>
      <IsUploadingContext.Provider value={isUploadingContextValue}>
        <DeletingContext.Provider value={deleteContextValue}>
          <EditingContext.Provider value={editingContextValue}>
            <RejectContextProvider value={rejectContextValue}>
              <WarningsOnlyContext.Provider value={warningsOnlyContextValue}>
                <div className={"flex flex-col gap-9 py-16"}>
                  <LayoutBlock innerClassName={"flex items-start justify-between"}>
                    <PeriodToggle
                      periods={iterationOptions}
                      value={activeIteration?.start_date ?? lifetimeSymbol}
                      onChange={handleChangeIteration}
                    />

                    {activeIteration && <StatusBanner iteration={activeIteration} />}
                  </LayoutBlock>
                  {activeAssignment && activeAssignment.prospects_with_warning_count > 0 && (
                    <LayoutBlock>
                      <Alert
                        variant={"warning"}
                        title={
                          <div className={"flex flex-wrap justify-between gap-10"}>
                            <span>
                              {t("Prospects_WarningsAlert_Title", {
                                count: activeAssignment.prospects_with_warning_count,
                              })}
                            </span>
                            <CheckboxBase
                              onChange={e => warningsOnlyContextValue.setValue(e.target.checked)}
                              checked={warningsOnlyContextValue.value}
                              toggle
                            >
                              {t("Prospects_WarningsAlert_Text")}
                            </CheckboxBase>
                          </div>
                        }
                      ></Alert>
                    </LayoutBlock>
                  )}

                  {activeIteration ? (
                    <ProspectsIteration salesCycle={salesCycle} />
                  ) : (
                    <ProspectsLifetime salesCycle={salesCycle} />
                  )}
                </div>

                <EditingFlyout />
                <DeleteModal />
                <AddProspectsModal enabledFeatures={[EFeature.XLS, EFeature.Leads]} />
                {rejectingProspects && <RejectModal onSubmit={handleRejectSubmit} />}
              </WarningsOnlyContext.Provider>
            </RejectContextProvider>
          </EditingContext.Provider>
        </DeletingContext.Provider>
      </IsUploadingContext.Provider>
    </AssignmentContext.Provider>
  )
}
