import React from "react"
import {useTranslation} from "react-i18next"

import {FieldLabel} from "../../components/fields/FieldLabel"
import {ButtonRadioRenderer, RadioConnected} from "../../components/fields/RadioButton"
import {enumToOptions} from "../../utils/i18n"

const yomOptions = enumToOptions("YearsOfMarket")

const MarketPresence = () => {
  const {t} = useTranslation()

  return (
    <FieldLabel
      required
      label={t("SalesStrategy_Year on the market")}
      legend={t("SalesStrategy_How long you run your product on the market?")}
      name={"years_of_market"}
    >
      <div className={"flex gap-4"}>
        {yomOptions.map(({value, title}) => (
          <RadioConnected value={value} name={"years_of_market"} key={value} RenderComponent={ButtonRadioRenderer}>
            {title}
          </RadioConnected>
        ))}
      </div>
    </FieldLabel>
  )
}

export default MarketPresence
