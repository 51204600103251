import {i18n} from "../../i18n.ts"
import {ERole, TMetaQueryData} from "./fakeTypes.ts"

export type TTab = "all" | "sales" | "owners" | "admins"
export const tabDefinitions = {
  all: {role: undefined, title: i18n.t("SaaSUserManagement_Tab_All"), metaQueryKey: "all_users_count"},
  admins: {role: ERole.admin, title: i18n.t("SaaSUserManagement_Tab_Admins"), metaQueryKey: "admins_count"},
  owners: {role: ERole.owner, title: i18n.t("SaaSUserManagement_Tab_Owners"), metaQueryKey: "owners_count"},
  sales: {
    role: ERole.salesperson,
    title: i18n.t("SaaSUserManagement_Tab_Salespeople"),
    metaQueryKey: "salespeople_count",
  },
} satisfies {[tab in TTab]: {role: ERole | undefined; title: string; metaQueryKey?: keyof TMetaQueryData}}

export const tabsOrder = ["all", "sales", "admins"] as const satisfies TTab[]
