import React from "react"
import {twMerge} from "tailwind-merge"

export const StatusBannerGeneric: React.FC<{
  color: "yellow" | "blue" | "cyan"
  Icon?: React.ComponentType<{className?: string}>
  title: React.ReactNode
  text?: React.ReactNode
}> = ({color, text, title, Icon}) => {
  return (
    <div
      className={twMerge([
        "gap-x-3 gap-y-1 rounded-lg border p-4",
        Icon ? "grid grid-cols-[min-content_minmax(0,_1fr)] items-center" : "flex flex-col",
        color === "yellow" && "border-cr-yellow text-cr-yellow",
        color === "blue" && "border-cr-blue text-cr-blue",
        color === "cyan" && "border-cr-cyan",
      ])}
    >
      {Icon && (
        <div
          className={twMerge([
            "rounded-full p-1",
            color === "yellow" && "bg-cr-yellow-light",
            color === "blue" && "bg-cr-blue-light",
          ])}
        >
          <Icon className={"h-6 w-6"} />
        </div>
      )}
      <span className={"font-bold"}>{title}</span>
      <span className={"col-start-2 text-sm text-cr-grey-65"}>{text}</span>
    </div>
  )
}
