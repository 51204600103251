import {useTranslation} from "react-i18next"
import {AcademicCapIcon, LightBulbIcon, UserIcon} from "@heroicons/react/24/outline"

import {Link} from "../../components/Link"
import WelcomeLayout from "../../components/WelcomeLayout"
import {useUserSettingsOrLogout} from "../../queries/user.ts"
import {IntroVideoModal} from "./IntroVideoModal"

const Welcome = () => {
  const {t} = useTranslation()

  const {user} = useUserSettingsOrLogout()

  return (
    <>
      <WelcomeLayout
        title={t("SalespersonDashboard_Welcome on board!")}
        nodes={[
          {
            title: t("SalespersonDashboard_Create a profile"),
            text: t(
              "SalespersonDashboard_Let us know more about you. Your profile info will help us provide the best possible job opportunities for you as a salesperson."
            ),
            Icon: UserIcon,
            Action: ({className}) => (
              <Link className={className} noUnderline to={`/salesperson/${user.id}/profile`}>
                {t("SalespersonDashboard_Open Profile →")}
              </Link>
            ),
          },
          {
            title: t("SalespersonDashboard_Educate yourself"),
            text: t(
              "SalespersonDashboard_To ensure your success, we've curated essential learning materials for you. Before you start your sales journey with us, please complete the following resources."
            ),
            Icon: AcademicCapIcon,
            Action: ({className}) => (
              <Link className={className} noUnderline to={`/salesperson/${user.id}/learning-materials`}>
                {t("SalespersonDashboard_Start learning →")}
              </Link>
            ),
          },
          {
            title: t("SalespersonDashboard_Get approval"),
            text: t(
              "SalespersonDashboard_After you finish the educational materials and provide your profile information, our administration will carefully review and approve your eligibility for employment."
            ),
            Icon: LightBulbIcon,
          },
        ]}
      />
      <IntroVideoModal />
    </>
  )
}

export default Welcome
