import {useTranslation} from "react-i18next"

import CheckboxesAdd from "../../components/formElements/CheckboxesAdd"
import {i18n} from "../../i18n"
import {maxProductUniqueItemsCount} from "./stepsConfig.ts"

const optionsDefault = [
  i18n.t("SalesStrategy_StepUniqueness_NewTechnology"),
  i18n.t("SalesStrategy_StepUniqueness_NewProcess"),
  i18n.t("SalesStrategy_StepUniqueness_BetterExperience"),
  i18n.t("SalesStrategy_StepUniqueness_CreatingNewSegment"),
  i18n.t("SalesStrategy_StepUniqueness_BrandRecognition"),
]

const Uniqueness = () => {
  const {t} = useTranslation()

  return (
    <CheckboxesAdd
      name={"product_uniques"}
      label={t("SalesStrategy_StepUniqueness_Title")}
      legend={t("SalesStrategy_StepUniqueness_Description", {count: maxProductUniqueItemsCount})}
      inputLabel={t("SalesStrategy_StepUniqueness_AddYourOwn")}
      optionsDefault={optionsDefault}
      disabledAdd={value => value.length >= maxProductUniqueItemsCount}
    />
  )
}

export default Uniqueness
