import React from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"
import {useQueryClient} from "@tanstack/react-query"

import {useUserSettingsQuery} from "../queries/user.ts"
import {useRedirectOnUserChange} from "../utils/useRedirectOnUserChange.ts"
import api, {rollbar} from "./index"

export const LOGOUT_UNAUTHORIZED_TOAST_ID = "unauthorized"

export const LogoutIfUnauthorized: React.FC = () => {
  const {t} = useTranslation()

  useRedirectOnUserChange()

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {data: userSettings} = useUserSettingsQuery()

  React.useEffect(() => {
    const interceptor = api.instance.interceptors.response.use(
      undefined,
      async (e: undefined | {code?: string; response?: {status?: number}}) => {
        // Generic Axios 'ERR_NETWORK' errors don't have a response field. These errors can happen for example when CORS fails.
        if (e?.response == null) {
          rollbar.warning("Intercepted failed request without response", e?.code, e)
          // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
          return Promise.reject(e)
        }

        if (e.response.status !== 401 || !userSettings) {
          // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
          return Promise.reject(e)
        }

        await queryClient.resetQueries()
        toast.info(t("LogIn_UnauthorizedLogoutToast"), {
          toastId: LOGOUT_UNAUTHORIZED_TOAST_ID,
          autoClose: false,
        })
        navigate("/log-in")

        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        return Promise.reject(e)
      }
    )

    return () => {
      api.instance.interceptors.response.eject(interceptor)
    }
  }, [navigate, queryClient, t, userSettings])

  return null
}
