import React from "react"
import {useTranslation} from "react-i18next"
import {CalendarDaysIcon} from "@heroicons/react/16/solid"

import {GenericErrorAlert} from "../../../../components/Alert.tsx"
import {LayoutBlock} from "../../../../components/Layout/LayoutBlock.tsx"
import {Loading} from "../../../../components/Loading.tsx"
import {getCurrentLanguage} from "../../../../i18n.ts"
import {useSalesCycleNewsDetailQuery} from "../../../../queries/salesCycles.ts"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {useDocumentTitle, useNumParam} from "../../../../utils/hooks.tsx"
import {isSalespersonSalesCycleNews} from "../../types.ts"
import {ActivityFeedCard} from "../ActivityFeed/ActivityFeedCard.tsx"
import {MetricsBlock} from "../components/MetricsBlock.tsx"
import {SalesProgressCard} from "../components/SalesProgressCard.tsx"

export const NewsTab: React.FC = () => {
  const {t} = useTranslation()
  useDocumentTitle(t("SellingDetails_Tabs_News"))

  const salesCycleId = useNumParam("salesCycleId")

  const {data, error, refetch} = useSalesCycleNewsDetailQuery(salesCycleId)

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data) {
    return <Loading size={"xl"} containerClassName={"py-12"} />
  }

  return (
    <LayoutBlock outerClassName={"pt-16 grow"} innerClassName={"flex flex-col gap-8"}>
      {data.iteration_start_date && (
        <div className={"relative inline-flex self-start rounded-full border border-cr-grey-15 bg-white p-1"}>
          <div className={"flex items-center gap-2 rounded-full px-4 py-2 text-xs"}>
            <CalendarDaysIcon className={"-mt-0.5 size-5 shrink-0"} />
            <span className={"grow truncate text-center"}>
              {apiDateToJS(data.iteration_start_date).toLocaleString(getCurrentLanguage(), {
                month: "long",
                year: "2-digit",
              })}
            </span>
          </div>
        </div>
      )}

      <div className={"flex grow flex-wrap gap-4"}>
        <div className={"flex grow flex-col gap-4"}>
          <MetricsBlock data={data} />
          {!isSalespersonSalesCycleNews(data) && <SalesProgressCard data={data} />}
        </div>

        <ActivityFeedCard
          salesCycleId={data.id}
          adminUrl={"activity_feeds_administration_url" in data ? data.activity_feeds_administration_url : undefined}
        />
      </div>
    </LayoutBlock>
  )
}
