import {useState} from "react"
import {twMerge} from "tailwind-merge"

import logoIcon from "../../../assets/logoIcon.svg"
import logoText from "../../../assets/logoText.svg"

const LogoAnimated = () => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className={"h-16 overflow-hidden pt-4 xl:h-20 xl:pt-6"}
      onMouseEnter={() => setIsHovered(true)}
      onAnimationEnd={() => setIsHovered(false)}
    >
      <div className={"relative"}>
        <span className={"sr-only"}>CloseRocket</span>
        <img className={twMerge(["absolute h-8 w-auto", isHovered && "animate-launch"])} src={logoIcon} alt={"Logo"} />
        <img className={"h-8 w-auto"} src={logoText} alt={"CloseRocket"} />
      </div>
    </div>
  )
}

export default LogoAnimated
