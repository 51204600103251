import resolveConfig from "tailwindcss/resolveConfig"

import twConfig from "../../tailwind.config"

function getTailwindColors() {
  const {theme} = resolveConfig(twConfig)

  // The bundled type does not merge in the colors from the extend key, so we need to fix the type
  return (theme as any).colors as (typeof theme & {
    extend: {colors: NonNullable<unknown>}
  })["extend"]["colors"] &
    (typeof theme & {colors: NonNullable<unknown>})["colors"]
}

export const tailwindColors = getTailwindColors()
