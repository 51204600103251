import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {CheckIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {ProfileImage} from "../../../components/ProfileImage.tsx"
import {AOrder} from "../../../services/types.generated.ts"
import {renderPrice} from "../../../utils"
import {BulletedTransList, commonTransComponents} from "../../../utils/i18n.tsx"
import {BenefitsItem} from "./BenefitsItem.tsx"

export const BenefitsCloseRocket: React.FC<{order: AOrder}> = ({order}) => {
  const {t} = useTranslation()

  const salesPeopleTotalPrice = order.order_items.reduce((acc, item) => acc + item.total_price, 0)
  const setupFeeTotalPrice = order.service_items.reduce((acc, item) => acc + item.total_price, 0)

  return (
    <div className={"flex grow basis-1/3 flex-col gap-8 rounded-lg bg-cr-blue px-14 py-10 text-cr-white"}>
      <h2 className={"text-3xl font-bold"}>{t("CreateSalesCycle_Benefits_CloseRocket_Title")}</h2>

      <BulletedTransList>
        <Trans
          i18nKey={"CreateSalesCycle_Benefits_CloseRocket_MainList"}
          components={{
            ...commonTransComponents,
            bullet: <CheckIcon className={"size-5 translate-y-1 text-inherit"} />,
            item: <span className={"font-bold"} />,
          }}
        />
      </BulletedTransList>

      <BenefitsItem
        title={t("CreateSalesCycle_Benefits_SalesTalent")}
        price={
          <>
            <span>{renderPrice(salesPeopleTotalPrice, order.currency, {noDecimal: true})}</span>{" "}
            <span className={"text-xs font-normal"}>{t("CreateSalesCycle_Benefits_CloseRocket_Monthly")}</span>
          </>
        }
      />
      <div className={"flex flex-wrap gap-2"} data-testid={"profile-images"}>
        {order.order_items.map(item => (
          <ProfileImage
            className={twMerge("size-12", item.sales_person == null && "bg-cr-blue-mid text-cr-white")}
            key={item.id}
            src={item.sales_person?.profile_picture_thumbnail_url ?? undefined}
          />
        ))}
      </div>

      <BenefitsItem
        title={t("CreateSalesCycle_Benefits_SetupFee")}
        price={
          <>
            <span>{renderPrice(setupFeeTotalPrice, order.currency, {noDecimal: true})}</span>{" "}
            <span className={"text-xs font-normal"}>{t("CreateSalesCycle_Benefits_CloseRocket_OneOff")}</span>
          </>
        }
      />
      <BulletedTransList>
        <Trans
          i18nKey={"CreateSalesCycle_Benefits_CloseRocket_SetupFeeList"}
          components={{
            ...commonTransComponents,
            bullet: <CheckIcon className={"size-5 translate-y-1 text-inherit"} />,
          }}
        />
      </BulletedTransList>

      <BenefitsItem
        title={t("CreateSalesCycle_Benefits_FirstPayment")}
        price={renderPrice(order.total_price, order.currency, {noDecimal: true})}
      />
      <div className={"text-xs"}>
        <Trans i18nKey={"CreateSalesCycle_Benefits_CloseRocket_Disclaimer"} components={commonTransComponents} />
      </div>
    </div>
  )
}
