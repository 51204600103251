import React from "react"
import {useTranslation} from "react-i18next"

import {Link} from "./Link"

const SCROLL_HEIGHT_THRESHOLD = 2

export const ExpandableBox: React.FC<{
  lineClamp?: number
  lineHeight?: string
  children: React.ReactNode
}> = ({lineClamp = 5, lineHeight = "1.25em", children}) => {
  const {t} = useTranslation()
  const [isTextOverflowing, setIsTextOverflowing] = React.useState(false)
  const [isExpanded, setIsExpanded] = React.useState(false)
  const textContainerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const textContainer = textContainerRef.current
    const isOverflowing =
      !!textContainer && textContainer.scrollHeight - SCROLL_HEIGHT_THRESHOLD > textContainer.clientHeight
    setIsTextOverflowing(isOverflowing)
    if (!isOverflowing) {
      setIsExpanded(false)
    }
  }, [children])

  const handleShowMoreClick = () => setIsExpanded(true)

  const handleShowLessClick = () => setIsExpanded(false)

  return (
    <div className={"flex flex-col items-start gap-1"}>
      <div
        ref={textContainerRef}
        data-testid={"expandable-text-container"}
        style={{
          lineHeight,
          ...(isExpanded ? {} : {overflow: "hidden", maxHeight: `calc(${lineClamp} * ${lineHeight})`}),
        }}
      >
        {children}
      </div>
      {isTextOverflowing && !isExpanded && (
        <Link data-testid={"show-more-link"} onClick={handleShowMoreClick}>
          {t("ExpandableBox_ShowMore")}
        </Link>
      )}
      {isExpanded && (
        <Link data-testid={"show-less-link"} onClick={handleShowLessClick}>
          {t("ExpandableBox_ShowLess")}
        </Link>
      )}
    </div>
  )
}
