import React from "react"
import {Slide, ToastContainer} from "react-toastify"
import {Portal} from "@headlessui/react"
import {Provider as RollbarProvider} from "@rollbar/react"
import {QueryClientProvider} from "@tanstack/react-query"
import {ReactQueryDevtools} from "@tanstack/react-query-devtools"

import {NotificationIcon} from "./utils/notify/NotificationCenterToast"
import Router from "./Router"
// eslint-disable-next-line no-restricted-imports
import {queryClient, rollbar, ServicesUserSetup} from "./services"

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RollbarProvider instance={rollbar}>
        <ServicesUserSetup />

        <Router />
      </RollbarProvider>

      <Portal>
        <ToastContainer
          icon={props => <NotificationIcon type={props.type} />}
          transition={Slide}
          toastClassName={"sora !rounded-lg !shadow-lg !ring-1 !ring-black !ring-opacity-5 !text-sm"}
          progressClassName={"!h-0.5"}
        />
      </Portal>
      <Portal>
        <ReactQueryDevtools initialIsOpen={false} />
      </Portal>
    </QueryClientProvider>
  )
}

export default App
