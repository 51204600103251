import React from "react"
import {Controller, FieldPath, FieldValues} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {Listbox, Transition} from "@headlessui/react"
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid"
import isEqual from "fast-deep-equal"
import {twMerge} from "tailwind-merge"

import {pickProps} from "../../utils/types"
import {TBaseSharedProps, TConnectedField} from "../fields/components"
import {FieldLabel, pickFieldLabelProps, TFieldLabelProps} from "../fields/FieldLabel"
import {InputAction} from "../InputAction"

export type TOption<T> = {
  value: T
  title: React.ReactNode
  key?: string | number
}

type TDropdownSharedProps<T> = TBaseSharedProps & {
  name?: string
  options: Array<TOption<T>>
  addCustom?: boolean
  openUp?: boolean
  buttonClassName?: string
  optionsListClassName?: string
  chevronClassName?: string
  placeholder?: React.ReactNode
  clearable?: boolean
  ghost?: boolean | "hover"
  renderOption?: (args: {option: TOption<T>; state: {selected: boolean; active: boolean}}) => React.ReactNode
} & (
    | {
        multiple: true
        renderValue?: (args: {
          selectedOptions: Array<TOption<T>> | undefined
          state: {open: boolean}
          placeholder?: React.ReactNode
        }) => React.ReactNode
      }
    | {
        multiple?: false
        renderValue?: (args: {
          selectedOption: TOption<T> | undefined
          state: {open: boolean}
          placeholder?: React.ReactNode
        }) => React.ReactNode
      }
  )

type TDropdownConnectedProps<T, V extends FieldValues, P extends FieldPath<V>> = TDropdownSharedProps<T> &
  Omit<TConnectedField<V, P>, "options">

type TDropdownBaseProps<T> = TDropdownSharedProps<T> & TCustomOrNot<T>

type TMultiple<T> =
  | {
      multiple: true
      value: T[] | undefined | null
      onChange: (value: T[] | undefined) => void
    }
  | {
      multiple?: false
      value: T | undefined | null
      onChange: (value: T | undefined) => void
    }

type TCustom<T> = {
  addCustom: true
  isCustomValueValid?: (customValue: unknown) => customValue is T
}

type TNotCustom = {addCustom?: false; isCustomValueValid?: never}

type TCustomOrNot<T> = (TNotCustom & TMultiple<T>) | (T extends string ? TCustom<T> & TMultiple<T> : never)

function DropdownBaseFn<T>(
  {
    value,
    onChange,
    options,
    multiple,
    addCustom,
    ghost,
    openUp,
    buttonClassName,
    optionsListClassName,
    chevronClassName,
    placeholder,
    clearable,
    renderOption,
    renderValue,
    isCustomValueValid = (_val: unknown): _val is string & T => true,
    hasError,
    ...props
  }: TDropdownBaseProps<T>,
  ref: React.ForwardedRef<HTMLElement>
) {
  const {t} = useTranslation()

  const [optionsCustom, setOptionsCustom] = React.useState<Array<TOption<T>>>(
    multiple && addCustom
      ? (value ?? [])
          .filter(oneValue => !options.find(option => isEqual(option.value, oneValue)))
          .map(oneValue => ({value: oneValue, title: oneValue}))
      : []
  )

  const optionsAll = React.useMemo(() => [...options, ...optionsCustom], [options, optionsCustom])

  const handleOptionAdd = (valueAdded: T & string) => {
    if (!addCustom || props.readOnly || props.disabled) {
      return
    }

    if (optionsAll.find(o => o.value === valueAdded)) {
      toast.error(`Option "${valueAdded}" already exists`)
      return
    }

    setOptionsCustom(old => [...old, {value: valueAdded, title: valueAdded}])

    if (multiple) {
      onChange([...(value ?? []), valueAdded])
    } else {
      onChange(valueAdded)
    }
  }

  const findOption = React.useCallback(
    (value: T) => {
      const option = optionsAll.find(e => isEqual(e.value, value))

      if (!option) {
        return undefined
      }

      return option
    },
    [optionsAll]
  )

  const valueOptions = React.useMemo(() => {
    if (value == null) {
      return multiple ? [] : null
    }

    return multiple
      ? value.map(findOption).filter((opt): opt is NonNullable<typeof opt> => opt != null)
      : (findOption(value) ?? null)
  }, [findOption, multiple, value])

  const valueStr = React.useMemo(() => {
    if (!valueOptions) {
      return <span className={"text-cr-grey-65"}>{placeholder}</span>
    }

    if (Array.isArray(valueOptions)) {
      return valueOptions.map(e => e?.title).join(", ")
    }

    return valueOptions?.title
  }, [placeholder, valueOptions])

  return (
    <Listbox
      ref={ref}
      multiple={multiple}
      value={valueOptions}
      onChange={opt => {
        if (props.readOnly || props.disabled) {
          return
        }

        if (!opt) {
          return multiple ? onChange([]) : onChange(undefined)
        }

        if (Array.isArray(opt)) {
          if (!multiple) {
            return
          }
          return onChange(opt.map(o => o.value))
        }
        if (multiple) {
          return
        }

        if (clearable && opt === valueOptions) {
          return onChange(undefined)
        }

        return onChange(opt.value)
      }}
      {...props}
    >
      {({open}) => (
        <div className={twMerge(["group relative", props.disabled && "cursor-not-allowed"])}>
          <Listbox.Button
            data-testid={`dropdown-button-${props.name}`}
            className={twMerge([
              "input-border relative w-full cursor-default bg-cr-white pb-1.5 pl-3 pr-10 pt-2 text-left text-cr-black focus:outline-none",
              "align-middle leading-7 disabled:pointer-events-none disabled:contrast-[0.9]",
              ghost === true && "input-border-ghost",
              ghost === "hover" && "input-border-ghost-hover",
              props.disabled && "input-border-disabled",
              props.readOnly && "input-border-readonly",
              hasError && "input-border-error",
              buttonClassName,
            ])}
          >
            <span
              className={twMerge([
                'block truncate empty:after:invisible empty:after:content-["."]',
                !valueOptions && "opacity-90",
              ])}
            >
              {renderValue
                ? multiple
                  ? renderValue({
                      selectedOptions: (valueOptions as Array<TOption<T>>) || undefined,
                      state: {open},
                      placeholder,
                    })
                  : renderValue({
                      selectedOption: (valueOptions as TOption<T>) || undefined,
                      state: {open},
                      placeholder,
                    })
                : valueStr}
            </span>
            <span className={"pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"}>
              <ChevronUpDownIcon
                className={twMerge(
                  "h-5 w-5 text-cr-grey-50 transition-colors group-hover:text-cr-grey-80",
                  chevronClassName
                )}
                aria-hidden={"true"}
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={React.Fragment}
            leave={"transition ease-in duration-100"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}
          >
            <Listbox.Options
              className={twMerge([
                "flex flex-col justify-between overflow-hidden",
                "absolute z-10 mt-1 max-h-60 w-full rounded-md bg-white py-1 text-left text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                addCustom && "max-h-96",
                openUp && "bottom-full",
                optionsListClassName,
              ])}
              data-testid={`dropdown-options-${props.name}`}
            >
              <div className={"scrollbar-slim overflow-auto"}>
                {optionsAll.map((option, index) => (
                  <Listbox.Option
                    key={option.key ?? String(option.value)}
                    className={({active, selected}) =>
                      twMerge(
                        "relative cursor-default select-none py-2 pl-4 pr-10 text-cr-black",
                        active ? "bg-cr-blue text-cr-white" : selected ? "bg-cr-blue-super-light" : "text-cr-grey-80"
                      )
                    }
                    value={option}
                    data-testid={`dropdown-options-${props.name}-${index}`}
                  >
                    {({selected, active}) => (
                      <>
                        <div className={twMerge(selected ? "font-semibold" : "font-normal", "block")}>
                          {renderOption ? renderOption({option, state: {selected, active}}) : option.title}
                        </div>

                        {selected ? (
                          <div
                            className={twMerge(
                              active ? "text-white" : "text-cr-blue",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className={"h-5 w-5"} aria-hidden={"true"} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </div>
              {addCustom && !props.readOnly && !props.disabled && (
                <FieldLabel
                  variant={"medium"}
                  name={"add-value"}
                  label={t("T_Didn't find any? Type in your own")}
                  containerClassName={"px-5 pb-5 pt-4 rounded-t-lg border-t"}
                  hideError
                >
                  <InputAction
                    onAction={val => (isCustomValueValid(val) ? handleOptionAdd(val) : undefined)}
                    actionLabel={t("T_Add")}
                  />
                </FieldLabel>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}

export const DropdownBase = React.forwardRef(DropdownBaseFn) as <T>(
  props: TDropdownBaseProps<T> & {ref?: React.ForwardedRef<HTMLElement>}
) => ReturnType<typeof DropdownBaseFn<T>>
;(DropdownBase as React.ForwardRefExoticComponent<any>).displayName = "Dropdown"

export function DropdownConnected<T, V extends FieldValues = any, P extends FieldPath<V> = any>(
  props: TDropdownConnectedProps<T, V, P>
) {
  return (
    <Controller
      name={props.name}
      render={({field, fieldState: {error}}) => <DropdownBase {...field} hasError={!!error} {...props} />}
    />
  )
}

export function DropdownField<T, V extends FieldValues = any, P extends FieldPath<V> = any>(
  props: TFieldLabelProps & TDropdownConnectedProps<T, V, P>
) {
  const fieldLabelProps = pickFieldLabelProps(props)
  const dropdownProps = pickDropdownConnectedProps(props)

  return (
    <FieldLabel {...fieldLabelProps}>
      <DropdownConnected {...dropdownProps} />
    </FieldLabel>
  )
}

function pickDropdownConnectedProps<T, V extends FieldValues, P extends FieldPath<V>>(
  props: React.ComponentProps<typeof DropdownField<T, V, P>>
) {
  return pickProps<TDropdownConnectedProps<T, V, P>>({
    name: true,
    readOnly: true,
    hasError: true,
    disabled: true,
    renderValue: true,
    multiple: true,
    addCustom: true,
    options: true,
    buttonClassName: true,
    optionsListClassName: true,
    chevronClassName: true,
    clearable: true,
    openUp: true,
    placeholder: true,
    renderOption: true,
    ghost: true,
  })(props)
}
