import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"
import {zodResolver} from "@hookform/resolvers/zod"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {Loading} from "../../../components/Loading.tsx"
import Modal from "../../../components/Modal.tsx"
import {useCheckoutCreateMutation, useCheckoutQuery, useUpdateCheckoutMutation} from "../../../queries/checkout.ts"
import {useUserSettingsOrLogout} from "../../../queries/user.ts"
import {
  AOrder,
  AOrderStatuses,
  APaymentMethods,
  ASubscriptionBillingTypes,
  AUsersCompanyUser,
} from "../../../services/types.generated.ts"
import {getFullName, useIsWelcomeScreenEnabled} from "../../../utils"
import {EGTMEvent, emitGTMEvent} from "../../../utils/gtm.ts"
import {useCountries, useDocumentTitle, useNumParam} from "../../../utils/hooks.tsx"
import {setFormErrorsFromAxios} from "../../../utils/validation.ts"
import {CheckoutForm, TFormData, validationSchema} from "../components/CheckoutForm.tsx"
import {Finished} from "../components/Finished.tsx"
import {OnboardingLayout} from "../components/OnboardingLayout.tsx"
import {StripeModal} from "../components/StripeModal.tsx"

const isOrderFinished = (order?: AOrder) => {
  if (!order || order.status === AOrderStatuses.Created) {
    return false
  }

  return (
    order.payment_method === APaymentMethods.BankTransfer ||
    (order.payment_method === APaymentMethods.Card && order.status === AOrderStatuses.Paid)
  )
}

export const CheckoutPage: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("CreateSalesCycle_Checkout_DocumentTitle"))

  const orderId = useNumParam("orderId")

  const {data, error, refetch} = useCheckoutQuery(orderId)

  return (
    <OnboardingLayout white>
      <LayoutBlock>
        {error ? (
          <GenericErrorAlert retry={refetch} />
        ) : data ? (
          <CheckoutLoaded order={data} />
        ) : (
          <Loading size={"xl"} fullSpace />
        )}
      </LayoutBlock>
    </OnboardingLayout>
  )
}

export const CheckoutLoaded: React.FC<{order: AOrder}> = ({order}) => {
  const isWelcomeScreenEnabled = useIsWelcomeScreenEnabled()
  const {user} = useUserSettingsOrLogout()
  const countries = useCountries()

  const navigate = useNavigate()

  const checkoutCreateMutation = useCheckoutCreateMutation()
  const updateCheckoutMutation = useUpdateCheckoutMutation()

  const [isStripeOpen, setIsStripeOpen] = React.useState(false)

  const methods = useForm<TFormData>({
    defaultValues: {
      customer_email: order.customer_email ?? user.email,
      company_name: order.company_name ?? (user as AUsersCompanyUser).company.name,
      customer_name: order.customer_name ?? getFullName(user),
      address: order.address ?? undefined,
      city: order.city ?? undefined,
      postal_code: order.postal_code ?? undefined,
      country_id: order.country?.id,
      state: order.state ?? undefined,
      company_number: order.company_number ?? undefined,
      company_vat_number: order.company_vat_number ?? undefined,
      payment_method: order.payment_method ?? undefined,
      subscription_billing_type: ASubscriptionBillingTypes.Month,
    },
    mode: "onTouched",
    resolver: zodResolver(validationSchema(countries)),
  })

  const countryId = methods.watch("country_id")
  const mutate = updateCheckoutMutation.mutateAsync

  React.useEffect(() => {
    mutate({
      orderId: order.id,
      order: {
        country_id: countryId,
      },
    })
  }, [countryId, order.id, mutate])

  const handleSubmit = React.useCallback(async () => {
    const values = methods.getValues()

    try {
      await checkoutCreateMutation.mutateAsync({
        orderId: order.id,
        data: {
          ...values,
          state: values.state ?? undefined,
          company_vat_number: values.company_vat_number ?? undefined,
        },
      })
    } catch (e) {
      setFormErrorsFromAxios(e, methods.setError, "order")
      return
    }

    // Bank transfer does not go through Stripe on FE
    if (values.payment_method === APaymentMethods.BankTransfer) {
      isWelcomeScreenEnabled &&
        emitGTMEvent({
          event: EGTMEvent.COMPANY_CHECKOUT_SEND,
          value: order.total_price,
          email: user.email,
          phone: user.phone_number ?? "",
        })

      return
    }

    // Open Stripe
    setIsStripeOpen(true)
  }, [
    checkoutCreateMutation,
    isWelcomeScreenEnabled,
    methods,
    order.id,
    order.total_price,
    user.email,
    user.phone_number,
  ])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <CheckoutForm order={order} />
      </form>

      <Modal
        isOpen={isOrderFinished(order)}
        onClose={() => navigate("/")}
        size={"sm"}
        disableCloseButton
        disableClickOutsideClose
      >
        <Finished order={order} />
      </Modal>

      <StripeModal
        isOpen={isStripeOpen}
        clientSecret={checkoutCreateMutation.data?.data.clientSecret}
        onClose={() => setIsStripeOpen(false)}
        order={order}
      />
    </FormProvider>
  )
}
