import React from "react"
import {FieldPath, FieldValues, RegisterOptions, useFormState} from "react-hook-form"
import {twMerge} from "tailwind-merge"

import {getDeepValue} from "../../utils"
import {getFormValidationMessages, isFormValidationError, TFormValidationError} from "../../utils/validation"

export type TConnectedField<T extends FieldValues, N extends FieldPath<T>> = {
  name: N
  options?: RegisterOptions<T, N>
}

export type TBaseSharedProps = {disabled?: boolean; readOnly?: boolean; hasError?: boolean}

export function useGetFieldVisibleError(name: string | undefined) {
  const {errors, isSubmitted, touchedFields, dirtyFields} = useFormState()

  if (!name) {
    return null
  }

  const isTouched = getDeepValue<boolean | undefined>(touchedFields, name) ?? false
  const isDirty = getDeepValue<boolean | undefined>(dirtyFields, name) ?? false
  const error = name ? getDeepValue<TFormValidationError>(errors, name) : null

  return ((isTouched || isDirty || isSubmitted) && error) || null
}

export const ErrorMessage: React.FC<{className?: string; error: unknown; testId?: string}> = ({
  className,
  error,
  testId,
}) => {
  if (!isFormValidationError(error)) {
    return null
  }

  const messages = getFormValidationMessages(error)
    .map(msg => msg.split("\n"))
    .flat()

  return (
    <div className={"contents"} data-testid={`error-${testId}`}>
      {messages.map((message, index) => (
        <p key={`${index}-${message}`} className={twMerge(["flex items-start gap-2 text-sm text-cr-red", className])}>
          {message}
        </p>
      ))}
    </div>
  )
}

export const FieldErrorMessage: React.FC<{name: string; reserveSpace?: boolean}> = ({name, reserveSpace}) => {
  const error = useGetFieldVisibleError(name)

  if (error) {
    return <ErrorMessage error={error} testId={name} />
  }

  if (reserveSpace) {
    return <div className={"h-5"} />
  }

  return null
}

export interface IRegisterField<T extends FieldValues, N extends FieldPath<T>> {
  name: N
  options?: RegisterOptions<T, N>
}

interface ILabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  required?: boolean
  legend?: React.ReactNode
  big?: boolean
  small?: boolean
}

export const Label: React.FC<ILabelProps> = ({required, className, legend, big, small, ...props}) => (
  <>
    <label
      {...props}
      className={twMerge(
        "label",
        !small && (big ? "text-2xl" : "text-lg"),
        big && "text-cr-blue",
        required && "required-after",
        className
      )}
    ></label>
    <legend className={"text-sm font-normal text-cr-grey-50"}>{legend}</legend>
  </>
)

/**
 * @deprecated Use TFieldLabelProps from ./FieldLabel.tsx
 */
export type TFieldLabelProps = Pick<ILabelProps, "required" | "legend" | "small"> & {
  id?: string
  label?: React.ReactNode
  className?: string
  name?: string
  hideLabel?: boolean
}

/**
 * @deprecated Use FieldLabel from ./FieldLabel.tsx
 */
export const FieldLabel: React.FC<TFieldLabelProps & {children?: JSX.Element | React.ReactNode[]}> = ({
  className,
  id,
  required,
  label,
  legend,
  small,
  children,
  name,
  hideLabel,
}) => {
  const hasError = !!useGetFieldVisibleError(name)

  if (hideLabel) {
    return children
  }

  return (
    <div className={twMerge(["text-left", hasError && "error", className])}>
      {label && (
        <Label htmlFor={id} required={required} legend={legend} small={small}>
          {label}
        </Label>
      )}

      {children && <div className={twMerge("relative", small ? "mt-1" : "mt-2")}>{children}</div>}

      {name && <FieldErrorMessage reserveSpace name={name} />}
    </div>
  )
}
