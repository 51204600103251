import React from "react"
import {twMerge} from "tailwind-merge"

export const Flyout: React.FC<{
  title: React.ReactNode
  subtitle?: React.ReactNode
  children: React.ReactNode
  buttons: React.ReactNode
  isOpen: boolean
  onClose: () => void
}> = ({title, subtitle, children, buttons, isOpen, onClose}) => {
  return (
    <div className={"relative isolate z-40"}>
      {isOpen && <div className={"fixed inset-0 z-40"} onClick={onClose} />}
      <div
        className={twMerge([
          "fixed bottom-0 left-full top-[var(--header-height)] w-[510px] bg-cr-white transition-all max-sm:w-full",
          "z-50 flex flex-col shadow-2xl",
          isOpen && "-translate-x-full",
        ])}
      >
        <div className={"flex flex-col gap-2 bg-cr-blue-mid p-6 text-cr-white"}>
          <h2 className={"text-lg font-bold"}>{title}</h2>
          {!!subtitle && <div className={"text-sm font-light"}>{subtitle}</div>}
        </div>
        <div className={"scrollbar-slim scroll-shadow-y flex grow flex-col overflow-auto"}>{children}</div>
        {buttons && <div className={"flex items-center justify-between border-t p-6"}>{buttons}</div>}
      </div>
    </div>
  )
}

export const FlyoutSectionRow: React.FC<{children: React.ReactNode}> = ({children}) => {
  return <span className={"col-span-full my-3.5 text-sm font-bold text-cr-blue"}>{children}</span>
}

export const FlyoutDataRow: React.FC<{
  legend: React.ReactNode
  children: React.ReactNode
  additionalInfo?: React.ReactNode
}> = ({legend, additionalInfo, children}) => {
  return (
    <>
      <span className={"leading-7 text-cr-grey-50"}>{legend}</span>
      <div className={"flex flex-col gap-4 text-sm"}>
        <div>{children}</div>
        <div className={"col-start-2 flex flex-col gap-2"}>{additionalInfo}</div>
      </div>
    </>
  )
}

export const FlyoutGrid: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <div
      className={
        "max-[400px]:grid-cols-1 grid grow auto-rows-min grid-cols-[max-content,_minmax(200px,_1fr)] items-baseline gap-x-10 gap-y-2 p-6"
      }
    >
      {children}
    </div>
  )
}
