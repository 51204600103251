import React from "react"
import {twMerge} from "tailwind-merge"

export const ArrowCircle: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <div className={"relative flex flex-col items-stretch"}>
      {children}
      <div
        className={twMerge([
          "absolute inset-y-[70px] left-1/2 w-20 -translate-x-1/2 rounded-full border-4 border-dashed border-cr-cyan-light",
          "max-sm:inset-y-10 max-sm:left-10 max-sm:translate-x-0",
        ])}
        aria-hidden={"true"}
      />
    </div>
  )
}
