import React from "react"

export const SellingNode: React.FC<{
  title: React.ReactNode
  subtitle?: React.ReactNode
  children: React.ReactNode
}> = ({children, title, subtitle}) => {
  return (
    <div className={"flex min-w-40 max-w-64 flex-col gap-4"}>
      <h3 className={"text-sm font-semibold text-cr-grey-50"}>{title}</h3>
      <div className={"flex grow flex-col gap-2"}>
        <h4 className={"grow text-2xl font-semibold text-cr-blue"}>{children}</h4>
        {subtitle && <h5 className={"text-xs text-cr-grey-50"}>{subtitle}</h5>}
      </div>
    </div>
  )
}
