import React from "react"

import {useReportsSalesCycleQuery} from "../../../queries/reports"
import {EMPTY_ARRAY} from "../../../utils"
import {lifetimeSymbol, PeriodToggle, TPeriodToggleValue} from "../components/PeriodToggle"
import {SendReportButton} from "../components/SendReportButton"
import {useCurrentSelectionState} from "../hooks"

export const PeriodSection: React.FC = () => {
  const {
    value: {salesCycleId, iterationId},
    setValue,
  } = useCurrentSelectionState()

  const {data} = useReportsSalesCycleQuery(salesCycleId)
  const iterations = data?.sales_cycle_iterations ?? EMPTY_ARRAY

  const periods = React.useMemo(() => {
    return iterations.map(iteration => iteration.start_date) ?? []
  }, [iterations])

  const currentPeriod = React.useMemo(() => {
    return iterations.find(iteration => iteration.id === iterationId)?.start_date ?? lifetimeSymbol
  }, [iterationId, iterations])

  const handleChangePeriod = React.useCallback(
    (period: TPeriodToggleValue) => {
      setValue(state => ({
        ...state,
        iterationId: iterations.find(iteration => iteration.start_date === period)?.id ?? null,
      }))
    },
    [iterations, setValue]
  )

  return (
    <div className={"flex flex-wrap-reverse items-center justify-between gap-8"}>
      <PeriodToggle value={currentPeriod} onChange={handleChangePeriod} periods={periods} />
      <SendReportButton />
    </div>
  )
}
