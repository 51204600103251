import React from "react"

import {VideoModal} from "../../components/VideoModal.tsx"
import {useUpdateSalespersonVideoIntroductionViewedMutation} from "../../queries/salespeople.ts"
import {useUserSettingsOrLogout} from "../../queries/user"
import {isSalesPersonUser} from "../../utils/types.ts"

export const IntroVideoModal: React.FC = () => {
  const {user} = useUserSettingsOrLogout()
  const updateMutation = useUpdateSalespersonVideoIntroductionViewedMutation()

  const isVideoViewed = isSalesPersonUser(user) && user.video_introduction_viewed

  const handleClose = () => {
    updateMutation.mutateAsync({salespersonId: user.id, videoIntroductionViewed: true})
  }

  return <VideoModal onClose={handleClose} isOpen={!isVideoViewed} youtubeId={"J4tsSzZWQz8"} />
}
