import {format} from "date-fns/format"
import {groupBy} from "lodash"

import {i18n} from "../../../../i18n.ts"
import {AActivityFeed, AProspectActivity} from "../../../../services/types.generated.ts"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"

export const getOrganizationName = (activity: AActivityFeed | AProspectActivity) => {
  return (
    activity.deal?.prospect?.organization_name ??
    activity.deal?.name ??
    i18n.t("SellingDetails_News_ActivityFeed_UnknownOrganization")
  )
}

export const groupActivities = <T extends AActivityFeed | AProspectActivity>(
  activities?: T[]
): Array<{date: string; activities: T[]}> => {
  if (!activities) {
    return []
  }

  const grouped: {[key: string]: T[]} = groupBy(activities, activity =>
    format(apiDateToJS(activity.activity_date), "yyyy-MM-dd")
  )
  const keys = Object.keys(grouped).sort().reverse()

  return keys.map(date => ({
    date,
    activities: grouped[date].sort((A, B) => {
      const timeA = apiDateToJS(A.changed_activity_at).getTime()
      const timeB = apiDateToJS(B.changed_activity_at).getTime()

      if (timeA === timeB) {
        if (isProspectActivity(A)) {
          return String(B.id).localeCompare(A.id)
        }

        return Number(B.id) - A.id
      }

      return timeB - timeA
    }),
  }))
}

export const isProspectActivity = (activity: AProspectActivity | AActivityFeed): activity is AProspectActivity => {
  return typeof activity.id === "string"
}
