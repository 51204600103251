import React from "react"
import {Trans} from "react-i18next"

import {getCurrentLanguage} from "../../../../../i18n.ts"
import {AActivityFeed} from "../../../../../services/types.generated.ts"
import {apiDateToJS} from "../../../../../utils/dateArithmetics.ts"
import {commonTransComponents} from "../../../../../utils/i18n.tsx"
import {getOrganizationName} from "../utils.ts"

export const ActivityBodyMeetingScheduled: React.FC<{activity: AActivityFeed}> = ({activity}) => {
  return (
    <div>
      <Trans
        i18nKey={"SellingDetails_News_ActivityFeed_MeetingScheduled"}
        components={commonTransComponents}
        values={{
          organization: getOrganizationName(activity),
          date: apiDateToJS(getDisplayDate(activity)).toLocaleString(getCurrentLanguage(), {
            month: "long",
            day: "numeric",
          }),
        }}
      />
    </div>
  )
}

const getDisplayDate = ({happens_at, created_at}: AActivityFeed) => {
  if (!happens_at) {
    return created_at
  }

  if (new Date(created_at) > new Date(happens_at)) {
    return created_at
  }

  return happens_at
}
