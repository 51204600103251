import React from "react"
import {Trans} from "react-i18next"

import {i18n} from "../../i18n.ts"
import {AGoals} from "../../services/types.generated.ts"
import {BulletedTransList} from "../../utils/i18n.tsx"
import {transComponents} from "./utils.tsx"

export const CommissionTooltip: React.FC<{goal: AGoals}> = ({goal}) => {
  return (
    <div className={"flex w-80 flex-col gap-4 whitespace-break-spaces text-sm"}>
      <span className={"font-bold"}>{commissionTooltipByGoal[goal].title}</span>
      <BulletedTransList>{commissionTooltipByGoal[goal].text}</BulletedTransList>
    </div>
  )
}

const commissionTooltipByGoal = {
  [AGoals.CloseDeal]: {
    title: i18n.t("SalespersonDashboard_NewOffer_Tooltip_Commission_CloseDeal_Title"),
    text: (
      <Trans components={transComponents} i18nKey={"SalespersonDashboard_NewOffer_Tooltip_Commission_CloseDeal_Text"} />
    ),
  },
  [AGoals.SetupMeeting]: {
    title: i18n.t("SalespersonDashboard_NewOffer_Tooltip_Commission_SetupMeeting_Title"),
    text: (
      <Trans
        components={transComponents}
        i18nKey={"SalespersonDashboard_NewOffer_Tooltip_Commission_SetupMeeting_Text"}
      />
    ),
  },
  [AGoals.ContractConclusion]: {
    title: i18n.t("SalespersonDashboard_NewOffer_Tooltip_Commission_ContractConclusion_Title"),
    text: (
      <Trans
        components={transComponents}
        i18nKey={"SalespersonDashboard_NewOffer_Tooltip_Commission_ContractConclusion_Text"}
      />
    ),
  },
  [AGoals.Registration]: {
    title: i18n.t("SalespersonDashboard_NewOffer_Tooltip_Commission_Registration_Title"),
    text: (
      <Trans
        components={transComponents}
        i18nKey={"SalespersonDashboard_NewOffer_Tooltip_Commission_Registration_Text"}
      />
    ),
  },
} satisfies {[goal in AGoals]: {title: string; text: React.ReactNode}}
