import React from "react"
import {useTranslation} from "react-i18next"
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline"

import {IconButton} from "../../../components/Button.tsx"
import {Link} from "../../../components/Link.tsx"
import {Tooltip} from "../../../components/Tooltip.tsx"
import {getCurrentLanguage} from "../../../i18n.ts"
import {ACompanyUserDashboardOrder, AOrderStatuses} from "../../../services/types.generated.ts"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"

export const UnpaidTooltipButton: React.FC<{order?: ACompanyUserDashboardOrder | null}> = ({order}) => {
  const {t} = useTranslation()

  if (!order || order.status === AOrderStatuses.Paid || !order.due_date) {
    return null
  }

  const dueDate = apiDateToJS(order.due_date).toLocaleString(getCurrentLanguage(), {month: "long", day: "numeric"})

  return (
    <Tooltip
      buttonNode={
        <IconButton
          className={"bg-cr-yellow-light text-cr-yellow hover:bg-cr-yellow-light"}
          data-testid={"unpaid-tooltip-button"}
        >
          <ExclamationTriangleIcon className={"size-5"} />
        </IconButton>
      }
    >
      <div className={"card-shadow flex flex-col gap-6 px-6 py-4"}>
        <h3 className={"font-semibold"}>{t("Dashboard_Company_Active_MissingPayment_Title")}</h3>
        <div className={"text-xs"}>{t("Dashboard_Company_Active_MissingPayment_Text", {date: dueDate})}</div>
        {order.external_invoice_url && (
          <Link
            noUnderline
            to={order.external_invoice_url}
            className={"font-semibold"}
            rel={"noreferrer"}
            target={"_blank"}
          >
            {t("Dashboard_Company_Active_MissingPayment_InvoiceLink")}
          </Link>
        )}
      </div>
    </Tooltip>
  )
}
