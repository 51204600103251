import React, {ChangeEventHandler} from "react"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../components/Alert"
import {CheckboxBase} from "../../../components/fields/Checkbox"
import {InputBase} from "../../../components/fields/Input"
import {DropdownBase, TOption} from "../../../components/formElements/Dropdown"
import {Loading} from "../../../components/Loading"
import Pagination from "../../../components/Pagination"
import {RowCountPickerLS, usePageSize} from "../../../components/RowCountPicker"
import {Table} from "../../../components/Table"
import {TOrderBy} from "../../../components/Table/shared"
import {i18n} from "../../../i18n"
import type {useDraftTableQuery} from "../../../queries/prospects"
import {useBulkDeprioritizeProspectsMutation, useBulkPrioritizeProspectsMutation} from "../../../queries/prospects"
import requestError from "../../../services/requestError"
import {AAdminProspect, AProspectQueryStatusValues} from "../../../services/types.generated"
import {EMPTY_ARRAY} from "../../../utils"
import {useDebouncedValue} from "../../../utils/hooks"
import {DeletingContext} from "../shared/context"
import {DataTable, TAdminTableColumn} from "../shared/DataTable.tsx"
import {SendForApprovalButton} from "../shared/SendForApprovalButton.tsx"
import {UploadButton} from "../shared/UploadButton.tsx"

enum EBulkAction {
  PRIORITIZE,
  DEPRIORITIZE,
  DELETE,
}

type TProps<TQuery extends typeof useDraftTableQuery> = {
  salesCycleId: number
  assignmentId: number

  isAddButtonVisible?: boolean
  isSendButtonVisible?: boolean | "disabled"
  isRejectedOnlyToggleVisible?: boolean
  isReadOnly?: boolean
  isPrioritizable?: boolean

  listQuery: TQuery
  paginationKey: string

  title: React.ReactNode
}

export function Section<TQuery extends typeof useDraftTableQuery>({
  assignmentId,
  salesCycleId,
  isSendButtonVisible,
  isAddButtonVisible,
  isRejectedOnlyToggleVisible,
  isReadOnly,
  isPrioritizable = true,
  listQuery,
  title,
  paginationKey,
}: TProps<TQuery>): React.ReactNode {
  const {t} = useTranslation()

  const [searchString, setSearchString] = React.useState("")
  const searchStringDebounced = useDebouncedValue(searchString)

  const [orderBy, setOrderBy] = React.useState<TOrderBy<TAdminTableColumn>>(undefined)

  const [status, setStatus] = React.useState<AProspectQueryStatusValues | null>(null)

  const handleChangeSearchString = React.useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    setSearchString(e.target.value)
  }, [])

  const {setValue: setDeletingProspects} = DeletingContext.useContext()

  const {data, error, isFetching, isLoading, refetch, pagination} = listQuery({
    assignmentId,
    searchString: searchStringDebounced,
    salesCycleId,
    orderBy,
    pageSize: usePageSize(paginationKey),
    status,
  })
  const prospects = (data?.data.prospects ?? EMPTY_ARRAY) as AAdminProspect[]

  const {checkedRows, checkRow} = Table.useRowChecking(prospects)

  const bulkPrioritizeMutation = useBulkPrioritizeProspectsMutation()
  const bulkDeprioritizeMutation = useBulkDeprioritizeProspectsMutation()

  const bulkActionOptions = React.useMemo(() => {
    return [
      isPrioritizable && {title: i18n.t("Prospects_BulkAction_Prioritize"), value: EBulkAction.PRIORITIZE},
      {title: i18n.t("Prospects_BulkAction_Deprioritize"), value: EBulkAction.DEPRIORITIZE},
      {title: i18n.t("Prospects_BulkAction_Delete"), value: EBulkAction.DELETE},
    ].filter(value => !!value) satisfies Array<TOption<EBulkAction>>
  }, [isPrioritizable])

  const handleBulkAction = React.useCallback(
    async (action: EBulkAction) => {
      if (action === EBulkAction.DELETE) {
        setDeletingProspects(checkedRows as number[])
        return
      }

      const mutation = {
        [EBulkAction.PRIORITIZE]: bulkPrioritizeMutation,
        [EBulkAction.DEPRIORITIZE]: bulkDeprioritizeMutation,
      }[action]

      try {
        await mutation.mutateAsync(checkedRows as number[])
        refetch()
      } catch (e) {
        requestError(e)
      }
    },
    [bulkDeprioritizeMutation, bulkPrioritizeMutation, checkedRows, refetch, setDeletingProspects]
  )

  return (
    <div className={"flex flex-col gap-4"}>
      <h3 className={"font-bold"}>{title}</h3>
      <div className={"flex flex-wrap-reverse justify-between gap-10"}>
        <div className={"flex flex-wrap items-center gap-4"}>
          {!isReadOnly && (
            <DropdownBase
              options={bulkActionOptions}
              placeholder={t("Prospects_BulkAction_Placeholder", {count: checkedRows.length})}
              value={undefined}
              multiple={false}
              onChange={handleBulkAction as any}
              buttonClassName={"min-w-[150px]"}
              disabled={checkedRows.length === 0}
            />
          )}
        </div>
        <div className={"flex flex-wrap items-center gap-4"}>
          {isRejectedOnlyToggleVisible && (
            <CheckboxBase
              toggle
              onChange={e => {
                setStatus(e.target.checked ? AProspectQueryStatusValues.Rejected : null)
              }}
              checked={status === AProspectQueryStatusValues.Rejected}
            >
              {t("Prospects_RejectedOnlyToggle")}
            </CheckboxBase>
          )}
          {isAddButtonVisible && <UploadButton variant={"light"} />}
          {isSendButtonVisible && !isReadOnly && (
            <SendForApprovalButton assignmentId={assignmentId} disabled={isSendButtonVisible === "disabled"} />
          )}
          <InputBase value={searchString} onChange={handleChangeSearchString} placeholder={t("SearchPlaceholder")} />
        </div>
      </div>

      {error ? (
        <GenericErrorAlert />
      ) : isLoading ? (
        <Loading containerClassName={"my-10"} />
      ) : (
        <>
          <DataTable
            data={prospects}
            isLoading={isFetching}
            isPrioritizeActionVisible={isPrioritizable}
            isReadOnly={isReadOnly}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            checkedRows={checkedRows}
            checkRow={checkRow}
            refetch={refetch}
          />
          <RowCountPickerLS total={pagination.total} autoHide paginationKey={paginationKey} />
          <Pagination {...pagination} autoHide />
        </>
      )}
    </div>
  )
}
