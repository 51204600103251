import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {Alert} from "../../../../components/Alert"
import {TCompanyProspectsAssignment} from "../../../../queries/prospects"
import {commonTransComponents} from "../../../../utils/i18n"

export const RejectingBanner: React.FC<{assignment: TCompanyProspectsAssignment}> = ({assignment}) => {
  const {t} = useTranslation()

  if (assignment.prospects_approved_count === 0 && assignment.prospects_ready_to_approve_count === 0) {
    return <Alert variant={"info"}>{t("Prospects_RejectingBanner_NoData")}</Alert>
  }

  if (
    assignment.prospects_approved_count >= assignment.prospects_mandatory_count &&
    assignment.prospects_ready_to_approve_count === 0
  ) {
    return null
  }

  if (
    assignment.prospects_ready_to_approve_count === 0 &&
    assignment.prospects_approved_count > 0 &&
    assignment.prospects_approved_count < assignment.prospects_mandatory_count
  ) {
    return <Alert variant={"secondary"}>{t("Prospects_RejectingBanner_NotEnoughProspects")}</Alert>
  }

  return (
    <Alert variant={"info"}>
      <div className={"flex gap-4"}>
        <div className={"grow"}>
          <Trans
            i18nKey={"Prospects_RejectingBanner_Text"}
            components={commonTransComponents}
            count={assignment.maximum_rejections}
            values={{firstName: assignment.sales_person?.first_name ?? assignment.package_name}}
          />
        </div>
        <div className={twMerge(["font-bold", assignment.rejections_limit === 0 && "text-cr-red"])}>
          {t("Prospects_RejectingBanner_Rejections", {count: assignment.rejections_limit})}
        </div>
      </div>
    </Alert>
  )
}
