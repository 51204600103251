import React from "react"
import {useTranslation} from "react-i18next"
import {ArrowDownRightIcon, ArrowUpRightIcon} from "@heroicons/react/16/solid"

export const Trend: React.FC<{value?: number | null}> = ({value}) => {
  const {t} = useTranslation()

  if (!value || value <= 0) {
    return null
  }

  return (
    <h4 className={"flex items-center gap-1 text-xs text-cr-grey-65"} data-testid={"trend"}>
      {value > 0 ? (
        <ArrowUpRightIcon className={"size-4 shrink-0 text-cr-green"} />
      ) : (
        <ArrowDownRightIcon className={"size-4 shrink-0 text-cr-red"} />
      )}

      <span>{t("Reports_Trend", {percentage: value.toFixed(1)})}</span>
    </h4>
  )
}

export const TrendSkeleton: React.FC = () => {
  return <h4 className={"w-32 rounded-full bg-cr-grey-15 text-xs"}>&nbsp;</h4>
}
