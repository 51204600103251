import React from "react"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import {ButtonLink} from "../../../components/Button.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {assignmentToSalesperson, Salespeople} from "../../../components/Salespeople.tsx"
import {SellingsBlockBadge} from "../../../components/SellingsBlock"
import {getCurrentLanguage} from "../../../i18n.ts"
import {useCompanyDashboardActiveSalesCyclesQuery} from "../../../queries/companyDashboard.ts"
import {ACompanyUserDashboardActiveSalesCycle, AOrderStatuses} from "../../../services/types.generated.ts"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {SellingHeader} from "../components/SellingHeader.tsx"
import {SellingNode} from "../components/SellingNode.tsx"
import {Stages} from "../components/Stages.tsx"
import {UnpaidTooltipButton} from "../components/UnpaidTooltipButton.tsx"

export const ActiveTab: React.FC = () => {
  const {data, error, refetch} = useCompanyDashboardActiveSalesCyclesQuery()

  return error ? (
    <GenericErrorAlert retry={refetch} />
  ) : data ? (
    <div className={"flex flex-col gap-10"}>
      {data.map(salesCycle => (
        <ActiveSelling key={salesCycle.id} salesCycle={salesCycle} />
      ))}
    </div>
  ) : (
    <Loading size={"xl"} fullSpace />
  )
}

export const ActiveSelling: React.FC<{salesCycle: ACompanyUserDashboardActiveSalesCycle}> = ({salesCycle}) => {
  const {t} = useTranslation()

  const activeSince = apiDateToJS(salesCycle.first_selling_date).toLocaleString(getCurrentLanguage(), {
    month: "long",
    year: "numeric",
  })
  const activeMonth = apiDateToJS(salesCycle.current_selling_date).toLocaleString(getCurrentLanguage(), {month: "long"})

  const isUnpaid = salesCycle.order && salesCycle.order.status !== AOrderStatuses.Paid

  return (
    <div className={"card-shadow flex flex-col"} data-testid={"salesCycle"}>
      <div className={"flex flex-col gap-6 px-12 py-8"}>
        <SellingHeader salesCycle={salesCycle}>
          <UnpaidTooltipButton order={salesCycle.order} />
          <SellingsBlockBadge isActive={true} status={isUnpaid ? "awaiting_payment" : salesCycle.status} />
          <ButtonLink variant={"light"} size={"sm"} to={`/sellings/${salesCycle.id}`}>
            {t("Dashboard_Company_Active_DetailsButton")}
          </ButtonLink>
        </SellingHeader>
        <div className={"flex flex-wrap justify-between gap-10 max-sm:flex-col"}>
          <SellingNode
            title={t("Dashboard_Company_Active_Month_Title")}
            subtitle={t("Dashboard_Company_Active_Month_Subtitle", {date: activeSince})}
          >
            {activeMonth}
          </SellingNode>
          {salesCycle.opportunities != null && (
            <SellingNode title={t("Dashboard_Company_Active_Opportunities_Title")}>
              {salesCycle.opportunities}
            </SellingNode>
          )}
          {salesCycle.activities != null && (
            <SellingNode
              title={t("Dashboard_Company_Active_Activities_Title")}
              subtitle={t("Dashboard_Company_Active_Activities_Subtitle")}
            >
              {salesCycle.activities}
            </SellingNode>
          )}
          {salesCycle.assignments && (
            <SellingNode title={t("Dashboard_Company_Active_SalesPeople_Title")}>
              <div className={"text-base"}>
                <Salespeople max={6} salesPeople={salesCycle.assignments.map(assignmentToSalesperson)} />
              </div>
            </SellingNode>
          )}
        </div>
      </div>
      <div className={"border-t border-t-cr-blue-light px-12 py-8"}>
        <Stages salesCycle={salesCycle} />
      </div>
    </div>
  )
}
