import React from "react"
import {useTranslation} from "react-i18next"
import {HeartIcon as HeartIconOutline} from "@heroicons/react/24/outline"
import {HeartIcon as HeartIconSolid} from "@heroicons/react/24/solid"

import {Link} from "../../../../../components/Link.tsx"
import {useActivityFeedLikeMutation, useActivityFeedUnlikeMutation} from "../../../../../queries/activityFeed.ts"
import requestError from "../../../../../services/requestError.tsx"
import {AActivityFeed} from "../../../../../services/types.generated.ts"

export const ActivityLike: React.FC<{activity: AActivityFeed}> = ({activity}) => {
  const {t} = useTranslation()

  const LikeIcon = activity.liked_by_company ? HeartIconSolid : HeartIconOutline

  const likeMutation = useActivityFeedLikeMutation()
  const unlikeMutation = useActivityFeedUnlikeMutation()

  const mutation = activity.liked_by_company ? unlikeMutation : likeMutation
  const handleClick = React.useCallback(async () => {
    try {
      await mutation.mutateAsync({activityId: activity.id})
    } catch (e) {
      requestError(e)
    }
  }, [mutation, activity.id])

  return (
    <div>
      <Link
        onClick={handleClick}
        loading={mutation.isPending}
        variant={"primary"}
        className={"inline-flex items-center gap-1 text-sm font-semibold"}
        noUnderline
      >
        <LikeIcon className={"size-4"} />
        {t("SellingDetails_News_ActivityFeed_Like")}
      </Link>
    </div>
  )
}
