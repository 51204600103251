import * as RetryModule from "./retry"

export async function wait(time: number = 1000) {
  return new Promise(res => {
    setTimeout(res, time)
  })
}

export async function callWithRetry<T extends (...args: any[]) => Promise<any>>(
  fn: T,
  options: Partial<{retryCount: number; waitTime: number; shouldIncreaseWaitTime: boolean}> = {}
): Promise<ReturnType<T>> {
  const {retryCount = 3, waitTime = 1000, shouldIncreaseWaitTime = true} = options

  try {
    return await fn()
  } catch (e) {
    if (!retryCount) {
      throw e
    }

    await RetryModule.wait(waitTime)

    return await callWithRetry(fn, {
      ...options,
      retryCount: retryCount - 1,
      waitTime: shouldIncreaseWaitTime ? waitTime * 2 : waitTime,
    })
  }
}
