import React from "react"
import {useTranslation} from "react-i18next"

import Pagination from "../../../../components/Pagination.tsx"
import {AAdminSalesCycleSalesNews, ACompanyUserSalesCycleSalesNews} from "../../../../services/types.generated.ts"
import {AssignmentProgress} from "./AssignmentProgress.tsx"

const PER_PAGE = 3

export const SalesProgressCard: React.FC<{data: ACompanyUserSalesCycleSalesNews | AAdminSalesCycleSalesNews}> = ({
  data,
}) => {
  const {t} = useTranslation()

  const [currentPage, setCurrentPage] = React.useState(1)

  const assignmentsSorted = React.useMemo(() => {
    return [...(data.assignments ?? [])].sort(
      (A, B) => (B.outreach ?? 0) / (B.reaches ?? 1) - (A.outreach ?? 0) / (A.reaches ?? 1)
    )
  }, [data])

  const pageAssignments = React.useMemo(() => {
    return assignmentsSorted.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE)
  }, [assignmentsSorted, currentPage])

  if (pageAssignments.length === 0) {
    return null
  }

  return (
    <div className={"card-shadow flex min-h-[480px] flex-col gap-4 p-6"}>
      <h3 className={"text-lg font-bold"}>{t("SellingDetails_News_Progress_Title")}</h3>
      <div className={"flex flex-col divide-y divide-cr-grey-5"}>
        {pageAssignments.map(assignment => (
          <div key={assignment.id} className={"py-6"}>
            <AssignmentProgress assignment={assignment} hideMeetings={data.hide_meeting_activities} />
          </div>
        ))}
      </div>
      <Pagination
        autoHide
        page={currentPage}
        setPage={setCurrentPage}
        pageSize={PER_PAGE}
        total={assignmentsSorted.length}
      />
    </div>
  )
}
