import {z} from "zod"

import {i18n} from "../../i18n.ts"
import {AGoals, ASalesCycle, ASalesCycleSegment} from "../../services/types.generated"
import {requiredFieldMessage, validateNativeEnum, validateNonemptyString} from "../../utils/validation"
import {TSalesFormData} from "./types"

export const validationSchema = (isFileUploaded: boolean) =>
  z.object({
    country_id: z.number({required_error: requiredFieldMessage}),
    ideal_customer_profile: z.string({required_error: requiredFieldMessage}),
    decision_maker_persona: z.array(validateNonemptyString()).nonempty(requiredFieldMessage),
    segment: validateNativeEnum(ASalesCycleSegment),
    price_list: z.custom<File>().refine(file => isFileUploaded || !!file, requiredFieldMessage),
    products_attributes: z
      .array(
        z.object({
          goal: validateNativeEnum(AGoals),
          commission_type: z.any(),
          commission_value_type: z.any(),
          commission: z
            .number({
              required_error: i18n.t("SalesStrategy_Commission is required"),
              invalid_type_error: i18n.t("SalesStrategy_Commission is required"),
            })
            .positive({message: i18n.t("SalesStrategy_Commission must be greater than 0")}),
        })
      )
      .nonempty(requiredFieldMessage),
  })

export function salesCycleToValues(salesCycle: ASalesCycle): TSalesFormData {
  return {
    country_id: salesCycle.country.id,
    segment: salesCycle.segment,
    segment_note: salesCycle.segment_note ?? undefined,
    ideal_customer_profile: salesCycle.ideal_customer_profile,
    decision_maker_persona: salesCycle.decision_maker_persona ?? [],
    price_list_url: salesCycle.price_list_url,
    products_attributes: salesCycle.products.map(product => ({
      ...product,
      currency_id: product.currency?.id,
    })),
    sales_person_offline_required: salesCycle.sales_person_offline_required,
    sales_person_online_required: salesCycle.sales_person_online_required,
    sales_person_requirements_note: salesCycle.sales_person_requirements_note ?? undefined,
  }
}
