import React from "react"

import {i18n} from "../../../i18n.ts"
import {useUserSettingsQuery} from "../../../queries/user.ts"
import {AUserTypes} from "../../../services/types.generated.ts"
import {EFeature, useIsFeatureEnabled} from "../../../utils/featureFlags.ts"
import {isSalesPersonUser} from "../../../utils/types.ts"

export const UserTypes = {...AUserTypes, Anonymous: "Anonymous"} as const

type TNavigationItem = {
  name: string
  href: string
  isExternal?: boolean
}

export const useNavigationItems = (): readonly TNavigationItem[] => {
  const {data, isPending} = useUserSettingsQuery()

  const user = data?.user

  const isUserManagementEnabled = useIsFeatureEnabled(EFeature.SHOW_SAAS_USER_MANAGEMENT)

  const withNullish = React.useMemo<Array<null | false | undefined | TNavigationItem>>(() => {
    if (isPending) {
      return []
    }

    switch (user?.type) {
      case AUserTypes.Admin:
        return [
          {name: i18n.t("T_Sales plans"), href: "/admin/sellings"},
          {name: i18n.t("T_Matching"), href: "/admin/matching"},
          {name: i18n.t("Leads_HeaderItem"), href: "/leads"},
        ]
      case AUserTypes.CompanyUser:
        return [
          {
            name: i18n.t("T_Dashboard"),
            href: "/dashboard",
          },
          {
            name: i18n.t("T_How it works"),
            href: "/how-it-works",
          },
          {
            name: i18n.t("T_Pricing"),
            href: i18n.t("Url_Pricing"),
            isExternal: true,
          },
        ]
      case AUserTypes.SalesPerson:
        if (!isSalesPersonUser(user)) {
          return []
        }

        return [
          {
            name: i18n.t("T_Dashboard"),
            href: "/dashboard",
          },
          user.leads_enabled && {name: i18n.t("Leads_HeaderItem"), href: "/leads"},
        ]
      case UserTypes.SaasSalesPerson:
        return [
          {
            name: i18n.t("T_Dashboard"),
            href: "/dashboard",
          },
        ]
      case AUserTypes.SaasCompanyUser:
        return [
          {
            name: i18n.t("T_Dashboard"),
            href: "/dashboard",
          },
          isUserManagementEnabled && {name: i18n.t("SaaSUserManagement_HeaderTitle"), href: "/user-management"},
        ]

      default:
        return []
    }
  }, [isPending, isUserManagementEnabled, user])

  return withNullish.filter(item => !!item)
}
