import React from "react"
import {useTranslation} from "react-i18next"
import {ClipboardDocumentCheckIcon} from "@heroicons/react/16/solid"
import {CheckIcon} from "@heroicons/react/20/solid"

import Button from "../../../components/Button.tsx"
import {ConfirmModal} from "../../../components/Modal.tsx"
import {useSendProspectsForApprovalMutation} from "../../../queries/prospects.ts"
import {useUserSettingsOrLogout} from "../../../queries/user.ts"
import requestError from "../../../services/requestError.tsx"
import {isSalesPersonUser} from "../../../utils/types.ts"

export const SendForApprovalButton: React.FC<{assignmentId: number; disabled?: boolean}> = ({
  assignmentId,
  disabled,
}) => {
  const {t} = useTranslation()

  const {user} = useUserSettingsOrLogout()
  const isSalesperson = isSalesPersonUser(user)

  const [isVisible, setIsVisible] = React.useState(false)

  const sendProspectsForApprovalMutation = useSendProspectsForApprovalMutation()

  const handleConfirm = React.useCallback(async () => {
    try {
      await sendProspectsForApprovalMutation.mutateAsync(assignmentId)
    } catch (e) {
      requestError(e)
    }
  }, [assignmentId, sendProspectsForApprovalMutation])

  return (
    <>
      <Button
        onClick={() => setIsVisible(true)}
        iconLeft={<ClipboardDocumentCheckIcon className={"h-5 w-5"} />}
        disabled={disabled}
      >
        {t("Prospects_SendForApproval_Button")}
      </Button>

      <ConfirmModal
        onConfirm={handleConfirm}
        title={t("Prospects_SendForApproval_Title")}
        confirmButtonText={t("Prospects_SendForApproval_ConfirmButton")}
        confirmButtonIcon={<CheckIcon className={"h-5 w-5"} />}
        isOpen={isVisible}
        onClose={() => setIsVisible(false)}
      >
        {isSalesperson ? t("Prospects_SendForApproval_TextSalesperson") : t("Prospects_SendForApproval_Text")}
      </ConfirmModal>
    </>
  )
}
