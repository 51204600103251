import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {CheckCircleIcon, ExclamationTriangleIcon} from "@heroicons/react/24/outline"

import {getCurrentLanguage} from "../../../../i18n"
import {TCompanyProspectsIteration} from "../../../../queries/prospects"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {commonTransComponents} from "../../../../utils/i18n"
import {StatusBannerGeneric} from "../../shared/StatusBannerGeneric"
import {getAssignmentPackageVariant} from "./AssignmentPackage"

export const StatusBanner: React.FC<{iteration: TCompanyProspectsIteration}> = ({iteration}) => {
  const {t} = useTranslation()

  if (iteration.assignments.every(assignment => getAssignmentPackageVariant(assignment) === "finished")) {
    return (
      <StatusBannerGeneric
        color={"blue"}
        Icon={CheckCircleIcon}
        title={t("Prospects_Banner_Finished_Title", {
          count: iteration.prospects_approved_count,
        })}
        text={t("Prospects_Banner_Finished_Text")}
      />
    )
  }

  return (
    <StatusBannerGeneric
      color={"yellow"}
      Icon={ExclamationTriangleIcon}
      title={t("Prospects_Banner_CompanyApproving_Title", {
        currentNum: iteration.prospects_approved_count,
        maxNum: iteration.prospects_mandatory_count,
      })}
      text={
        <>
          <Trans
            i18nKey={"Prospects_Banner_CompanyApproving_Text"}
            components={commonTransComponents}
            values={{
              date: apiDateToJS(iteration.deadline_approval_date).toLocaleString(getCurrentLanguage(), {
                month: "long",
                day: "numeric",
              }),
            }}
          />
        </>
      }
    />
  )
}
