import {i18n} from "../../i18n.ts"
import {TStep} from "./types.ts"

export const stepCompanyProfile = {
  name: i18n.t("T_Company profile"),
  href: "profile",
} as const satisfies TStep

export const stepCompanyTransactions = {
  name: i18n.t("CompanyProfile_Transactions"),
  href: "transactions",
  isAvatarHidden: true,
} as const satisfies TStep
