import {useMutation} from "@tanstack/react-query"
import {AxiosResponse} from "axios"

import {useUpdateSalesCyclesQueryCache} from "../../queries/salesCycles"
import api, {multipartRequest} from "../../services"
import {ASalesCycle} from "../../services/types.generated"
import {TSalesFormData} from "../SalesForm/types"
import {stepWeb} from "./stepsConfig.ts"
import type {steps} from "./utils"

export function useSaveStepMutation() {
  const onSuccess = useUpdateSalesCyclesQueryCache()

  return useMutation({
    mutationFn: saveStep,
    onSuccess,
  })
}

export function useFinalizeMutation() {
  const saveStepMutation = useSaveStepMutation()
  const onSuccess = useUpdateSalesCyclesQueryCache(false)

  return useMutation({
    mutationFn: async (args: Parameters<typeof saveStep>[0]) => {
      await saveStepMutation.mutateAsync(args)
      return api.salesCycles.finalizeCreate(args.salesCycleId)
    },
    onSuccess,
  })
}

export function useRemoveAttachmentMutation() {
  return useMutation({
    mutationFn: ({salesCycleId, attachment_id}: {salesCycleId: number; attachment_id: number}) =>
      api.salesCycles.removeAttachmentDelete(salesCycleId, {attachment_id}),
  })
}

async function saveStep({
  step,
  values,
  salesCycleId,
}: {
  step: (typeof steps)[number]
  values: TSalesFormData
  salesCycleId: number
}): Promise<AxiosResponse<{sales_cycle: ASalesCycle}>> {
  // Only pick the fields that change in this step
  const body = (step.fields as ReadonlyArray<keyof TSalesFormData>).reduce((acc, field) => {
    return {...acc, [field]: values[field]}
  }, {} as Partial<TSalesFormData>)

  if (step.href === stepWeb.href) {
    return multipartRequest({
      method: "PUT",
      path: `sales_cycles/${salesCycleId}`,
      data: {sales_cycle: body},
    })
  }

  return api.salesCycles.salesCyclesPartialUpdate(salesCycleId, {sales_cycle: body as TSalesFormData} as any)
}
