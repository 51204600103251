import React from "react"
import {useTranslation} from "react-i18next"
import {CheckIcon} from "@heroicons/react/20/solid"

import Button from "../../../../components/Button"
import {ConfirmModal} from "../../../../components/Modal"
import {useAutoApproveProspectsMutation} from "../../../../queries/prospects"
import requestError from "../../../../services/requestError"

export const AutoApproveButton: React.FC<{assignmentId: number}> = ({assignmentId}) => {
  const {t} = useTranslation()

  const [isVisible, setIsVisible] = React.useState(false)

  const autoApproveMutation = useAutoApproveProspectsMutation()

  const handleConfirm = React.useCallback(async () => {
    try {
      await autoApproveMutation.mutateAsync(assignmentId)
    } catch (e) {
      requestError(e)
    }
  }, [assignmentId, autoApproveMutation])

  return (
    <>
      <Button onClick={() => setIsVisible(true)} variant={"outlined"} color={"gray"} iconLeft={<CheckIcon />}>
        {t("Prospects_ApproveProspects_Button")}
      </Button>

      <ConfirmModal
        onConfirm={handleConfirm}
        title={t("Prospects_ApproveProspects_AutoApprove_Title")}
        isOpen={isVisible}
        onClose={() => setIsVisible(false)}
        confirmButtonText={t("Prospects_ApproveProspects_AutoApprove_ConfirmButton")}
        noIcon
      >
        {t("Prospects_ApproveProspects_AutoApprove_Text")}
      </ConfirmModal>
    </>
  )
}
