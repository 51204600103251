import {formatDistanceStrict, Locale, subMonths, subYears} from "date-fns"
import {enGB, sk} from "date-fns/locale"

import {ELanguage, getCurrentLanguage, i18n} from "../i18n"

const languageLocaleMap: Record<ELanguage, Locale> = {
  [ELanguage.EN]: enGB,
  [ELanguage.SK]: sk,
}

export const getDateFnsLocale = () => ({locale: languageLocaleMap[getCurrentLanguage()]})

export const monthsAndYears = (months: number) => {
  const locale = getDateFnsLocale()

  const years = Math.floor(months / 12)
  const monthsLeft = months % 12

  let result = ""
  if (years > 0) {
    result += formatDistanceStrict(new Date(), subYears(new Date(), years), locale)
  }
  if (years > 0 && monthsLeft > 0) {
    result += ` ${i18n.t("T_and")} `
  }
  if (monthsLeft > 0) {
    result += formatDistanceStrict(new Date(), subMonths(new Date(), monthsLeft), locale)
  }

  return result
}

export const apiDateToJS = (apiDate: string): Date => {
  const matches = /^(?<year>\d{4})-(?<month>\d{1,2})-(?<day>\d{1,2})$/.exec(apiDate)?.groups

  if (!matches) {
    return new Date(apiDate)
  }

  // We want to use the date in the local timezone, instead of UTC
  // As per spec - when you supply the time to new Date(), the local timezone will be used.
  // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
  return new Date(`${matches.year}-${matches.month.padStart(2, "0")}-${matches.day.padStart(2, "0")}T00:00:00`)
}
