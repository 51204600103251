import React from "react"
import {twMerge} from "tailwind-merge"

import LogoIcon from "../assets/logoIconCurrentColor.svg?react"
import {LoadableImage} from "./LoadableImage.tsx"

export const ProfileImage: React.FC<{
  className?: string
  src?: string
  alt?: string
  title?: string
  disabled?: boolean
  rectangular?: boolean
}> = ({className, rectangular, disabled, src, alt, title}) => {
  if (src) {
    return (
      <LoadableImage
        className={twMerge(
          !rectangular && "rounded-full",
          disabled && "bg-white brightness-200 contrast-50 grayscale",
          className
        )}
        src={src}
        alt={alt ?? title}
        title={title}
      />
    )
  }

  return <PlaceholderAvatar className={twMerge(!rectangular && "rounded-full", className)} title={title} />
}

export const PlaceholderAvatar: React.FC<{title?: string; className?: string}> = ({title, className}) => (
  <div
    data-testid={"PlaceholderAvatar"}
    className={twMerge("overflow-hidden bg-cr-grey-15 text-cr-white", className)}
    title={title}
  >
    <LogoIcon className={"p-[10%]"} role={"img"} />
  </div>
)
