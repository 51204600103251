import React from "react"
import {useTranslation} from "react-i18next"

import {FlyoutDataRow, FlyoutGrid, FlyoutSectionRow} from "../../../components/Flyout"
import {enumTranslKey} from "../../../utils/i18n"
import {TTabComponentProps} from "./EditingFlyout"

export const Details: React.FC<TTabComponentProps> = ({lead}) => {
  const {t} = useTranslation()

  return (
    <FlyoutGrid>
      <FlyoutSectionRow>{t("Leads_EditingFlyout_Ownership")}</FlyoutSectionRow>

      {lead.ownership_levels?.map(ownership => (
        <FlyoutDataRow legend={ownership.owner_name ?? t("Leads_EditingFlyout_Unknown")} key={ownership.id}>
          {t(enumTranslKey("OwnershipAccess", ownership.access), ownership.access)}
        </FlyoutDataRow>
      ))}

      <FlyoutSectionRow>{t("Leads_EditingFlyout_Source")}</FlyoutSectionRow>

      {lead.sources?.map(source => (
        <FlyoutDataRow legend={t("Leads_EditingFlyout_Source")} key={source}>
          {source}
        </FlyoutDataRow>
      ))}
    </FlyoutGrid>
  )
}
