import React from "react"
import {useTranslation} from "react-i18next"
import {CheckIcon} from "@heroicons/react/20/solid"
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {ButtonLoading} from "../../../components/Button.tsx"
import {Tooltip, TTooltipButtonProps} from "../../../components/Tooltip.tsx"
import {useAcceptProspectWarningsMutation} from "../../../queries/prospects.ts"
import requestError from "../../../services/requestError.tsx"
import {AssignmentContext} from "./context.ts"

export const Warnings: React.FC<{warnings: string[] | undefined | null; prospectId: number}> = ({
  warnings,
  prospectId,
}) => {
  const {t} = useTranslation()

  const acceptMutation = useAcceptProspectWarningsMutation()
  const {
    value: {assignment},
  } = AssignmentContext.useContextOrDie()

  const handleAccept = React.useCallback(async () => {
    try {
      if (!assignment?.id) {
        return
      }

      await acceptMutation.mutateAsync({assignmentId: assignment.id, prospectId})
    } catch (e) {
      requestError(e)
    }
  }, [acceptMutation, assignment?.id, prospectId])

  if (!warnings?.length) {
    return null
  }

  return (
    <Tooltip Button={TooltipButton}>
      <div className={"flex flex-col items-start gap-5 rounded-lg bg-cr-black p-5 text-sm text-cr-white"}>
        <ul className={"max-w-[500px] list-outside list-disc space-y-2 pl-6"}>
          {warnings.map(warning => (
            <li className={"whitespace-pre-wrap break-words"}>{warning}</li>
          ))}
        </ul>
        <ButtonLoading onClick={handleAccept} iconLeft={<CheckIcon />} size={"xs"} color={"yellow"} variant={"light"}>
          {t("Prospects_Table_Warnings_Accept")}
        </ButtonLoading>
      </div>
    </Tooltip>
  )
}

const TooltipButton = React.forwardRef<HTMLDivElement, TTooltipButtonProps>(({open}, ref) => {
  return (
    <div
      className={twMerge([
        "relative top-[1px] flex h-7 w-7 items-center justify-center rounded-full bg-transparent text-cr-yellow transition-all",
        open && "bg-cr-grey-5",
      ])}
      ref={ref}
    >
      <ExclamationTriangleIcon className={"w-5"} />
    </div>
  )
})
TooltipButton.displayName = "TooltipButton"
