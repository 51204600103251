import React from "react"

import {useUserSettingsOrLogout} from "../../../../../queries/user.ts"
import {AActivityFeed, AProspectActivity, AUserTypes} from "../../../../../services/types.generated.ts"
import {isProspectActivity} from "../utils.ts"
import {ActivityBody, ActivityBodyProspect, ActivityNote} from "./ActivityBody.tsx"
import {ActivityHeader} from "./ActivityHeader.tsx"
import {ActivityLike} from "./ActivityLike.tsx"

export const Activity: React.FC<{activity: AActivityFeed | AProspectActivity}> = ({activity}) => {
  const {
    user: {type},
  } = useUserSettingsOrLogout()

  return (
    <div className={"flex flex-col gap-4 py-8"} role={"listitem"}>
      <ActivityHeader activity={activity} />
      {isProspectActivity(activity) ? (
        <ActivityBodyProspect activity={activity} />
      ) : (
        <ActivityBody activity={activity} />
      )}
      <ActivityNote activity={activity} />
      {type === AUserTypes.CompanyUser && !isProspectActivity(activity) && <ActivityLike activity={activity} />}
    </div>
  )
}
