import React from "react"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import {ButtonLink} from "../../../components/Button.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {useCompanyDashboardDraftSalesCyclesQuery} from "../../../queries/companyDashboard.ts"
import {ACompanyUserDashboardDraftedSalesCycle, AOnboardingStatuses} from "../../../services/types.generated.ts"
import {ECreateSalesCyclePage, getCreateSellingLink} from "../../CreateSalesCycle/utils.ts"
import {stepWeb} from "../../SalesStrategy/stepsConfig.ts"
import {DraftStep} from "../components/DraftStep.tsx"
import {SellingHeader} from "../components/SellingHeader.tsx"
import {EOnboardingStepNum, getStepNum, stepsTotal} from "../WelcomeScreen/utils.ts"

export const DraftTab: React.FC = () => {
  const {data, error, refetch} = useCompanyDashboardDraftSalesCyclesQuery()

  return error ? (
    <GenericErrorAlert retry={refetch} />
  ) : data ? (
    <div className={"flex flex-col gap-10"}>
      {data.map(salesCycle => (
        <DraftSelling key={salesCycle.id} salesCycle={salesCycle} />
      ))}
    </div>
  ) : (
    <Loading size={"xl"} fullSpace />
  )
}

export const DraftSelling: React.FC<{salesCycle: ACompanyUserDashboardDraftedSalesCycle}> = ({salesCycle}) => {
  const {t} = useTranslation()

  const stepNum = getStepNum(salesCycle.onboarding_status)

  return (
    <div className={"card-shadow flex flex-col"} data-testid={"salesCycle"}>
      <div className={"flex flex-col gap-6 px-12 py-8"}>
        <SellingHeader salesCycle={salesCycle}>
          <span className={"text-xs"}>{t("Dashboard_Company_Draft_StepInfo", {step: stepNum, total: stepsTotal})}</span>
          {stepNum === EOnboardingStepNum.SALES_PEOPLE && (
            <ButtonLink
              to={getCreateSellingLink({salesCycleId: salesCycle.id, page: ECreateSalesCyclePage.SALESPEOPLE})}
              size={"sm"}
            >
              {t("Dashboard_Company_Draft_SalespeopleButton")}
            </ButtonLink>
          )}
          {stepNum === EOnboardingStepNum.KEY_DETAILS && (
            <ButtonLink to={`/sales-form/${salesCycle.id}`} size={"sm"}>
              {t("Dashboard_Company_Draft_KeyDetailsButton_Create")}
            </ButtonLink>
          )}
          {stepNum === EOnboardingStepNum.KNOWLEDGE && (
            <ButtonLink to={`/sales-strategy/${salesCycle.id}/${stepWeb.href}`} size={"sm"}>
              {salesCycle.strategy_touched
                ? t("Dashboard_Company_Draft_KnowledgeButton_Edit")
                : t("Dashboard_Company_Draft_KnowledgeButton_Create")}
            </ButtonLink>
          )}
        </SellingHeader>
        <div className={"grid grid-cols-1 gap-4 md:grid-cols-3"}>
          <DraftStep step={AOnboardingStatuses.RequestSalesTalents} currentStep={salesCycle.onboarding_status} />
          <DraftStep
            step={AOnboardingStatuses.AddKeyDetails}
            currentStep={salesCycle.onboarding_status}
            buttonNode={
              stepNum > EOnboardingStepNum.KEY_DETAILS && (
                <ButtonLink to={`/sales-form/${salesCycle.id}`} size={"xs"} variant={"outlined"}>
                  {t("Dashboard_Company_Draft_KeyDetailsButton_Edit")}
                </ButtonLink>
              )
            }
          />
          <DraftStep step={AOnboardingStatuses.ShareKnowledge} currentStep={salesCycle.onboarding_status} />
        </div>
      </div>
    </div>
  )
}
