import {useTranslation} from "react-i18next"

import {useUserSettingsOrLogout} from "../../queries/user.ts"
import {useDocumentTitle} from "../../utils/hooks"
import {SellingOffers} from "./SellingOffers"
import {Sellings} from "./Sellings"
import Welcome from "./Welcome"

const DashboardSalesperson = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Dashboard"))

  const {user} = useUserSettingsOrLogout()

  return (
    <div>
      {"hero_section_visible" in user && user.hero_section_visible && <Welcome />}
      <SellingOffers />
      <Sellings />
    </div>
  )
}

export default DashboardSalesperson
