import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {CheckCircleIcon} from "@heroicons/react/24/outline"

import Button from "../../../components/Button"
import {ConfirmModal} from "../../../components/Modal"
import {useReportsSalesCycleQuery, useSendReportMutation} from "../../../queries/reports"
import {useUserSettingsOrLogout} from "../../../queries/user.ts"
import requestError from "../../../services/requestError"
import {AUserTypes} from "../../../services/types.generated"
import {commonTransComponents} from "../../../utils/i18n"
import {useCurrentSelectionState} from "../hooks"

export const SendReportButton: React.FC = () => {
  const {t} = useTranslation()

  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const {
    value: {salesCycleId, iterationId},
  } = useCurrentSelectionState()

  const {
    user: {type: userType},
  } = useUserSettingsOrLogout()
  const {data, isLoading} = useReportsSalesCycleQuery(salesCycleId)
  const iteration = data?.sales_cycle_iterations.find(i => i.id === iterationId)

  const sendReportMutation = useSendReportMutation()

  const handleSend = React.useCallback(async () => {
    if (iterationId == null) {
      return false
    }

    try {
      await sendReportMutation.mutateAsync({salesCycleId, iterationId})
      toast.success(t("Reports_SendReport_SuccessToast"))
    } catch (e) {
      requestError(e)
      return false
    }
  }, [iterationId, salesCycleId, sendReportMutation, t])

  if (isLoading) {
    return <div data-testid={"loading"} />
  }

  if (userType !== AUserTypes.Admin || !iteration) {
    return null
  }

  const disabled =
    iteration.finished_report || !!("report_sent_by_super_admin" in iteration && iteration.report_sent_by_super_admin)

  return (
    <>
      <Button disabled={disabled} iconLeft={<CheckCircleIcon />} onClick={() => setIsModalVisible(true)}>
        {t("Reports_SendReport_Button")}
      </Button>
      <ConfirmModal
        onConfirm={handleSend}
        title={t("Reports_SendReport_Title")}
        isOpen={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        confirmButtonText={t("Reports_SendReport_ConfirmButton")}
        noIcon
      >
        <Trans i18nKey={"Reports_SendReport_Text"} components={commonTransComponents} />
      </ConfirmModal>
    </>
  )
}
