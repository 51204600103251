import React from "react"
import {useTranslation} from "react-i18next"

import {getLSItem, setLSItem, useLSValue} from "../utils/localStorage"
import {DropdownBase} from "./formElements/Dropdown"
import {DEFAULT_PAGE_SIZE} from "./Pagination.tsx"

const DEFAULT_PAGE_SIZES = [5, 10, 25, 50, 100] as const

export type TProps = {
  rowCount: number
  pageSizes?: number[]
  total?: number
  autoHide?: boolean
  onChange: (newRowCount: number) => void
  openUp?: boolean
}

export const RowCountPickerLS: React.FC<Omit<TProps, "rowCount" | "onChange"> & {paginationKey: string}> = props => {
  const {paginationKey, ...restProps} = props

  const rowCount = usePageSize(paginationKey)
  const handleChange = React.useCallback<TProps["onChange"]>(
    newRowCount => {
      setPageSize(paginationKey, newRowCount)
    },
    [paginationKey]
  )

  return <RowCountPicker rowCount={rowCount} onChange={handleChange} {...restProps} />
}

export const RowCountPicker: React.FC<TProps> = ({
  rowCount,
  onChange,
  pageSizes = DEFAULT_PAGE_SIZES,
  total,
  autoHide,
  openUp,
}) => {
  const {t} = useTranslation()

  const options = React.useMemo(() => {
    return Array.from(new Set([...pageSizes, rowCount]))
      .sort((A, B) => A - B)
      .map(count => ({title: count, value: count}))
  }, [pageSizes, rowCount])

  const handleChange = React.useCallback(
    (newRowCount: number | undefined) => {
      if (newRowCount == null) {
        return
      }
      onChange(newRowCount)
    },
    [onChange]
  )

  const isTotalLessThanSmallestPage = (total ?? Number.POSITIVE_INFINITY) <= options[0].value

  if (autoHide && isTotalLessThanSmallestPage) {
    return null
  }

  return (
    <div className={"flex items-center justify-end gap-2 text-sm"}>
      {t("RowCountPicker_Showing")}
      <div>
        <DropdownBase value={rowCount} onChange={handleChange} options={options} openUp={openUp} />
      </div>
      {t("RowCountPicker_Rows")}
    </div>
  )
}

export const usePageSize = (key: unknown, defaultValue = DEFAULT_PAGE_SIZE): number => {
  return useLSValue("pageSizes")?.[JSON.stringify(key)] ?? defaultValue
}

export const setPageSize = (key: unknown, size: number): void => {
  if (key == null) {
    return
  }

  const oldValue: {[key: string]: number} = getLSItem("pageSizes")?.data ?? {}
  const newValue = {...oldValue, [JSON.stringify(key)]: size}

  return setLSItem("pageSizes", newValue)
}
