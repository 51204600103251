import {useState} from "react"

import {TOrderBy} from "../../../components/Table/shared.ts"
import {ASalesPlan} from "../../../services/types.generated.ts"
import {useDebouncedValue} from "../../../utils/hooks.tsx"

export type TSalesPlansTableColumn = keyof ASalesPlan | "team" | "market" | "duration"

export type TSalesPlansFiltering = ReturnType<typeof useSalesPlansFiltering>

export const useSalesPlansFiltering = () => {
  const [orderBy, setOrderBy] = useState<TOrderBy<TSalesPlansTableColumn>>()

  const [searchString, setSearchString] = useState("")
  const searchStringDebounced = useDebouncedValue(searchString)

  const [countryId, setCountryId] = useState<number | undefined>()
  const countryIdDebounced = useDebouncedValue(countryId)

  return {
    orderBy,
    setOrderBy,
    searchString,
    searchStringDebounced,
    setSearchString,
    countryId,
    countryIdDebounced,
    setCountryId,
  }
}
