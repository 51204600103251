import {t} from "i18next"

import {AOnboardingStatuses} from "../../../services/types.generated.ts"

const steps = [
  AOnboardingStatuses.RequestSalesTalents,
  AOnboardingStatuses.AddKeyDetails,
  AOnboardingStatuses.ShareKnowledge,
] satisfies AOnboardingStatuses[]

export enum EOnboardingStepNum {
  SALES_PEOPLE = 1,
  KEY_DETAILS,
  KNOWLEDGE,
}

export type TSmartText = {unfinished: React.ReactNode; finished: React.ReactNode}

export type TStep = {
  title: React.ReactNode
  text: React.ReactNode | TSmartText
  time?: number
}

export const stepDefinitions: {[step in AOnboardingStatuses]: TStep} = {
  [AOnboardingStatuses.RequestSalesTalents]: {
    title: t("WelcomeScreen_Company_Steps_1_Title"),
    text: {
      unfinished: t("WelcomeScreen_Company_Steps_1_TextActive"),
      finished: t("WelcomeScreen_Company_Steps_1_TextDone"),
    },
  },
  [AOnboardingStatuses.AddKeyDetails]: {
    title: t("WelcomeScreen_Company_Steps_2_Title"),
    text: t("WelcomeScreen_Company_Steps_2_Text"),
    time: 3,
  },
  [AOnboardingStatuses.ShareKnowledge]: {
    title: t("WelcomeScreen_Company_Steps_3_Title"),
    text: t("WelcomeScreen_Company_Steps_3_Text"),
    time: 7,
  },
}

const isSmartText = (text: TStep["text"]): text is TSmartText => typeof text === "object" && "finished" in (text ?? {})

export const getStepText = (step: AOnboardingStatuses, finished: boolean) => {
  const {text} = stepDefinitions[step]
  return isSmartText(text) ? (finished ? text.finished : text.unfinished) : text
}

export const stepsTotal = Object.keys(AOnboardingStatuses).length

export const getStepNum = (step?: AOnboardingStatuses): EOnboardingStepNum | 0 => (step ? steps.indexOf(step) + 1 : 0)
