import React from "react"
import {Trans, useTranslation} from "react-i18next"

import {getCurrentLanguage} from "../../../../i18n"
import {
  TAdminsProspectsIteration,
  TSaasCompanyProspectsIteration,
  TSalespersonProspectsIteration,
} from "../../../../queries/prospects"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {commonTransComponents} from "../../../../utils/i18n"
import {StatusBannerGeneric} from "../../shared/StatusBannerGeneric"

export const StatusBanner: React.FC<{
  iteration: TAdminsProspectsIteration | TSalespersonProspectsIteration | TSaasCompanyProspectsIteration
}> = ({iteration}) => {
  const {t} = useTranslation()

  if (!("deadline_add_prospects_date" in iteration) || !iteration.deadline_add_prospects_date) {
    return null
  }
  return (
    <StatusBannerGeneric
      color={"cyan"}
      title={t("Prospects_Banner_Admin_Title")}
      text={
        <Trans
          i18nKey={"Prospects_Banner_Admin_Text"}
          components={commonTransComponents}
          values={{
            date: apiDateToJS(iteration.deadline_add_prospects_date).toLocaleString(getCurrentLanguage(), {
              month: "long",
              day: "numeric",
            }),
          }}
        />
      }
    />
  )
}
