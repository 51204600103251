import React from "react"
import {useTranslation} from "react-i18next"
import {ArrowRightIcon} from "@heroicons/react/20/solid"
import {startOfMonth} from "date-fns"
import {format} from "date-fns/format"

import {GenericErrorAlert} from "../../components/Alert.tsx"
import {Flag} from "../../components/Flag.tsx"
import {LayoutBlock} from "../../components/Layout/LayoutBlock.tsx"
import {Link} from "../../components/Link.tsx"
import {Loading} from "../../components/Loading.tsx"
import {useSaaSDashboardQuery, useSaaSSalesPlansQuery} from "../../queries/salesPlans.ts"
import {AUserTypes} from "../../services/types.generated.ts"
import {apiDateToJS} from "../../utils/dateArithmetics.ts"
import {useDocumentTitle} from "../../utils/hooks.tsx"
import {useValueByUserType} from "../../utils/userTypes.ts"
import {PeriodToggle} from "../Reports/components/PeriodToggle.tsx"
import {Stages} from "./Stages.tsx"
import {Table} from "./Table.tsx"
import {useSalesPlansFiltering} from "./utils.ts"

export const DashboardSaaS: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("Dashboard_SaaS_DocumentTitle"))

  const {data, error, refetch} = useSaaSDashboardQuery()

  if (error) {
    return (
      <LayoutBlock outerClassName={"py-16 bg-cr-blue-super-light grow"}>
        <GenericErrorAlert retry={refetch} />
      </LayoutBlock>
    )
  }

  if (!data) {
    return <Loading size={"xl"} />
  }

  return <DashboardSaaSLoaded dateList={data.dates} />
}

export const DashboardSaaSLoaded: React.FC<{dateList?: string[]}> = ({dateList: dateListInput}) => {
  const {t} = useTranslation()

  const title = useValueByUserType({
    [AUserTypes.SaasCompanyUser]: t("Dashboard_SaaS_Title_Company"),
    [AUserTypes.SaasSalesPerson]: t("Dashboard_SaaS_Title_Salesperson"),
  })

  const dateList = React.useMemo(() => {
    if (!dateListInput || dateListInput.length === 0) {
      return [format(startOfMonth(new Date()), "yyyy-MM-dd")]
    }
    return dateListInput
  }, [dateListInput])

  const initialDate = React.useMemo(() => {
    const nowTime = startOfMonth(new Date()).getTime()
    const datesDistanceFromNow = dateList.map(date => ({
      date,
      distance: Math.abs(apiDateToJS(date).getTime() - nowTime),
    }))

    return datesDistanceFromNow.sort((A, B) => A.distance - B.distance)[0].date
  }, [dateList])

  const filters = useSalesPlansFiltering(initialDate)
  const {startDate, setStartDate} = filters

  const {data, error, refetch} = useSaaSSalesPlansQuery({
    startDate,
  })
  const salesCycles = data?.data.sales_cycles

  return (
    <LayoutBlock outerClassName={"py-16 bg-cr-blue-super-light grow"} innerClassName={"flex flex-col gap-12"}>
      <div className={"flex flex-wrap justify-between gap-10"}>
        <h1 className={"text-4xl font-semibold text-cr-blue"}>{title}</h1>
        <PeriodToggle periods={dateList} value={startDate} onChange={setStartDate as any} noLifetime />
      </div>

      {error ? (
        <GenericErrorAlert retry={refetch} />
      ) : salesCycles == null ? (
        <Loading />
      ) : salesCycles.length === 0 || salesCycles.every(sC => (sC.assignments ?? []).length === 0) ? (
        <div className={"py-10 text-center text-cr-grey-50"}>{t("Dashboard_SaaS_Empty")}</div>
      ) : (
        <>
          <div className={"flex flex-col gap-4"}>
            <h2 className={"font-bold"}>{t("Dashboard_SaaS_Progress_Title")}</h2>
            <Stages date={startDate} salesCycles={salesCycles} />
          </div>

          {salesCycles.map(salesCycle => (
            <div className={"flex flex-col gap-4"} key={salesCycle.id}>
              <div className={"flex flex-wrap justify-between gap-4"}>
                <div className={"flex gap-2"}>
                  <Flag code={salesCycle.country.code} className={"h-5 w-7 rounded-lg"} />
                  <h2 className={"font-bold"}>{salesCycle.name}</h2>
                </div>
                <Link
                  to={`/sellings/${salesCycle.id}`}
                  className={"flex items-center gap-2 text-sm font-bold"}
                  flipUnderline
                  target={"_blank"}
                >
                  <span>{t("Dashboard_SaaS_MoreDetails")}</span>
                  <ArrowRightIcon className={"w-6"} />
                </Link>
              </div>

              <Table date={startDate} salesCycle={salesCycle} />
            </div>
          ))}
        </>
      )}
    </LayoutBlock>
  )
}
