import React from "react"

export const BenefitsItem: React.FC<{price: React.ReactNode; title: React.ReactNode}> = ({title, price}) => {
  return (
    <div className={"flex items-baseline gap-4 border-b border-b-cr-blue-light py-4"}>
      <div className={"grow font-bold"}>{title}</div>
      <div className={"text-2xl font-bold"}>{price}</div>
    </div>
  )
}
