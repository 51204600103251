import React, {ComponentProps, ReactNode} from "react"
import {twMerge} from "tailwind-merge"

import {FieldErrorMessage, Label} from "./fields/components"
import {TFieldLabelProps} from "./fields/FieldLabel"

const Layout: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <div
      className={
        "grid w-full grid-cols-[fit-content(400px)_auto] items-baseline gap-x-2 gap-y-2 md:gap-x-8 xl:gap-x-20"
      }
    >
      {children}
    </div>
  )
}

const Separator: React.FC<{invisible?: boolean}> = ({invisible}) => {
  return <hr className={twMerge("col-span-2 my-2", invisible && "invisible")} />
}

const CustomLabel: React.FC<ComponentProps<typeof Label>> = props => {
  return (
    <div>
      <Label small {...props} className={twMerge(props.className, "font-normal text-cr-grey-50")} />
    </div>
  )
}

const FormField: React.FC<TFieldLabelProps> = ({
  name,
  required,
  label,
  legend: _legend,
  hideError,
  labelClassName,
  children,
  errorMessageProps,
}) => {
  return (
    <>
      <TerseFormLayout.Label required={required} htmlFor={name} className={labelClassName}>
        {label}
      </TerseFormLayout.Label>
      <div className={"flex flex-col gap-2 text-left"}>
        <div>{children}</div>
        {!hideError && <FieldErrorMessage name={name} reserveSpace {...errorMessageProps} />}
      </div>
    </>
  )
}

const Header: React.FC<{children: ReactNode; className?: string; subHeader?: ReactNode}> = ({
  children,
  className,
  subHeader,
}) => (
  <div className={"col-span-2"}>
    <h3 className={twMerge(["label", className])}>{children}</h3>
    {subHeader && <h4 className={"text-sm font-normal text-cr-grey-50"}>{subHeader}</h4>}
  </div>
)

export const TerseFormLayout = Object.assign(Layout, {Separator, Label: CustomLabel, Header, FormField})
