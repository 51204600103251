import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import Button, {ButtonForm} from "../../../components/Button.tsx"
import {TextareaField} from "../../../components/fields/Textarea.tsx"
import {DropdownField, TOption} from "../../../components/formElements/Dropdown.tsx"
import Modal from "../../../components/Modal.tsx"
import {i18n} from "../../../i18n.ts"
import {useInviteUsersMutation} from "../../../queries/saasUserManagement.ts"
import requestError from "../../../services/requestError.tsx"
import {enumTranslKey} from "../../../utils/i18n.tsx"
import {validateNativeEnum, validateNonemptyString} from "../../../utils/validation.ts"
import {InviteUsersModalContext} from "../context.ts"
import {ERole} from "../fakeTypes.ts"

const validationSchema = z.object({
  emailString: validateNonemptyString().refine(
    emailString => {
      const split = splitEmails(emailString)
      return split.length > 0
    },
    {message: i18n.t("SaaSUserManagement_InviteModal_NoEmails"), path: ["emailString"]}
  ),
  role: validateNativeEnum(ERole),
})

type TForm = z.infer<typeof validationSchema>

const roleOptions = [
  {value: ERole.salesperson, title: i18n.t(enumTranslKey("SaaSUserRole", ERole.salesperson))},
  {value: ERole.admin, title: i18n.t(enumTranslKey("SaaSUserRole", ERole.admin))},
] as const satisfies Array<TOption<ERole>>

export const InviteUsersModal: React.FC = () => {
  const {t} = useTranslation()

  const context = InviteUsersModalContext.useContextOrDie()

  const methods = useForm<TForm>({
    resolver: zodResolver(validationSchema),
    defaultValues: {emailString: "", role: undefined},
  })

  const reset = methods.reset
  React.useEffect(() => {
    if (!context.value) {
      return
    }
    reset()
  }, [reset, context.value])

  const inviteUsersMutation = useInviteUsersMutation()

  const handleSubmit = React.useCallback(
    async (values: TForm) => {
      try {
        await inviteUsersMutation.mutateAsync({
          emails: splitEmails(values.emailString),
          role: values.role,
        })
        context.setValue(false)
      } catch (e) {
        requestError(e)
      }
    },
    [context, inviteUsersMutation]
  )

  const emailString = methods.watch("emailString")
  const numEmails = React.useMemo(() => {
    return splitEmails(emailString).length
  }, [emailString])

  return (
    <Modal
      isOpen={context.value}
      onClose={() => context.setValue(false)}
      title={t("SaaSUserManagement_InviteModal_Title")}
      size={"sm"}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} className={"flex flex-col gap-8"}>
          <div>
            <TextareaField
              name={"emailString"}
              label={<span className={"font-semibold"}>{t("SaaSUserManagement_InviteModal_EmailField")}</span>}
              variant={"small"}
              rows={4}
              errorMessageProps={{reserveSpace: false}}
            />
            <span className={"text-xs text-cr-grey-50"}>{t("SaaSUserManagement_InviteModal_MultipleEmails")}</span>
          </div>
          <DropdownField
            name={"role"}
            label={<span className={"font-semibold"}>{t("SaaSUserManagement_InviteModal_RoleField")}</span>}
            placeholder={t("SaaSUserManagement_InviteModal_RolePlaceholder")}
            variant={"small"}
            options={roleOptions}
            errorMessageProps={{reserveSpace: false}}
          />
          <div className={"flex justify-end gap-4"}>
            <Button variant={"outlined"} color={"gray"} onClick={() => context.setValue(false)}>
              {t("SaaSUserManagement_InviteModal_CancelButton")}
            </Button>
            <ButtonForm disabled={numEmails < 1}>
              {numEmails === 0
                ? t("SaaSUserManagement_InviteModal_SubmitButtonZero")
                : t("SaaSUserManagement_InviteModal_SubmitButton", {count: numEmails})}
            </ButtonForm>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export const splitEmails = (source: string): string[] => {
  const emailSchema = z.string().email()

  return source
    .split(/[,;\n]/)
    .map(email => email.trim())
    .filter(email => email.length > 0 && !emailSchema.safeParse(email).error)
}
