import React from "react"
import {twMerge} from "tailwind-merge"

import {useReportsAssignmentsQuery, useReportsSalesCycleQuery} from "../../../queries/reports"
import {AssignmentCard, AssignmentCardSkeleton} from "../components/AssignmentCard"
import {useCurrentAssignmentId, useCurrentSelectionState} from "../hooks"

export const AssignmentsSection: React.FC = () => {
  const {
    value: {salesCycleId, iterationId, salespersonId},
    setValue,
  } = useCurrentSelectionState()
  const assignmentId = useCurrentAssignmentId()

  const {data, isFetching, isLoading} = useReportsAssignmentsQuery(salesCycleId, iterationId)
  const salesCycleQuery = useReportsSalesCycleQuery(salesCycleId)
  const canPreselectAssignment = salesCycleQuery.data?.max_assignments_count_per_iteration === 1

  const handleClick = React.useCallback(
    (id: string) => () => {
      if (id === assignmentId) {
        setValue(state => ({...state, salespersonId: null}))
        return
      }

      const assignment = data.find(a => a.group_id === id)

      setValue(state => ({...state, salespersonId: assignment?.sales_person?.id ?? null}))
    },
    [assignmentId, data, setValue]
  )

  // Try to reselect previously selected salesperson when assignments change
  React.useEffect(() => {
    if (isLoading) {
      return
    }

    const selectedSalespersonAssignment = data.find(a => a.sales_person?.id === salespersonId)
    let selectedSalespersonId = selectedSalespersonAssignment?.sales_person?.id ?? null
    if (!selectedSalespersonId && canPreselectAssignment && data.length === 1) {
      selectedSalespersonId = data[0].sales_person?.id ?? null
    }

    setValue(state => ({
      ...state,
      salespersonId: selectedSalespersonId,
    }))
  }, [data, canPreselectAssignment, isLoading, salespersonId, setValue])

  if (data.length === 0 && !isLoading) {
    return null
  }

  return (
    <div
      className={twMerge("flex flex-wrap gap-4", isFetching && "pointer-events-none animate-pulse")}
      role={"radiogroup"}
      data-testid={"AssignmentsSection"}
    >
      {isLoading ? (
        <>
          <AssignmentCardSkeleton />
          <AssignmentCardSkeleton />
          <AssignmentCardSkeleton />
        </>
      ) : (
        data.map((assignment, index) => (
          <AssignmentCard
            key={assignment.group_id}
            assignment={assignment}
            selected={assignment.sales_person?.id === salespersonId}
            winner={index === 0}
            onClick={handleClick(assignment.group_id)}
          />
        ))
      )}
    </div>
  )
}
