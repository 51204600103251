import React from "react"
import {useTranslation} from "react-i18next"
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {Tooltip, TTooltipButtonProps} from "../../../components/Tooltip.tsx"
import {enumTranslKey} from "../../../utils/i18n.tsx"

export const RejectedReason: React.FC<{reason?: string | null}> = ({reason}) => {
  const {t} = useTranslation()

  if (!reason) {
    return null
  }

  return (
    <Tooltip Button={TooltipButton}>
      <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
        {t(enumTranslKey("ProspectRejectionReason", reason), reason)}
      </div>
    </Tooltip>
  )
}

const TooltipButton = React.forwardRef<HTMLDivElement, TTooltipButtonProps>(({open}, ref) => {
  return (
    <div
      className={twMerge([
        "relative top-[1px] flex h-7 w-7 items-center justify-center rounded-full bg-transparent text-cr-red transition-all",
        open && "bg-cr-grey-5",
      ])}
      ref={ref}
    >
      <ExclamationTriangleIcon className={"w-5"} />
    </div>
  )
})
TooltipButton.displayName = "TooltipButton"
