import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {produce} from "immer"

import api, {multipartRequest, queryKey} from "../services"
import {ASalesPersonAttributes, ASalesPersonProfile, ASettings} from "../services/types.generated"
import {isSalesPersonUser} from "../utils/types"
import {useUserSettingsOrLogout} from "./user.ts"

export function useSalespersonQuery(id: number) {
  return useQuery({
    queryKey: queryKey.salesperson(id),
    queryFn: async () => (await api.salesPeople.salesPeopleDetail(id)).data.sales_person,
  })
}

export function useSalespersonPublicQuery(id: number) {
  return useQuery({
    queryKey: queryKey.salespersonPublic(id),
    queryFn: async () => (await api.salesPeople.publicDetail(id)).data.sales_person,
  })
}

export function useBusinessInfoQuery() {
  const {
    user: {id},
  } = useUserSettingsOrLogout()

  return useQuery({
    queryKey: queryKey.salespersonBusinessInfo(id),
    queryFn: async () => (await api.salesPeople.businessDataDetail(id)).data.business_data,
  })
}

export function useUpdateSalespersonPictureMutation() {
  const updateSalespersonQueryCachePicture = useUpdateSalespersonQueryCachePicture()
  const queryClient = useQueryClient()

  return useMutation({
    // A multipart request has to avoid default axios processing
    // in order to provide proper format for Rails (see wrapForMultipart()).
    // This means we have to use a custom request instead of api.companies.companiesPartialUpdate()
    mutationFn: async ({salespersonId, file}: {salespersonId: number; file: File}) => {
      const response = await multipartRequest({
        method: "PATCH",
        path: `sales_people/${salespersonId}`,
        data: {sales_person: {profile: {profile_picture: file}}},
      })

      queryClient.refetchQueries({queryKey: queryKey.userSettings})

      return response
    },
    onSuccess: response =>
      updateSalespersonQueryCachePicture(response.data.sales_person.id, response.data.sales_person),
  })
}

export function useRegisterSalespersonMutation() {
  return useMutation({
    mutationFn: ({email}: {email: string}) => api.salesPeople.salesPeopleCreate({sales_person: {email}}),
  })
}

export function useRegisterSalespersonResendMutation() {
  return useMutation({
    mutationFn: ({email}: {email: string}) => api.salesPeople.confirmationCreate({sales_person: {email}}),
  })
}

export function useVerifySalespersonMutation() {
  return useMutation({
    mutationFn: (data: Parameters<typeof api.salesPeople.confirmationPartialUpdate>[0]) =>
      api.salesPeople.confirmationPartialUpdate(data),
  })
}

export function useUpdateBusinessInfoMutation() {
  const {
    user: {id},
  } = useUserSettingsOrLogout()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (sales_person: Parameters<typeof api.salesPeople.submitBusinessDataCreate>[1]["sales_person"]) =>
      api.salesPeople.submitBusinessDataCreate(id, {sales_person}),
    onSuccess: () => {
      queryClient.refetchQueries({queryKey: queryKey.salesDashboard(id)})
    },
  })
}

export function useUpdateSalespersonProfileMutation() {
  const updateSalespersonQueryCacheProfile = useUpdateSalespersonQueryCacheProfile()

  return useMutation({
    mutationFn: async ({
      salespersonId,
      profile,
    }: {
      salespersonId: number
      profile: NonNullable<ASalesPersonAttributes["profile"]>
    }) => {
      const transformedData = Object.entries(profile).reduce(
        (acc, [key, value]) => {
          // Drop keys without a value
          if (value == null || value === "" || Number.isNaN(value)) {
            return acc
          }

          return {...acc, [key]: value}
        },
        {} as typeof profile
      )

      return multipartRequest({
        method: "PATCH",
        path: `sales_people/${salespersonId}`,
        data: {sales_person: {profile: transformedData}},
      })
    },
    onSuccess: response =>
      updateSalespersonQueryCacheProfile(response.data.sales_person.id, response.data.sales_person),
  })
}

export function useUpdateSalespersonLearningMaterialsMutation() {
  const updateSalespersonQueryCacheLearningMaterials = useUpdateSalespersonQueryCacheLearningMaterials()

  return useMutation({
    mutationFn: async ({
      salespersonId,
      learningMaterials,
    }: {
      salespersonId: number
      learningMaterials: NonNullable<ASalesPersonAttributes["learning_materials"]>
    }) => {
      return api.salesPeople.salesPeoplePartialUpdate(salespersonId, {
        sales_person: {learning_materials: learningMaterials},
      })
    },
    onSuccess: response =>
      updateSalespersonQueryCacheLearningMaterials(response.data.sales_person.id, response.data.sales_person),
  })
}

export function useUpdateSalespersonVideoIntroductionViewedMutation() {
  const updateUserSettingsQueryCacheVideoIntroductionViewed = useUpdateUserSettingsQueryCacheVideoIntroductionViewed()

  return useMutation({
    mutationFn: async ({
      salespersonId,
      videoIntroductionViewed,
    }: {
      salespersonId: number
      videoIntroductionViewed: boolean
    }) => {
      return api.salesPeople.salesPeoplePartialUpdate(salespersonId, {
        sales_person: {profile: {video_introduction_viewed: videoIntroductionViewed}},
      })
    },
    onMutate: ({salespersonId, videoIntroductionViewed}) =>
      updateUserSettingsQueryCacheVideoIntroductionViewed(salespersonId, videoIntroductionViewed),
  })
}

export function useUpdateSalespersonQueryCachePicture() {
  const queryClient = useQueryClient()

  return (id: number, {profile}: ASalesPersonProfile) => {
    queryClient.setQueryData(
      queryKey.salesperson(id),
      (cachedSalesperson: ReturnType<typeof useSalespersonQuery>["data"]) => {
        if (!cachedSalesperson) {
          return
        }

        return produce(cachedSalesperson, draft => {
          draft.profile.profile_picture_url = profile.profile_picture_url
          draft.profile.profile_picture_thumbnail_url = profile.profile_picture_thumbnail_url
        })
      }
    )
  }
}

export function useUpdateSalespersonQueryCacheProfile() {
  const queryClient = useQueryClient()

  return (id: number, {profile}: ASalesPersonProfile) => {
    queryClient.setQueryData(
      queryKey.salesperson(id),
      (cachedSalesperson: ReturnType<typeof useSalespersonQuery>["data"]) => {
        if (!cachedSalesperson) {
          return
        }

        return produce(cachedSalesperson, draft => {
          draft.profile = profile
        })
      }
    )
  }
}

export function useUpdateSalespersonQueryCacheLearningMaterials() {
  const queryClient = useQueryClient()

  return (id: number, {learning_materials}: ASalesPersonProfile) => {
    queryClient.setQueryData(
      queryKey.salesperson(id),
      (cachedSalesperson: ReturnType<typeof useSalespersonQuery>["data"]) => {
        if (!cachedSalesperson) {
          return
        }

        return produce(cachedSalesperson, draft => {
          draft.learning_materials = learning_materials
        })
      }
    )
  }
}

function useUpdateUserSettingsQueryCacheVideoIntroductionViewed() {
  const queryClient = useQueryClient()

  return (id: number, videoIntroductionViewed: boolean) => {
    queryClient.setQueryData(queryKey.userSettings, (userSettings: ASettings | undefined) => {
      if (!userSettings) {
        return
      }

      return produce(userSettings, draft => {
        if (isSalesPersonUser(draft.user) && draft.user.id === id) {
          draft.user.video_introduction_viewed = videoIntroductionViewed
        }
      })
    })
  }
}
