import React from "react"

import {TBadgeColor} from "../../components/Badge.tsx"
import {ADashboardStages, ASaasCompanyUsersDashboardAssignments} from "../../services/types.generated.ts"

export type TSalesPlansTableColumn =
  | "salesperson"
  | "prospects"
  | "emailing"
  | "calling"
  | "meetings"
  | "won"
  | "price offer"
  | "last activity"

export type TSalesPlansFiltering = ReturnType<typeof useSalesPlansFiltering>

export const useSalesPlansFiltering = (initialDate: string) => {
  const [startDate, setStartDate] = React.useState(initialDate)

  return {
    startDate,
    setStartDate,
  }
}

export const hasStageProblems = (
  stage: ADashboardStages,
  assignment: ASaasCompanyUsersDashboardAssignments
): boolean => {
  switch (stage) {
    case ADashboardStages.FirstOutreach:
      return (assignment.statistics.emails_sent_activities_uniq ?? 0) < assignment.reaches
    case ADashboardStages.SecondOutreach:
      return (assignment.statistics.calls_made_activities_uniq ?? 0) < assignment.reaches
    case ADashboardStages.PresentationDemo:
      return (assignment.statistics.meetings_done_activities ?? 0) < assignment.minimum_meetings_count
    case ADashboardStages.Closing:
      return false
  }
}

export const getTableBadgeColor = ({
  stage,
  currentStage,
  assignment,
}: {
  stage: ADashboardStages
  currentStage?: ADashboardStages
  assignment: ASaasCompanyUsersDashboardAssignments
}): TBadgeColor => {
  const isCurrentStage = stage === currentStage

  return isCurrentStage ? "light-blue" : hasStageProblems(stage, assignment) ? "red" : "green"
}
