import React from "react"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../../components/Alert.tsx"
import {ButtonLink} from "../../../../components/Button.tsx"
import {Loading} from "../../../../components/Loading.tsx"
import Pagination, {DEFAULT_PAGE_SIZE} from "../../../../components/Pagination.tsx"
import {useActivityFeedQuery} from "../../../../queries/activityFeed.ts"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {tailwindColors} from "../../../../utils/tailwind.ts"
import {Activity} from "./components/Activity.tsx"
import {ActivityDayTitle} from "./components/ActivityDayTitle.tsx"
import {groupActivities} from "./utils.ts"

export const ActivityFeedCard: React.FC<{salesCycleId: number; adminUrl?: string}> = ({salesCycleId, adminUrl}) => {
  const {t} = useTranslation()

  const {data, error, refetch, pagination} = useActivityFeedQuery(salesCycleId, DEFAULT_PAGE_SIZE)
  const activities = data?.data.activity_feeds

  const groupedActivities = React.useMemo(() => {
    return groupActivities(activities)
  }, [activities])

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  return (
    <div
      className={
        "card-shadow flex h-[708px] min-w-[450px] flex-col gap-4 self-start bg-cr-blue-super-light p-6 lg:max-w-[450px]"
      }
    >
      <div className={"flex items-center justify-between gap-4"}>
        <h3 className={"text-lg font-bold"}>{t("SellingDetails_News_ActivityFeed_Title")}</h3>
        {adminUrl && (
          <ButtonLink to={adminUrl} size={"xs"} target={"_blank"}>
            {t("SellingDetails_News_ActivityFeed_AdminButton")}
          </ButtonLink>
        )}
      </div>
      {activities ? (
        activities.length === 0 ? (
          <div className={"flex grow items-center justify-center text-sm"}>
            {t("SellingDetails_News_ActivityFeed_NoActivities")}
          </div>
        ) : (
          <div className={"flex flex-col justify-between"}>
            <div
              role={"list"}
              className={"scrollbar-slim scroll-shadow-y -mr-4 max-h-[592px] overflow-auto pr-4"}
              style={{"--bg": tailwindColors["cr-blue"]["super-light"]} as React.CSSProperties}
            >
              {groupedActivities.map(({date, activities}) => (
                <div key={date} role={"group"}>
                  <ActivityDayTitle date={apiDateToJS(date)} />

                  <div className={"divide-y divide-cr-blue-light"}>
                    {activities.map(activity => (
                      <Activity key={activity.id} activity={activity} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <Pagination {...pagination} autoHide />
          </div>
        )
      ) : (
        <Loading />
      )}
    </div>
  )
}
