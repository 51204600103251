import React from "react"
import {Trans, useTranslation} from "react-i18next"

import {ConfirmModal} from "../../../components/Modal.tsx"
import {useCancelInvitationMutation} from "../../../queries/saasUserManagement.ts"
import requestError from "../../../services/requestError.tsx"
import {getFullName} from "../../../utils"
import {commonTransComponents} from "../../../utils/i18n.tsx"
import {CancelInvitationContext} from "../context.ts"

export const CancelInvitationModal: React.FC = () => {
  const {t} = useTranslation()

  const context = CancelInvitationContext.useContext()
  const user = context.value

  const cancelInvitationMutation = useCancelInvitationMutation()

  const handleCancel = React.useCallback(async () => {
    try {
      if (user?.id === undefined) {
        return false
      }

      await cancelInvitationMutation.mutateAsync({id: user.id})
    } catch (e) {
      requestError(e)
      return false
    }
  }, [cancelInvitationMutation, user?.id])

  return (
    <ConfirmModal
      onConfirm={handleCancel}
      isOpen={!!user}
      onClose={() => context.setValue(null)}
      title={t("SaaSUserManagement_Table_CancelInvite_Title")}
      variant={"warning"}
      confirmButtonText={t("SaaSUserManagement_Table_CancelInvite_ConfirmButton")}
      cancelButtonText={t("SaaSUserManagement_Table_CancelInvite_CancelButton")}
    >
      <Trans
        i18nKey={"SaaSUserManagement_Table_CancelInvite_Text"}
        values={{name: getFullName(user, user?.email ?? t("SaaSUserManagement_Table_CancelInvite_NamePlaceholder"))}}
        components={commonTransComponents}
      />
    </ConfirmModal>
  )
}
