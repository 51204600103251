import React from "react"
import {useFormContext, useWatch} from "react-hook-form"
import {useTranslation} from "react-i18next"

import CheckboxButton from "../fields/CheckboxButton"
import {FieldLabel, TFieldLabelProps} from "../fields/components"
import {InputAction} from "../InputAction"

interface ICheckboxesAdd extends TFieldLabelProps {
  optionsDefault: string[]
  inputLabel: string
  name: string
  disabledAdd?: boolean | ((value: string[]) => boolean)
}

const emptyValue: string[] = []

const CheckboxesAdd: React.FC<ICheckboxesAdd> = ({name, optionsDefault, inputLabel, disabledAdd, ...props}) => {
  const {t} = useTranslation()

  const value: string[] = useWatch()[name] ?? emptyValue

  const [allOptions, setAllOptions] = React.useState<string[]>([])

  React.useEffect(() => {
    setAllOptions(opts => Array.from(new Set([...optionsDefault, ...opts, ...value])))
  }, [value, optionsDefault])

  const {setValue} = useFormContext()

  const disabledAddValue = typeof disabledAdd === "function" ? disabledAdd(value) : disabledAdd

  return (
    <>
      <FieldLabel {...props} name={name} className={"mb-4"}>
        <div className={"-mx-2 -mt-2 text-left"}>
          {allOptions.map(option => (
            <CheckboxButton
              key={option}
              value={option}
              name={name}
              disabled={!value.includes(option) && disabledAddValue}
            />
          ))}
        </div>
      </FieldLabel>

      <FieldLabel label={inputLabel} small>
        <InputAction
          disabled={disabledAddValue}
          actionLabel={t("T_Add")}
          inputClassName={"pr-14"}
          onAction={newItem => {
            setValue(name, Array.from(new Set([...value, newItem])), {
              shouldTouch: true,
              shouldDirty: true,
              shouldValidate: true,
            })
          }}
        />
      </FieldLabel>
    </>
  )
}

export default CheckboxesAdd
