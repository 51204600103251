import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import kreativGangImage from "../assets/client-logos/kreativ-gang.png"
import solsolImage from "../assets/client-logos/solsol.svg"
import unipasImage from "../assets/client-logos/unipas.svg"
import yuzuImage from "../assets/client-logos/yuzu.png"
import zoozaImage from "../assets/client-logos/zooza.png"
import CrLogo from "../assets/logoCurrentColor.svg?react"
import ladyImage from "../assets/welcomeBg.jpg"
import {Footer} from "./Layout/Footer.tsx"
import {UnstyledLink} from "./Link.tsx"

// All the following magic CSS numbers were chosen by a scientific process called
// "trying different things until they look nice". Feel free to experiment.

export const HeroLayout: React.FC<{
  children: React.ReactNode
  leftContent?: React.ReactNode
  leftAction?: React.ReactNode
  rightAction?: React.ReactNode
}> = ({
  children,
  leftAction,
  rightAction,
  leftContent = (
    <img
      src={ladyImage}
      className={"absolute -right-48 bottom-0 max-h-full w-[120%] min-w-[800px] max-w-[1000px] object-contain"}
    />
  ),
}) => {
  return (
    <div className={"grid grow grid-cols-[0px_1fr_0px] transition-all lg:grid-cols-[45%_minmax(auto,_1280px)_auto]"}>
      {/* Fancy left panel */}
      <div className={"relative isolate flex overflow-hidden bg-cr-blue-mid"}>
        <div
          className={
            "absolute right-0 top-0 z-10 w-full max-w-[700px] p-7 pt-8 transition-all max-lg:opacity-0 tall:pt-16"
          }
        >
          <Logo className={"text-cr-white"} />
        </div>

        <Clients />

        {leftContent}
      </div>

      {/* Main content */}
      <div className={"flex flex-col"}>
        <div
          className={twMerge(
            "flex min-h-0 items-start p-7 pt-8 transition-all tall:pt-16",
            !leftAction && !rightAction && "lg:hidden"
          )}
        >
          <div className={"mr-10 shrink-0 overflow-hidden transition-all lg:mr-0 lg:w-0"}>
            <Logo className={"text-cr-blue transition-all lg:text-cr-white"} />
          </div>
          <div
            className={twMerge(
              "mt-2 flex h-full grow items-center justify-between gap-10 transition-all max-lg:justify-end",
              !leftAction && "justify-end max-lg:flex-row-reverse max-lg:justify-between"
            )}
          >
            {leftAction && (
              <div className={"text-cr-grey-50"} data-testid={"left-action"}>
                {leftAction}
              </div>
            )}
            {rightAction && (
              <div className={"text-cr-grey-50"} data-testid={"right-action"}>
                {rightAction}
              </div>
            )}
          </div>
        </div>

        <div className={"flex h-full w-full grow flex-col gap-10 py-10"}>
          <div className={"flex grow items-center justify-center"}>{children}</div>
        </div>

        <div className={"px-8"}>
          <Footer noPadding />
        </div>
      </div>

      {/* Right side filler */}
      <div />
    </div>
  )
}

export const Logo: React.FC<{className?: string; disabled?: boolean}> = ({className, disabled}) => {
  const {t} = useTranslation()

  return (
    <UnstyledLink to={disabled ? undefined : "/"} className={"inline-block"}>
      <CrLogo className={twMerge("w-52", className)} title={t("CloseRocket")} />
    </UnstyledLink>
  )
}

export const Clients: React.FC = () => {
  const {t} = useTranslation()

  return (
    <div
      className={
        "absolute bottom-0 right-0 z-10 flex w-full max-w-[700px] flex-col gap-2 p-7 pb-8 transition-all tall:pb-14"
      }
    >
      <div className={"text-cr-white"}>{t("Clients_TrustedBy")}</div>
      <div className={"flex items-center justify-between brightness-200"}>
        <img src={zoozaImage} className={"h-7"} alt={t("Clients_Zooza")} />
        <img src={solsolImage} className={"h-5"} alt={t("Clients_Solsol")} />
        <img src={kreativGangImage} className={"h-10"} alt={t("Clients_KreativGang")} />
        <img src={unipasImage} className={"h-6"} alt={t("Clients_Unipas")} />
        <img src={yuzuImage} className={"h-10"} alt={t("Clients_Yuzu")} />
      </div>
    </div>
  )
}
