import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {TReportsMetric, useReportsMetricsQuery} from "../../../queries/reports"
import {enumTranslKey} from "../../../utils/i18n"
import {Metric, MetricSkeleton} from "../components/Metric"
import {useAreMeetingsDisabled, useCurrentAssignmentId, useCurrentSelectionState} from "../hooks"

export const MetricsSection: React.FC = () => {
  const {t} = useTranslation()

  const {
    value: {salesCycleId, iterationId, metric: selectedMetric},
    setValue,
  } = useCurrentSelectionState()
  const assignmentId = useCurrentAssignmentId()

  const areMeetingsDisabled = useAreMeetingsDisabled()

  const {data, isFetching, isLoading} = useReportsMetricsQuery(salesCycleId, iterationId, assignmentId)

  const handleClick = React.useCallback(
    (metric: TReportsMetric) => () => {
      setValue(state => ({...state, metric}))
    },
    [setValue]
  )

  return (
    <div
      className={twMerge("flex flex-wrap gap-4", isFetching && "pointer-events-none animate-pulse")}
      role={"radiogroup"}
      data-testid={"MetricsSection"}
    >
      {(
        [
          "activities",
          "clients_reached",
          "emails_sent",
          "calls_made",
          "meetings_done",
          "opportunities",
        ] satisfies TReportsMetric[]
      )
        .filter(metric => !(areMeetingsDisabled && metric === "meetings_done"))
        .map(metric =>
          isLoading ? (
            <MetricSkeleton key={metric} title={t(enumTranslKey("ReportsMetric", metric))} />
          ) : (
            <Metric
              key={metric}
              title={t(enumTranslKey("ReportsMetric", metric))}
              value={data?.counters[metric] ?? 0}
              trend={data?.trends?.[metric]}
              selected={selectedMetric === metric}
              onClick={handleClick(metric)}
            />
          )
        )}
    </div>
  )
}
