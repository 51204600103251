import isEqual from "fast-deep-equal"

import {ELanguage} from "../../i18n.ts"
import {tabId} from "../tabId.ts"
import {
  CURRENT_LS_VERSIONS,
  deleteLSItem,
  getAllLSItems,
  setLSItem,
  TLSAllTimeKey,
  TLSCurrentKey,
  TLSItemWithKey,
  TLSVersion,
} from "./index"

export const UPDATERS = [
  // Language 'en' is now 'en-gb' for date formatting reasons
  makeUpdater({
    fromKey: "language",
    fromVersion: 1,
    migrateFn: ({data}) => ({version: 2, key: "language", data: data === "en" ? ELanguage.EN : (data as ELanguage)}),
  }),

  // We store the tabId to not do any redirects on the current tab
  makeUpdater({
    fromKey: "currentUserId",
    fromVersion: 1,
    migrateFn: ({data}) => ({version: 2, key: "currentUserId", data: {userId: data, tabId}}),
  }),
] as const

export function updateAllLSItems(updaters: readonly TVersionUpdater[] = UPDATERS) {
  const allItems = getAllLSItems()

  allItems.forEach(item => {
    const updatedItem = getUpdatedLSItem({updaters, item})

    // If the updaters say the item should be deleted, or if the updaters do not reach the current version
    // Delete the item
    if (!updatedItem || updatedItem.version !== CURRENT_LS_VERSIONS[updatedItem.key as TLSCurrentKey]) {
      deleteLSItem(item.key)
      return
    }

    // If the updaters change the key
    // Remove the old item and remake it with the updated data
    if (updatedItem.key !== item.key) {
      deleteLSItem(item.key)
      setLSItem(updatedItem.key, updatedItem.data)
      return
    }

    // If the key stays the same, but the data or version changed
    // Set the new data (the version is updated by setLSItem)
    if (!isEqual(updatedItem.data, item.data) || updatedItem.version !== item.version) {
      setLSItem(updatedItem.key, updatedItem.data)
    }
  })
}

function getUpdatedLSItem({
  updaters,
  item,
}: {
  updaters: readonly TVersionUpdater[]
  item: TLSItemWithKey
}): TLSItemWithKey | null {
  const updater = updaters.find(u => u.fromKey === item.key && u.fromVersion === item.version)

  if (!updater) {
    return item
  }

  const updatedItem = updater.migrateFn(item)
  return updatedItem && getUpdatedLSItem({updaters, item: updatedItem})
}

type TVersionUpdater<
  TFromKey extends TLSAllTimeKey = any,
  TFromVersion extends TLSVersion<TFromKey> = any,
  TToKey extends TLSAllTimeKey = any,
  TToVersion extends TLSVersion<TToKey> = any,
> = {
  fromKey: TFromKey
  fromVersion: TFromVersion
  /**
   * Return `null` to remove the item
   */
  migrateFn: (old: TLSItemWithKey<TFromKey, TFromVersion>) => TLSItemWithKey<TToKey, TToVersion> | null
}

function makeUpdater<
  TFromKey extends TLSAllTimeKey = any,
  TFromVersion extends TLSVersion<TFromKey> = any,
  TToKey extends TLSAllTimeKey = any,
  TToVersion extends TLSVersion<TToKey> = any,
>(ret: TVersionUpdater<TFromKey, TFromVersion, TToKey, TToVersion>): typeof ret {
  return ret
}
