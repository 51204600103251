import {faker} from "@faker-js/faker"
import {format} from "date-fns/format"

import {makeEntity} from "../../utils/testing/entities.ts"

export enum ERole {
  "admin" = "admin",
  "salesperson" = "salesperson",
  "owner" = "owner",
}

export enum EStatus {
  "active" = "active",
  "deactivated" = "deactivated",
  "invited" = "invited",
}

export type TUser = {
  id: number
  email: string
  first_name?: string | null
  last_name?: string | null
  role: ERole
  status: EStatus
  profile_picture_url: string | null
  profile_picture_thumbnail_url: string | null
  joined_at: string
  invited_by: TUser | null
}

export const makeUser = (isOwner?: boolean) => {
  const status = isOwner
    ? EStatus.active
    : faker.helpers.arrayElement<EStatus>([EStatus.deactivated, EStatus.invited, EStatus.active])
  const pic =
    status === EStatus.invited
      ? null
      : isOwner
        ? "https://upload.wikimedia.org/wikipedia/commons/f/f6/Irish_clover.svg"
        : faker.image.avatar()

  return makeEntity<TUser>({
    first_name: status === EStatus.invited ? undefined : isOwner ? "Barry" : faker.person.firstName(),
    last_name: status === EStatus.invited ? undefined : isOwner ? "O'Shea" : faker.person.lastName(),
    id: faker.number.int(),
    invited_by: null,
    joined_at: format(faker.date.anytime(), "yyyy-MM-dd"),
    role: isOwner ? ERole.owner : ERole.salesperson,
    email: faker.internet.email(),
    status,
    profile_picture_url: pic,
    profile_picture_thumbnail_url: pic,
  })
}

export type TMetaQueryData = {
  all_users_count?: number
  salespeople_count?: number
  admins_count?: number
  owners_count?: number
}
