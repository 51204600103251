import React from "react"
import {useTranslation} from "react-i18next"
import {TrophyIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {UnstyledLink} from "../../../components/Link"
import {ProfileImage} from "../../../components/ProfileImage.tsx"
import {ACompanyUserAssignmentReporting} from "../../../services/types.generated"
import {getFullName, renderPrice} from "../../../utils"
import {enumTranslKey} from "../../../utils/i18n"
import {Trend, TrendSkeleton} from "./Trend"

export const AssignmentCard: React.FC<{
  assignment: ACompanyUserAssignmentReporting
  selected: boolean
  winner: boolean
  onClick?: () => void
}> = ({assignment, selected, winner, onClick}) => {
  const {t} = useTranslation()

  return (
    <UnstyledLink
      className={twMerge(
        "card-shadow flex min-w-52 max-w-96 flex-1 items-center gap-2 p-6 transition-all",
        selected && "border-cr-blue",
        onClick && "cursor-pointer"
      )}
      onClick={onClick}
      role={"radio"}
      aria-checked={selected}
    >
      <div className={"relative flex shrink-0"}>
        <ProfileImage
          src={assignment.sales_person?.profile_picture_thumbnail_url ?? undefined}
          title={getFullName(assignment.sales_person)}
          className={"size-[75px]"}
        />
        {winner && (
          <div
            className={"absolute bottom-0 left-0 -translate-x-1/3 translate-y-1/3 rounded-full bg-cr-white p-2 shadow"}
          >
            <TrophyIcon className={"size-6 text-cr-yellow"} />
          </div>
        )}
      </div>
      <div className={"flex min-w-0 flex-col gap-1 self-start"}>
        <h2 className={"line-clamp-1 font-bold text-cr-blue"}>{getFullName(assignment.sales_person)}</h2>
        <h3>
          <span className={"text-2xl font-bold"}>{renderPrice(assignment.total_activities)}</span>
          <span className={"text-sm"}> / {t(enumTranslKey("ReportsMetric", "activities"))}</span>
        </h3>
        <Trend value={assignment.activities_trend} />
      </div>
    </UnstyledLink>
  )
}

export const AssignmentCardSkeleton: React.FC = () => {
  return (
    <UnstyledLink
      className={"card-shadow flex min-w-52 max-w-96 flex-1 items-center gap-2 p-6"}
      role={"radio"}
      data-testid={"loading"}
    >
      <div className={"relative flex shrink-0"}>
        <div className={"size-[75px] rounded-full bg-cr-grey-15"} />
      </div>
      <div className={"flex min-w-0 flex-col gap-1 self-start"}>
        <h2 className={"w-40 rounded-full bg-cr-grey-15"}>&nbsp;</h2>
        <h3 className={"w-32 rounded-full bg-cr-grey-15 text-2xl"}>&nbsp;</h3>
        <TrendSkeleton />
      </div>
    </UnstyledLink>
  )
}
