import React, {ChangeEventHandler} from "react"
import {useTranslation} from "react-i18next"
import {CheckCircleIcon} from "@heroicons/react/24/solid"

import {GenericErrorAlert} from "../../../components/Alert"
import {InputBase} from "../../../components/fields/Input"
import {Loading} from "../../../components/Loading"
import Pagination from "../../../components/Pagination"
import {RowCountPickerLS, usePageSize} from "../../../components/RowCountPicker"
import {TOrderBy} from "../../../components/Table/shared"
import type {useCompanyWaitingToApproveTableQuery} from "../../../queries/prospects"
import {useDebouncedValue} from "../../../utils/hooks"
import {ApproveDatabaseButton} from "./components/ApproveDatabaseButton"
import {TCompanyTableColumn, TTableProps} from "./types"

type TProps = {
  salesCycleId: number
  assignmentId: number

  title?: React.ReactNode

  isApproveButtonVisible?: boolean
  isSubmittedIndicatorVisible?: boolean
  defaultOrder?: TOrderBy<TCompanyTableColumn>

  listQuery: typeof useCompanyWaitingToApproveTableQuery
  TableComponent: React.ComponentType<TTableProps>
  paginationKey: string
}

export const Section: React.FC<TProps> = ({
  assignmentId,
  salesCycleId,
  title,
  TableComponent,
  isApproveButtonVisible,
  isSubmittedIndicatorVisible,
  defaultOrder,
  listQuery,
  paginationKey,
}) => {
  const {t} = useTranslation()

  const [searchString, setSearchString] = React.useState("")
  const searchStringDebounced = useDebouncedValue(searchString)

  const [orderBy, setOrderBy] = React.useState<TOrderBy<TCompanyTableColumn>>(defaultOrder)

  const handleChangeSearchString = React.useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    setSearchString(e.target.value)
  }, [])

  const {data, error, isFetching, isLoading, refetch, pagination} = listQuery({
    assignmentId,
    searchString: searchStringDebounced,
    salesCycleId,
    orderBy,
    pageSize: usePageSize(paginationKey, 50),
  })

  return (
    <div className={"flex flex-col gap-4"}>
      <div className={"flex items-center justify-between gap-10"}>
        <div className={"grow"}>
          <h3 className={"font-bold"}>{title}</h3>
        </div>
        <div className={"flex flex-wrap items-center justify-end gap-4"}>
          {isApproveButtonVisible && <ApproveDatabaseButton assignmentId={assignmentId} />}
          {isSubmittedIndicatorVisible && (
            <div className={"flex items-center gap-2 font-semibold text-cr-blue"}>
              <CheckCircleIcon className={"h-5 w-5"} />
              <span>{t("Prospects_SubmittedIndicator")}</span>
            </div>
          )}
          <InputBase value={searchString} onChange={handleChangeSearchString} placeholder={t("SearchPlaceholder")} />
        </div>
      </div>

      {error ? (
        <GenericErrorAlert />
      ) : isLoading ? (
        <Loading containerClassName={"my-10"} />
      ) : (
        <>
          <TableComponent
            data={data?.data.prospects ?? []}
            isLoading={isFetching}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            refetch={refetch}
            assignmentId={assignmentId}
          />
          <RowCountPickerLS total={pagination.total} autoHide paginationKey={paginationKey} />
          <Pagination {...pagination} autoHide />
        </>
      )}
    </div>
  )
}
