import React from "react"
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react"
import {InformationCircleIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

export type TTooltipButtonProps = React.RefAttributes<any> & {open?: boolean; close?: () => void}

export const Tooltip: React.FC<
  {
    children: React.ReactNode
    placement?: Placement
    disabled?: boolean
    openDelay?: number
    closeDelay?: number
  } & (
    | {
        Button: React.ComponentType<TTooltipButtonProps>
        buttonNode?: never
        buttonClassName?: never
        iconClassName?: never
      }
    | {Button?: never; buttonNode: React.ReactNode; buttonClassName?: never; iconClassName?: never}
    | {Button?: never; buttonNode?: never; buttonClassName?: string; iconClassName?: string}
  )
> = ({
  children,
  disabled,
  openDelay,
  closeDelay = 200,
  Button,
  buttonNode,
  placement = "top",
  buttonClassName,
  iconClassName,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const {context, refs, floatingStyles} = useFloating({
    placement,
    middleware: [flip(), offset(10), shift({padding: 16})],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  })

  const hover = useHover(context, {enabled: !disabled, delay: {open: openDelay, close: closeDelay}})

  const {getReferenceProps, getFloatingProps} = useInteractions([hover])
  const {styles} = useTransitionStyles(context, {
    duration: {open: 100, close: 75},
    initial: {opacity: 0, transform: "scale(0.95)"},
    open: {opacity: 1, transform: "scale(1)"},
    common: ({side}) => ({
      transformOrigin: {
        top: "bottom",
        bottom: "top",
        left: "right",
        right: "left",
      }[side],
    }),
  })

  return (
    <>
      <div>
        {Button ? (
          <Button open={isOpen} close={() => setIsOpen(false)} ref={refs.setReference} {...getReferenceProps()} />
        ) : buttonNode ? (
          <div ref={refs.setReference} {...getReferenceProps()}>
            {buttonNode}
          </div>
        ) : (
          <button
            ref={refs.setReference}
            data-open={isOpen || undefined}
            className={twMerge([
              "inline rounded-full outline outline-2 outline-transparent transition-all data-[open]:outline-black",
              buttonClassName,
            ])}
            {...getReferenceProps()}
          >
            <InformationCircleIcon className={twMerge(["h-6", iconClassName])} />
          </button>
        )}
      </div>

      {isOpen && (
        <FloatingPortal>
          <div className={"absolute z-[100]"} ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <div style={{...styles}}>{children}</div>
          </div>
        </FloatingPortal>
      )}
    </>
  )
}
