import React from "react"
import {twMerge} from "tailwind-merge"

import {FilterButton} from "./FilterButton.tsx"
import {OrderButton} from "./OrderButton.tsx"
import {TColumnMeta, TColumns, useTableContext} from "./shared"

export type THeaderCellValueProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
}

export type THeaderCellProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
  onOrder?: () => void
}

function DefaultHeaderCellValue<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
}: THeaderCellValueProps<TCol, TRowData>): React.ReactNode {
  return columnMeta.column
}

export function DefaultHeaderCell<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  onOrder,
}: THeaderCellProps<TCol, TRowData>): React.ReactNode {
  const {orderBy} = useTableContext<TCol, TRowData>()

  const Value = columnMeta.HeaderCellValue ?? DefaultHeaderCellValue

  const align = columnMeta.align ?? "left"

  return (
    <div
      className={twMerge(
        "flex items-center justify-between gap-2",
        "sticky top-0 z-10 select-none transition-all",
        "min-h-10 px-2 py-2.5 md:px-6 md:py-3",
        "bg-cr-white group-[.ghost]:bg-cr-white",
        "text-sm font-bold text-cr-blue-dark"
      )}
    >
      <div
        className={twMerge(
          "grow whitespace-nowrap",
          "flex items-center gap-2 empty:px-0",
          align === "left" && "justify-start",
          align === "center" && "justify-center",
          align === "right" && "justify-end"
        )}
      >
        <Value columnMeta={columnMeta} />
        {!!onOrder && (
          <div className={"shrink-0"}>
            <OrderButton
              direction={orderBy?.column === columnMeta.column ? orderBy.direction : null}
              onOrder={onOrder}
            />
          </div>
        )}
      </div>

      {columnMeta.FilterContent && (
        <div className={"shrink-0"}>
          <FilterButton columnMeta={columnMeta} />
        </div>
      )}
    </div>
  )
}
