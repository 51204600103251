import {z} from "zod"

import {TSalesFormData} from "../SalesForm/types"

export enum EStepStatus {
  completed = "completed",
  current = "current",
  upcoming = "upcoming",
  error = "error",
  loading = "loading",
}

export type TStep = {
  name: string
  description: string
  href: string
  fields: ReadonlyArray<keyof TSalesFormData>
  validationSchema: z.AnyZodObject
}
