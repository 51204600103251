import React from "react"
import ReactDOM from "react-dom/client"

import {hackFixChromeTranslate} from "./utils/hackFixChromeTranslate"
import {updateAllLSItems} from "./utils/localStorage/update"
import App from "./App"

import "./i18n"

import "react-toastify/dist/ReactToastify.css"
import "./index.css"

updateAllLSItems()

hackFixChromeTranslate()

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
