export const getEvenlySteppedItems = <T>(arr: T[], numItems: number): T[] => {
  if (numItems < 0) {
    throw new Error("Cannot divide into a negative amount of groups")
  }

  if (numItems >= arr.length) {
    return arr
  }

  if (numItems === 0) {
    return []
  }

  if (numItems === 1) {
    return [arr[Math.round(arr.length / 2) - 1]]
  }

  const ret = []
  const step = (arr.length - 1) / (numItems - 1)

  for (let i = 0; i <= arr.length; i += step) {
    ret.push(arr[Math.round(i)])
  }

  return ret
}
