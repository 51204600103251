import {i18n} from "../i18n.ts"
import {ACommissionTypes, ACommissionValueTypes} from "../services/types.generated.ts"

export const commissionTitle = {
  [ACommissionTypes.OneOff]: {
    [ACommissionValueTypes.FixedPrice]: i18n.t("Commission_OneOff_FixedPrice"),
    [ACommissionValueTypes.Percentage]: i18n.t("Commission_OneOff_Percentage"),
  },
  [ACommissionTypes.Recurring]: {
    [ACommissionValueTypes.FixedPrice]: i18n.t("Commission_Recurring_FixedPrice"),
    [ACommissionValueTypes.Percentage]: i18n.t("Commission_Recurring_Percentage"),
  },
} satisfies {
  [commissionType in ACommissionTypes]: {[commissionValueType in ACommissionValueTypes]: string}
}
