import React from "react"
import {useTranslation} from "react-i18next"
import {format} from "date-fns/format"
import {twMerge} from "tailwind-merge"

import {Alert} from "../../components/Alert"
import {Link} from "../../components/Link"
import {Loading} from "../../components/Loading"
import {Table} from "../../components/Table"
import {TColumnsMeta, TOrderBy} from "../../components/Table/shared"
import {orderData, sortDate, sortNumber, sortString} from "../../components/Table/sortFunctions"
import {i18n} from "../../i18n"
import {useCompanyQuery} from "../../queries/companies"
import {ATransaction, ATransactionPaymentStatusEnum} from "../../services/types.generated"
import {renderPrice} from "../../utils"
import {apiDateToJS} from "../../utils/dateArithmetics.ts"
import {useNumParam} from "../../utils/hooks"
import {enumTranslKey} from "../../utils/i18n"

type TColumns = "title" | "invoice" | "paidAt" | "payment_id" | "amount" | "paymentType" | "status"

const columnsMeta = [
  {
    column: "title",
    sortFn: sortString(row => row.sales_cycle.name),
    HeaderCellValue: () => i18n.t("T_Title"),
    CellValue: ({row}) => row.sales_cycle.name,
  },
  {
    column: "invoice",
    HeaderCellValue: () => i18n.t("T_Invoice"),
    CellValue: ({row}) => (
      <div>
        <div className={"whitespace-nowrap"}>{row.invoice_number}</div>
        <div>
          <Link className={"text-xs"} to={row.external_invoice_url} target={"_blank"} rel={"noreferrer"}>
            PDF
          </Link>
        </div>
      </div>
    ),
  },
  {
    column: "paidAt",
    sortFn: sortDate(row => (row.paid_at ? apiDateToJS(row.paid_at) : undefined)),
    HeaderCellValue: () => i18n.t("CompanyProfile_Plan"),
    CellValue: ({row}) => <PaidAt value={row.paid_at} />,
  },
  {column: "payment_id", sortFn: sortNumber(row => row.payment_id), HeaderCellValue: () => i18n.t("T_ID")},
  {
    column: "amount",
    align: "right",
    sortFn: sortNumber(row => row.amount),
    HeaderCellValue: () => i18n.t("T_Amount"),
    CellValue: ({row}) => <span className={"whitespace-nowrap"}>{renderPrice(row.amount, row.currency)}</span>,
  },
  {
    column: "paymentType",
    sortFn: sortString(row => row.payment_method),
    HeaderCellValue: () => i18n.t("CompanyProfile_Type"),
    CellValue: ({row}) => (
      <span className={"whitespace-nowrap"}>{i18n.t(enumTranslKey("PaymentMethod", row.payment_method), "-")}</span>
    ),
  },
  {
    column: "status",
    sortFn: sortString(row => row.payment_status),
    HeaderCellValue: () => i18n.t("T_Status"),
    CellValue: ({row}) => <Status value={row.payment_status} />,
  },
] satisfies TColumnsMeta<TColumns, ATransaction & {id: number}>

export const CompanyTransactions: React.FC = () => {
  const {t} = useTranslation()

  const companyId = useNumParam("companyId")

  const {error, data} = useCompanyQuery(companyId)
  const transactions = data?.transactions.map(t => ({...t, id: t.order_id}))

  const [orderBy, setOrderBy] = React.useState<TOrderBy<TColumns>>(undefined)

  const orderedTransactions = React.useMemo(() => {
    return orderData({
      orderBy,
      columnsMeta,
      data: transactions,
    })
  }, [orderBy, transactions])

  if (error) {
    return (
      <Alert title={t("CompanyProfile_An error has occurred while loading the company's data")} variant={"error"} />
    )
  }

  if (!transactions) {
    return <Loading size={"xl"} />
  }

  if (!transactions.length) {
    return (
      <div className={"card p-0"}>
        <h2 className={"p-8"}>{t("CompanyProfile_There are currently no transactions for your account.")}</h2>
      </div>
    )
  }

  return <Table columnsMeta={columnsMeta} data={orderedTransactions} orderBy={orderBy} onOrder={setOrderBy} />
}

const PaidAt: React.FC<{value: string | null | undefined}> = ({value}) => {
  if (!value) {
    return <>-</>
  }

  const date = apiDateToJS(value)

  return (
    <div>
      <div>{format(date, "d MMM, y")}</div>
      <div className={"text-xs text-cr-grey-50"}>{format(date, "hh:mm a")}</div>
    </div>
  )
}

const statusColors = {
  [ATransactionPaymentStatusEnum.Paid]: "bg-cr-green-light text-cr-green",
  [ATransactionPaymentStatusEnum.Awaiting]: "bg-cr-yellow-light text-cr-yellow",
  [ATransactionPaymentStatusEnum.Failed]: "bg-cr-red-light text-cr-red",
  [ATransactionPaymentStatusEnum.AuthorizationRequired]: "bg-cr-blue-super-light text-cr-blue",
} as const satisfies {[status in ATransactionPaymentStatusEnum]: string}

const Status: React.FC<{value: ATransactionPaymentStatusEnum}> = ({value}) => {
  const {t} = useTranslation()

  return (
    <div className={twMerge("inline-block rounded-full px-2 py-1 text-xs font-medium capitalize", statusColors[value])}>
      {t(enumTranslKey("PaymentStatus", value))}
    </div>
  )
}
