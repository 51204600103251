import React from "react"
import {useTranslation} from "react-i18next"
import {
  EnvelopeIcon,
  ExclamationTriangleIcon,
  PhoneIcon,
  RocketLaunchIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"
import {CheckIcon} from "@heroicons/react/24/solid"
import {twMerge} from "tailwind-merge"

import {Badge} from "../../components/Badge.tsx"
import {IconButton} from "../../components/Button.tsx"
import {Tooltip} from "../../components/Tooltip.tsx"
import {getCurrentLanguage, i18n} from "../../i18n.ts"
import {ADashboardStages, ASaasCompanyUserDashboardSalesCycle} from "../../services/types.generated.ts"
import {apiDateToJS} from "../../utils/dateArithmetics.ts"
import {getStageTimings, TStageTimings} from "../DashboardSalesperson/Selling.tsx"
import {hasStageProblems} from "./utils.ts"

export const Stages: React.FC<{date: string; salesCycles?: ASaasCompanyUserDashboardSalesCycle[]}> = ({
  salesCycles,
  date,
}) => {
  const timings = getStageTimings(apiDateToJS(date))

  const hasProblem = React.useCallback(
    (stage: ADashboardStages): boolean => {
      return (salesCycles ?? []).some(salesCycle =>
        salesCycle.assignments?.some(assignment => hasStageProblems(stage, assignment))
      )
    },
    [salesCycles]
  )

  return (
    <div
      className={
        "cr-shadow grid grid-cols-1 flex-wrap gap-px rounded-lg bg-cr-blue-light md:grid-cols-2 lg:grid-cols-4"
      }
    >
      <Stage
        Icon={EnvelopeIcon}
        stage={ADashboardStages.FirstOutreach}
        timings={timings}
        hasProblem={hasProblem(ADashboardStages.FirstOutreach)}
      />
      <Stage
        Icon={PhoneIcon}
        stage={ADashboardStages.SecondOutreach}
        timings={timings}
        hasProblem={hasProblem(ADashboardStages.SecondOutreach)}
      />
      <Stage
        Icon={UsersIcon}
        stage={ADashboardStages.PresentationDemo}
        timings={timings}
        hasProblem={hasProblem(ADashboardStages.PresentationDemo)}
      />
      <Stage Icon={RocketLaunchIcon} stage={ADashboardStages.Closing} timings={timings} />
    </div>
  )
}

const titleByStage = {
  [ADashboardStages.FirstOutreach]: i18n.t("Dashboard_SaaS_Progress_FirstOutreach"),
  [ADashboardStages.SecondOutreach]: i18n.t("Dashboard_SaaS_Progress_SecondOutreach"),
  [ADashboardStages.PresentationDemo]: i18n.t("Dashboard_SaaS_Progress_PresentationDemo"),
  [ADashboardStages.Closing]: i18n.t("Dashboard_SaaS_Progress_Closing"),
} satisfies {[stage in ADashboardStages]: string}

export const Stage: React.FC<{
  Icon: React.ComponentType<{className?: string}>
  hasProblem?: boolean
  stage: ADashboardStages
  timings: TStageTimings
}> = ({hasProblem, Icon, timings, stage}) => {
  const {t} = useTranslation()

  const stageWithTiming = timings.find(timing => timing.stage === stage)!
  const start = stageWithTiming.start.toLocaleString(getCurrentLanguage(), {month: "short", day: "numeric"})
  const end = stageWithTiming.end.toLocaleString(getCurrentLanguage(), {month: "short", day: "numeric"})

  const isPast = stageWithTiming.end < new Date()
  const isFuture = stageWithTiming.start > new Date()
  const isCurrent = !isPast && !isFuture

  const IconToUse = isPast ? CheckIcon : Icon

  return (
    <div
      className={twMerge(
        "group relative flex items-center justify-between gap-4 bg-cr-white px-6 py-5 first:rounded-l-lg last:rounded-r-lg",
        isCurrent && "border-b-4 border-cr-blue"
      )}
    >
      <div
        className={twMerge(
          "shrink-0 rounded-full border-2 p-2",
          isPast && "border-cr-blue bg-cr-blue text-white",
          isCurrent && "border-cr-blue bg-cr-white text-cr-blue",
          isFuture && "border-cr-blue-light bg-cr-white text-cr-grey-50"
        )}
      >
        <IconToUse className={"h-7 transition-all max-lg:h-5"} />
      </div>
      <div className={"flex grow flex-col justify-between gap-1"}>
        <h3
          className={twMerge(
            "text-xs font-semibold uppercase",
            isPast && "text-cr-black",
            isCurrent && "text-cr-blue",
            isFuture && "text-cr-grey-50"
          )}
        >
          {titleByStage[stage]}
        </h3>
        <div className={"text-sm text-cr-grey-50"}>
          {start} - {end}
        </div>
      </div>
      {isCurrent && (
        <Badge color={"light-blue"} className={"self-start"}>
          {t("Dashboard_SaaS_Progress_Now")}
        </Badge>
      )}
      {isPast && hasProblem && (
        <Tooltip
          buttonNode={
            <IconButton className={"text-cr-red"} noEdges>
              <ExclamationTriangleIcon className={"size-6"} />
            </IconButton>
          }
        >
          <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
            {t("Dashboard_SaaS_Progress_Problem")}
          </div>
        </Tooltip>
      )}
      <div
        className={
          "absolute right-0 z-10 size-4 translate-x-1/2 rotate-45 rounded-tr-[5px] border-r border-t border-cr-blue-light bg-cr-white group-last:hidden"
        }
      ></div>
    </div>
  )
}
