import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {CheckIcon} from "@heroicons/react/20/solid"

import {ButtonLoading} from "../../../components/Button.tsx"
import {Flag} from "../../../components/Flag.tsx"
import {useLanguagesQuery} from "../../../queries/languages.ts"
import {renderPrice} from "../../../utils"
import {commonTransComponents} from "../../../utils/i18n.tsx"
import {isPlaceholderSalesperson, TSalesPerson} from "../utils.ts"
import {SalespersonCard} from "./SalespersonCard.tsx"

export const SalespersonRequestCard: React.FC<
  React.ComponentProps<typeof RequestSalespersonBody> & Omit<React.ComponentProps<typeof SalespersonCard>, "children">
> = ({isRequested, onToggle, ...cardProps}) => {
  const pkg = cardProps.salesPerson.packages?.[0]

  return (
    <SalespersonCard
      {...cardProps}
      cyanRibbonText={
        pkg && (
          <Trans
            i18nKey={"CreateSalesCycle_SalespersonCard_PriceRibbon_From"}
            components={commonTransComponents}
            values={{
              price: renderPrice(pkg.monthly_price, pkg.currency, {
                noDecimal: true,
              }),
            }}
          />
        )
      }
    >
      <RequestSalespersonBody salesPerson={cardProps.salesPerson} onToggle={onToggle} isRequested={isRequested} />
    </SalespersonCard>
  )
}

export const RequestSalespersonBody: React.FC<{
  salesPerson: TSalesPerson
  isRequested: boolean
  onToggle: () => Promise<void>
}> = ({salesPerson, onToggle, isRequested}) => {
  const {t} = useTranslation()

  const {data} = useLanguagesQuery()

  const nativeLanguageText = data?.find(lang => lang.id === salesPerson.native_language_id)?.name
  const nativeLanguageTextMarked = nativeLanguageText
    ? t("CreateSalesCycle_SalespersonCard_Languages_Native", {language: nativeLanguageText})
    : undefined
  const spokenLanguagesText = salesPerson.spoken_language_ids
    ?.filter(id => id !== salesPerson.native_language_id)
    ?.map(id => data?.find(lang => lang.id === id)?.name)
    ?.filter(text => text != null)

  const allLanguagesText = [nativeLanguageTextMarked, ...(spokenLanguagesText ?? [])].filter(text => text != null)

  return (
    <div className={"flex grow flex-col gap-4 text-sm"}>
      {isPlaceholderSalesperson(salesPerson) && (
        <div className={"flex flex-col gap-1"}>
          <strong>{t("CreateSalesCycle_SalespersonCard_AboutTitle")}</strong>
          <div>{salesPerson.about}</div>
        </div>
      )}
      {salesPerson.years_of_experience != null && (
        <div className={"flex flex-col gap-1"}>
          <strong>{t("CreateSalesCycle_SalespersonCard_YearsOfExperience_Title")}</strong>
          <div>
            {t("CreateSalesCycle_SalespersonCard_YearsOfExperience_Text", {count: salesPerson.years_of_experience})}
          </div>
        </div>
      )}
      {allLanguagesText.length > 0 && (
        <div className={"flex flex-col gap-1"}>
          <strong>{t("CreateSalesCycle_SalespersonCard_Languages_Title")}</strong>
          <div>{allLanguagesText.join(", ")}</div>
        </div>
      )}
      <div className={"grow"} />
      {salesPerson.country && (
        <div className={"flex items-center gap-2"}>
          <Flag code={salesPerson.country.code} className={"card-shadow h-6 w-8 rounded-md"} />
          {salesPerson.country.name}
        </div>
      )}
      <div className={"flex items-end"}>
        <ButtonLoading
          iconLeft={isRequested ? <CheckIcon className={"size-4"} /> : undefined}
          onClick={onToggle}
          fullWidth
          variant={isRequested ? "contained" : "outlined"}
        >
          {isRequested
            ? t("CreateSalesCycle_SalespersonCard_RequestButton_Requested")
            : t("CreateSalesCycle_SalespersonCard_RequestButton_Unrequested")}
        </ButtonLoading>
      </div>
    </div>
  )
}
