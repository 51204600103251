import {useTranslation} from "react-i18next"

import {ButtonLink} from "../../components/Button"
import {useDocumentTitle} from "../../utils/hooks"

const Completed = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("SalesStrategy_Sales strategy form"))

  return (
    <div className={"m-auto max-w-2xl pt-12"}>
      <div className={"card"}>
        <div className={"text-center"}>
          <h1 className={"text-4xl font-bold text-cr-blue"}>
            {t("SalesStrategy_Thank you for completing your strategy information")}
          </h1>
          <p className={"py-8 text-justify text-cr-grey-50"}>
            {t("SalesStrategy_You Rock!")} <br />
            <br />
            {t("SalesStrategy_Your strategy information set up is done.")}
            <br />
            {t("SalesStrategy_We can get to work on creating a sales strategy just for you.")}
          </p>
          <div className={"flex justify-center pt-4"}>
            <ButtonLink to={`/`}>{t("T_Continue to dashboard")}</ButtonLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Completed
