import {FC} from "react"
import {useTranslation} from "react-i18next"
import {CheckCircleIcon} from "@heroicons/react/24/outline"

import Button from "../../../../components/Button"
import {ProfileImage} from "../../../../components/ProfileImage.tsx"
import {getCurrentLanguage} from "../../../../i18n"
import {AMatchingSalesPerson, ASalesPerson} from "../../../../services/types.generated"
import {getFullName} from "../../../../utils"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {enumTranslKey} from "../../../../utils/i18n"

const isMatchingSalesPerson = (person: AMatchingSalesPerson | ASalesPerson): person is AMatchingSalesPerson =>
  "remaining_capacity" in person && "capacity" in person && "years_of_experience" in person

const Person: FC<{
  person: AMatchingSalesPerson | ASalesPerson
  selected?: boolean
  onSelect?: (id: number | undefined) => void
  startDate: string
}> = ({person, selected = false, onSelect, startDate}) => {
  const {t} = useTranslation()

  return (
    <div className={"my-4 w-full overflow-hidden rounded-lg border border-cr-grey-15"}>
      <div className={"flex justify-between"}>
        <div className={"flex p-3"}>
          <ProfileImage
            className={"mr-2 inline-block size-14"}
            src={person.profile_picture_thumbnail_url ?? undefined}
          />
          <div>
            <h3 className={"text-xl font-bold"}>{getFullName(person, String(person.id))}</h3>
            {isMatchingSalesPerson(person) && <PersonBasicInfo person={person} />}
          </div>
        </div>
        <div className={"m-4"}>
          {isMatchingSalesPerson(person) && person.accepted && (
            <span className={"rounded-full bg-cr-green-light px-3 py-1 text-xs font-medium text-cr-green"}>
              {t("Matching_Approved")}
            </span>
          )}
        </div>
      </div>
      {isMatchingSalesPerson(person) && (
        <div className={"flex items-center border-t border-t-cr-grey-15 bg-cr-blue-super-light p-4"}>
          <div className={"flex grow flex-col gap-2"}>
            <p>
              {t("Matching_Remaining capacity for")}{" "}
              <b>{apiDateToJS(startDate).toLocaleString(getCurrentLanguage(), {month: "long"})}</b>:{" "}
              <b className={"text-cr-blue"}>
                {person.remaining_capacity}/{person.capacity}
              </b>
            </p>
            {person.worked_on_previous_iteration && (
              <p className={"text-cr-blue"}>
                <CheckCircleIcon className={"inline-block h-4 w-4 align-middle"} />{" "}
                {t("Matching_Salesperson already worked on previous iteration")}
              </p>
            )}
            {person.worked_with_same_company && (
              <p className={"text-cr-blue"}>
                <CheckCircleIcon className={"inline-block h-4 w-4 align-middle"} />{" "}
                {t("Matching_Salesperson was already sales plan for this company")}
              </p>
            )}
          </div>
          <div className={"shrink-0 pl-4"}>
            {!onSelect ? (
              ""
            ) : selected ? (
              <Button variant={"light"} onClick={() => onSelect(undefined)}>
                {t("Matching_Matched")}
              </Button>
            ) : (
              <Button onClick={() => onSelect(person.id)}>{t("Matching_Match")}</Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const PersonBasicInfo: FC<{person: AMatchingSalesPerson}> = ({person}) => {
  const {t} = useTranslation()

  const items = [
    person.years_of_experience != null &&
      t("Matching_{{yearsOfExperience}} years", {yearsOfExperience: person.years_of_experience}),
    person.segment?.map(s => t(enumTranslKey("Segment", s))).join(", ") || t("Matching_unknown segment"),
    person.country?.name,
  ].filter(Boolean) as string[]

  return <span className={"text-cr-grey-50"}>{items.join(" | ")}</span>
}

export default Person
