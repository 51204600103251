export enum EGTMEvent {
  GET_STARTED = "get_started",
  COMPANY_SIGNUP_START = "1_join_company",
  COMPANY_SIGNUP_SEND = "1_join_closerocket",
  COMPANY_SIGNUP_VERIFY = "1_verification",
  COMPANY_PROFILE_SEND = "1_company_profile",
  COMPANY_SALES_FORM_SEND = "1_sales_form",
  COMPANY_PRICING_PACKAGES_SEND = "1_pricing_packages",
  COMPANY_CHECKOUT_SEND = "1_purchase",
  COMPANY_SALES_STRATEGY_SEND = "1_sales_strategy",
  SALES_SIGNUP_START = "2_join_sales",
  SALES_SIGNUP_SEND = "2_join_closerocket",
  SALES_SIGNUP_VERIFY = "2_verification",
  SALES_PROFILE_SEND = "2_sales_profile",
}

type TGTMSpecificEvent =
  | {event: EGTMEvent.GET_STARTED}
  | {event: EGTMEvent.COMPANY_SIGNUP_START}
  | {event: EGTMEvent.COMPANY_SIGNUP_SEND; email: string}
  | {event: EGTMEvent.COMPANY_SIGNUP_VERIFY; company: string; phone: string}
  | {event: EGTMEvent.COMPANY_PROFILE_SEND; phone: string; email: string}
  | {event: EGTMEvent.COMPANY_SALES_FORM_SEND}
  | {event: EGTMEvent.COMPANY_PRICING_PACKAGES_SEND; value: number}
  | {event: EGTMEvent.COMPANY_CHECKOUT_SEND; phone: string; email: string; value: number}
  | {event: EGTMEvent.COMPANY_SALES_STRATEGY_SEND}
  | {event: EGTMEvent.SALES_SIGNUP_START}
  | {event: EGTMEvent.SALES_SIGNUP_SEND; email: string}
  | {event: EGTMEvent.SALES_SIGNUP_VERIFY; name: string; phone: string}
  | {event: EGTMEvent.SALES_PROFILE_SEND; name: string; phone: string; email: string}

type TGTMEvent = {event: EGTMEvent} & Record<string, any>

export function emitGTMEvent(data: TGTMSpecificEvent) {
  ;(
    window as typeof window & {
      dataLayer?: {push: (args: TGTMEvent) => void}
    }
  ).dataLayer?.push(data)
}
