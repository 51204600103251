import * as React from "react";
const SvgLogoIconCurrentColor = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 350 350", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(10.4717,0,0,10.4717,5.5,6)", fill: "currentColor" }, /* @__PURE__ */ React.createElement("path", { d: "M2.703,25.201L4.321,28C7.814,25.978 11.867,24.819 16.187,24.819C20.506,24.819 24.559,25.978 28.052,28L29.67,25.201C25.701,22.903 21.095,21.587 16.187,21.587C11.278,21.587 6.672,22.903 2.703,25.201Z", style: {
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M20.502,0L17.266,0C17.266,9.952 22.697,18.66 30.755,23.324L32.373,20.525C25.282,16.421 20.502,8.758 20.502,0Z", style: {
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.871,0C11.871,8.758 7.091,16.421 0,20.525L1.618,23.324C9.676,18.66 15.108,9.952 15.108,0L11.871,0Z", style: {
  fillRule: "nonzero"
} })));
export default SvgLogoIconCurrentColor;
