import React, {FC} from "react"
import {useFormContext, useWatch} from "react-hook-form"
import {useTranslation} from "react-i18next"

import {FieldLabel, TFieldLabelProps} from "../fields/components"
import {InputAction} from "../InputAction"

export type TOnDeleteTag = (tag: string) => () => void

export interface ITags extends TFieldLabelProps {
  name: string
  tagClassNameOverride?: string
  renderTags?: (tags: string[], onDeleteTag: TOnDeleteTag) => JSX.Element
}

const Tags: FC<ITags> = ({id, name, renderTags, tagClassNameOverride, ...props}) => {
  const {t} = useTranslation()

  const value: string[] = useWatch()[name] ?? []

  const {setValue} = useFormContext()

  const handleDeleteTag: TOnDeleteTag = tag => () => {
    const tagSet = new Set(value)
    tagSet.delete(tag)

    setValue(name, Array.from(tagSet), {shouldTouch: true, shouldDirty: true, shouldValidate: true})
  }

  return (
    <FieldLabel id={id ?? name} name={name} {...props}>
      <InputAction
        actionLabel={t("T_Add")}
        inputClassName={"pr-14"}
        onAction={tag => {
          setValue(name, Array.from(new Set([...value, tag])), {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          })
        }}
      />

      {value.length > 0 && (
        <div className={"-mx-1 mt-2 text-left"}>
          {renderTags
            ? renderTags(value, handleDeleteTag)
            : value.map(tag => (
                <div
                  key={tag}
                  className={
                    tagClassNameOverride ??
                    "m-1 inline-block rounded-md bg-cr-grey-5 px-3 py-1 pr-1 text-sm font-medium text-cr-grey-50"
                  }
                  data-testid={"tag"}
                >
                  {tag}
                  <button className={"px-2 text-cr-grey-50 hover:text-black"} onClick={handleDeleteTag(tag)}>
                    ×
                  </button>
                </div>
              ))}
        </div>
      )}
    </FieldLabel>
  )
}

export default Tags
