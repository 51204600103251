import React from "react"
import {useTranslation} from "react-i18next"
import {ArchiveBoxIcon} from "@heroicons/react/24/outline"

import {IconButton} from "../../../components/Button.tsx"
import {Link} from "../../../components/Link"
import {Tooltip} from "../../../components/Tooltip"
import {ImportsHistoryModal} from "./ImportsHistoryModal"

export const ImportsHistoryButton: React.FC<{isImporting?: boolean}> = ({isImporting}) => {
  const {t} = useTranslation()

  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className={"flex items-center gap-2"}>
      <Tooltip
        buttonNode={
          <IconButton
            className={isOpen ? "pointer-events-none" : undefined}
            onClick={() => setIsOpen(true)}
            size={"lg"}
          >
            <ArchiveBoxIcon className={"size-6"} />
          </IconButton>
        }
      >
        <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
          {t("Leads_ImportsHistory_Tooltip")}
        </div>
      </Tooltip>
      {isImporting && (
        <span className={"text-sm"}>
          {t("Leads_ImportsHistory_ImportingText")}{" "}
          <Link onClick={() => setIsOpen(true)}>{t("Leads_ImportsHistory_ImportingCheckStatus")}</Link>
        </span>
      )}
      <ImportsHistoryModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
}
