import React from "react"
import {useTranslation} from "react-i18next"
import {EnvelopeIcon, PhoneIcon, UsersIcon} from "@heroicons/react/24/outline"
import {BarTooltipProps} from "@nivo/bar"

import {Badge, TBadgeColor} from "../../../../components/Badge"
import {ProfileImage} from "../../../../components/ProfileImage.tsx"
import {i18n} from "../../../../i18n"
import {getFullName} from "../../../../utils"
import {useAreMeetingsDisabled} from "../../hooks"
import {TDatum} from "./ActivitiesGraphCard"

type TTooltipDataConfigItem = {
  Icon: React.ComponentType<{className?: string}>
  text: string
  badgeColor: TBadgeColor
  value: (assignment: TDatum["assignments"][number]) => number
}

export const ActivitiesGraphTooltip: React.FC<BarTooltipProps<TDatum>> = ({data}) => {
  const {t} = useTranslation()

  const areMeetingsDisabled = useAreMeetingsDisabled()

  const tooltipDataConfig = React.useMemo(() => {
    return [
      {
        Icon: EnvelopeIcon,
        text: i18n.t("Reports_Chart_Activities_Emails"),
        badgeColor: "light-blue",
        value: assignment => assignment.values.emails,
      },
      {
        Icon: PhoneIcon,
        text: i18n.t("Reports_Chart_Activities_Calls"),
        badgeColor: "cyan",
        value: assignment => assignment.values.calls,
      },
      ...(areMeetingsDisabled
        ? []
        : [
            {
              Icon: UsersIcon,
              text: i18n.t("Reports_Chart_Activities_Meetings"),
              badgeColor: "blue",
              value: assignment => assignment.values.meetings,
            } satisfies TTooltipDataConfigItem,
          ]),
    ] satisfies TTooltipDataConfigItem[]
  }, [areMeetingsDisabled])

  let total = (data.calls ?? 0) + (data.emails ?? 0)
  if (!areMeetingsDisabled) {
    total += data.meetings ?? 0
  }

  return (
    <div className={"card-shadow flex min-w-32 translate-y-1/2 flex-col gap-4 p-4"}>
      <h2 className={"font-bold"}>{data.date}</h2>
      <h3>
        {`${t("Reports_Chart_Total")}: `}
        <span className={"font-bold"}>{total}</span>
      </h3>

      <div className={"grid grid-flow-col grid-rows-[auto_auto_auto] gap-x-6 gap-y-4"}>
        {data.assignments?.map(assignment => (
          <div
            data-testid={"tooltip-assignment"}
            key={assignment.id}
            className={"flex w-full flex-col gap-2 border-t border-t-cr-grey-15 pt-4 text-sm"}
          >
            <div className={"flex items-center gap-4"}>
              <ProfileImage
                className={"size-12 shrink-0"}
                src={assignment.sales_person?.profile_picture_thumbnail_url ?? undefined}
                alt={getFullName(assignment.sales_person)}
              />
              <h4 className={"font-bold text-cr-blue"}>{getFullName(assignment.sales_person)}</h4>
            </div>
            {tooltipDataConfig.map(({Icon, badgeColor, text, value}) => (
              <div className={"flex items-center gap-4 pl-3"} key={text}>
                <Icon className={"size-5 shrink-0"} />
                <span className={"grow"}>{text}:</span>
                <Badge color={badgeColor} className={"min-w-12 py-1 text-sm font-bold"}>
                  {value(assignment)}
                </Badge>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
