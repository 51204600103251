import {initReactI18next} from "react-i18next"
import i18nOriginal from "i18next"

import enEnum from "../locales/en-gb/translation.enum.json" with {type: "json"}
import enGenerated from "../locales/en-gb/translation.generated.json" with {type: "json"}
import skEnum from "../locales/sk/translation.enum.json" with {type: "json"}
import skGenerated from "../locales/sk/translation.generated.json" with {type: "json"}
import {getLSItem} from "./utils/localStorage"

export const resources = {
  en: {generated: enGenerated, enum: enEnum},
  sk: {generated: skGenerated, enum: skEnum},
} as const

export enum ELanguage {
  SK = "sk",
  EN = "en-gb",
}

export function isKnownLanguage(lang: unknown): lang is ELanguage {
  return Object.values(ELanguage).includes(lang as ELanguage)
}

function getLanguageFromBrowser(): ELanguage | undefined {
  const lang = globalThis.navigator?.language.match(/(\w+)-?/)?.[1]

  return isKnownLanguage(lang) ? lang : undefined
}

export function getCurrentLanguage(): ELanguage {
  return getLSItem("language")?.data ?? getLanguageFromBrowser() ?? ELanguage.EN
}

i18nOriginal
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getCurrentLanguage(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    keySeparator: "_",
    defaultNS: "generated",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export const i18n = i18nOriginal
