import {makeEntity} from "../../utils/testing/entities"
import {TCurrentSelectionState} from "./hooks"

export const buildUrl = ({iterationId, metric, salesCycleId, salespersonId}: TCurrentSelectionState) => {
  const queryParams = [
    iterationId != null && `iterationId=${iterationId}`,
    salespersonId != null && `salespersonId=${salespersonId}`,
  ]
    .filter(Boolean)
    .join("&")

  return `/sellings/${salesCycleId}/reports/${metric}${queryParams.length ? `?${queryParams}` : ""}`
}

export const makeCurrentSelectionState = () =>
  makeEntity<TCurrentSelectionState>({
    metric: "activities",
    salespersonId: null,
    salesCycleId: 420,
    iterationId: null,
  })

export const makeNavigationState = (selection: TCurrentSelectionState = makeCurrentSelectionState()()) => ({
  path: "/admin?/sellings/:salesCycleId/reports/:metric",
  currentUrl: buildUrl(selection),
})
