import React from "react"
import {useTranslation} from "react-i18next"
import {ArrowLeftIcon} from "@heroicons/react/20/solid"

import {ButtonLoading} from "../../components/Button"
import {Link} from "../../components/Link"
import Modal from "../../components/Modal"

export const FinalizeModal: React.FC<{isOpen: boolean; onClose: () => void; onSubmit: () => Promise<void>}> = ({
  onClose,
  isOpen,
  onSubmit,
}) => {
  const {t} = useTranslation()

  return (
    <Modal size={"sm"} isOpen={isOpen} onClose={onClose} disableClickOutsideClose title={t("T_Sales strategy form")}>
      <div className={"flex flex-col gap-8"}>
        <p>
          {t(
            "SalesStrategy_Providing additional information will enable us to create the optimal strategy to maximize your sales potential. Please take a moment to make sure all the fields are complete."
          )}
        </p>
        <p>
          {t(
            "SalesStrategy_If you decide to submit, we will match you with the best sales talent from your industry. You will find them in your dashboard."
          )}
        </p>
        <div className={"flex flex-row-reverse items-center justify-between"}>
          <ButtonLoading onClick={onSubmit}>{t("T_Submit")}</ButtonLoading>
          <Link
            onClick={onClose}
            variant={"neutral"}
            flipUnderline
            className={"flex items-center gap-4 text-sm font-semibold"}
          >
            <ArrowLeftIcon className={"h-5 w-5"} />
            {t("SalesStrategy_Back to editing")}
          </Link>
        </div>
      </div>
    </Modal>
  )
}
