import React from "react"
import {useTranslation} from "react-i18next"
import {LightBulbIcon, SparklesIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {useReportsNewsAndInsightsQuery} from "../../../queries/reports"
import {SectionCard, SectionCardSkeleton} from "../components/SectionCard"
import {useCurrentSelectionState} from "../hooks"

export const NewsAndInsightsSection: React.FC = () => {
  const {t} = useTranslation()
  const {
    value: {salesCycleId, iterationId},
  } = useCurrentSelectionState()

  const {data, isFetching, isLoading} = useReportsNewsAndInsightsQuery(salesCycleId, iterationId)

  if (!isLoading && !data?.news && !data?.insights) {
    return null
  }

  return (
    <div
      data-testid={"NewsAndInsightsSection"}
      className={twMerge("flex gap-4", isFetching && "pointer-events-none animate-pulse")}
    >
      {isLoading ? (
        <>
          <SectionCardSkeleton />
          <SectionCardSkeleton />
        </>
      ) : (
        <>
          {data?.news && (
            <SectionCard testId={"news"} headline={t("Reports_NewsAndInsights_NewsTitle")} Icon={SparklesIcon} isDark>
              {data.news}
            </SectionCard>
          )}
          {data?.insights && (
            <SectionCard testId={"insights"} headline={t("Reports_NewsAndInsights_InsightsTitle")} Icon={LightBulbIcon}>
              {data.insights}
            </SectionCard>
          )}
        </>
      )}
    </div>
  )
}
