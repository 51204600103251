import React from "react"
import {useTranslation} from "react-i18next"
import {PhoneIcon} from "@heroicons/react/24/outline"

import {CheckboxConnected} from "../../../../components/fields/Checkbox.tsx"
import {FieldErrorMessage} from "../../../../components/fields/components.tsx"
import {InputField} from "../../../../components/fields/Input.tsx"

export const DetailsPage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <>
      <InputField label={t("Signup_Salesperson_AccountCreatePage_FirstName")} name={"first_name"} variant={"medium"} />
      <InputField label={t("Signup_Salesperson_AccountCreatePage_LastName")} name={"last_name"} variant={"medium"} />
      <InputField
        Icon={PhoneIcon}
        label={t("Signup_Salesperson_AccountCreatePage_PhoneNumber")}
        placeholder={t("Signup_Salesperson_AccountCreatePage_PhoneNumberPlaceholder")}
        name={"phone_number"}
        variant={"medium"}
      />
      <div className={"my-2"}>
        <CheckboxConnected name={"businessEntityAgreed"}>
          {t("Signup_Salesperson_AccountCreatePage_BusinessEntity")}
        </CheckboxConnected>
        <FieldErrorMessage name={"businessEntityAgreed"} reserveSpace />
      </div>
    </>
  )
}
