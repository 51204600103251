import React from "react"
import {useTranslation} from "react-i18next"
import AutoSizer from "react-virtualized-auto-sizer"
import {Bar, BarDatum} from "@nivo/bar"

import {GenericErrorAlert} from "../../../../components/Alert"
import {Loading} from "../../../../components/Loading"
import {getCurrentLanguage} from "../../../../i18n"
import {useReportsActivitiesChartQuery, useReportsMetricsQuery} from "../../../../queries/reports"
import {AActivitiesChartData, AActivitiesValues} from "../../../../services/types.generated"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {enumTranslKey} from "../../../../utils/i18n"
import {tailwindColors} from "../../../../utils/tailwind"
import {useAreMeetingsDisabled, useCurrentAssignmentId, useCurrentSelectionState} from "../../hooks"
import {LegendItem} from "../DataLegend"
import {ActivitiesGraphTooltip} from "./ActivitiesGraphTooltip"
import {MetricGraphCard} from "./MetricGraphCard"

export type TDatum = {
  meetings?: number
  calls?: number
  emails?: number
  date: string
  assignments: AActivitiesChartData[number]["assignments"]
}

const COLORS = {
  meetings: tailwindColors["cr-blue"]["DEFAULT"],
  emails: tailwindColors["cr-blue"]["mid"],
  calls: tailwindColors["cr-cyan"]["DEFAULT"],
} satisfies {[key in keyof AActivitiesValues]: string}

export const ActivitiesGraphCard: React.FC = () => {
  const {t} = useTranslation()

  const {
    value: {salesCycleId, iterationId},
  } = useCurrentSelectionState()
  const assignmentId = useCurrentAssignmentId()

  const {data, error, isFetching, isLoading, refetch} = useReportsActivitiesChartQuery(
    salesCycleId,
    iterationId,
    assignmentId
  )
  const {data: metricsData} = useReportsMetricsQuery(salesCycleId, iterationId, assignmentId)

  const areMeetingsDisabled = useAreMeetingsDisabled()

  const graphData = React.useMemo<TDatum[]>(() => {
    return (
      data?.map(d => {
        const start = apiDateToJS(d.start_date).toLocaleString(getCurrentLanguage(), {
          month: "short",
          day: "numeric",
        })
        const end = apiDateToJS(d.end_date).toLocaleString(getCurrentLanguage(), {
          month: "short",
          day: "numeric",
        })

        return {
          meetings: d.values?.meetings ?? 0,
          calls: d.values?.calls ?? 0,
          emails: d.values?.emails ?? 0,
          date: `${start} - ${end}`,
          assignments: d.assignments,
        }
      }) ?? []
    )
  }, [data])

  if (error) {
    return (
      <MetricGraphCard>
        <GenericErrorAlert retry={refetch} />
      </MetricGraphCard>
    )
  }

  return (
    <MetricGraphCard
      className={isFetching ? "pointer-events-none animate-pulse" : undefined}
      title={t(enumTranslKey("ReportsMetric", "activities"))}
      total={metricsData?.counters.activities}
    >
      <div className={"flex gap-8"}>
        <LegendItem label={t("Reports_Chart_Activities_Emails")} color={COLORS.emails} />
        <LegendItem label={t("Reports_Chart_Activities_Calls")} color={COLORS.calls} />
        {!areMeetingsDisabled && <LegendItem label={t("Reports_Chart_Activities_Meetings")} color={COLORS.meetings} />}
      </div>

      <div className={"flex h-full items-center justify-center"}>
        {isLoading ? (
          <Loading delayShow={false} />
        ) : graphData.length === 0 ? (
          <span className={"text-sm text-cr-grey-50"}>{t("Reports_NoData")}</span>
        ) : (
          <div className={"h-full w-full"} data-testid={"chart"}>
            <AutoSizer>
              {({width, height}) => (
                <Bar
                  width={width}
                  height={height}
                  data={graphData as unknown as BarDatum[]}
                  keys={areMeetingsDisabled ? ["calls", "emails"] : ["calls", "emails", "meetings"]}
                  indexBy={"date"}
                  colors={dataDef => COLORS[dataDef.id as keyof AActivitiesValues]}
                  motionConfig={"stiff"}
                  borderRadius={3}
                  borderColor={"white"}
                  borderWidth={2}
                  enableTotals={true}
                  enableLabel={false}
                  margin={{left: 30, top: 20, bottom: 30}}
                  axisLeft={{tickValues: 4, tickSize: 0}}
                  gridYValues={4}
                  tooltip={ActivitiesGraphTooltip as any}
                  theme={{
                    grid: {
                      line: {
                        stroke: tailwindColors["cr-grey"]["15"],
                        strokeWidth: 1,
                        strokeDasharray: "4 6",
                      },
                    },
                    text: {
                      fontFamily: "inherit",
                    },
                  }}
                />
              )}
            </AutoSizer>
          </div>
        )}
      </div>
    </MetricGraphCard>
  )
}
