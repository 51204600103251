import React from "react"

import {AActivityEvents} from "../../../services/types.generated"
import {ActivitiesGraphCard} from "../components/graph-cards/ActivitiesGraphCard"
import {ActivityOccurrencesGraphCard} from "../components/graph-cards/ActivityOccurrencesGraphCard"
import {ClientsReachedGraphCard} from "../components/graph-cards/ClientsReachedGraphCard"
import {OpportunitiesGraphCard} from "../components/graph-cards/OpportunitiesGraphCard"
import {WonLostGraphCard} from "../components/graph-cards/WonLostGraphCard"
import {useAreMeetingsDisabled, useCurrentSelectionState} from "../hooks"

export const GraphSection: React.FC = () => {
  const {
    value: {metric},
  } = useCurrentSelectionState()

  const areMeetingsDisabled = useAreMeetingsDisabled()

  return (
    <div data-testid={"GraphSection"} className={"flex flex-wrap-reverse gap-4"}>
      <div data-testid={"MetricGraph"} className={"contents"}>
        {metric === "activities" && <ActivitiesGraphCard />}
        {metric === "clients_reached" && <ClientsReachedGraphCard />}
        {metric === "emails_sent" && <ActivityOccurrencesGraphCard activity={AActivityEvents.EmailSent} />}
        {metric === "calls_made" && <ActivityOccurrencesGraphCard activity={AActivityEvents.CallingDone} />}
        {metric === "meetings_done" && !areMeetingsDisabled && (
          <ActivityOccurrencesGraphCard activity={AActivityEvents.MeetingHappened} />
        )}
        {metric === "opportunities" && <OpportunitiesGraphCard />}
      </div>

      <WonLostGraphCard />
    </div>
  )
}
