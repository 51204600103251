import React from "react"
import {useTranslation} from "react-i18next"
import {CheckIcon} from "@heroicons/react/20/solid"

import Button from "../../../../components/Button"
import {ConfirmModal} from "../../../../components/Modal"
import {useApproveProspectsMutation} from "../../../../queries/prospects"
import requestError from "../../../../services/requestError"

export const ApproveDatabaseButton: React.FC<{assignmentId: number}> = ({assignmentId}) => {
  const {t} = useTranslation()

  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const approveProspectsMutation = useApproveProspectsMutation()

  const handleConfirm = React.useCallback(async () => {
    try {
      await approveProspectsMutation.mutateAsync(assignmentId)
      setIsModalVisible(false)
    } catch (e) {
      requestError(e)
    }
  }, [approveProspectsMutation, assignmentId])

  return (
    <>
      <Button fullWidth={false} wrapperClassName={"self-end"} onClick={() => setIsModalVisible(true)}>
        {t("Prospects_ApproveDatabase_Button")}
      </Button>
      <ConfirmModal
        onConfirm={handleConfirm}
        title={t("Prospects_ApproveDatabase_Title")}
        confirmButtonIcon={<CheckIcon className={"h-5 w-5"} />}
        confirmButtonText={t("Prospects_ApproveDatabase_ConfirmButton")}
        isOpen={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        {t("Prospects_ApproveDatabase_Text")}
      </ConfirmModal>
    </>
  )
}
