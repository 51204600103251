import {FC} from "react"
import {useTranslation} from "react-i18next"
import {NotificationCenterItem} from "react-toastify/addons/use-notification-center"
import {TypeOptions} from "react-toastify/dist/types"
import {XMarkIcon} from "@heroicons/react/20/solid"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline"

export const NotificationIcon: FC<{type?: TypeOptions}> = ({type}) => {
  switch (type) {
    case "success":
      return <CheckCircleIcon className={"h-6 w-6 text-cr-green"} aria-hidden={"true"} />

    case "error":
      return <ExclamationCircleIcon className={"h-6 w-6 text-cr-red"} aria-hidden={"true"} />

    case "warning":
      return <ExclamationTriangleIcon className={"h-6 w-6 text-cr-yellow"} aria-hidden={"true"} />

    case "info":
    default:
      return <InformationCircleIcon className={"h-6 w-6 text-cr-blue"} aria-hidden={"true"} />
  }
}

const NotificationCenterToast: FC<{
  notification: NotificationCenterItem<void>
  onClose: () => void
}> = ({notification, onClose}) => {
  const {t} = useTranslation()

  return (
    <div className={"w-full bg-white p-2"}>
      <div className={"flex justify-between"}>
        <div className={"flex"}>
          <div className={"flex-shrink-0 p-2"}>
            <NotificationIcon type={notification.type} />
          </div>
          <div className={"flex items-center"}>
            <p className={"text-sm text-cr-grey-50"}>
              {typeof notification.content === "string" ? notification.content : undefined}
            </p>
          </div>
        </div>

        <div className={"ml-4 flex flex-shrink-0 items-start"}>
          <button
            type={"button"}
            className={
              "inline-flex rounded-md bg-white text-cr-grey-15 transition-colors hover:text-cr-grey-50 focus:outline-none focus:ring-2 focus:ring-cr-blue focus:ring-offset-2"
            }
            onClick={onClose}
          >
            <span className={"sr-only"}>{t("T_Close")}</span>
            <XMarkIcon className={"h-5 w-5"} aria-hidden={"true"} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default NotificationCenterToast
