import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {LanguagePicker} from "../LanguagePicker"
import {Link} from "../Link"

export const Footer: React.FC<{noPadding?: boolean}> = ({noPadding}) => {
  const {t} = useTranslation()

  return (
    <footer className={twMerge("flex w-full justify-center border-t bg-cr-white", !noPadding && "px-6 lg:px-8")}>
      <div
        className={
          "flex w-full max-w-screen-xl flex-col items-center justify-between gap-8 py-6 text-cr-grey-50 sm:flex-row-reverse"
        }
      >
        <div className={"flex flex-row-reverse gap-8 sm:contents"}>
          <Link flipUnderline variant={"neutral"} to={t("Url_Contact")} target={"_blank"}>
            {t("T_Contact us")}
          </Link>
          <Link flipUnderline variant={"neutral"} to={t("Url_PrivacyPolicy")} target={"_blank"}>
            {t("T_Legal")}
          </Link>
          <Link flipUnderline variant={"neutral"} to={"/cookies"}>
            {t("CookiesPage_Link")}
          </Link>
        </div>
        <div className={"flex grow justify-center"}>
          <div className={"inline-block"}>
            <LanguagePicker />
          </div>
        </div>
        <div>{t("Footer_© 2023 CloseRocket. All rights reserved.")}</div>
      </div>
    </footer>
  )
}
