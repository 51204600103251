import React from "react"
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/16/solid"
import {twMerge} from "tailwind-merge"

import {IconButton} from "../Button.tsx"
import {EOrderDirection} from "./shared.ts"

export const OrderButton: React.FC<{direction: EOrderDirection | null; onOrder: () => void}> = ({
  direction,
  onOrder,
}) => {
  return (
    <IconButton
      onClick={onOrder}
      className={twMerge("flex aspect-square flex-col text-cr-grey-30", direction && "!bg-cr-blue-light")}
    >
      <ChevronUpIcon
        className={twMerge("-mt-1 h-4 shrink-0 scale-x-125", direction === EOrderDirection.ASC && "text-cr-blue")}
      />
      <ChevronDownIcon
        className={twMerge("-mt-2 h-4 shrink-0 scale-x-125", direction === EOrderDirection.DESC && "text-cr-blue")}
      />
    </IconButton>
  )
}
