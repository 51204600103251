import React from "react"
import {Trans, useTranslation} from "react-i18next"

import addSalesPlanImg from "../../assets/how-it-works/add-sales-plan.svg"
import approvalImg from "../../assets/how-it-works/approval.svg"
import callingImg from "../../assets/how-it-works/calling.svg"
import closingImg from "../../assets/how-it-works/closing.svg"
import dashboardImg from "../../assets/how-it-works/dashboard.svg"
import emailingImg from "../../assets/how-it-works/emailing.svg"
import matchingImg from "../../assets/how-it-works/matching.svg"
import meetingImg from "../../assets/how-it-works/meeting.svg"
import reportingImg from "../../assets/how-it-works/reporting.svg"
import salesStartImg from "../../assets/how-it-works/sales-start.svg"
import strategyInfoImg from "../../assets/how-it-works/strategy-info.svg"
import updateProfileImg from "../../assets/how-it-works/update-profile.svg"
import {LayoutTitle} from "../../components/LayoutTitle"
import {i18n} from "../../i18n"
import {useDocumentTitle} from "../../utils/hooks"
import {commonTransComponents} from "../../utils/i18n"
import {ArrowCircle} from "./ArrowCircle"
import {Item, TItem} from "./Item"
import {SectionHeader, SectionSubheader} from "./Section"

export const HowItWorks: React.FC = () => {
  const {t} = useTranslation()
  useDocumentTitle(t("HowItWorks_How does it work"))

  return (
    <div className={"bg-cr-white"}>
      <LayoutTitle
        header={t("HowItWorks_How does it work")}
        subheader={<Trans i18nKey={"HowItWorks_subheader"} components={commonTransComponents} />}
      />

      <div className={"flex flex-col items-stretch pb-20 pt-10"}>
        <SectionHeader>{t("HowItWorks_Before we start selling")}</SectionHeader>

        <Item {...items.profile} odd />

        <ArrowCircle>
          <Item {...items.salesPlan} arrow />
          <Item {...items.strategy} odd arrow />

          <SectionSubheader>{t("HowItWorks_Repeats with every new sales plan")}</SectionSubheader>

          <Item {...items.dashboard} arrow />
          <Item {...items.matching} odd arrow />
        </ArrowCircle>

        <ArrowCircle>
          <Item {...items.approval} arrow />

          <SectionSubheader>{t("HowItWorks_Repeats every month")}</SectionSubheader>

          <Item {...items.salesStart} odd arrow last />
        </ArrowCircle>

        <SectionHeader>{t("HowItWorks_Monthly sales process")}</SectionHeader>

        <Item {...items.emailing} />
        <Item {...items.calling} odd />
        <Item {...items.meeting} />
        <Item {...items.closing} odd />
        <Item {...items.reporting} last />
      </div>
    </div>
  )
}

const items = {
  profile: {
    when: i18n.t("HowItWorks_After sign up"),
    title: i18n.t("HowItWorks_Update profile"),
    description: i18n.t(
      "HowItWorks_Having an awesome company profile will help us to match you with the best sales talents in your segment."
    ),
    image: updateProfileImg,
  },
  salesPlan: {
    when: i18n.t("HowItWorks_Anytime"),
    title: i18n.t("HowItWorks_Add sales plan and products"),
    description: i18n.t(
      "HowItWorks_Add products info and choose the sales plan that suits you best, depending on how many prospects you want to reach per month."
    ),
    image: addSalesPlanImg,
  },
  strategy: {
    when: i18n.t("HowItWorks_Before the sales plan is started"),
    title: i18n.t("HowItWorks_Fill out strategy info"),
    description: i18n.t(
      "HowItWorks_Describe your products and provide us with the information needed for building the right sales strategy."
    ),
    image: strategyInfoImg,
  },
  dashboard: {
    when: i18n.t("HowItWorks_After the sales plan is set up"),
    title: i18n.t("T_Dashboard"),
    description: i18n.t("HowItWorks_Check the dashboard to see the next steps or to download the strategy."),
    image: dashboardImg,
  },
  matching: {
    when: i18n.t("HowItWorks_The next 15th day of the month"),
    title: i18n.t("HowItWorks_Matching with sales talents"),
    description: (
      <div>
        {i18n.t("HowItWorks_We match you with the best sales talents on market.")}
        <div className={"text-cr-grey-65"}>
          {i18n.t(
            "HowItWorks_NOTE After a successful matching, you should create an email address for each sales talent within 2 days."
          )}
        </div>
      </div>
    ),
    image: matchingImg,
  },
  approval: {
    when: i18n.t("HowItWorks_Up to 10 days from payment"),
    title: i18n.t("HowItWorks_Prospects database approval"),
    description: i18n.t(
      "HowItWorks_We will create the database for your approval with the contacts of companies from your target audience."
    ),
    image: approvalImg,
  },
  salesStart: {
    when: i18n.t("HowItWorks_1st day of the next month"),
    title: i18n.t("HowItWorks_Sales starting"),
    description: i18n.t("HowItWorks_Sales talents are ready to start emailing, calling, selling, and closing."),
    image: salesStartImg,
  },
  emailing: {
    when: i18n.t("HowItWorks_Week 01"),
    title: i18n.t("HowItWorks_FirstOutreach"),
    description: i18n.t("HowItWorks_The first phase starts with emailing to whole list of prospects."),
    image: emailingImg,
  },
  calling: {
    when: i18n.t("HowItWorks_Week 02"),
    title: i18n.t("HowItWorks_SecondOutreach"),
    description: i18n.t(
      "HowItWorks_The highest conversion rates usually comes from calling, therefore we start calling right after emailing phase."
    ),
    image: callingImg,
  },
  meeting: {
    when: i18n.t("HowItWorks_Week 03"),
    title: i18n.t("HowItWorks_PresentationDemo"),
    description: i18n.t("HowItWorks_We set up and go to personal meeting and online meetings with potential leads."),
    image: meetingImg,
  },
  closing: {
    when: i18n.t("HowItWorks_Week 01 - 04"),
    title: i18n.t("HowItWorks_Closing deals"),
    description: i18n.t(
      "HowItWorks_To ensure the biggest conversion rates we send price offers and order agreements and negotiate terms of cooperation."
    ),
    image: closingImg,
  },
  reporting: {
    when: i18n.t("HowItWorks_1st day of the next month"),
    title: i18n.t("HowItWorks_Reporting"),
    description: i18n.t(
      "HowItWorks_To have a structured overview of our results, reports with all data and conversion statistics will come to you on monthly basis."
    ),
    image: reportingImg,
  },
} as const satisfies {[key: string]: TItem}
