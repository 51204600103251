import React from "react"

import {Flag} from "../../../components/Flag.tsx"
import {ACompanyUserDashboardDraftedSalesCycle} from "../../../services/types.generated.ts"

export const SellingHeader: React.FC<{
  salesCycle: Pick<ACompanyUserDashboardDraftedSalesCycle, "country" | "name">
  children?: React.ReactNode
}> = ({salesCycle, children}) => {
  return (
    <div className={"flex flex-wrap justify-between gap-4"}>
      <div className={"flex items-center gap-4"}>
        <Flag code={salesCycle.country.code} className={"card-shadow h-5 w-7 rounded-md"} />
        <h3 className={"text-2xl font-semibold"}>{salesCycle.name}</h3>
      </div>
      <div className={"flex grow items-center justify-end gap-4"}>{children}</div>
    </div>
  )
}
