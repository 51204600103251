import React from "react"
import {useTranslation} from "react-i18next"
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {Link} from "../../components/Link"
import {SellingOffer} from "./SellingOffer"
import {useSalesPersonDashboardQuery} from "./utils"

export const SellingOffers: React.FC = () => {
  const {t} = useTranslation()

  const {data, refetch} = useSalesPersonDashboardQuery()
  const assignmentOffers = data?.data.dashboard?.assignment_offers

  const [currentOfferIndex, setCurrentOfferIndex] = React.useState(0)
  const currentOffer = React.useMemo(() => {
    return assignmentOffers?.[currentOfferIndex]
  }, [currentOfferIndex, assignmentOffers])

  React.useEffect(() => {
    if (currentOfferIndex === assignmentOffers?.length) {
      setCurrentOfferIndex(i => i - 1)
    }
  }, [currentOfferIndex, assignmentOffers?.length])

  if (!assignmentOffers?.length || !currentOffer) {
    return null
  }

  return (
    <div className={"flex justify-center bg-cr-blue-super-light px-6 py-16 text-left lg:px-8"}>
      <div className={"flex w-full max-w-screen-xl flex-col gap-10"}>
        <h1 className={"text-3xl font-semibold text-cr-blue"}>
          {t("SalespersonDashboard_NewOffer_Title", {count: assignmentOffers.length})}
        </h1>

        <div className={"flex items-stretch gap-0"}>
          {assignmentOffers.length > 1 && (
            <ChangeOfferArrow
              disabled={currentOfferIndex === 0}
              onChange={() => setCurrentOfferIndex(index => index - 1)}
            />
          )}

          <div className={"flex min-w-0 shrink grow flex-col gap-7"}>
            <SellingOffer offer={currentOffer} refetch={refetch} />

            <Indicators
              currentOfferIndex={currentOfferIndex}
              numOffers={assignmentOffers.length}
              onChange={setCurrentOfferIndex}
            />
          </div>

          {assignmentOffers.length > 1 && (
            <ChangeOfferArrow
              right
              disabled={currentOfferIndex === assignmentOffers.length - 1}
              onChange={() => setCurrentOfferIndex(index => index + 1)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export const ChangeOfferArrow: React.FC<{right?: boolean; disabled?: boolean; onChange: () => void}> = ({
  onChange,
  right,
  disabled,
}) => {
  const Icon = right ? ChevronRightIcon : ChevronLeftIcon

  return (
    <Link
      className={twMerge([
        "shrink-0 grow-0 cursor-pointer text-cr-blue transition-all hover:text-cr-blue-dark",
        disabled && "pointer-events-none text-transparent",
      ])}
      onClick={onChange}
    >
      <div className={"flex h-full w-full items-center justify-center"}>
        <Icon className={"h-10 w-10"} />
      </div>
    </Link>
  )
}

export const Indicators: React.FC<{
  numOffers: number
  currentOfferIndex: number
  onChange: (offerIndex: number) => void
}> = ({numOffers, currentOfferIndex, onChange}) => {
  if (numOffers < 2) {
    return null
  }

  return (
    <div className={"flex justify-center gap-2"}>
      {Array.from({length: numOffers}).map((_, i) => (
        <Link
          key={i}
          className={twMerge([
            "aspect-square w-3 rounded-full bg-cr-grey-15 transition-all",
            i === currentOfferIndex && "bg-cr-blue",
          ])}
          onClick={() => onChange(i)}
        />
      ))}
    </div>
  )
}
