import {useTranslation} from "react-i18next"

import CheckboxesAdd from "../../components/formElements/CheckboxesAdd"
import {i18n} from "../../i18n"

const optionsDefault = [
  i18n.t("SalesStrategy_Saving time"),
  i18n.t("SalesStrategy_Saving money"),
  i18n.t("SalesStrategy_Saving on human capacity"),
  i18n.t("SalesStrategy_Earning more money"),
  i18n.t("SalesStrategy_Making process more efficient"),
]

const Benefits = () => {
  const {t} = useTranslation()

  return (
    <CheckboxesAdd
      name={"product_benefits"}
      label={t("SalesStrategy_Benefits of your product")}
      legend={t("SalesStrategy_Describe how your solution makes better life for target audience. Choose max 3. items")}
      inputLabel={t("SalesStrategy_Add your own")}
      optionsDefault={optionsDefault}
      disabledAdd={value => value.length >= 3}
    />
  )
}

export default Benefits
