import React from "react"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import {Badge} from "../../../components/Badge.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {useCompanyDashboardPausedCyclesQuery} from "../../../queries/companyDashboard.ts"
import {ASalesCycleStatuses} from "../../../services/types.generated.ts"
import {enumTranslKey} from "../../../utils/i18n.tsx"
import {SimpleSelling} from "../components/SimpleSelling.tsx"

export const PausedTab: React.FC = () => {
  const {t} = useTranslation()

  const {data, error, refetch} = useCompanyDashboardPausedCyclesQuery()

  return error ? (
    <GenericErrorAlert retry={refetch} />
  ) : data ? (
    <div className={"flex flex-col gap-10"}>
      {data.map(salesCycle => (
        <SimpleSelling
          key={salesCycle.id}
          salesCycle={salesCycle}
          badge={<Badge color={"grey"}>{t(enumTranslKey("ASalesCycleStatuses", ASalesCycleStatuses.Paused))}</Badge>}
        />
      ))}
    </div>
  ) : (
    <Loading size={"xl"} fullSpace />
  )
}
