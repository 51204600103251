import React from "react"

import {AutocompleteFilterContent} from "../../../../components/AutocompleteFilter.tsx"
import {EnumFilterContent} from "../../../../components/EnumFilter.tsx"
import {useTableContext} from "../../../../components/Table/shared.ts"
import {getReportsProspectsAutocompleteQuery} from "../../../../queries/reports.ts"
import {
  ACompanyUserSalesCycleProspects,
  ASalesCycleProspectAutocompleteFields,
} from "../../../../services/types.generated.ts"
import {enumToOptions} from "../../../../utils/i18n.tsx"
import {
  ProspectsFilteringContext,
  TProspectsTableColumn,
  TProspectsTableMetadata,
  TStatusOption,
  useCurrentSelectionState,
} from "../../hooks.ts"

export const SegmentFilter: React.FC = () => {
  const {
    value: {salesCycleId},
  } = useCurrentSelectionState()
  const {segmentFilter, setSegmentFilter} = ProspectsFilteringContext.useContext()

  const autocompleteQuery = getReportsProspectsAutocompleteQuery(
    salesCycleId,
    ASalesCycleProspectAutocompleteFields.Segment
  )

  return (
    <AutocompleteFilterContent
      useAutocompleteQuery={autocompleteQuery}
      onChange={setSegmentFilter}
      value={segmentFilter}
    />
  )
}

export const StageFilter: React.FC = () => {
  const {stageFilter, setStageFilter} = ProspectsFilteringContext.useContext()
  const {
    metadata: {stageOptions},
  } = useTableContext<TProspectsTableColumn, ACompanyUserSalesCycleProspects, TProspectsTableMetadata>()

  return <EnumFilterContent options={stageOptions} multiple value={stageFilter} onChange={setStageFilter} />
}

const statusOptions = enumToOptions("ProspectStatus") as TStatusOption[]
export const StatusFilter: React.FC = () => {
  const {statusFilter, setStatusFilter} = ProspectsFilteringContext.useContext()

  return <EnumFilterContent options={statusOptions} multiple value={statusFilter} onChange={setStatusFilter} />
}
