import React from "react"
import {useTranslation} from "react-i18next"
import {CheckIcon} from "@heroicons/react/20/solid"
import {LinkIcon} from "@heroicons/react/24/solid"

import Button from "../../../components/Button.tsx"
import {ConfirmModal} from "../../../components/Modal.tsx"
import {useSyncWithCrmMutation} from "../../../queries/prospects.ts"
import requestError from "../../../services/requestError.tsx"

export const CRMButton: React.FC<{assignmentId: number}> = ({assignmentId}) => {
  const {t} = useTranslation()

  const syncWithCrmMutation = useSyncWithCrmMutation()

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const handleConfirm = React.useCallback(async () => {
    try {
      await syncWithCrmMutation.mutateAsync(assignmentId)
    } catch (e) {
      requestError(e)

      return false
    }
  }, [assignmentId, syncWithCrmMutation])

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} iconLeft={<LinkIcon className={"h-4 w-4"} />}>
        {t("Prospects_CRMSync_Button")}
      </Button>

      <ConfirmModal
        onConfirm={handleConfirm}
        title={t("Prospects_CRMSync_Title")}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        confirmButtonText={t("Prospects_CRMSync_ConfirmButton")}
        confirmButtonIcon={<CheckIcon className={"h-5 w-5"} />}
      >
        {t("Prospects_CRMSync_Text")}
      </ConfirmModal>
    </>
  )
}
