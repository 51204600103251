import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"

import api, {queryKey} from "../services"
import {AOrder, Subscriptions} from "../services/types.generated"

export function useCheckoutQuery(id: number) {
  return useQuery({
    queryKey: queryKey.checkoutDetail(id),
    queryFn: async () => (await api.subscriptions.checkoutDetail(id)).data.order,
  })
}

export function useCheckoutPreviewQuery(id: number) {
  return useQuery({
    queryKey: queryKey.checkoutPreview(id),
    queryFn: async () => (await api.subscriptions.checkoutPreviewDetail(id)).data.preview_order,
  })
}

export function useUpdateCheckoutQueryCache() {
  const queryClient = useQueryClient()

  return (order: AOrder) => {
    queryClient.setQueryData(queryKey.checkoutDetail(order.id), () => order)
  }
}

export function useUpdateCheckoutMutation() {
  const updateCheckoutQueryCache = useUpdateCheckoutQueryCache()

  return useMutation({
    mutationFn: async ({orderId, order}: {orderId: number; order: Subscriptions.CheckoutUpdate.RequestBody["order"]}) =>
      api.subscriptions.checkoutUpdate(orderId, {order}),
    mutationKey: queryKey.checkoutUpdate,
    onSuccess: response => updateCheckoutQueryCache(response.data.order),
  })
}

export type TCheckoutCreateRequestBody = NonNullable<Subscriptions.CheckoutCreate.RequestBody["order"]>
export type TCheckoutCreateMutationParam = {orderId: number; data: TCheckoutCreateRequestBody}
export function useCheckoutCreateMutation() {
  const updateCheckoutQueryCache = useUpdateCheckoutQueryCache()

  return useMutation({
    mutationFn: async ({orderId, data}: TCheckoutCreateMutationParam) =>
      api.subscriptions.checkoutCreate(orderId, {order: data}),
    onSuccess: response => {
      if (!response.data.order) {
        return
      }

      return updateCheckoutQueryCache(response.data.order)
    },
  })
}

export function useCheckoutCreateMutationNew() {
  const updateCheckoutQueryCache = useUpdateCheckoutQueryCache()

  return useMutation({
    mutationFn: async ({
      packages,
      salesCycleId,
    }: {
      salesCycleId: number
      packages: Array<{packageId: number; salesPersonId: number | undefined}>
    }) =>
      api.subscriptions.checkoutCreate2({
        checkout: {
          sales_cycle_id: salesCycleId,
          packages: packages.map(pkg => ({package_id: pkg.packageId, sales_person_id: pkg.salesPersonId})),
        },
      }),
    onSuccess: response => {
      return updateCheckoutQueryCache(response.data.order)
    },
  })
}
