import React, {HTMLInputTypeAttribute, InputHTMLAttributes} from "react"
import {FieldPath, FieldValues, useFormContext} from "react-hook-form"
import {EnvelopeIcon, KeyIcon} from "@heroicons/react/24/outline"
import {debounce} from "lodash"
import {twMerge} from "tailwind-merge"

import {pickProps} from "../../utils/types"
import {Loading} from "../Loading"
import {TBaseSharedProps, TConnectedField, useGetFieldVisibleError} from "./components"
import {FieldLabel, pickFieldLabelProps, TFieldLabelProps} from "./FieldLabel"

const inputTypeIconMap = {
  email: EnvelopeIcon,
  password: KeyIcon,
} as const

export type TInputSharedProps = TBaseSharedProps & {
  Icon?: React.ComponentType | "loading" | false
  children?: React.ReactNode
  inputClassName?: string
  borderClassName?: string
  debounced?: boolean | number
  ghost?: boolean | "hover"
  type?: HTMLInputTypeAttribute
  placeholder?: string
}

export type TInputConnectedProps<T extends FieldValues, N extends FieldPath<T>> = TInputSharedProps &
  TConnectedField<T, N>

export type TInputBaseProps = TInputSharedProps & InputHTMLAttributes<HTMLInputElement>

const DEFAULT_DEBOUNCE_TIME = 500

export const InputBase = React.forwardRef<HTMLInputElement, TInputBaseProps>(
  (
    {
      Icon: IconProp,
      children,
      ghost,
      hasError,
      inputClassName,
      borderClassName,
      id: idProp,
      onChange,
      debounced,
      ...props
    },
    ref
  ) => {
    const id = idProp ?? props.name

    const Icon = React.useMemo(() => {
      if (IconProp === false) {
        return undefined
      }

      if (IconProp === undefined) {
        return inputTypeIconMap[props.type as keyof typeof inputTypeIconMap]
      }

      if (IconProp === "loading") {
        return LoadingIcon
      }

      return IconProp
    }, [IconProp, props.type])

    const handleChange = React.useMemo(() => {
      if (onChange && debounced) {
        return debounce(onChange, typeof debounced === "number" ? debounced : DEFAULT_DEBOUNCE_TIME)
      }

      return onChange
    }, [debounced, onChange])

    return (
      <div
        className={twMerge([
          "input-border relative flex items-center gap-0 overflow-hidden p-0",
          ghost === true && "input-border-ghost",
          ghost === "hover" && "input-border-ghost-hover",
          (props.readOnly || props.disabled) && "input-border-readonly",
          hasError && "input-border-error",
          borderClassName,
        ])}
      >
        {Icon && (
          <div className={"pointer-events-none flex shrink items-center justify-center pl-3"}>
            <Icon className={"size-5 text-cr-grey-50"} aria-hidden={"true"} />
          </div>
        )}
        <input
          ref={ref}
          {...props}
          id={id}
          className={twMerge(["w-full grow border-none bg-transparent", inputClassName])}
          onChange={handleChange}
        />
        {children && <div className={"flex items-center justify-center"}>{children}</div>}
      </div>
    )
  }
)
InputBase.displayName = "Input"

export const LoadingIcon: React.FC<{className?: string}> = ({className}) => {
  return <Loading size={"xs"} color={"gray"} delayShow={false} containerClassName={className} />
}

export function InputConnected<T extends FieldValues, N extends FieldPath<T>>({
  name,
  options,
  ...props
}: TInputConnectedProps<T, N>) {
  const {register} = useFormContext<T>()
  const hasError = !!useGetFieldVisibleError(name)

  return <InputBase {...register(name, options)} hasError={hasError} {...props} />
}

export function InputField<T extends FieldValues, N extends FieldPath<T>>(
  props: TFieldLabelProps & TInputConnectedProps<T, N>
) {
  const fieldLabelProps = pickFieldLabelProps(props)
  const inputProps = pickInputConnectedProps(props)

  return (
    <FieldLabel {...fieldLabelProps}>
      <InputConnected {...inputProps} />
    </FieldLabel>
  )
}

function pickInputConnectedProps<T extends FieldValues, N extends FieldPath<T>>(
  props: React.ComponentProps<typeof InputField<T, N>>
) {
  return pickProps<TInputConnectedProps<T, N>>({
    name: true,
    hasError: true,
    debounced: true,
    Icon: true,
    borderClassName: true,
    ghost: true,
    children: true,
    options: true,
    inputClassName: true,
    type: true,
    placeholder: true,
    readOnly: true,
    disabled: true,
  })(props)
}
