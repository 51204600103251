import React from "react"
import {useTranslation} from "react-i18next"
import {useNotificationCenter} from "react-toastify/addons/use-notification-center"
import {BellIcon} from "@heroicons/react/24/outline"

import {MenuButton} from "../../components/Layout/Header/MenuButton.tsx"
import {Menu} from "../../components/Menu"
import {MenuItem} from "../../components/Menu/MenuItem"
import NotificationCenterToast from "./NotificationCenterToast"

const NotificationsRenderer: React.FC = () => {
  const {t} = useTranslation()
  const {notifications, remove} = useNotificationCenter<void>()

  return (
    <Menu
      Button={({isOpen}) => (
        <MenuButton isOpen={isOpen}>
          <BellIcon className={"size-full"} title={t("Header_Notifications")} />
        </MenuButton>
      )}
      items={[
        notifications.length === 0 && (
          <p className={"py-6 text-center font-black text-cr-grey-30"}>{t("Header_NoNotifications")}</p>
        ),
        ...notifications.map(notification => (
          <MenuItem key={notification.id}>
            <NotificationCenterToast notification={notification} onClose={() => remove(notification.id)} />
          </MenuItem>
        )),
      ]}
    />
  )
}

export default NotificationsRenderer
