import React from "react"
import {twMerge} from "tailwind-merge"

import {TColumnMeta, TColumns} from "./shared"

export type TCellValueProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
  row: TRowData
}

export type TCellProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
  row: TRowData
  className?: string
}

function DefaultCellValue<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  row,
}: TCellValueProps<TCol, TRowData>): React.ReactNode {
  return row[columnMeta.column] ?? "-"
}

export function DefaultCell<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  row,
  className,
}: TCellProps<TCol, TRowData>): React.ReactNode {
  const Value: React.ComponentType<TCellValueProps<TCol, TRowData>> = columnMeta.CellValue ?? DefaultCellValue
  Value.displayName = Value.displayName ?? "CustomValue"

  const align = columnMeta.align ?? "left"
  const verticalAlign = columnMeta.verticalAlign ?? "center"

  return (
    <div
      className={twMerge([
        `transition-all group-hover/row:bg-cr-blue-super-light`,
        "px-2 py-2.5 text-sm font-normal empty:px-0 md:px-6 md:py-4",
        "border-b border-b-cr-blue-super-light group-last/row:border-b-0 group-first-of-type/row:border-t group-first-of-type/row:border-t-cr-blue-light",
        "flex",
        align === "left" && "justify-start",
        align === "center" && "justify-center",
        align === "right" && "justify-end",
        verticalAlign === "top" && "items-start",
        verticalAlign === "center" && "items-center",
        verticalAlign === "bottom" && "items-end",
        className,
      ])}
    >
      <Value row={row} columnMeta={columnMeta} />
    </div>
  )
}
