import React from "react"

export type TChartDataItem = {
  id: string | number
  value: number
  label: string
  color: string
}

export const DataLegend: React.FC<{data: TChartDataItem[]; isPercentage?: boolean; precision?: number}> = ({
  data,
  isPercentage,
  precision = 2,
}) => {
  const total = React.useMemo(() => {
    return data.reduce((acc, currentValue) => acc + currentValue.value, 0)
  }, [data])

  return (
    <div className={"flex gap-8"}>
      {data.map(item => (
        <LegendItem
          key={item.label}
          label={item.label}
          color={item.color}
          value={Number((isPercentage ? (item.value / total) * 100 : item.value).toFixed(precision))}
          isPercentage={isPercentage}
        />
      ))}
    </div>
  )
}

export const LegendItem: React.FC<{label: string; color: string; value?: number; isPercentage?: boolean}> = ({
  label,
  value,
  color,
  isPercentage,
}) => {
  return (
    <div className={"flex items-center gap-2 text-sm"}>
      <div className={"size-4 rounded-full"} style={{backgroundColor: color}} />
      <div className={"whitespace-nowrap"}>
        {value != null && (
          <span className={"font-bold"}>
            {value}
            {isPercentage && "%"}
          </span>
        )}{" "}
        {label}
      </div>
    </div>
  )
}
