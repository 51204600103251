import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {produce} from "immer"

import api, {multipartRequest, queryKey} from "../services"
import {ACompanyAttributes, ACompanyProfile} from "../services/types.generated"

export function useCompanyQuery(id: number) {
  return useQuery({
    queryKey: queryKey.company(id),
    queryFn: async () => (await api.companies.companiesDetail(id)).data.company,
  })
}

export function useCompanyWelcomeScreenQuery(id: number) {
  return useQuery({
    queryKey: queryKey.companyWelcomeScreen(id),
    queryFn: async () => (await api.companyUsers.welcomeScreenDetail(id)).data.welcome_screen,
    staleTime: 0,
  })
}

export function useUpdateCompanyPictureMutation() {
  const queryClient = useQueryClient()
  const updateCompanyQueryCachePicture = useUpdateCompanyQueryCachePicture()

  return useMutation({
    // A multipart request has to avoid default axios processing
    // in order to provide proper format for Rails (see wrapForMultipart()).
    // This means we have to use a custom request instead of api.companies.companiesPartialUpdate()
    mutationFn: async ({companyId, file}: {companyId: number; file: File}) => {
      const response = await multipartRequest({
        method: "PATCH",
        path: `companies/${companyId}`,
        data: {company: {profile: {profile_picture: file}}},
      })

      queryClient.refetchQueries({queryKey: queryKey.userSettings})

      return response
    },
    onSuccess: response => updateCompanyQueryCachePicture(response.data.company.id, response.data.company.profile),
  })
}

export function useUpdateCompanyProfileMutation() {
  const updateCompanyQueryCacheProfile = useUpdateCompanyQueryCacheProfile()

  return useMutation({
    mutationFn: async ({
      companyId,
      profile,
    }: {
      companyId: number
      profile: NonNullable<ACompanyAttributes["profile"]>
    }) => {
      const transformedData = Object.entries(profile).reduce(
        (acc, [key, value]) => {
          // Drop keys without a value
          if (value == null || value === "" || Number.isNaN(value)) {
            return acc
          }

          return {...acc, [key]: value}
        },
        {} as typeof profile
      )

      return api.companies.companiesPartialUpdate(companyId, {company: {profile: transformedData}})
    },
    onSuccess: response => updateCompanyQueryCacheProfile(response.data.company.id, response.data.company.profile),
  })
}

export function useUpdateCompanyMembersMutation() {
  const updateCompanyQueryCacheMembers = useUpdateCompanyQueryCacheMembers()

  return useMutation({
    mutationFn: async ({
      companyId,
      members,
    }: {
      companyId: number
      members: NonNullable<ACompanyAttributes["members"]>[0]
    }) => {
      const transformedData = Object.entries(members).reduce(
        (acc, [key, value]) => {
          // Drop keys without a value
          if (value == null || value === "" || Number.isNaN(value)) {
            return acc
          }

          return {...acc, [key]: value}
        },
        {} as typeof members
      )

      return api.companies.companiesPartialUpdate(companyId, {company: {members: [transformedData]}})
    },
    onSuccess: response => updateCompanyQueryCacheMembers(response.data.company.id, response.data.company.members),
  })
}

export function useUpdateCompanyQueryCachePicture() {
  const queryClient = useQueryClient()

  return (id: number, profile: ACompanyProfile["profile"]) => {
    queryClient.setQueryData(queryKey.company(id), (cachedCompany: ReturnType<typeof useCompanyQuery>["data"]) => {
      if (!cachedCompany) {
        return
      }

      return produce(cachedCompany, draft => {
        draft.profile.profile_picture_url = profile.profile_picture_url
        draft.profile.profile_picture_thumbnail_url = profile.profile_picture_thumbnail_url
      })
    })
  }
}

export function useUpdateCompanyQueryCacheProfile() {
  const queryClient = useQueryClient()

  return (id: number, profile: ACompanyProfile["profile"]) => {
    queryClient.setQueryData(queryKey.company(id), (cachedCompany: ReturnType<typeof useCompanyQuery>["data"]) => {
      if (!cachedCompany) {
        return
      }

      return produce(cachedCompany, draft => {
        draft.profile = profile
      })
    })
  }
}

export function useUpdateCompanyQueryCacheMembers() {
  const queryClient = useQueryClient()

  return (id: number, members: ACompanyProfile["members"]) => {
    queryClient.setQueryData(queryKey.company(id), (cachedCompany: ReturnType<typeof useCompanyQuery>["data"]) => {
      if (!cachedCompany) {
        return
      }

      return produce(cachedCompany, draft => {
        draft.members = members
      })
    })
  }
}

export function useRegisterCompanyMutation() {
  return useMutation({
    mutationFn: ({email}: {email: string}) => api.companyUsers.companyUsersCreate({company_user: {email}}),
  })
}

export function useRegisterCompanyResendMutation() {
  return useMutation({
    mutationFn: ({email}: {email: string}) => api.companyUsers.confirmationCreate({company_user: {email}}),
  })
}

export function useVerifyCompanyMutation() {
  return useMutation({
    mutationFn: (data: Parameters<typeof api.companyUsers.confirmationPartialUpdate>[0]) =>
      api.companyUsers.confirmationPartialUpdate(data),
  })
}
