import React from "react"
import {useTranslation} from "react-i18next"
import {PlayCircleIcon} from "@heroicons/react/24/outline"
import {StarIcon} from "@heroicons/react/24/solid"

import {UnstyledLink} from "../../../components/Link.tsx"
import {ProfileImage} from "../../../components/ProfileImage.tsx"
import {EFeature, useIsFeatureEnabled} from "../../../utils/featureFlags.ts"
import {isPlaceholderSalesperson, TSalesPerson} from "../utils.ts"

export const SalespersonCard: React.FC<{
  salesPerson: TSalesPerson
  onOpenVideo: () => void
  children: React.ReactNode
  cyanRibbonText?: React.ReactNode
}> = ({salesPerson, children, onOpenVideo, cyanRibbonText}) => {
  const {t} = useTranslation()

  const isPlaceholder = isPlaceholderSalesperson(salesPerson)
  const isRatingVisible = useIsFeatureEnabled(EFeature.SHOW_SALESPERSON_RATINGS)

  return (
    <div className={"card-shadow inline-flex w-96 shrink-0 flex-col overflow-hidden"} data-testid={"salesperson-card"}>
      <div className={"relative flex h-44"}>
        <ProfileImage
          src={salesPerson.profile_picture_url ?? undefined}
          rectangular
          className={"w-full bg-cr-blue-mid text-cr-white"}
        />

        <div className={"absolute -right-2 top-4 flex flex-col items-end gap-1"}>
          {cyanRibbonText && <div className={"rounded-lg bg-cr-cyan p-2 pr-4 text-sm"}>{cyanRibbonText}</div>}
          {salesPerson.embed_video_id && (
            <UnstyledLink
              className={
                "flex cursor-pointer items-center gap-1 rounded-lg bg-cr-blue-light p-2 pr-4 text-sm text-cr-blue"
              }
              onClick={onOpenVideo}
            >
              <PlayCircleIcon className={"size-4"} />
              {t("CreateSalesCycle_SalespersonCard_VideoRibbon")}
            </UnstyledLink>
          )}
        </div>

        <div
          className={
            "cr-shadow absolute bottom-0 left-1/2 inline-flex -translate-x-1/2 translate-y-1/2 items-center gap-3 rounded-full bg-cr-blue-dark px-8 py-2 text-cr-white"
          }
        >
          <div className={"flex items-center gap-2"}>
            {!isPlaceholder && <div className={"size-2 rounded-full bg-cr-cyan"} />}
            <span
              className={"max-w-52 overflow-hidden text-ellipsis font-bold"}
              title={salesPerson.first_name ?? undefined}
            >
              {salesPerson.first_name}
            </span>
          </div>

          {!isPlaceholder && isRatingVisible && (
            <>
              <div className={"h-4 w-px bg-cr-white"} />

              <div className={"flex items-center gap-2"}>
                <StarIcon className={"size-4 text-cr-yellow"} />
                <span className={"text-sm"}>{salesPerson.rating.toFixed(1)}</span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={"flex grow flex-col p-6 pt-8"}>{children}</div>
    </div>
  )
}
