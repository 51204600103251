import {twMerge} from "tailwind-merge"

type TSize = "xs" | "sm" | "md" | "xl"
type TColor = "default" | "white" | "gray"

type TProps = {
  size?: TSize
  color?: TColor
  fullSpace?: boolean
  delayShow?: boolean
  containerClassName?: string
}

const sizePropTable = {
  xs: {container: "w-4 h-4", spinner: "border-2"},
  sm: {container: "w-6 h-6", spinner: "border-2"},
  md: {container: "w-8 h-8", spinner: "border-4"},
  xl: {container: "w-16 h-16", spinner: "border-4"},
} as const satisfies {[sizeProp in TSize]: {container: string; spinner: string}}

const colorPropTable = {
  default: "border-cr-blue/30 border-r-cr-blue",
  white: "border-cr-white/30 border-r-cr-white",
  gray: "border-cr-grey-80/30 border-r-cr-grey-80",
} as const satisfies {[sizeProp in TColor]: string}

export const Loading: React.FC<TProps> = ({
  size = "md",
  color = "default",
  fullSpace = true,
  delayShow = true,
  containerClassName,
}) => {
  return (
    <div
      className={twMerge([
        "flex",
        fullSpace && "h-full w-full grow items-center justify-center",
        delayShow && "animate-loading-fade-in",
        containerClassName,
      ])}
      data-testid={"loading"}
    >
      <div className={twMerge(["relative inline-block animate-loading-container", sizePropTable[size].container])}>
        <div
          className={twMerge([
            "h-full w-full animate-loading-spinner rounded-full border-solid",
            sizePropTable[size].spinner,
            colorPropTable[color],
          ])}
        />
      </div>
    </div>
  )
}
