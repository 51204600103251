import React from "react"
import {twMerge} from "tailwind-merge"

import {TUnstyledLinkProps, UnstyledLink} from "./Link"

export const TabsContainer: React.FC<{children: React.ReactNode; className?: string; size?: "sm" | "lg"}> = ({
  className,
  children,
  size = "lg",
}) => {
  return (
    <div
      className={twMerge(
        "flex min-h-14 w-full items-stretch gap-10 overflow-auto border-b border-b-cr-grey-15",
        size === "sm" && "text-sm font-medium",
        size === "lg" && "text-base font-semibold",
        className
      )}
    >
      {children}
    </div>
  )
}

export const TabItem = React.forwardRef<
  HTMLAnchorElement,
  {isActive: boolean; children: React.ReactNode} & TUnstyledLinkProps
>(({isActive, children, className, disabled, ...linkProps}, ref) => {
  const isClickable = !disabled && (!!linkProps.onClick || !!linkProps.to)

  return (
    <UnstyledLink
      ref={ref}
      className={twMerge(
        "flex shrink-0 cursor-default items-center justify-center border-b-2 border-b-transparent text-cr-grey-50 transition-all",
        isClickable && "cursor-pointer",
        isClickable && !isActive && "hover:border-b-cr-grey-15 hover:text-cr-grey-90",
        isActive && "border-b-cr-blue text-cr-blue",
        className
      )}
      role={"tab"}
      aria-selected={isActive}
      disabled={disabled}
      {...linkProps}
    >
      {children}
    </UnstyledLink>
  )
})
TabItem.displayName = "TabItem"
