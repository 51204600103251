import React from "react"
import {useTranslation} from "react-i18next"

import {InputField} from "../../../../components/fields/Input.tsx"

export const DetailsPage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <>
      <InputField
        name={"companyName"}
        variant={"medium"}
        label={t("Signup_Company_AccountCreatePage_CompanyName")}
        placeholder={t("Signup_Company_AccountCreatePage_CompanyNamePlaceholder")}
      />
      <InputField
        name={"phoneNumber"}
        variant={"medium"}
        label={t("Signup_Company_AccountCreatePage_PhoneNumber")}
        placeholder={t("Signup_Company_AccountCreatePage_PhoneNumberPlaceholder")}
      />
    </>
  )
}
