import React from "react"
import {useTranslation} from "react-i18next"
import {DocumentArrowDownIcon} from "@heroicons/react/24/outline"

import {Link} from "../../../components/Link"
import {useReportsSalesCycleQuery} from "../../../queries/reports"
import {useCurrentSelectionState} from "../hooks"

export const DownloadReportButton = () => {
  const {t} = useTranslation()
  const {
    value: {salesCycleId, iterationId},
  } = useCurrentSelectionState()

  const {data, isLoading} = useReportsSalesCycleQuery(salesCycleId)
  const iteration = data?.sales_cycle_iterations.find(i => i.id === iterationId)

  if (isLoading) {
    return <div data-testid={"loading"} />
  }

  if (!iteration?.download_report_url) {
    return null
  }

  return (
    <Link
      to={iteration.download_report_url}
      className={"inline-flex items-center gap-1 text-sm"}
      data-testid={"download-report-link"}
      noUnderline
    >
      <DocumentArrowDownIcon className={"size-5"} />
      {t("Reports_Prospects_DownloadExcelButton")}
    </Link>
  )
}
