import React from "react"
import {useTranslation} from "react-i18next"

import Button from "../../../../components/Button"
import {Table} from "../../../../components/Table"

export const NoProspects: React.FC = () => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-col gap-4"}>
      <Button disabled fullWidth={false} wrapperClassName={"self-end"}>
        {t("Prospects_ApproveDatabase_Button")}
      </Button>
      <Table<"", any> data={[]} columnsMeta={[{column: ""}]}>
        <div className={"col-span-full p-10 text-center text-sm"}>{t("Prospects_NoProspects_NoData")}</div>
      </Table>
    </div>
  )
}
