import React from "react"
import {useTranslation} from "react-i18next"
import {
  ArrowPathIcon,
  EnvelopeIcon,
  FireIcon,
  PhoneIcon,
  RocketLaunchIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

import {TSalesCycleNews} from "../../types.ts"
import {MetricCard} from "./MetricCard.tsx"

export const MetricsBlock: React.FC<{data: TSalesCycleNews}> = ({data}) => {
  const {t} = useTranslation()

  return (
    <div className={"grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] gap-4"} data-testid={"MetricsBlock"}>
      <MetricCard
        title={t("SellingDetails_News_Metric_Emails")}
        value={data.statistics?.emails_sent ?? 0}
        Icon={EnvelopeIcon}
      />
      <MetricCard
        title={t("SellingDetails_News_Metric_Calls")}
        value={data.statistics?.calls_made ?? 0}
        Icon={PhoneIcon}
      />
      {!data.hide_meeting_activities && (
        <MetricCard
          title={t("SellingDetails_News_Metric_Meetings")}
          value={data.statistics?.meetings_done ?? 0}
          Icon={UsersIcon}
        />
      )}
      <MetricCard
        title={t("SellingDetails_News_Metric_Activities")}
        value={data.statistics?.activities ?? 0}
        Icon={FireIcon}
      />
      <MetricCard
        title={t("SellingDetails_News_Metric_Opportunities")}
        value={data.statistics?.opportunities ?? 0}
        Icon={ArrowPathIcon}
      />
      <MetricCard
        title={t("SellingDetails_News_Metric_Won")}
        value={data.statistics?.won ?? 0}
        Icon={RocketLaunchIcon}
      />
    </div>
  )
}
