import React from "react"
import {CheckCircleIcon, ExclamationCircleIcon} from "@heroicons/react/24/solid"
import {twMerge} from "tailwind-merge"

import {ProfileImage} from "../../../components/ProfileImage.tsx"
import {TabItem} from "../../../components/Tabs"

export type TAssignmentVariant = "initial" | "in_progress" | "finished"

type TAssignmentDisplayProps = {
  image?: string
  onClick: () => void
  active: boolean
  variant: TAssignmentVariant
  name: string
  min: number
  max: number
}
export const AssignmentDisplay = React.forwardRef<HTMLAnchorElement, TAssignmentDisplayProps>(
  ({min, max, image, active, onClick, variant, name}, ref) => {
    const icon = React.useMemo(() => {
      switch (variant) {
        case "finished":
          return <CheckCircleIcon className={"h-4 w-4"} />
        case "in_progress":
          return <ExclamationCircleIcon className={"h-4 w-4"} />
        default:
          return null
      }
    }, [variant])

    return (
      <TabItem
        ref={ref}
        className={"flex snap-center items-center gap-4 font-normal text-cr-black"}
        isActive={active}
        onClick={onClick}
      >
        <ProfileImage className={"size-10"} src={image} alt={name} />
        <div className={"flex flex-col"}>
          <div className={"whitespace-nowrap"}>{name}</div>
          <div
            className={twMerge([
              "flex items-center gap-1 text-sm text-cr-grey-50",
              variant === "finished" && "text-cr-blue",
              variant === "in_progress" && "text-cr-yellow",
            ])}
          >
            {icon}
            <span>
              {min} / {max}
            </span>
          </div>
        </div>
      </TabItem>
    )
  }
)
AssignmentDisplay.displayName = "AssignmentDisplay"
