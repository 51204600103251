import React from "react"
import {useTranslation} from "react-i18next"

import {Link} from "../../../components/Link.tsx"
import {useResendInvitationMutation} from "../../../queries/saasUserManagement.ts"
import requestError from "../../../services/requestError.tsx"
import {CancelInvitationContext, DeactivateUserContext, ReactivateUserContext} from "../context.ts"
import {ERole, EStatus, TUser} from "../fakeTypes.ts"

export const ActionsCell: React.FC<{row: TUser}> = ({row}) => {
  if (row.role === ERole.owner) {
    return null
  }
  if (row.status === EStatus.invited) {
    return <ActionsCellInvited row={row} />
  }
  if (row.status === EStatus.active) {
    return <ActionsCellActive row={row} />
  }
  if (row.status === EStatus.deactivated) {
    return <ActionsCellDeactivated row={row} />
  }

  return null
}

export const ActionsCellDeactivated: React.FC<{row: TUser}> = ({row}) => {
  const {t} = useTranslation()

  const reactivateContext = ReactivateUserContext.useContext()

  return (
    <Link variant={"neutral"} className={"font-semibold"} flipUnderline onClick={() => reactivateContext.setValue(row)}>
      {t("SaaSUserManagement_Table_Reactivate_Button")}
    </Link>
  )
}

export const ActionsCellActive: React.FC<{row: TUser}> = ({row}) => {
  const {t} = useTranslation()

  const deactivateContext = DeactivateUserContext.useContext()

  return (
    <Link variant={"error"} className={"font-semibold"} flipUnderline onClick={() => deactivateContext.setValue(row)}>
      {t("SaaSUserManagement_Table_Deactivate_Button")}
    </Link>
  )
}

export const ActionsCellInvited: React.FC<{row: TUser}> = ({row}) => {
  const {t} = useTranslation()

  const [isResent, setIsResent] = React.useState(false)

  const resendInvitationMutation = useResendInvitationMutation()

  const handleResend = React.useCallback(async () => {
    try {
      await resendInvitationMutation.mutateAsync({id: row.id})
      setIsResent(true)
    } catch (e) {
      requestError(e)
    }
  }, [resendInvitationMutation, row.id])

  const cancelInvitationContext = CancelInvitationContext.useContext()

  return (
    <div className={"flex gap-4"}>
      {isResent ? (
        <span className={"text-cr-green"}>{t("SaaSUserManagement_Table_ResendInvite_Sent")}</span>
      ) : (
        <Link
          variant={"primary"}
          className={"font-semibold"}
          flipUnderline
          loading={resendInvitationMutation.isPending}
          onClick={handleResend}
        >
          {t("SaaSUserManagement_Table_ResendInvite_Default")}
        </Link>
      )}
      <Link
        variant={"neutral"}
        className={"font-semibold"}
        flipUnderline
        onClick={() => cancelInvitationContext.setValue(row)}
      >
        {t("SaaSUserManagement_Table_CancelInvite_Button")}
      </Link>
    </div>
  )
}
