import React from "react"
import {useNavigate} from "react-router-dom"

import {ELanguage, getCurrentLanguage, isKnownLanguage} from "../i18n"
import {enumToOptions} from "../utils/i18n"
import {setLSItem} from "../utils/localStorage"
import {DropdownBase, TOption} from "./formElements/Dropdown"

export const LanguagePicker: React.FC = () => {
  const navigate = useNavigate()
  const [currentLanguage, setCurrentLanguage] = React.useState<ELanguage>(getCurrentLanguage())

  const handleChangeLanguage = React.useCallback((lang: ELanguage | undefined | null) => {
    if (!isKnownLanguage(lang)) {
      return
    }

    setCurrentLanguage(oldLang => {
      if (lang !== oldLang) {
        window.location.reload()
      }

      return lang
    })
  }, [])

  React.useEffect(() => {
    const url = new URL(document.location.href)
    const params = url.searchParams
    let paramLang = params.get("lang")

    if (paramLang === "en") {
      // use "en-gb" for "en" from the URL for backwards compatibility
      paramLang = "en-gb"
    }
    if (!isKnownLanguage(paramLang)) {
      return
    }

    params.delete("lang")
    navigate(url.pathname + url.search + url.hash, {replace: true})

    handleChangeLanguage(paramLang)
  }, [handleChangeLanguage, navigate])

  React.useEffect(() => {
    setLSItem("language", currentLanguage)
    document.documentElement.lang = currentLanguage
  }, [currentLanguage])

  const languageOptions = enumToOptions("Language") as Array<TOption<ELanguage>>

  return (
    <DropdownBase
      options={languageOptions}
      value={currentLanguage}
      onChange={handleChangeLanguage}
      openUp
      buttonClassName={"min-w-[150px]"}
    />
  )
}
