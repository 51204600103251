import React from "react"
import {useTranslation} from "react-i18next"
import AutoSizer from "react-virtualized-auto-sizer"
import {Bar, BarTooltipProps} from "@nivo/bar"
import {UseQueryResult} from "@tanstack/react-query"

import {GenericErrorAlert} from "../../../../components/Alert"
import {Loading} from "../../../../components/Loading"
import {TReportsMetric, useReportsMetricsQuery} from "../../../../queries/reports"
import {enumTranslKey} from "../../../../utils/i18n"
import {tailwindColors} from "../../../../utils/tailwind"
import {useCurrentAssignmentId, useCurrentSelectionState} from "../../hooks"
import {LegendItem} from "../DataLegend"
import {MetricGraphCard} from "./MetricGraphCard"

export type TDatum<T extends string> = {
  type: T
  label: string
  value: number
}
export type TConfig<T extends string> = Array<{
  key: T
  label: string
  color: string
}>

export function SimpleBarGraphCard<T extends string>({
  query,
  config,
  metric,
}: {
  query: (
    salesCycleId: number,
    iterationId: number | null,
    assignmentId: string | null
  ) => UseQueryResult<Record<T, number> | null | undefined>
  config: TConfig<T>
  metric: TReportsMetric
}): React.ReactNode {
  const {t} = useTranslation()

  const {
    value: {salesCycleId, iterationId},
  } = useCurrentSelectionState()
  const assignmentId = useCurrentAssignmentId()

  const {data, error, isFetching, isLoading, refetch} = query(salesCycleId, iterationId, assignmentId)
  const {data: metricsData} = useReportsMetricsQuery(salesCycleId, iterationId, assignmentId)

  const graphData = React.useMemo<Array<TDatum<T>>>(() => {
    return config.map(configItem => ({
      type: configItem.key,
      value: data?.[configItem.key] ?? 0,
      label: configItem.label,
    }))
  }, [config, data])

  if (error) {
    return (
      <MetricGraphCard>
        <GenericErrorAlert retry={refetch} />
      </MetricGraphCard>
    )
  }

  return (
    <MetricGraphCard
      className={isFetching ? "pointer-events-none animate-pulse" : undefined}
      title={t(enumTranslKey("ReportsMetric", metric))}
      total={metricsData?.counters[metric]}
    >
      <div className={"flex h-full items-center justify-center"}>
        {isLoading ? (
          <Loading delayShow={false} />
        ) : data == null ? (
          <span className={"text-sm text-cr-grey-50"}>{t("Reports_NoData")}</span>
        ) : (
          <div className={"h-full w-full"} data-testid={"chart"}>
            <AutoSizer>
              {({width, height}) => (
                <Bar
                  width={width}
                  height={height}
                  data={graphData}
                  indexBy={"label"}
                  colors={dataDef => config.find(({key}) => key === dataDef.data.type)?.color ?? ""}
                  motionConfig={"stiff"}
                  borderRadius={3}
                  enableTotals={true}
                  enableLabel={false}
                  margin={{left: 30, top: 20, bottom: 30}}
                  axisLeft={{tickValues: 4, tickSize: 0}}
                  gridYValues={4}
                  theme={{
                    grid: {
                      line: {
                        stroke: tailwindColors["cr-grey"]["15"],
                        strokeWidth: 1,
                        strokeDasharray: "4 6",
                      },
                    },
                    text: {
                      fontFamily: "inherit",
                    },
                  }}
                  tooltip={Tooltip}
                />
              )}
            </AutoSizer>
          </div>
        )}
      </div>
    </MetricGraphCard>
  )
}

export function Tooltip<T extends string>({value, color, data: {label}}: BarTooltipProps<TDatum<T>>) {
  return (
    <div className={"card-shadow px-4 py-2"}>
      <LegendItem label={label} color={color} value={value} />
    </div>
  )
}
