import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {ArrowUpTrayIcon, CheckIcon} from "@heroicons/react/20/solid"
import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

import Button, {ButtonForm} from "../../../components/Button"
import {FileInputField} from "../../../components/fields/File"
import {InputField} from "../../../components/fields/Input"
import {DropdownField, TOption} from "../../../components/formElements/Dropdown"
import {Link} from "../../../components/Link"
import Modal from "../../../components/Modal"
import {i18n} from "../../../i18n"
import {useUploadLeadsMutation} from "../../../queries/leads"
import {baseURL} from "../../../services"
import {AOwnershipLevels} from "../../../services/types.generated"
import {enumTranslKey} from "../../../utils/i18n"
import {requiredFieldMessage, setFormErrorsFromAxios, validateNonemptyString} from "../../../utils/validation"

enum EStep {
  Initial,
  Finished,
}

export const AddLeadsModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  simple?: boolean
}> = ({isOpen, onClose, simple}) => {
  const [step, setStep] = React.useState(EStep.Initial)

  React.useEffect(() => {
    if (isOpen) {
      setStep(EStep.Initial)
    }
  }, [isOpen])

  return (
    <Modal disableClickOutsideClose size={"lg"} isOpen={isOpen} onClose={onClose}>
      {step === EStep.Initial && (
        <InitialStep onClose={onClose} onSuccess={() => setStep(EStep.Finished)} simple={simple} />
      )}
      {step === EStep.Finished && <FinishedStep onClose={onClose} />}
    </Modal>
  )
}

const validationSchema = z.object({
  source: validateNonemptyString(),
  leads_file: z.custom<File>().refine(file => !!file, requiredFieldMessage),
  owner: validateNonemptyString(),
})

const validationSchemaSimple = z.object({
  leads_file: z.custom<File>().refine(file => !!file, requiredFieldMessage),
})

const ownershipLevels = [
  {
    title: i18n.t(enumTranslKey("ProspectOwnershipLevel", AOwnershipLevels.CloseRocket)),
    value: AOwnershipLevels.CloseRocket,
  },
] satisfies Array<TOption<AOwnershipLevels>>

export const InitialStep: React.FC<{onSuccess: () => void; onClose: () => void; simple?: boolean}> = ({
  onClose,
  onSuccess,
  simple,
}) => {
  const {t} = useTranslation()

  const methods = useForm<{leads_file: File; source: string; owner: AOwnershipLevels}>({
    resolver: zodResolver(simple ? validationSchemaSimple : validationSchema),
  })

  const uploadMutation = useUploadLeadsMutation()

  const handleSubmit = React.useCallback(async () => {
    const values = methods.getValues()

    try {
      await uploadMutation.mutateAsync({
        ownership_level: values.owner,
        leads_file: values.leads_file,
        source: values.source,
      })
      onSuccess()
    } catch (e) {
      setFormErrorsFromAxios(e, methods.setError, "leads_import")
    }
  }, [methods, onSuccess, uploadMutation])

  return (
    <FormProvider {...methods}>
      <form className={"flex flex-col gap-8"} onSubmit={methods.handleSubmit(handleSubmit)}>
        <h1 className={"text-2xl font-semibold"}>{t("Leads_ImportModal_UploadStep_Title")}</h1>
        <p className={"text-cr-grey-50"}>{t("Leads_ImportModal_UploadStep_Description")}</p>

        <div className={"flex flex-col gap-4"}>
          <div className={"flex flex-col gap-2"}>
            <FileInputField
              label={t("Leads_ImportModal_UploadStep_FileLabel")}
              name={"leads_file"}
              accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
              errorMessageProps={{reserveSpace: false}}
            />

            <div className={"text-right text-sm"}>
              <Trans
                i18nKey={"Leads_ImportModal_UploadStep_Template"}
                components={{
                  templateLink: (
                    <Link
                      to={baseURL + "leads-import-template-example.xlsx"}
                      download
                      flipUnderline
                      className={"font-semibold"}
                    />
                  ),
                }}
              />
            </div>
          </div>

          {!simple && (
            <DropdownField
              label={t("Leads_ImportModal_UploadStep_OwnerLabel")}
              name={"owner"}
              options={ownershipLevels}
            />
          )}

          {!simple && <InputField name={"source"} label={t("Leads_ImportModal_UploadStep_SourceLabel")} />}
        </div>

        <div className={"flex justify-end gap-4"}>
          <Button variant={"outlined"} color={"gray"} onClick={onClose}>
            {t("T_Cancel")}
          </Button>

          <ButtonForm iconLeft={<ArrowUpTrayIcon />}>{t("T_Import")}</ButtonForm>
        </div>
      </form>
    </FormProvider>
  )
}

export const FinishedStep: React.FC<{onClose: () => void}> = ({onClose}) => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-col gap-8"}>
      <h1 className={"text-2xl font-semibold"}>{t("Leads_ImportModal_FinishedStep_Title")}</h1>
      <p className={"text-cr-grey-50"}>{t("Leads_ImportModal_FinishedStep_Text")}</p>

      <div className={"flex justify-end"}>
        <Button onClick={onClose} iconLeft={<CheckIcon />}>
          {t("T_OK")}
        </Button>
      </div>
    </div>
  )
}
