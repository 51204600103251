import React from "react"
import {useTranslation} from "react-i18next"

import {ButtonLoading} from "./Button"

type TProps = {
  title?: React.ReactNode
  variant: "success" | "warning" | "info" | "secondary" | "error"
  children?: React.ReactNode
}

const variants = {
  info: {
    backgroundColor: "bg-cr-blue-super-light",
    titleColor: "text-cr-blue-dark",
    textColor: "text-cr-blue",
    icon: (
      <svg className={"h-5 w-5 text-cr-blue"} viewBox={"0 0 20 20"} fill={"currentColor"} aria-hidden={"true"}>
        <path
          fillRule={"evenodd"}
          d={
            "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
          }
          clipRule={"evenodd"}
        />
      </svg>
    ),
  },
  secondary: {
    backgroundColor: "bg-cr-cyan-light",
    titleColor: "text-cr-cyan-dark",
    textColor: "text-cr-black",
    icon: (
      <svg className={"h-5 w-5 text-cr-black"} viewBox={"0 0 20 20"} fill={"currentColor"} aria-hidden={"true"}>
        <path
          fillRule={"evenodd"}
          d={
            "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
          }
          clipRule={"evenodd"}
        />
      </svg>
    ),
  },
  warning: {
    backgroundColor: "bg-cr-yellow-light",
    titleColor: "text-cr-grey-90",
    textColor: "text-cr-yellow",
    icon: (
      <svg className={"h-5 w-5 text-cr-yellow"} viewBox={"0 0 20 20"} fill={"currentColor"} aria-hidden={"true"}>
        <path
          fillRule={"evenodd"}
          d={
            "M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
          }
          clipRule={"evenodd"}
        />
      </svg>
    ),
  },
  error: {
    backgroundColor: "bg-cr-red-light",
    titleColor: "text-cr-grey-90",
    textColor: "text-cr-red",
    icon: (
      <svg className={"h-5 w-5 text-cr-red"} viewBox={"0 0 20 20"} fill={"currentColor"} aria-hidden={"true"}>
        <path
          fillRule={"evenodd"}
          d={
            "M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
          }
          clipRule={"evenodd"}
        />
      </svg>
    ),
  },
  success: {
    backgroundColor: "bg-cr-green-light",
    titleColor: "text-cr-grey-90",
    textColor: "text-cr-green",
    icon: (
      <svg className={"h-5 w-5 text-cr-green"} viewBox={"0 0 20 20"} fill={"currentColor"} aria-hidden={"true"}>
        <path
          fillRule={"evenodd"}
          d={
            "M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
          }
          clipRule={"evenodd"}
        />
      </svg>
    ),
  },
} as const satisfies {
  [variant in TProps["variant"]]: {
    backgroundColor: string
    titleColor: string
    textColor: string
    icon: React.ReactNode
  }
}

export const Alert: React.FC<TProps> = ({title, variant, children}) => {
  const {backgroundColor, icon, titleColor, textColor} = variants[variant]

  return (
    <div className={`${backgroundColor} mb-6 rounded-md p-4 text-left`}>
      <div className={"flex"}>
        <div className={"flex-shrink-0"}>{icon}</div>
        <div className={"ml-3 flex flex-grow flex-col gap-2"}>
          {!!title && <h3 className={`${titleColor} text-sm font-medium`}>{title}</h3>}
          {!!children && <div className={`${textColor} text-sm`}>{children}</div>}
        </div>
      </div>
    </div>
  )
}

export const GenericErrorAlert: React.FC<{retry?: () => Promise<unknown>}> = ({retry}) => {
  const {t} = useTranslation()

  return (
    <Alert title={t("GenericError_Title")} variant={"error"}>
      <div className={"flex items-center gap-8"}>
        <div className={"grow"}>{t("GenericError_Text")}</div>
        {retry && (
          <ButtonLoading onClick={retry} variant={"light"} className={"whitespace-nowrap"}>
            {t("GenericError_Retry")}
          </ButtonLoading>
        )}
      </div>
    </Alert>
  )
}
