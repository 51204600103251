import React from "react"
import {Trans} from "react-i18next"

import {AActivityFeed, AProspectActivity} from "../../../../../services/types.generated.ts"
import {commonTransComponents} from "../../../../../utils/i18n.tsx"
import {getOrganizationName} from "../utils.ts"

export const ActivityBodyPriceOfferSent: React.FC<{activity: AActivityFeed | AProspectActivity}> = ({activity}) => {
  return (
    <div>
      <Trans
        i18nKey={"SellingDetails_News_ActivityFeed_OfferSent"}
        components={commonTransComponents}
        values={{
          organization: getOrganizationName(activity),
        }}
      />
    </div>
  )
}
