import React from "react"
import {Trans, useTranslation} from "react-i18next"

import {FileInputConnected} from "../../components/fields/File.tsx"
import {Link} from "../../components/Link.tsx"
import {commonTransComponents} from "../../utils/i18n.tsx"

export const SalespersonResume: React.FC = () => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-col gap-2"}>
      <FileInputConnected name={"resume"} accept={"application/pdf"} />
      <div className={"text-sm text-cr-grey-50"}>{t("SalespersonProfile_Profile_Resume_AcceptedFileTypes")}</div>
      <div className={"text-sm"}>
        <Trans
          components={{
            ...commonTransComponents,
            kickresumeLink: (
              <Link
                to={"https://www.kickresume.com/en/free-bundle/aec4f87b-b3d5-420c-9091-c27c71f4de5c/"}
                target={"_blank"}
                rel={"noreferrer"}
              />
            ),
          }}
          i18nKey={"SalespersonProfile_Profile_Resume_Tip"}
        />
      </div>
    </div>
  )
}
