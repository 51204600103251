import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {AOnboardingStatuses} from "../../../services/types.generated.ts"
import {getStepNum, getStepText, stepDefinitions} from "../WelcomeScreen/utils.ts"
import {StepTitle} from "../WelcomeScreen/WelcomeScreen.tsx"

export const DraftStep: React.FC<{
  currentStep: AOnboardingStatuses
  step: AOnboardingStatuses
  buttonNode?: React.ReactNode
}> = ({currentStep, step, buttonNode}) => {
  const {t} = useTranslation()

  const stepNum = getStepNum(step)
  const currentStepNum = getStepNum(currentStep)
  const isFinished = currentStepNum > stepNum

  const {time} = stepDefinitions[step]

  return (
    <div
      className={twMerge(
        "card flex flex-col gap-2 border-cr-blue-light p-6",
        isFinished && !buttonNode && "opacity-50"
      )}
    >
      <div className={"flex flex-wrap items-baseline justify-between gap-4"}>
        <StepTitle active={stepNum === currentStepNum} finished={isFinished} step={step} />
        {buttonNode ||
          (time && (
            <div className={"whitespace-nowrap text-xs font-semibold"}>
              {t("WelcomeScreen_Company_Steps_Time", {time})}
            </div>
          ))}
      </div>
      <div>{getStepText(step, currentStepNum > stepNum)}</div>
    </div>
  )
}
