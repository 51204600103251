import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {toast} from "react-toastify"

import {ConfirmModal} from "../../../components/Modal.tsx"
import {useBulkDeleteProspectsMutation, useDeleteProspectMutation} from "../../../queries/prospects.ts"
import requestError from "../../../services/requestError.tsx"
import {usePrevious} from "../../../utils/hooks.tsx"
import {commonTransComponents} from "../../../utils/i18n.tsx"
import {DeletingContext, EditingContext, TDeletingProspect} from "./context.ts"

export const DeleteModal: React.FC = () => {
  const {value: prospect} = DeletingContext.useContext()

  return (
    <>
      <DeleteModalSingle prospect={Array.isArray(prospect) ? null : prospect} />
      <DeleteModalBulk prospectIds={Array.isArray(prospect) ? prospect : null} />
    </>
  )
}

export const DeleteModalSingle: React.FC<{prospect: TDeletingProspect | null}> = ({prospect: currentProspect}) => {
  const {t} = useTranslation()

  const {setValue: setProspect} = DeletingContext.useContext()
  const {setValue: setEditingProspect} = EditingContext.useContext()
  const previousProspect = usePrevious(currentProspect)

  const prospect = currentProspect ?? previousProspect

  const deleteProspectMutation = useDeleteProspectMutation()

  const handleDeleteProspect = React.useCallback(async () => {
    if (!currentProspect) {
      return
    }

    try {
      await deleteProspectMutation.mutateAsync(currentProspect.id)
      setEditingProspect(null)
      toast.success(t("Prospects_DeleteModal_Success_Single", {companyName: currentProspect.organization_name}))
    } catch (e) {
      requestError(e)

      return false
    }
  }, [currentProspect, deleteProspectMutation, setEditingProspect, t])

  return (
    <ConfirmModal
      variant={"error"}
      title={t("Prospects_DeleteModal_Title")}
      isOpen={!!currentProspect}
      onClose={() => {
        setProspect(null)
      }}
      onConfirm={handleDeleteProspect}
      confirmButtonText={t("Prospects_DeleteModal_Remove")}
    >
      <Trans
        i18nKey={"Prospects_DeleteModal_Text_Single"}
        components={commonTransComponents}
        values={{companyName: prospect?.organization_name}}
      />
    </ConfirmModal>
  )
}

export const DeleteModalBulk: React.FC<{prospectIds: number[] | null}> = ({prospectIds: currentProspectIds}) => {
  const {t} = useTranslation()

  const {setValue: setProspect} = DeletingContext.useContext()
  const previousProspectIds = usePrevious(currentProspectIds)

  const prospectIds = currentProspectIds ?? previousProspectIds

  const bulkDeleteProspectsMutation = useBulkDeleteProspectsMutation()

  const handleDeleteProspects = React.useCallback(async () => {
    if (!currentProspectIds) {
      return
    }

    try {
      await bulkDeleteProspectsMutation.mutateAsync(currentProspectIds)
      toast.success(t("Prospects_DeleteModal_Success_Bulk", {count: currentProspectIds.length}))
    } catch (e) {
      requestError(e)

      return false
    }
  }, [bulkDeleteProspectsMutation, currentProspectIds, t])

  return (
    <ConfirmModal
      variant={"error"}
      title={t("Prospects_DeleteModal_Title")}
      isOpen={!!currentProspectIds}
      onClose={() => {
        setProspect(null)
      }}
      onConfirm={handleDeleteProspects}
      confirmButtonText={t("Prospects_DeleteModal_Remove")}
    >
      {t("Prospects_DeleteModal_Text_Bulk", {count: prospectIds?.length})}
    </ConfirmModal>
  )
}
