import React from "react"
import {useTranslation} from "react-i18next"
import {faker} from "@faker-js/faker"
import {ChevronLeftIcon} from "@heroicons/react/20/solid"
import {PlusCircleIcon} from "@heroicons/react/24/outline"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import Button from "../../../components/Button.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {Link, UnstyledLink} from "../../../components/Link.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {VideoModal} from "../../../components/VideoModal.tsx"
import {useSalesCyclePeopleQuery} from "../../../queries/salesCycles.ts"
import {useNumParam} from "../../../utils/hooks.tsx"
import {OnboardingLayout} from "../components/OnboardingLayout.tsx"
import {SalespersonRequestCard} from "../components/SalespersonRequestCard.tsx"
import {
  ECreateSalesCyclePage,
  getCreateSellingLink,
  isPlaceholderSalesperson,
  TPlaceholderSalesPerson,
  TSalesPerson,
} from "../utils.ts"

export const SalespeopleChoice: React.FC<{onSubmit: (salesPeople: TSalesPerson[]) => void}> = ({onSubmit}) => {
  const {t} = useTranslation()

  const salesCycleId = useNumParam("salesCycleId")

  const salesPeopleQuery = useSalesCyclePeopleQuery(salesCycleId)

  const apiPlaceholder = salesPeopleQuery.data?.meta?.sales_person_placeholder
  const salesPeople = salesPeopleQuery.data?.sales_people

  const makePlaceholder = React.useMemo<(() => TPlaceholderSalesPerson) | undefined>(() => {
    if (!apiPlaceholder) {
      return undefined
    }

    return () => ({
      id: faker.number.int(),
      placeholder: true,
      first_name: t("CreateSalesCycle_Salespeople_Placeholder_Name"),
      about: t("CreateSalesCycle_Salespeople_Placeholder_About"),
      packages: apiPlaceholder.packages,
      country: apiPlaceholder.country,
    })
  }, [apiPlaceholder, t])

  const [currentVideoId, setCurrentVideoId] = React.useState<string | null>(null)
  const [requestedSalesPeople, setRequestedSalesPeople] = React.useState<TSalesPerson[]>([])

  const requestedPlaceholderSalesPeople = React.useMemo(() => {
    return requestedSalesPeople.filter(isPlaceholderSalesperson)
  }, [requestedSalesPeople])

  React.useEffect(() => {
    setRequestedSalesPeople([])
  }, [salesPeopleQuery.data])

  const handleToggleSalesperson = (salesPerson: TSalesPerson) => async () => {
    setRequestedSalesPeople(requestedSalesPeople => {
      const salesPeopleSet = new Set(requestedSalesPeople)
      salesPeopleSet.has(salesPerson) ? salesPeopleSet.delete(salesPerson) : salesPeopleSet.add(salesPerson)

      return Array.from(salesPeopleSet)
    })
  }

  return (
    <OnboardingLayout
      title={t("CreateSalesCycle_Salespeople_Choice_Title")}
      subtitle={t("CreateSalesCycle_Salespeople_Choice_Subtitle")}
      step={2}
      total={3}
      leftAction={
        <Link
          to={getCreateSellingLink({salesCycleId, page: ECreateSalesCyclePage.COUNTRY})}
          flipUnderline
          variant={"neutral"}
          className={"inline-flex items-center gap-1"}
        >
          <ChevronLeftIcon className={"size-5"} />
          {t("CreateSalesCycle_Back")}
        </Link>
      }
      rightAction={
        <Button onClick={() => onSubmit(requestedSalesPeople)} disabled={requestedSalesPeople.length === 0}>
          {t("CreateSalesCycle_Salespeople_SubmitButton")}
        </Button>
      }
    >
      <LayoutBlock innerClassName={"flex flex-col gap-16"}>
        {salesPeopleQuery.error ? (
          <GenericErrorAlert retry={salesPeopleQuery.refetch} />
        ) : salesPeople ? (
          <>
            <div className={"flex flex-wrap justify-center gap-7"} data-testid={"real-salespeople"}>
              {salesPeople.map(salesPerson => (
                <SalespersonRequestCard
                  key={salesPerson.id}
                  salesPerson={salesPerson}
                  isRequested={requestedSalesPeople.includes(salesPerson)}
                  onToggle={handleToggleSalesperson(salesPerson)}
                  onOpenVideo={() => setCurrentVideoId(salesPerson.embed_video_id ?? null)}
                />
              ))}
            </div>
            <div className={"flex flex-col gap-8"}>
              <div className={"border-b border-b-cr-blue-mid-light pb-2 text-sm"}>
                <h3 className={"font-bold"}>{t("CreateSalesCycle_Salespeople_Placeholder_Title")}</h3>
                <h4>{t("CreateSalesCycle_Salespeople_Placeholder_Subtitle")}</h4>
              </div>
              <div className={"flex flex-wrap justify-center gap-7"} data-testid={"placeholder-salespeople"}>
                {requestedPlaceholderSalesPeople.map(salesPerson => (
                  <SalespersonRequestCard
                    key={salesPerson.id}
                    salesPerson={salesPerson}
                    isRequested
                    onToggle={handleToggleSalesperson(salesPerson)}
                    onOpenVideo={() => setCurrentVideoId(salesPerson.embed_video_id ?? null)}
                  />
                ))}

                {makePlaceholder && (
                  <UnstyledLink
                    onClick={handleToggleSalesperson(makePlaceholder())}
                    className={
                      "inline-flex min-h-[340px] w-96 shrink-0 cursor-pointer items-center justify-center rounded-lg border border-dashed border-cr-blue-mid transition-all hover:border-cr-blue"
                    }
                  >
                    <div className={"flex flex-col items-center gap-4 text-cr-blue"}>
                      <PlusCircleIcon className={"size-16"} />
                      <span className={"text-sm font-semibold"}>
                        {t("CreateSalesCycle_Salespeople_Placeholder_CTA")}
                      </span>
                    </div>
                  </UnstyledLink>
                )}
              </div>
            </div>
          </>
        ) : (
          <Loading size={"xl"} fullSpace />
        )}
      </LayoutBlock>
      <VideoModal onClose={() => setCurrentVideoId(null)} youtubeId={currentVideoId} />
    </OnboardingLayout>
  )
}
