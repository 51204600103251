import React from "react"
import {useTranslation} from "react-i18next"

import {useDocumentTitle} from "../../../utils/hooks.tsx"
import {TSalesPerson} from "../utils.ts"
import {SalespeopleChoice} from "./SalespeopleChoicePage.tsx"
import {SalespeoplePackages} from "./SalespeoplePackagesPage.tsx"

export const SalespeoplePage: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("CreateSalesCycle_Salespeople_DocumentTitle"))

  const [requestedSalesPeople, setRequestedSalesPeople] = React.useState<TSalesPerson[]>([])

  return requestedSalesPeople.length > 0 ? (
    <SalespeoplePackages salesPeople={requestedSalesPeople} onBack={() => setRequestedSalesPeople([])} />
  ) : (
    <SalespeopleChoice onSubmit={setRequestedSalesPeople} />
  )
}
