import {useUserSettingsQuery} from "../queries/user.ts"
import {AUserTypes} from "../services/types.generated.ts"

export const useValueByUserType = <T extends {[userType in AUserTypes | "logged-out"]?: any}>(
  signPost: T
): T[keyof T] => {
  const {status, data} = useUserSettingsQuery()

  if (status === "error" && signPost["logged-out"]) {
    return signPost["logged-out"]
  }

  if (status === "success" && data?.user.type in signPost) {
    return signPost[data.user.type]!
  }

  throw new Error(`Unable to return a value for user type "${data?.user.type}"`)
}
