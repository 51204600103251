import React from "react"
import {useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {LinkIcon} from "@heroicons/react/24/solid"

import {FieldLabel} from "../../components/fields/FieldLabel.tsx"
import {
  FileUploaderConnected,
  TUploadedApiFile,
  UploadedFilesWithDeleteConfirm,
} from "../../components/fields/FileUploader.tsx"
import {InputField} from "../../components/fields/Input"
import {useNumParam} from "../../utils/hooks.tsx"
import {TSalesFormData} from "../SalesForm/types.ts"
import {useRemoveAttachmentMutation} from "./api.ts"

const Web = () => {
  const {t} = useTranslation()

  return (
    <>
      <div className={"flex flex-col gap-4"}>
        <InputField
          required
          label={t("SalesStrategy_Link to your website")}
          Icon={LinkIcon}
          name={"website"}
          placeholder={t("SalesStrategy_Add the link here")}
        />
        <FieldLabel
          name={"appendable_attachments"}
          label={t("SalesStrategy_Documents")}
          legend={t("SalesStrategy_DocumentsLegend")}
        >
          <div className={"flex flex-col gap-4"}>
            <FileUploaderConnected name={"appendable_attachments"} multiple={true} />
            <UploadedAttachments />
          </div>
        </FieldLabel>
      </div>
    </>
  )
}

const UploadedAttachments: React.FC = () => {
  const salesCycleId = useNumParam("salesCycleId")

  const {watch, setValue} = useFormContext<TSalesFormData>()

  const attachments = watch("attachments") ?? undefined

  const removeAttachmentMutation = useRemoveAttachmentMutation()

  const handleFileRemoveConfirm = React.useCallback(
    async (file: TUploadedApiFile) => {
      await removeAttachmentMutation.mutateAsync({salesCycleId, attachment_id: file.id})
      setValue(
        "attachments",
        attachments?.filter(({id}) => id !== file.id)
      )
    },
    [salesCycleId, attachments, removeAttachmentMutation, setValue]
  )

  return <UploadedFilesWithDeleteConfirm files={attachments} onConfirm={handleFileRemoveConfirm} />
}

export default Web
