import React from "react"
import {useTranslation} from "react-i18next"
import {formatDistanceToNowStrict} from "date-fns/formatDistanceToNowStrict"

import LogoIcon from "../../../../../assets/logoIconCurrentColor.svg?react"
import {ProfileImage} from "../../../../../components/ProfileImage.tsx"
import {getCurrentLanguage} from "../../../../../i18n.ts"
import {AActivityFeed, AActivityFeedUserType, AProspectActivity} from "../../../../../services/types.generated.ts"
import {getFullName} from "../../../../../utils"
import {apiDateToJS, getDateFnsLocale} from "../../../../../utils/dateArithmetics.ts"
import {enumTranslKey} from "../../../../../utils/i18n.tsx"

export const ActivityHeader: React.FC<{activity: AActivityFeed | AProspectActivity}> = ({activity}) => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-wrap justify-between gap-4"}>
      {activity.created_by && (
        <div className={"flex gap-3"}>
          <ProfileImage className={"size-10"} src={activity.created_by.profile_picture_thumbnail_url ?? undefined} />

          <div className={"flex flex-col"}>
            <span className={"flex items-center gap-2 font-semibold"}>
              {getFullName(activity.created_by)}
              {activity.created_by.type === AActivityFeedUserType.Admin && <AdminBadge />}
            </span>
            <span className={"text-sm text-cr-grey-50"}>
              {activity.created_by.type && t(enumTranslKey("ActivityFeedUserType", activity.created_by.type))}
            </span>
          </div>
        </div>
      )}

      <div className={"grow text-right"}>
        <span
          title={apiDateToJS(activity.changed_activity_at).toLocaleString(getCurrentLanguage(), {
            dateStyle: "medium",
            timeStyle: "medium",
          })}
        >
          {formatDistanceToNowStrict(apiDateToJS(activity.changed_activity_at), getDateFnsLocale())}
        </span>
      </div>
    </div>
  )
}

export const AdminBadge: React.FC = () => {
  return (
    <div className={"size-5 overflow-hidden rounded-full"}>
      <LogoIcon className={"bg-cr-blue p-[15%] text-cr-white"} role={"img"} />
    </div>
  )
}
