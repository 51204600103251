import React from "react"
import {useTranslation} from "react-i18next"

import {InputBase} from "../../../components/fields/Input"
import {DropdownBase} from "../../../components/formElements/Dropdown"
import {useCountryOptions} from "../../../utils/hooks.tsx"
import {FilteringContext} from "./context.ts"

export const SearchBox: React.FC = () => {
  const {t} = useTranslation()

  const {searchString, setSearchString} = FilteringContext.useContext()

  return (
    <InputBase
      value={searchString}
      placeholder={t("SearchPlaceholder")}
      onChange={e => setSearchString(e.target.value)}
      autoFocus
    />
  )
}

export const CountryFilter: React.FC = () => {
  const {t} = useTranslation()

  const {countryId, setCountryId} = FilteringContext.useContext()
  const countryOptions = useCountryOptions()

  return (
    <DropdownBase
      clearable
      buttonClassName={"w-60"}
      value={countryId}
      options={countryOptions}
      placeholder={t("T_Target market")}
      onChange={setCountryId}
    />
  )
}
