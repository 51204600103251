import {useMutation, useQuery} from "@tanstack/react-query"

import api, {queryKey} from "../services"

export const useGDPRQuery = (token: string) =>
  useQuery({
    queryKey: queryKey.GDPR(token),
    queryFn: async () => (await api.privacyRequest.privacyRequestList({token})).data.privacy_request,
  })

export const useGDPRDeleteMutation = () =>
  useMutation({
    mutationFn: (token: string) => api.privacyRequest.requestRemoveCreate({token}),
  })
