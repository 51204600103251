export enum ETab {
  ACTIVE = "active",
  DRAFT = "draft",
  PAUSED = "paused",
  FINISHED = "finished",
}

export const getDesiredTab = (
  currentTab?: ETab,
  activeCount?: number,
  draftCount?: number,
  pausedCount?: number,
  finishedCount?: number
): ETab | null => {
  if (!activeCount && !draftCount && !pausedCount && !finishedCount) {
    return null
  }

  if (
    (currentTab === ETab.ACTIVE && activeCount) ||
    (currentTab === ETab.DRAFT && draftCount) ||
    (currentTab === ETab.PAUSED && pausedCount) ||
    (currentTab === ETab.FINISHED && finishedCount)
  ) {
    return currentTab
  }

  return activeCount ? ETab.ACTIVE : draftCount ? ETab.DRAFT : pausedCount ? ETab.PAUSED : ETab.FINISHED
}
