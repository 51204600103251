import React from "react"
import {useTranslation} from "react-i18next"
import {PlusCircleIcon} from "@heroicons/react/24/outline"

import Button, {TButtonProps} from "../../../components/Button"
import {useUserSettingsOrLogout} from "../../../queries/user.ts"
import {isSalesPersonUser} from "../../../utils/types.ts"
import {AddLeadsModal} from "./AddLeadsModal"

export const AddLeadsButton: React.FC<TButtonProps> = props => {
  const {t} = useTranslation()

  const {user} = useUserSettingsOrLogout()

  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <>
      <Button iconLeft={<PlusCircleIcon />} onClick={() => setIsOpen(true)} {...props}>
        {t("Leads_Add_Button")}
      </Button>

      <AddLeadsModal isOpen={isOpen} onClose={() => setIsOpen(false)} simple={isSalesPersonUser(user)} />
    </>
  )
}
