import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {zodResolver} from "@hookform/resolvers/zod"
import {useQueryClient} from "@tanstack/react-query"
import {z} from "zod"

import {GenericErrorAlert} from "../../components/Alert"
import {ButtonForm} from "../../components/Button"
import {InputConnected} from "../../components/fields/Input"
import {TextareaConnected} from "../../components/fields/Textarea"
import {DropdownConnected, TOption} from "../../components/formElements/Dropdown"
import {Loading} from "../../components/Loading"
import {TerseFormLayout} from "../../components/TerseFormLayout"
import {i18n} from "../../i18n"
import {useLanguagesQuery} from "../../queries/languages"
import {useSalespersonQuery, useUpdateSalespersonProfileMutation} from "../../queries/salespeople"
import {useUserSettingsOrLogout} from "../../queries/user.ts"
import {queryKey} from "../../services"
import {ALanguage, ASalesPersonAttributes, AUserTypes} from "../../services/types.generated"
import {getFullName, useIsWelcomeScreenEnabled} from "../../utils"
import {EGTMEvent, emitGTMEvent} from "../../utils/gtm"
import {useCountryOptions, useNumParam} from "../../utils/hooks"
import {enumToOptions} from "../../utils/i18n"
import {
  requiredFieldMessage,
  setFormErrorsFromAxios,
  validateEmail,
  validateNonemptyArray,
  validateNonemptyString,
  validatePhoneNumber,
  validateRequiredNumber,
} from "../../utils/validation"
import {SalespersonResume} from "./SalespersonResume.tsx"

const validationSchema = z.object({
  email: validateEmail(),
  first_name: validateNonemptyString(),
  last_name: validateNonemptyString(),
  years_of_experience: z
    .number({invalid_type_error: requiredFieldMessage})
    .positive(i18n.t("Validation_RequiredPositive"))
    .int(i18n.t("Validation_RequiredInteger")),
  linkedin_profile: z
    .string()
    .regex(
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm,
      i18n.t("Validation_LinkedIn")
    )
    .or(z.literal("")),
  resume: z.custom<File>().refine(file => {
    return !file || file.size < 10_000_000
  }, i18n.t("T_The file you have picked is too large. Maximum size 10MB.")),
  country_id: z.number({required_error: requiredFieldMessage}).positive(),
  segment: validateNonemptyArray(validateNonemptyString()),
  about: validateNonemptyString(),
  phone_number: z.intersection(validateNonemptyString(), validatePhoneNumber()),
  native_language_id: validateRequiredNumber(),
})

type TFormData = NonNullable<ASalesPersonAttributes["profile"]>

export const SalespersonProfile: React.FC = () => {
  const {isFetched: areLanguagesFetched, data: languages} = useLanguagesQuery()

  const salespersonId = useNumParam("salespersonId")

  const {error, data, refetch} = useSalespersonQuery(salespersonId)

  const transformedData: TFormData = React.useMemo(
    () => ({
      email: data?.profile.email ?? undefined,
      first_name: data?.profile.first_name ?? undefined,
      last_name: data?.profile.last_name ?? undefined,
      linkedin_profile: data?.profile.linkedin_profile ?? undefined,
      resume_url: data?.profile.resume_url ?? undefined,
      segment: data?.profile.segment ?? undefined,
      about: data?.profile.about ?? undefined,
      years_of_experience: data?.profile.years_of_experience ?? undefined,
      capacity: data?.profile.capacity ?? undefined,
      country_id: data?.profile.country?.id ?? undefined,
      native_language_id: data?.profile.native_language?.id,
      phone_number: data?.profile.phone_number ?? undefined,
      spoken_language_ids:
        data?.profile.spoken_languages?.map(l => l.id).filter((l): l is number => l !== undefined) ?? [],
    }),
    [data]
  )

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data || !areLanguagesFetched) {
    return <Loading size={"xl"} />
  }

  return <SalespersonProfileLoaded data={transformedData} salespersonId={salespersonId} languages={languages ?? []} />
}

const SalespersonProfileLoaded: React.FC<{data: TFormData; salespersonId: number; languages: ALanguage[]}> = ({
  data,
  salespersonId,
  languages,
}) => {
  const queryClient = useQueryClient()

  const {t} = useTranslation()
  const {user} = useUserSettingsOrLogout()
  const isWelcomeScreenEnabled = useIsWelcomeScreenEnabled()

  const countryOptions = useCountryOptions()

  const updateSalespersonProfileMutation = useUpdateSalespersonProfileMutation()

  const methods = useForm<typeof data>({
    mode: "onTouched",
    resolver: zodResolver(validationSchema),
  })

  React.useEffect(() => {
    methods.reset(data)
  }, [data, methods])

  const handleSave = React.useCallback(async () => {
    const {email, ...dataWithoutEmail} = methods.getValues()

    try {
      await updateSalespersonProfileMutation.mutateAsync({salespersonId, profile: dataWithoutEmail})
      queryClient.refetchQueries({queryKey: queryKey.userSettings, type: "all"})

      isWelcomeScreenEnabled &&
        emitGTMEvent({
          event: EGTMEvent.SALES_PROFILE_SEND,
          name: getFullName(user),
          email: email ?? user.email,
          phone: dataWithoutEmail.phone_number ?? "",
        })

      toast.success(t("SalespersonProfile_Profile_SavedToast"))
    } catch (e) {
      return setFormErrorsFromAxios(e, methods.setError, "sales_person.profile")
    }
  }, [methods, updateSalespersonProfileMutation, salespersonId, queryClient, isWelcomeScreenEnabled, user, t])

  const segmentOptions = React.useMemo(() => enumToOptions("Segment"), [])
  const languageOptions = React.useMemo<Array<TOption<number>>>(
    () =>
      languages
        .map(l => ({value: l.id, title: l.name}))
        .filter((l): l is {value: number; title: string} => l.value !== undefined)
        .sort((A, B) => A.title.localeCompare(B.title)),
    [languages]
  )

  return (
    <FormProvider {...methods}>
      <form className={"card"} onSubmit={methods.handleSubmit(handleSave)}>
        <TerseFormLayout>
          <TerseFormLayout.FormField name={"email"} label={t("T_E-mail")} required>
            <InputConnected name={"email"} readOnly />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"first_name"} label={t("T_First name")} required>
            <InputConnected name={"first_name"} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"last_name"} label={t("T_Last name")} required>
            <InputConnected name={"last_name"} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"phone_number"} label={t("T_Phone number")} required>
            <InputConnected name={"phone_number"} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"native_language_id"} label={t("T_Native language")} required>
            <DropdownConnected name={"native_language_id"} options={languageOptions} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"spoken_language_ids"} label={t("T_Other languages spoken")}>
            <DropdownConnected name={"spoken_language_ids"} options={languageOptions} multiple />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"years_of_experience"} label={t("T_Years of experience")} required>
            <InputConnected name={"years_of_experience"} options={{valueAsNumber: true}} type={"number"} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"linkedin_profile"} label={t("T_LinkedIn profile")}>
            <InputConnected name={"linkedin_profile"} placeholder={"https://www.linkedin.com/in/katesimkova/"} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"resume"} label={t("SalespersonProfile_Profile_Resume_Label")}>
            <SalespersonResume />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"country_id"} label={t("T_Country")} required>
            <DropdownConnected name={"country_id"} options={countryOptions} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"segment"} label={t("T_Segment")} required>
            <DropdownConnected name={"segment"} options={segmentOptions} multiple />
          </TerseFormLayout.FormField>

          {user.type === AUserTypes.Admin && (
            <>
              <TerseFormLayout.Separator />

              <TerseFormLayout.FormField name={"capacity"} label={t("T_Capacity")}>
                <InputConnected name={"capacity"} options={{valueAsNumber: true}} type={"number"} />
              </TerseFormLayout.FormField>
            </>
          )}

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"about"} label={t("T_About")} required>
            <TextareaConnected name={"about"} rows={5} />
          </TerseFormLayout.FormField>

          <ButtonForm wrapperClassName={"col-start-2 justify-self-end mt-6"} disabled={!methods.formState.isDirty}>
            {t("T_Save changes")}
          </ButtonForm>
        </TerseFormLayout>
      </form>
    </FormProvider>
  )
}
