import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {XMarkIcon} from "@heroicons/react/20/solid"

import {APreviewOrder} from "../../../services/types.generated.ts"
import {renderPrice} from "../../../utils"
import {BulletedTransList, commonTransComponents} from "../../../utils/i18n.tsx"
import {BenefitsItem} from "./BenefitsItem.tsx"

export const BenefitsInternal: React.FC<{order: APreviewOrder}> = ({order}) => {
  const {t} = useTranslation()

  return (
    <div className={"flex grow basis-1/3 flex-col gap-8 py-10 text-cr-grey-50"}>
      <h2 className={"text-3xl font-bold"}>{t("CreateSalesCycle_Benefits_Internal_Title")}</h2>
      <BulletedTransList>
        <Trans
          i18nKey={"CreateSalesCycle_Benefits_Internal_MainList"}
          components={{
            ...commonTransComponents,
            bullet: <XMarkIcon className={"size-5 translate-y-1 text-inherit"} />,
            item: <span className={"font-bold"} />,
          }}
        />
      </BulletedTransList>
      <BenefitsItem
        title={t("CreateSalesCycle_Benefits_SalesTalent")}
        price={renderPrice(order.max_regular_sales_person_price, order.order.currency, {noDecimal: true})}
      />
      <BenefitsItem
        title={t("CreateSalesCycle_Benefits_SetupFee")}
        price={renderPrice(order.max_regular_setup_fee_price, order.order.currency, {noDecimal: true})}
      />
      <ul className={"flex list-inside list-disc flex-col gap-2"}>
        <Trans
          i18nKey={"CreateSalesCycle_Benefits_Internal_SetupFeeList"}
          components={{
            ...commonTransComponents,
            item: <li />,
          }}
        />
      </ul>
      <BenefitsItem
        title={t("CreateSalesCycle_Benefits_FirstPayment")}
        price={renderPrice(order.max_total_regular_price, order.order.currency, {noDecimal: true})}
      />
    </div>
  )
}
