import React from "react"
import {useTranslation} from "react-i18next"
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {GenericErrorAlert} from "../../../components/Alert"
import Button from "../../../components/Button"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock"
import {Loading} from "../../../components/Loading"
import {EOrderDirection} from "../../../components/Table/shared"
import {sortDate} from "../../../components/Table/sortFunctions.ts"
import {TabsContainer} from "../../../components/Tabs"
import {
  TCompanyProspectsIteration,
  useCompanyFinishedTableQuery,
  useCompanyWaitingToApproveTableQuery,
  useProspectsSalesCycleQuery,
} from "../../../queries/prospects"
import {ACompanyProspectsSalesCycle} from "../../../services/types.generated"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {useDocumentTitle, useNumParam} from "../../../utils/hooks"
import {useHorizontalSnapScroll} from "../../../utils/useHorizontalSnapScroll"
import {PeriodToggle, TPeriodToggleValue} from "../../Reports/components/PeriodToggle.tsx"
import {RejectContextProvider, TProspectContext, TRejectProspect} from "../shared/context"
import {useActiveAssignment, useActiveIteration} from "../shared/hooks"
import {salesCycleRemoveIterationsWithoutAssignments} from "../shared/utils.ts"
import {AssignmentPackage} from "./components/AssignmentPackage"
import {FinishedTable} from "./components/FinishedTable"
import {NoProspects} from "./components/NoProspects"
import {RejectingBanner} from "./components/RejectingBanner"
import {StatusBanner} from "./components/StatusBanner"
import {WaitingForApprovalTable} from "./components/WaitingForApprovalTable"
import {Section} from "./Section"

export const CompanyProspects: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Prospects"))

  const salesCycleId = useNumParam("salesCycleId")
  const {data, error, refetch} = useProspectsSalesCycleQuery(salesCycleId)

  const salesCycle = React.useMemo(() => {
    if (!data) {
      return null
    }

    return salesCycleRemoveIterationsWithoutAssignments(data)
  }, [data])

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data) {
    return <Loading size={"xl"} />
  }

  return <ProspectsLoaded salesCycle={salesCycle as ACompanyProspectsSalesCycle} />
}

export const ProspectsLoaded: React.FC<{
  salesCycle: ACompanyProspectsSalesCycle
}> = ({salesCycle}) => {
  const {t} = useTranslation()

  const [activeIteration, setActiveIteration] = useActiveIteration(salesCycle)
  const [activeAssignment, setActiveAssignment] = useActiveAssignment(activeIteration)

  const {onScroll, containerClassName, currentItemRef, containerRef, leftButton, rightButton} =
    useHorizontalSnapScroll()

  const [rejectingProspects, setRejectingProspects] = React.useState<TRejectProspect[] | null>(null)
  const rejectContextValue = React.useMemo<TProspectContext>(
    () => ({
      prospects: rejectingProspects,
      setProspects: setRejectingProspects,
      rejectionsLeft: activeAssignment?.rejections_limit ?? 0,
    }),
    [activeAssignment?.rejections_limit, rejectingProspects]
  )

  const iterationOptions = React.useMemo<string[]>(() => {
    return [...salesCycle.sales_cycle_iterations]
      .sort(sortDate<TCompanyProspectsIteration>(iteration => apiDateToJS(iteration.start_date))(EOrderDirection.DESC))
      .map(iteration => iteration.start_date)
  }, [salesCycle.sales_cycle_iterations])

  const handleChangeIteration = React.useCallback(
    (startDate: TPeriodToggleValue) => {
      setActiveIteration(
        salesCycle.sales_cycle_iterations.find(iteration => iteration.start_date === startDate) ?? null
      )
    },
    [salesCycle.sales_cycle_iterations, setActiveIteration]
  )

  if (!activeIteration || !activeAssignment) {
    return <Loading />
  }

  const hasNoProspects =
    activeAssignment.prospects_ready_to_approve_count === 0 && activeAssignment.prospects_approved_count === 0

  return (
    <RejectContextProvider value={rejectContextValue}>
      <LayoutBlock outerClassName={"py-16"} innerClassName={"flex flex-col gap-9"}>
        <div className={"flex items-start justify-between"}>
          <PeriodToggle
            periods={iterationOptions}
            value={activeIteration.start_date}
            onChange={handleChangeIteration}
            noLifetime
          />

          <StatusBanner iteration={activeIteration} />
        </div>

        <TabsContainer className={"min-h-20"}>
          <Button
            disabled={leftButton.disabled}
            onClick={leftButton.onClick}
            fullWidth={false}
            shape={"circular"}
            wrapperClassName={"self-center"}
          >
            <ArrowLeftIcon className={"w-6"} />
          </Button>

          <div
            className={twMerge(["no-scrollbar flex grow scroll-mt-20 gap-10", containerClassName])}
            ref={containerRef}
            onScroll={onScroll}
          >
            {activeIteration?.assignments.map(assignmentPackage => (
              <AssignmentPackage
                key={assignmentPackage.id}
                ref={assignmentPackage.id === activeAssignment.id ? currentItemRef : undefined}
                onClick={() => setActiveAssignment(assignmentPackage)}
                active={assignmentPackage.id === activeAssignment.id}
                assignmentPackage={assignmentPackage}
              />
            ))}
          </div>

          <Button
            disabled={rightButton.disabled}
            onClick={rightButton.onClick}
            fullWidth={false}
            shape={"circular"}
            wrapperClassName={"self-center"}
          >
            <ArrowRightIcon className={"w-6"} />
          </Button>
        </TabsContainer>

        <RejectingBanner assignment={activeAssignment} />

        {activeAssignment.prospects_ready_to_approve_count > 0 && (
          <Section
            isApproveButtonVisible
            title={
              activeAssignment.prospects_approved_count > 0 ? t("Prospects_WaitingForApprovalCompany_Title") : undefined
            }
            salesCycleId={salesCycle.id}
            assignmentId={activeAssignment.id}
            TableComponent={WaitingForApprovalTable}
            listQuery={useCompanyWaitingToApproveTableQuery}
            paginationKey={"prospects company waiting for approval"}
          />
        )}
        {activeAssignment.prospects_approved_count > 0 && (
          <Section
            title={t("Prospects_FinishedCompany_Title", {
              current: activeAssignment.prospects_approved_count,
              mandatory: activeAssignment.prospects_mandatory_count,
            })}
            salesCycleId={salesCycle.id}
            assignmentId={activeAssignment.id}
            listQuery={useCompanyFinishedTableQuery}
            TableComponent={FinishedTable}
            defaultOrder={{column: "priority", direction: EOrderDirection.DESC}}
            isSubmittedIndicatorVisible={activeAssignment.prospects_ready_to_approve_count === 0}
            paginationKey={"prospects company finished"}
          />
        )}

        {hasNoProspects && <NoProspects />}
      </LayoutBlock>
    </RejectContextProvider>
  )
}
