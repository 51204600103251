import React from "react"
import {twMerge} from "tailwind-merge"

import {useLoadPicture} from "../utils/hooks.tsx"
import {Loading} from "./Loading.tsx"
import {PlaceholderAvatar} from "./ProfileImage.tsx"

export const LoadableImage: React.FC<React.HTMLProps<HTMLImageElement>> = props => {
  const {isLoading, isError} = useLoadPicture(props.src ?? "")

  if (isLoading) {
    return (
      <div
        className={twMerge("inline-flex animate-fade-in items-center justify-center bg-cr-grey-15", props.className)}
      >
        <Loading size={"sm"} delayShow={false} fullSpace={false} color={"white"} />
      </div>
    )
  }

  if (isError) {
    return <PlaceholderAvatar {...props} />
  }

  return <img {...props} />
}
