import React from "react"

import {InputBase, TInputSharedProps} from "./fields/Input"
import Button from "./Button.tsx"

type TInputActionProps = TInputSharedProps & {
  actionLabel: string
  onAction: (value: string) => void
  initialValue?: string
  disableClearOnSubmit?: boolean
  allowSubmitEmpty?: boolean
}

export const InputAction: React.FC<TInputActionProps> = ({
  actionLabel,
  onAction,
  disableClearOnSubmit,
  allowSubmitEmpty,
  initialValue = "",
  ...props
}) => {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleSubmit = () => {
    const trimmed = value.trim()

    if (trimmed === "" && !allowSubmitEmpty) {
      return
    }

    onAction(trimmed)
    !disableClearOnSubmit && setValue("")
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      handleSubmit()
    } else if (e.key === " ") {
      e.stopPropagation()
    }
  }

  return (
    <InputBase {...props} onChange={e => setValue(e.target.value)} value={value} onKeyDown={handleKeyDown}>
      <Button
        disabled={props.disabled || props.readOnly}
        variant={"outlined"}
        size={"xs"}
        className={"m-2 w-auto px-2 py-1"}
        onClick={handleSubmit}
      >
        {actionLabel}
      </Button>
    </InputBase>
  )
}
