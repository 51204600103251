import {FC, ReactNode} from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"
import {twMerge} from "tailwind-merge"

import {ASalesCycleIterationStatuses, ASalesCycleStatuses} from "../../services/types.generated"
import {enumTranslKey} from "../../utils/i18n"
import {Badge} from "../Badge"

type TIterationStatus = ASalesCycleStatuses | ASalesCycleIterationStatuses | "awaiting_payment"

export const SellingsBlockCard: FC<{detailLink?: string; children: ReactNode}> = ({detailLink, children}) => {
  const navigate = useNavigate()

  return (
    <div
      data-testid={"salesCycle"}
      className={twMerge(
        "card-shadow mb-12 p-0 last-of-type:mb-0",
        detailLink && "cursor-pointer transition-all hover:border-cr-blue"
      )}
      onClick={detailLink ? () => navigate(detailLink) : undefined}
    >
      {children}
    </div>
  )
}

export const SellingsBlockBadge: FC<{isActive: boolean; status?: TIterationStatus}> = ({isActive, status}) => {
  const {t} = useTranslation()

  if (status === ASalesCycleStatuses.Draft) {
    return <Badge color={"grey"}>{t(enumTranslKey("SellingBlockStatus", "Draft"))}</Badge>
  }

  if (status === ASalesCycleStatuses.Ongoing) {
    return <Badge color={"green"}>{t(enumTranslKey("SellingBlockStatus", "Ongoing"))}</Badge>
  }

  if (status === ASalesCycleIterationStatuses.Matching) {
    return <Badge color={"grey"}>{t(enumTranslKey("SellingBlockStatus", "Matching"))}</Badge>
  }

  if (status === ASalesCycleIterationStatuses.Acceptance) {
    return <Badge color={"grey"}>{t(enumTranslKey("SellingBlockStatus", "Acceptance"))}</Badge>
  }

  if (status === ASalesCycleStatuses.Created || status === ASalesCycleIterationStatuses.Created) {
    return <Badge color={"grey"}>{t(enumTranslKey("SellingBlockStatus", "Created"))}</Badge>
  }

  if (status === ASalesCycleStatuses.Finished || status === ASalesCycleIterationStatuses.Finished) {
    return <Badge color={"light-blue"}>{t(enumTranslKey("SellingBlockStatus", "Finished"))}</Badge>
  }

  if (status === ASalesCycleIterationStatuses.ReadyToStart) {
    return <Badge color={"grey"}>{t(enumTranslKey("SellingBlockStatus", "Ready to start"))}</Badge>
  }

  if (status === "awaiting_payment") {
    return <Badge color={"yellow"}>{t(enumTranslKey("SellingBlockStatus", "Awaiting payment"))}</Badge>
  }

  if (status === ASalesCycleIterationStatuses.Declined) {
    return <Badge color={"red"}>{t(enumTranslKey("SellingBlockStatus", "Declined"))}</Badge>
  }

  if (isActive || status === ASalesCycleIterationStatuses.Active) {
    return <Badge color={"cyan"}>{t(enumTranslKey("SellingBlockStatus", "Active"))}</Badge>
  }

  return null
}
