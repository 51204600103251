import React from "react"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"

import {Alert} from "../../components/Alert.tsx"
import {ButtonLoading} from "../../components/Button.tsx"
import {useReportsSalesCycleQuery, useSendReportMutation} from "../../queries/reports.ts"
import requestError from "../../services/requestError.tsx"
import {useCurrentSelectionState} from "./hooks.ts"

export const SuperadminSentAlert: React.FC = () => {
  const {t} = useTranslation()

  const {
    value: {salesCycleId, iterationId},
  } = useCurrentSelectionState()

  const {data} = useReportsSalesCycleQuery(salesCycleId)
  const iteration = data?.sales_cycle_iterations.find(i => i.id === iterationId)

  const sendReportMutation = useSendReportMutation()

  const handleSendReport = React.useCallback(async () => {
    if (iterationId == null) {
      return false
    }

    try {
      await sendReportMutation.mutateAsync({salesCycleId, iterationId})
      toast.success(t("Reports_SuperadminAlert_SuccessToast"))
    } catch (e) {
      requestError(e)
      return false
    }
  }, [iterationId, salesCycleId, sendReportMutation, t])

  if (!iteration || !("report_sent_by_super_admin" in iteration) || !iteration.report_sent_by_super_admin) {
    return null
  }

  return (
    <Alert variant={"warning"}>
      <div className={"flex items-center gap-8"}>
        <div className={"grow"}>{t("Reports_SuperadminAlert_Text")}</div>
        <ButtonLoading onClick={handleSendReport} shape={"round"} size={"xs"}>
          {t("Reports_SuperadminAlert_Button")}
        </ButtonLoading>
      </div>
    </Alert>
  )
}
