import {useQuery} from "@tanstack/react-query"

import api, {queryKey} from "../services"
import {useUserSettingsOrLogout} from "./user.ts"

export const useCompanyDashboardQuery = () => {
  const {user} = useUserSettingsOrLogout()

  return useQuery({
    queryKey: queryKey.companyDashboard(user.id),
    queryFn: async () => (await api.companyUsers.dashboardDetail(user.id)).data.dashboard,
    staleTime: 0,
  })
}

export const useCompanyDashboardActiveSalesCyclesQuery = () => {
  const {user} = useUserSettingsOrLogout()

  return useQuery({
    queryKey: queryKey.companyDashboardActive(user.id),
    queryFn: async () => (await api.companyUsers.dashboardActiveSalesCyclesDetail(user.id)).data.sales_cycles,
    staleTime: 0,
  })
}
export const useCompanyDashboardDraftSalesCyclesQuery = () => {
  const {user} = useUserSettingsOrLogout()

  return useQuery({
    queryKey: queryKey.companyDashboardDraft(user.id),
    queryFn: async () => (await api.companyUsers.dashboardDraftedSalesCyclesDetail(user.id)).data.sales_cycles,
    staleTime: 0,
  })
}
export const useCompanyDashboardFinishedSalesCyclesQuery = () => {
  const {user} = useUserSettingsOrLogout()

  return useQuery({
    queryKey: queryKey.companyDashboardFinished(user.id),
    queryFn: async () => (await api.companyUsers.dashboardFinishedSalesCyclesDetail(user.id)).data.sales_cycles,
    staleTime: 0,
  })
}

export const useCompanyDashboardPausedCyclesQuery = () => {
  const {user} = useUserSettingsOrLogout()

  return useQuery({
    queryKey: queryKey.companyDashboardPaused(user.id),
    queryFn: async () => (await api.companyUsers.dashboardPausedSalesCyclesDetail(user.id)).data.sales_cycles,
    staleTime: 0,
  })
}
