import React from "react"

import {TSaasCompanyProspectsAssignment} from "../../../../queries/prospects"
import {getFullName} from "../../../../utils"
import {AssignmentDisplay, TAssignmentVariant} from "../../shared/AssignmentDisplay"

const getAssignmentPackageVariant = (assignmentPackage: TSaasCompanyProspectsAssignment): TAssignmentVariant => {
  if (assignmentPackage.prospects_finished_count === 0) {
    return "initial"
  }

  if (assignmentPackage.prospects_approved_count >= assignmentPackage.prospects_mandatory_count) {
    return "finished"
  }

  return "in_progress"
}

export const AssignmentPackage = React.forwardRef<
  HTMLAnchorElement,
  {
    assignmentPackage: TSaasCompanyProspectsAssignment
    active: boolean
    onClick: () => void
  }
>(({assignmentPackage, active, onClick}, ref) => {
  return (
    <AssignmentDisplay
      ref={ref}
      onClick={onClick}
      active={active}
      variant={getAssignmentPackageVariant(assignmentPackage)}
      name={getFullName(assignmentPackage.sales_person)}
      min={assignmentPackage.prospects_approved_count}
      max={assignmentPackage.prospects_mandatory_count}
      image={assignmentPackage.sales_person?.profile_picture_thumbnail_url ?? undefined}
    />
  )
})
AssignmentPackage.displayName = "AssignmentPackage"
