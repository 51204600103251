import React from "react"
import {useTranslation} from "react-i18next"

export const StepCounter: React.FC<{total: number; step: number}> = props => {
  const {t} = useTranslation()

  const id = React.useId()

  const total = Math.max(1, props.total)
  const step = Math.min(Math.max(1, props.step), total)

  return (
    <div className={"inline-flex flex-col items-center gap-2"}>
      <span id={id} className={"text-xs"}>
        {t("StepCounter_StepCount", {step, total})}
      </span>
      <div className={"flex gap-2"} role={"progressbar"} aria-labelledby={id}>
        {Array.from({length: step - 1}, (_, n) => n).map(n => (
          <div key={`active-${n}`} data-testid={"past-progress"} className={"size-2 rounded-full bg-cr-blue"} />
        ))}

        <div data-testid={"current-progress"} className={"h-2 w-8 rounded-full bg-cr-blue"} />

        {Array.from({length: total - step}, (_, n) => n).map(n => (
          <div
            key={`inactive-${n}`}
            data-testid={"future-progress"}
            className={"size-2 rounded-full bg-cr-blue-mid-light"}
          />
        ))}
      </div>
    </div>
  )
}
