import {useTranslation} from "react-i18next"

import {TextareaField} from "../../components/fields/Textarea"
import {stepElevatorPitch} from "./stepsConfig.ts"

const ElevatorPitch = () => {
  const {t} = useTranslation()

  return (
    <TextareaField
      rows={4}
      label={stepElevatorPitch.name}
      legend={t("SalesStrategy_Describe your product in one sentence")}
      placeholder={t(
        "SalesStrategy_e.g. We are CloseRocket, we connect sales talents with companies in order to help them grow."
      )}
      name={"elevator_pitch"}
    />
  )
}

export default ElevatorPitch
